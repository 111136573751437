import usePageTitle from "../../../hooks/use-page-title"
import DateTime from "../../../components/common/DateTime";
import { Dropdown } from "react-bootstrap";
import { MdSearch } from "react-icons/md";
import { useMemo, useRef, useState } from "react";
import { usePortalSettingSet } from "../../../context/portal-context";
import ServerSideSubmissionDataTable from "../../../components/common/ServerSideSubmissionDataTable";
import ApiSubmission from "../../../modules/api/submission";
import { HiOutlineEye } from "react-icons/hi";
import RoleFilterSelector from "../../../components/portal/RoleFilterSelector";
import { CONST_SELECT_SUBMISSION_TYPES, SUBMISSION_WL } from "../../../config/constants";
import PopupSubmissionList, { TPopupSubmissionListRef } from "../../../components/popup/PopupSubmissionList";

export default function PageSubmissionAdmin() {
  const title = "Manage Submission";
  usePageTitle(title);
  usePortalSettingSet("label_header", title);

  const [submissionType, setSubmissionType] = useState(useMemo(() => SUBMISSION_WL, []))
  const [filterBy, setFilterBy] = useState("name");
  const [refresh, setRefresh] = useState(false);
  const [search, setSearch] = useState("");
  const popupSubmissionList = useRef<TPopupSubmissionListRef>();

  return <main className="form-generic content-generic content-generic--fluid">

    <h1 className="fs-title hide-mobile-down">{title}</h1>

    <div className="cg__actions">
      <div className="cg__action-buttons">
        <button className="btn btn-outline-primary"
          onClick={() => setRefresh(i => !i)}
        >
          Refresh
        </button>
      </div>
    </div>

    <div className="cg__actions">
      <div className="cg__action-buttons">
        <RoleFilterSelector default={submissionType} options={CONST_SELECT_SUBMISSION_TYPES} onChange={(v) => {
          setSubmissionType(v);
        }} />
      </div>
      <div className="cg__action-advance-search">
        <div className="cg__action-dropdown me-2">
          <select defaultValue={filterBy} onChange={(e) => {
            setFilterBy(e.target.value);
          }}>
            <option value="name">Name</option>
            <option value="email">Email</option>
            {/* <option value="group">Group</option> */}
          </select>
        </div>
        <div className="cg__action-search">
          <span>
            <MdSearch/>
          </span>
          <input type="text" placeholder="search keywords" onChange={(e) => setSearch(e.target.value)} />
        </div>
      </div>
    </div>

    <PopupSubmissionList ref={popupSubmissionList} />

    <ServerSideSubmissionDataTable
      refresh={refresh}
      api={ApiSubmission.pagingSearch}
      submissionType={submissionType}
      filterBy={filterBy}
      search={search}
      columns={[
        {
          id: "name",
          name: "Name",
          cell: (row) => row.name
        },
        {
          id: "email",
          name: "Email",
          cell: (row) => row.email
        },
        {
          id: "group",
          name: "Group",
          cell: (row) => row?.group
            ? row?.group?.groupName
            : "-"
        },
        {
          id: "latestSubmission",
          name: "Latest Modified Submission",
          cell: (row) => (() => {
            let exists = false;
            let from = "";
            let to = "";
            let submissions = row.submissions;

            if (Array.isArray(submissions) && submissions.length > 0) {
              exists = true;
              from = submissions[0].from;
              to = submissions[0].to;
            }

            return <>
              {exists ? <>
                <DateTime date={from} format="DD MMM" />
                {" "}-{" "}
                <DateTime date={to} format="DD MMM YYYY" />
              </> : "Unavailable"}
            </>;
          })()
        },
        {
          name: "Action",
          button: true,
          cell: (row) => <div>
            <Dropdown align={"end"} drop={"down"}>
              <Dropdown.Toggle variant="primary" id="dropdown-basic" className="btn-sm">
                Action
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <button className="dropdown-item btn-danger" onClick={() => {
                  popupSubmissionList.current.open(row, submissionType);
                }}>
                  <HiOutlineEye/> {" "} View Submissions
                </button>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        }
      ]}
    />

  </main>;
}
