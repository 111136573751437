import { useState, useEffect } from "react";
import classNames from "classnames";
import { MdSave } from "react-icons/md";
import { ROUTES } from "../../../routes";
import Utils from "../../../utils/utils";
import { HiX, HiArrowLeft } from "react-icons/hi";
import { Link, useNavigate } from "react-router-dom";
import FGField from "../../../components/dumb/FGField";
import Notifier from "../../../modules/notifier/notifier";
import Multilingual from "../../../modules/multilingual/multilingual";
import {
  CRON_JOB_TYPE_HOURLY,
  CRON_JOB_TYPE_DAILY,
  CONST_SELECT_CRON_JOB_TYPE,
} from "../../../config/constants";
import ApiScheduleReminder, {
  JobPayload,
} from "../../../modules/api/schedule-reminder";
import FGDropdownApi from "../../../components/form/FGDropdownApi";
import moment from "moment";

export default function PageScheduleReminderAdd() {
  const [sending, setSending] = useState(false);
  const [contentChanged, setContentChanged] = useState(false);
  const [availableTasks, setAvailableTasks] = useState([]);
  const [formData, setFormData] = useState({
    taskName: "",
    typeCronJob: CRON_JOB_TYPE_HOURLY,
    stepSize: "1",
    startTime: "00:00",
    startDateTime: null,
    beforeDays: "0",
    description: "",
  });
  const navigate = useNavigate();

  useEffect(() => {
    getAvailableTasks();
  }, []);

  useEffect(() => {
    setStartDateTime(formData.typeCronJob, formData.startTime);
  }, [formData.typeCronJob, formData.startTime]);

  const getAvailableTasks = async () => {
    const { error, data } = await ApiScheduleReminder.getUnregisteredJobs();
    if (error) {
      Notifier.error(error);
    } else {
      const availableTasks = data.map((task) => {
        return {
          label: task,
          value: task,
        };
      });
      setAvailableTasks(availableTasks);
    }
  };

  const setStartDateTime = (type: string, startAt: string) => {
    let datetime = new Date(
      `${moment(new Date()).format("YYYY-MM-DD").toString()} ${startAt}:00`
    );
    const now = new Date();
    if (datetime < now && type === CRON_JOB_TYPE_HOURLY) {
      datetime = new Date(datetime.getTime() + 86400000);
    } else if (type === CRON_JOB_TYPE_DAILY) {
      datetime = new Date(datetime.getTime() + 86400000);
    }
    setFormData((formData) => ({
      ...formData,
      startDateTime: datetime,
    }));
  };

  const handleOnChange = (e: any) => {
    if (e.target.name === "startTime" && !e.target.value) {
      return;
    }
    if (
      e.target.name === "stepSize" &&
      (parseInt(e.target.value) < 1 || parseInt(e.target.value) > 100)
    ) {
      return;
    }
    if (
      e.target.name === "beforeDays" &&
      (parseInt(e.target.value) < 0 || parseInt(e.target.value) > 100)
    ) {
      return;
    }
    setContentChanged(true);
    setFormData((d) => ({
      ...d,
      [e.target.name]: e.target.value,
    }));
  };

  const validateBeforeSubmit = () => {
    if (!formData.taskName) {
      Notifier.error("Please select name");
      return false;
    }
    return true;
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    const valid = validateBeforeSubmit();
    if (!valid) {
      return;
    }
    const payload: JobPayload = JSON.parse(
      JSON.stringify({ ...formData, startTime: undefined })
    );
    setSending(true);
    const { success, error } = await ApiScheduleReminder.postJob(payload);
    if (!success) {
      Notifier.error(error);
    } else {
      Notifier.success("Task has been created successfully");
      await Utils.sleep(2000);
      navigate(ROUTES.PORTAL_SCHEDULE_REMINDER);
    }
    setSending(false);
  };

  return (
    <main className="form-generic content-generic">
      <h1 className="fs-title hide-mobile-down">Add New Task</h1>
      <div className="cg__actions hide-mobile-down">
        <Link
          to={ROUTES.PORTAL_SCHEDULE_REMINDER}
          className="btn btn-primary"
          onClick={(e) => {
            if (
              contentChanged &&
              !window.confirm(Multilingual.get("CONFIRM_ACTION_CANT_BE_UNDONE"))
            ) {
              e.preventDefault();
            }
          }}
        >
          <HiArrowLeft /> Back
        </Link>
      </div>
      <form onSubmit={handleSubmit}>
        <FGDropdownApi
          variant="normal"
          label="Task Name"
          name="taskName"
          defaultValue={formData.taskName}
          options={availableTasks}
          onChange={handleOnChange}
        />
        <FGDropdownApi
          variant="normal"
          label="Type Cron Job"
          name="typeCronJob"
          defaultValue={formData.typeCronJob}
          options={CONST_SELECT_CRON_JOB_TYPE}
          onChange={handleOnChange}
        />
        <FGField
          name={"stepSize"}
          label={"Step Size"}
          comp="FGInput"
          className={classNames(["fg__input", { "fg__control--normal": true }])}
        >
          <input
            min={1}
            max={100}
            id="stepSize"
            name="stepSize"
            type="number"
            required
            value={formData.stepSize}
            onChange={handleOnChange}
          />
        </FGField>
        <FGField
          name={"startTime"}
          label={`Start Time ( ${moment(formData.startDateTime).format(
            "MM/DD/YYYY hh:mm A"
          )} )`}
          comp="FGInput"
          className={classNames(["fg__input", { "fg__control--normal": true }])}
        >
          <input
            id="startTime"
            name="startTime"
            type="time"
            required
            value={formData.startTime}
            onChange={handleOnChange}
          />
        </FGField>
        <FGField
          name={"beforeDays"}
          label={`Start before ${formData.beforeDays} day(s)`}
          comp="FGInput"
          className={classNames(["fg__input", { "fg__control--normal": true }])}
        >
          <input
            type="number"
            id="beforeDays"
            name="beforeDays"
            required
            min={0}
            value={formData.beforeDays}
            onChange={handleOnChange}
          />
        </FGField>
        <div>
          <button type="submit" className="btn btn-success" disabled={sending}>
            <MdSave /> Add
          </button>{" "}
          <Link
            to={ROUTES.PORTAL_SCHEDULE_REMINDER}
            className="btn btn-primary"
            onClick={(e) => {
              if (
                contentChanged &&
                !window.confirm(
                  Multilingual.get("CONFIRM_ACTION_CANT_BE_UNDONE")
                )
              ) {
                e.preventDefault();
              }
            }}
          >
            <HiX /> Cancel
          </Link>
        </div>
      </form>
    </main>
  );
}
