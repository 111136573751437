import { useEffect } from "react";
import Notifier from "../modules/notifier/notifier";
import AppUser from "../modules/app-user/app-user";
import Utils from "../utils/utils";
import { useNavigate } from "react-router-dom";
import { throttle } from "lodash";
import useSessionStore from "./use-session-store";

export default function useAutoLogout(maxAgeInSeconds = 3600) {
  const navigate = useNavigate();
  const logStore = useSessionStore("logout");

  useEffect(() => {
    let checkTimer: any;
    const $window = $(window);
    const resetTime = throttle(() => {
      logStore.set("exp", Date.now() + (maxAgeInSeconds * 1000));
    }, 1000, { trailing: true });

    function onExpired() {
      clearInterval(checkTimer);
      Notifier.error("Automatically logged out due to inactivity. Please login again.");
      AppUser.logout().then(() => Utils.sleep(1000))
        .then(() => navigate("/auth/login"));
    }

    $window.on({
      "blur": resetTime,
      "focus": resetTime,
      "mousemove": resetTime,
      "wheel": resetTime,
      "keypress": resetTime,
      "click": resetTime
    });

    resetTime();

    checkTimer = setInterval(() => {
      const now = Date.now();
      const exp = logStore.get("exp", now);
      if (exp <= now) onExpired();
    }, 1000);

    return () => {
      $window.off("blur", resetTime);
      $window.off("focus", resetTime);
      $window.off("mousemove", resetTime);
      $window.off("keypress", resetTime);
      $window.off("click", resetTime);
      $window.off("wheel", resetTime);
      clearInterval(checkTimer);
    };
  }, []);
}
