import React, { Dispatch, SetStateAction, useContext, useMemo } from "react";
import { TMessageItem } from "../../modules/api/message";
import ModuleDate from "../../modules/date";
import { LABEL_ANONYMOUS_USER } from "../../config/constants";
import AppUserContext from "../../context/app-user-context";
import { Link } from "react-router-dom";
import { HiPencil } from "react-icons/hi";
import { nl2br } from "../../utils/jquery-helper";

export type TFeedbackItemsProps = {
  messages: TMessageItem[];
  refreshMessages: Dispatch<SetStateAction<boolean>>;
  labelUnavailable?: string;
  hideTraitLabel?: boolean;
  onClickEdit?: (...params: any) => any;
};

FeedbackItems.defaultProps = {
  labelUnavailable: "Feedback not available.",
  onClickEdit: () => {}
}

export default function FeedbackItems(props: TFeedbackItemsProps) {
  const user = useContext(AppUserContext);
  const messages = useMemo(() => {
    return props.messages.map(message => <React.Fragment key={message._id}>
      <div className="feedback-item">
        {message.anonymous && <h1>{LABEL_ANONYMOUS_USER}</h1>}
        {!message.anonymous && <h1>{message.senderName}</h1>}
        <div className="feedback-item__sub-title">
          {user && user.referenceUserId === message.senderId && <>
            <Link to={""} onClick={(e) => {
              e.preventDefault();
              props.onClickEdit(e);
            }}><HiPencil/> Edit</Link> | {" "}
          </>}
          {!props.hideTraitLabel && <>{message?.trait?.name ?? "Unknown trait"} | </>}{ModuleDate.getTitleFormat(message.createdDate)}
          {message.status === "Draft" && <> | <span style={{color: `red`}} className="draft-message">DRAFT</span></>}
        </div>
        <div className="feedback-item__content" dangerouslySetInnerHTML={{ __html: nl2br(message?.message) }}></div>
      </div>
    </React.Fragment>)
  }, [props.messages]);

  return <div className="feedback-items">
    {props.messages.length === 0 && <p className="alert alert-not-found">
      {props.labelUnavailable}
    </p>}

    {messages}
  </div>;
}
