import { useEffect, useMemo, useState } from "react";

const calculate = (() => {
  let $window = $(window);

  return () => {
    if ($window.length === 0) {
      $window = $(window);
    }

    const width = $window.width();
    const height = $window.height();
    const isMobile = width <= 768.98;
    const isTablet = width >= 768 && width <= 1199.98;
    const isDesktop = width >= 1200 && width <= 1439.98;
    const isDesktopLarge = width >= 1440;

    return {
      isMobile,
      isTablet,
      isDesktop,
      isDesktopLarge,
      isMobilePortrait: isMobile && width <= 479.98,
      isTabletPortrait: isTablet && width <= 991.98,
      width,
      height
    };
  };
})();

export type TViewport = ReturnType<typeof calculate>;

export default function useViewport() {
  const initialValue = useMemo(() => calculate(), []);
  const [viewport, setViewport] = useState<TViewport>(initialValue);

  useEffect(() => {
    let timers;
    const $window = $(window);
    const throttle = () => {
      clearTimeout(timers);
      timers = setTimeout(() => {
        setViewport(calculate());
      }, 150);
    };

    $window.on("resize", throttle);
    return () => { $window.off("resize", throttle) };
  }, []);

  return viewport;
}
