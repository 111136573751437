import { useEffect } from "react";
import { isDebugMode, isLocalhost } from "../config/config";

const ENABLED = isDebugMode || isLocalhost;
const CLASS_ACTIVE = 'viewport-state--active';
const KEY_STORE = 'viewport-state-toggle';

const $window = $(window);

function init() {
  const $root = $('.js-viewport-state');

  if ($root.data('init')) return;

  const $check = $root.find('input[type=checkbox]');
  const $indicator = $('<div className="viewport-indicator">0px</div>');

  function checkState() {
    const isChecked = $check.prop('checked');

    if (isChecked) {
      $root.addClass(CLASS_ACTIVE);
    } else {
      $root.removeClass(CLASS_ACTIVE);
    }
  }

  function addViewportWidthIndicator() {
    $indicator.appendTo($root);
  }

  function onResizeHandler() {
    $indicator.html($window.width() + 'px');
  }

  function attachEvents() {
    $check.on('change', function () {
      const isChecked = $check.prop('checked');

      if (isChecked) {
        localStorage.setItem(KEY_STORE, '1');
      } else {
        localStorage.setItem(KEY_STORE, '0');
      }

      checkState();
    });
    $window.on('resize', onResizeHandler);
  }

  // load checked state from storage first
  $check.prop('checked', (localStorage.getItem(KEY_STORE) === '1'));

  // check initial (on load) state
  checkState();
  onResizeHandler();
  addViewportWidthIndicator();
  attachEvents();

  $root.data('init', true);
}

export default function ViewportInfo() {
  useEffect(() => {
    if (!ENABLED) return;
    setTimeout(() => init(), 100);
  }, []);

  return <>
    {ENABLED && <div className="viewport-state js-viewport-state">
      <input type="checkbox" />
      <span className="v-desktop-large-inline">desktop-large</span>
      <span className="v-desktop-inline">desktop</span>
      <span className="v-tablet-inline">tablet</span>
      <span className="v-tablet-portrait-inline">tablet-portrait</span>
      <span className="v-mobile-inline">mobile</span>
      <span className="v-mobile-portrait-inline">mobile-portrait</span>
    </div>}
  </>;
}
