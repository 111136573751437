import moment from "moment-timezone";
import { TIMEZONE } from "../config/constants";

// e.g. Jan 1, 2013
const FORMAT_DEFAULT = "MMM D, yyyy";

function startOfDay(date: number | string | Date) {
  return moment.tz(date, TIMEZONE).startOf("day").toISOString()
}

function endOfDay(date: number | string | Date) {
  return moment.tz(date, TIMEZONE).endOf("day").toISOString();
}

function getDefaultFormat(date): string {
  try {
    const result = moment(date).format(FORMAT_DEFAULT);
    if (typeof result !== "string") throw new Error();
    return result;
  } catch (ignore) {
    return "";
  }
}

function getTitleFormat(date): string {
  try {
    const result = moment(date).format("MMMM D, yyyy");
    if (typeof result !== "string") throw new Error();
    return result;
  } catch (ignore) {
    return "";
  }
}

function isBeforeSubmissionDue(due) {
  if (!due) {
    return false;
  }

  const dueDate = new Date(due);
  const now = new Date();

  return dueDate > now;
}

function formatSubmissionDateRange(fromInput, toInput) {
  const from = moment(fromInput);
  const to = moment(toInput);

  return `${from.format(FORMAT_DEFAULT)} - ${to.format(FORMAT_DEFAULT)}`;
}

const ModuleDate = {
  startOfDay,
  endOfDay,
  getDefaultFormat,
  getTitleFormat,
  isBeforeSubmissionDue,
  formatSubmissionDateRange
};

export default ModuleDate;
