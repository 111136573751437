import React, { Suspense, useState } from "react";
import FGField from "../dumb/FGField";
import classNames from "classnames";

const ReactDatePicker = React.lazy(() => import("../lazy/ReactDatePicker"));

/** @see `<FGField/>` */
export default function FGDatePicker(props: JSX.IntrinsicElements["input"] & {
  label: string|JSX.Element|JSX.Element[];
  desc?: string|JSX.Element|JSX.Element[];
  variant?: "normal" | "inset";
}) {

  return (
    <FGField name={props.name} label={props.label} desc={props.desc}
      className={classNames([
        {"fg__control--normal": props.variant === "normal"}
      ])}
    >
      <Suspense fallback={<div className="fst-italic">Loading editor...</div>}>
        <ReactDatePicker />
      </Suspense>
    </FGField>
  )
}
