import axios from "axios";
import { apiUrl, getAuthHeader } from "./base";
import ROLE from "../role";

async function login(username: string, password: string): Promise<{
  status: true;
  message: string;
  data: Partial<TUser>;
} | {
  status: false;
  message: string;
}> {
  try {
    const reqAuth = await axios.post(apiUrl("/apiauth/login"), {
      userName: username,
      password: password
    });

    const { data } = reqAuth;

    if (data.result !== "OK") {
      throw new Error(data.message ?? "Unable to login due to unknown error");
    }

    if (data.data.role === "Supper Administrator") {
      data.data.role = ROLE.ADMIN;
    }

    return {
      status: true,
      message: "Successfully logged-in",
      data: data.data
    };
  } catch (err) {
    const resMsg = err?.response?.data?.message;

    return {
      status: false,
      message: typeof resMsg === "string" ? resMsg : err.message
    };
  }
}

async function refreshToken(authToken: string, refreshToken: string): Promise<{
  status: true;
  message: string;
  data: TUser;
} | {
  status: false;
  networkError: boolean;
  message: string;
}> {
  try {
    const reqAuth = await axios.get(apiUrl(`/apiauth/refreshToken?refreshToken=${refreshToken}`), {
      headers: {
        Authorization: `Bearer ${authToken}`
      }
    });

    const { data } = reqAuth;

    if (data.result !== "OK") {
      throw new Error(data.message ?? "Unable to refresh token due to unknown error");
    }

    return {
      status: true,
      message: "New token issued",
      data: data.data
    };
  } catch (err) {
    let networkError = false;

    switch (err?.code) {
      case "ERR_NETWORK":
      case "ERR_BAD_RESPONSE":
        networkError = true;
    }

    return {
      status: false,
      networkError,
      message: err.message
    };
  }
}

const updatePassword = async (formData: any) => {
  try {
    const reqAuth = await axios.post(apiUrl("/apiauth/updatepassword"), formData, await getAuthHeader());

    const { data } = reqAuth;

    if (data.result !== "OK") {
      throw new Error(data.message ?? "Unable to update password due to unknown error");
    }

    return {
      status: true,
      message: data.message
    };
  } catch (err) {
    const resMsg = err?.response?.data?.message;

    return {
      status: false,
      message: typeof resMsg === "string" ? resMsg : err.message
    };
  }
};

const forgotPassword = async (email: string) => {
  try {
    const payload = {
      email: email,
      resetPasswordUrl: `${process.env.REACT_APP_WEB_URL}/auth/reset-password`,
    };
    const reqAuth = await axios.post(
      apiUrl("/apiauth/forgotpassword"),
      payload
    );

    const { data } = reqAuth;

    if (data.result !== "OK") {
      throw new Error(
        data.message ??
          "Unable to send reset password request due to unknown error"
      );
    }

    return {
      status: true,
      message: data.message,
    };
  } catch (err) {
    const resMsg = err?.response?.data?.message;

    return {
      status: false,
      message: typeof resMsg === "string" ? resMsg : err.message,
    };
  }
};

const resetPassword = async (resetPasswordToken: string, payload: any) => {
  try {
    const authHeader = {
      headers: {
        Authorization: `Bearer ${resetPasswordToken}`
      }
    }
    const reqAuth = await axios.post(apiUrl("/apiauth/resetpassword"), payload, authHeader);

    const { data } = reqAuth;

    if (data.result !== "OK") {
      throw new Error(data.message ?? "Unable to reset password due to unknown error");
    }

    return {
      status: true,
      message: data.message
    };
  } catch (err) {
    const resMsg = err?.response?.data?.message;

    return {
      status: false,
      message: typeof resMsg === "string" ? resMsg : err.message
    };
  }
};

const setupPassword = async (setupPasswordToken: string, payload: any) => {
  try {
    const authHeader = {
      headers: {
        Authorization: `Bearer ${setupPasswordToken}`
      }
    }
    const reqAuth = await axios.post(apiUrl("/apiauth/setuppassword"), payload, authHeader);

    const { data } = reqAuth;

    if (data.result !== "OK") {
      throw new Error(data.message ?? "Unable to setup password due to unknown error");
    }

    return {
      status: true,
      message: data.message
    };
  } catch (err) {
    const resMsg = err?.response?.data?.message;

    return {
      status: false,
      message: typeof resMsg === "string" ? resMsg : err.message
    };
  }
};

const ApiAuth = {
  login,
  refreshToken,
  updatePassword,
  resetPassword,
  forgotPassword,
  setupPassword
};

export default ApiAuth;
