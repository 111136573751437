import { useEffect, useState } from "react";
import FGField from "../dumb/FGField";
import Select from "react-select";
import classNames from "classnames";

type TOptionItem = {
  label: string;
  value: any;
  disabled?: boolean;
};

type TFGDropdownApiProps = JSX.IntrinsicElements["select"] & {
  label: string|JSX.Element|JSX.Element[];
  desc?: string|JSX.Element|JSX.Element[];
  options?: TOptionItem[];
  defaultValue?: string;
  disabled?: boolean;
  onChange: (value: string) => void;
  /** defaults to "inset" */
  variant?: "normal" | "inset";

  // additional stuff
  api?: () => Promise<any[] | TOptionItem[]>;
  mapper?: (data: any[]) => TOptionItem[];
  defaultValueMapper?: (options: TOptionItem[], defaultValue: any) => TOptionItem[];
  onChangeMapper?: (selectedOptions: TOptionItem[], fieldName: string) => any;
};

FGDropdownApi.defaultProps = {
  selectStyle: "inset",
  defaultValueMapper: (options, defaultValue) => {
    const separated = typeof defaultValue === "string" ? defaultValue.split(/\,/) : [];

    if (
      separated.length === 0 ||
      (typeof defaultValue === "string" && defaultValue === "")
    ) {
      return [];
    }

    return options.filter(item => {
      return separated.some(sep => sep == item.value);
    });
  },
  onChangeMapper: (e: any, name) => {
    let value;

    if (Array.isArray(e)) {
      value = e.map(i => i.value).join(",");
    } else {
      value = e.value;
    }

    return {
      target: {
        name,
        value
      }
    };
  }
} as Partial<TFGDropdownApiProps>;

export default function FGDropdownApi(props: TFGDropdownApiProps) {
  const [options, setOptions] = useState<TOptionItem[]>([]);
  const [fromApi, setFromApi] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!Array.isArray(props.options)) return;
    setOptions(props.options);
  }, [props.options]);

  useEffect(() => {
    if (typeof props.api === "undefined") {
      setLoading(false);
      return;
    }

    setFromApi(true);
    props.api().then((data) => {
      const result = typeof props.mapper === "function" ? props.mapper(data) : data;
      setLoading(false);
      setOptions(result);
    });
  }, []);

  return <FGField className={classNames([
    props.className,
    "fg__react-select",
    {"fg__react-select--normal": props.variant === "normal"}
  ])} name={props.name} label={props.label} desc={props.desc} comp="FGDropdown">
    {loading && fromApi && <div>
      Loading options...
    </div>}

    {!loading && <div className="fg__react-select__box">
      <Select options={options}
        value={props.defaultValueMapper(options, props.defaultValue)}
        isDisabled={props.disabled}
        id={props.name}
        name={props.name}
        isMulti={props.multiple}
        isOptionDisabled={(option) => option.disabled}
        defaultValue={props.defaultValueMapper(options, props.defaultValue)}
        classNamePrefix="fg__react-select"
        onChange={(e: any) => {
          props.onChange(props.onChangeMapper(e, props.name));
        }}
      />
    </div>}
  </FGField>;
}
