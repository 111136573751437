import ReactDOM from 'react-dom/client';
import './styles/main.scss';
import 'primereact/resources/themes/bootstrap4-light-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { RouterProvider } from 'react-router-dom';
import { router } from './routes';
import { NotifierContainer } from './modules/notifier/notifier';
import Multilingual from './modules/multilingual/multilingual';
import ViewportInfo from './components/ViewportInfo';
import { ModulePopupContainer } from './modules/popup/popup';

// fallback to client's time if `window.SERVER_TIME` is unavailable
window.SERVER_TIME = window["SERVER_TIME"] || (new Date()).toISOString();

Multilingual.init();

const root = ReactDOM.createRoot(
  document.getElementById('sit-iwsp') as HTMLElement
);

root.render(<>
  <NotifierContainer/>
  <RouterProvider router={router} />
  <ViewportInfo/>
  <ModulePopupContainer />
</>);
