import { useState } from "react";
import { HiOutlineSearch, HiOutlineMenuAlt2 } from "react-icons/hi";
import { Link } from "react-router-dom";

interface SelectableButtonProps {
  label: string;
  selected: boolean;
  onClick: () => void;
  className?: string;
  onButtonClick?: () => void;
}

const SelectableButton = ({
  label,
  selected,
  onClick,
  className,
  onButtonClick,
}: SelectableButtonProps) => {
  return (
    <button
      type="button"
      className={`btn btn-primary sort-button ${
        selected ? "btn--primary" : ""
      } ${className}`}
      onClick={onClick}
    >
      {label}
    </button>
  );
};

interface HeaderToolsProps {
  selectableButtons: SelectableButtonProps[];
  onSearchChange: (searchQuery: string) => void;
  selectedSort: string | null;
  onSortChange: (selectedSort: string) => void;
  buttonLink?: string;
  buttonLinkName?: string;
  register?: boolean;
  onButtonClick?: () => void;
}

export default function HeaderTools({
  selectableButtons,
  onSearchChange,
  selectedSort,
  onSortChange,
  buttonLink,
  buttonLinkName,
  register,
  onButtonClick,
}: HeaderToolsProps) {
  const [searchQuery, setSearchQuery] = useState("");

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
    onSearchChange(event.target.value);
  };

  return (
    <div data-comp="HeaderTools">
      <div className="d-flex gap-3 mb-4">
        {selectableButtons.map((button) => (
          <SelectableButton
            key={button.label}
            label={button.label}
            selected={button.selected}
            onClick={button.onClick}
            className={button.className}
            onButtonClick={button.onButtonClick}
          />
        ))}
        {!register && buttonLink && buttonLinkName && (
          <>
            <div className="flex-grow-1"></div>
            <div>
              {onButtonClick ? (
                <button
                  type="button"
                  className="btn btn-danger header-tools__button d-flex align-items-center "
                  onClick={onButtonClick}
                >
                  {buttonLinkName}
                </button>
              ) : (
                <Link
                  to={buttonLink}
                  className="btn btn-danger header-tools__button d-flex align-items-center "
                >
                  {buttonLinkName}
                </Link>
              )}
            </div>
          </>
        )}
      </div>
      <div className="mb-3 header-tools">
        <div className="flex-grow-1">
          <div className="input-group h-100 header-tools__search">
            <span className="input-group-text" id="search">
              <HiOutlineSearch />
            </span>
            <input
              type="text"
              className="form-control"
              placeholder="Search..."
              aria-label="search"
              aria-describedby="search"
              value={searchQuery}
              onChange={handleSearchChange}
            />
          </div>
        </div>
        <div>
          <div
            className={`d-flex header-tools__sort ${
              register ? "hts--register" : ""
            }`}
          >
            <span className="input-group-text ">
              <HiOutlineMenuAlt2 />
            </span>
            <select
              className="form-select bg-white border-0 flex-grow-1"
              id="sort"
              aria-label="Sort"
              value={selectedSort}
              onChange={(e) => onSortChange(e.target.value)}
            >
              <option value="year">Sort by Year</option>
              <option value="name">Sort by Name</option>
              <option value="email">Sort by Email</option>
              <option value="batch">Sort by Batch</option>
              <option value="study_program">Sort by Programme</option>
            </select>
          </div>
        </div>
        {register && (
          <>
            <div>
              {onButtonClick ? (
                <button
                  type="button"
                  className="btn btn-danger header-tools__button d-flex align-items-center "
                  onClick={onButtonClick}
                >
                  {buttonLinkName}
                </button>
              ) : (
                <Link
                  to={buttonLink}
                  className="btn btn-danger header-tools__button d-flex align-items-center "
                >
                  {buttonLinkName}
                </Link>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
}
