import { Link } from "react-router-dom";
import usePageTitle from "../../../hooks/use-page-title"
import { ROUTES } from "../../../routes";
import BasicDataTable from "../../../components/common/BasicDataTable";
import Utils from "../../../utils/utils";
import DateTime from "../../../components/common/DateTime";
import { Dropdown } from "react-bootstrap";
import { MdDelete, MdModeEditOutline, MdSearch } from "react-icons/md";
import { useContext, useEffect, useRef, useState } from "react";
import useDebounce from "../../../hooks/use-debounce";
import { usePortalSettingSet } from "../../../context/portal-context";
import ApiMailTemplate, { TMailTemplateItem } from "../../../modules/api/mail-template";
import Debouncer from "../../../modules/debouncer/debouncer";
import AppUserContext from "../../../context/app-user-context";
import Notifier from "../../../modules/notifier/notifier";
import ModulePopup from "../../../modules/popup/popup";

export default function PageMailTemplates() {
  const title = "Mail Templates";
  usePageTitle(title);
  usePortalSettingSet("label_header", title);

  const user = useContext(AppUserContext);
  const [refresh, setRefresh] = useState(false);
  const refLoading = useRef(false);
  const [dataApi, setDataApi] = useState<TMailTemplateItem[]>([]);
  const [data, setData] = useState<TMailTemplateItem[]>([]);
  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const [loadingTable, setLoadingTable] = useState(true);
  const [search, setSearch] = useState("");
  const searchTerm = useDebounce(search, 500);

  useEffect(() => {
    if (refLoading.current) return;
    refLoading.current = true;
    setLoadingTable(true);
    ApiMailTemplate.getAllTemplates().then(data => {
      refLoading.current = false;
      setDataApi(data);
    })
  }, [refresh]);

  useEffect(() => {
    (async () => {
      setLoadingTable(true);
      await Utils.sleep(100);

      if (searchTerm === "") {
        setData(dataApi);
        setLoadingTable(false);
        return;
      }

      const r = new RegExp(Utils.escapeRegExp(searchTerm), "i");
      setData(dataApi.filter(i => {
        return r.test(i.name) || r.test(i.subject);
      }));
      setLoadingTable(false);
    })();
  }, [searchTerm, dataApi]);

  return <main className="form-generic content-generic content-generic--fluid">

    <h1 className="fs-title hide-mobile-down">{title}</h1>

    <div className="cg__actions">
      <div className="cg__action-buttons">
        <Link to={ROUTES.PORTAL_MAIL_TEMPLATE + "/add"} className="btn btn-primary">
          Add New Template
        </Link>
        {" "}
        <button className="btn btn-outline-primary"
          onClick={() => setRefresh(i => !i)}
        >
          Refresh
        </button>
      </div>
      <div className="cg__action-search">
        <span>
          <MdSearch/>
        </span>
        <input type="text" placeholder="search" onChange={(e) => setSearch(e.target.value)} />
      </div>
    </div>

    <BasicDataTable
      loading={loadingTable}
      columns={[
        {
          id: "name",
          name: "Name",
          cell: (row) => row.name,
          sortable: true,
          sortFunction: Utils.sortByObjectPropNatural("name")
        },
        {
          id: "subject",
          name: "Subject",
          cell: (row) => row.subject,
          sortable: true,
          sortFunction: Utils.sortByObjectPropNatural("subject")
        },
        {
          id: "modifiedDate",
          name: "Last update",
          cell: (row) => <DateTime date={row.modifiedDate} format="MMM DD, YYYY HH:mm:ss" />,
          sortable: true,
          sortFunction: Utils.sortByObjectPropNumber("modifiedDate")
        },
        {
          name: "Action",
          button: true,
          cell: (row) => <div>
            <Dropdown align={"end"} drop={"down"}>
              <Dropdown.Toggle variant="primary" id="dropdown-basic" className="btn-sm">
                Action
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Link className="dropdown-item" to={`edit/${row._id}`}>
                  <MdModeEditOutline/> {" "} Edit
                </Link>

                <Dropdown.Divider />

                <button className="dropdown-item btn-danger" onClick={() => {
                  ModulePopup.showAlertConfirm({
                    title: "Delete Confirmation",
                    description: <>
                      <p>Are you sure to delete this mail template?
                        <br/> This action can't be undone.
                      </p>
                    </>,
                    onYes: () => Debouncer.execute("DELETE_MAIL", async () => {
                      const res = await ApiMailTemplate.remove(row._id, user.id);

                      if (res.status) {
                        setRefresh(i => !i);
                        Notifier.success(res.message);
                      } else {
                        Notifier.error(res.message);
                      }
                    })
                  });
                }}>
                  <MdDelete/> {" "} Delete
                </button>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        }
      ]}
      data={data}
      search={search}
      selectableRows={false}
      onSelectedRowsChange={(e) => {
        setSelectedRows(e.selectedRows);
      }}
    />

  </main>;
}
