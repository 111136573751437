export type TLoadingDataProps = {
  show: boolean;
  text?: string;
};

LoadingData.defaultProps = {
  show: false,
  text: "Loading. Please wait..."
};

export default function LoadingData(props: TLoadingDataProps) {
  return <>
    {props.show && <p className="alert alert-info">
      {props.text}
    </p>}
  </>
}
