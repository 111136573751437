import Utils from "../utils/utils";

const ROLE = {
  ADMIN: "ADMINISTRATOR",
  STUDENT: "STUDENT",
  SUPERVISOR: "SIT SUPERVISOR",
  INDUSTRY_SUPERVISOR: "INDUSTRY SUPERVISOR"
} as const;

export function getRoleLabel(role: string) {
  switch (role.toUpperCase()) {
    case ROLE.ADMIN:
      return "Administrator";
    case ROLE.STUDENT:
      return "Student";
    case ROLE.SUPERVISOR:
      return "SIT Supervisor";
    case ROLE.INDUSTRY_SUPERVISOR:
      return "Industry Supervisor";
    default:
      return "";
  }
}

export function getRoleList(): TRole[] {
  return [
    {
      _id: ROLE.STUDENT,
      name: getRoleLabel(ROLE.STUDENT),
      description: "Student or peer."
    },
    {
      _id: ROLE.SUPERVISOR,
      name: getRoleLabel(ROLE.SUPERVISOR),
      description: "Supervisor from SIT."
    },
    {
      _id: ROLE.INDUSTRY_SUPERVISOR,
      name: getRoleLabel(ROLE.INDUSTRY_SUPERVISOR),
      description: "Supervisor from Industry."
    },
    {
      _id: ROLE.ADMIN,
      name: getRoleLabel(ROLE.ADMIN),
      description: "Owner of the app that can do most of available features."
    }
  ];
}

export function isAdmin(user: Pick<TUser, "role"> | string) {
  if (typeof user === "string") {
    return user === ROLE.ADMIN;
  }

  return user?.role?.toUpperCase() === ROLE.ADMIN;
}

export function getMatchedRole(value, ifNotMatch = ROLE.STUDENT) {
  if (!(typeof value === "string" && value.trim().length > 0)) {
    return ifNotMatch;
  }

  switch (value.trim().toUpperCase()) {
    case "ADMIN":
    case ROLE.ADMIN:
      return ROLE.ADMIN;
    case ROLE.STUDENT:
      return ROLE.STUDENT;
    case "SUPERVISOR":
    case "SPV":
    case ROLE.SUPERVISOR:
      return ROLE.SUPERVISOR;
    case "INDUSTRY":
    case ROLE.INDUSTRY_SUPERVISOR:
      return ROLE.INDUSTRY_SUPERVISOR;
    default:
      return ifNotMatch;
  }
}

export function getExistingRoleUrlParam(paramName = "role", ifNotMatch = ROLE.STUDENT) {
  return getMatchedRole(Utils.parseQueryString()[paramName], ifNotMatch);
}

export default ROLE;
