import { useState, useEffect, forwardRef, useImperativeHandle } from "react";
import Notifier from "../../modules/notifier/notifier";
import DataTable, {
  TableColumn,
  TableStyles,
} from "react-data-table-component";
import { CRON_JOB_STATUS_RUNNING } from "../../config/constants";

export type TServerSideScheduleReminderApiResult = {
  data: any[];
  error: string;
};

export type TServerSideScheduleReminderTableProps = {
  api: () => Promise<TServerSideScheduleReminderApiResult>;
  columns: TableColumn<any>[];
  customStyles?: TableStyles;
  refresh?: boolean;
};

const ServerSideScheduleReminderTable = forwardRef(
  (props: TServerSideScheduleReminderTableProps, ref) => {
    const [loading, setLoading] = useState(false);
    const [items, setItems] = useState<any[]>([]);

    useEffect(() => {
      setLoading(true);
      props
        .api()
        .then(({ data, error }) => {
          if (error) {
            Notifier.error(error);
          } else {
            setItems(data);
          }
          setLoading(false);
        })
        .catch((exception) => {
          setLoading(false);
          throw new Error(exception.message);
        });
    }, [props.refresh]);

    useImperativeHandle(ref, () => ({
      updateJobStatus(
        id: string,
        status: string,
        startDateTime: string = undefined
      ) {
        const tasks = JSON.parse(JSON.stringify(items));
        const index = tasks.findIndex((task: any) => task._id === id);
        if (index !== -1) {
          tasks[index].status = status;
          if (startDateTime) {
            tasks[index].startDateTime = startDateTime;
          }
          if (status !== CRON_JOB_STATUS_RUNNING) {
            tasks[index].timeRun = 0;
          }
          setItems(tasks);
        }
      },
      deleteJob(id: string) {
        const tasks = [...items].filter((item) => item._id !== id);
        setItems(tasks);
      },
    }));

    return (
      <div className="basic-rdt" data-comp="ServerSideGenericDataTable">
        <DataTable
          progressPending={loading}
          progressComponent={<></>}
          data={items}
          columns={props.columns}
          customStyles={props.customStyles}
        />
        {loading && (
          <div className="ssd__loading">
            <div className="ssd__loading__text">Loading...</div>
          </div>
        )}
      </div>
    );
  }
);

export default ServerSideScheduleReminderTable;
