import { getRoleList } from "../modules/role";

export const TIMEZONE = "Asia/Singapore";

export const SUBMISSION_WL = "Workplace Learning";
export const SUBMISSION_PD = "Professional Development";

export const LABEL_ANONYMOUS_USER = "Anonymous User";
export const LABEL_UNKNOWN_TRAIT = "Unknown Trait";

// in months
export const MAX_PD_SUBMISSION = 12;

export const MAX_TRAITS_IN_A_SUBMISSION = 3;

export const SUBMISSION_STATUS_PUBLISH = "Publish";
export const SUBMISSION_STATUS_DRAFT = "Draft";

export const CRON_JOB_STATUS_SCHEDULED = "Scheduled";
export const CRON_JOB_STATUS_RUNNING = "Running";
export const CRON_JOB_STATUS_STOPPED = "Stopped";
export const CRON_JOB_TYPE_HOURLY = "Hourly";
export const CRON_JOB_TYPE_DAILY = "Daily";

export const TIME_FROM_SERVER = new Date(window.SERVER_TIME);
export const CURRENT_YEAR = TIME_FROM_SERVER.getFullYear();

/** starts from 1 */
export const MONTH_ARR = [
  "", "January", "February", "March", "April", "May", "June", "July", "August",
  "September", "October", "November", "December"
];

export const MONTH_ARR_SHORT = [
  "", "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct",
  "Nov", "Dec"
];

export const CONST_YEARS = (() => {
  const FROM = 2020;
  const TO = CURRENT_YEAR + 1;
  const years = [];

  for (let curr = FROM; curr <= TO; curr += 1) {
    years.push(curr);
  }

  return years.reverse();
})();

/** Used for `<select/>`'s options */
export const CONST_SELECT_YEAR = CONST_YEARS.map(i => ({
  value: i.toString(),
  label: i.toString()
}));

/** Used for `<select/>`'s options */
export const CONST_SELECT_ROLES = getRoleList().map(item => {
  return {
    label: item.name,
    value: item._id
  };
});

/** Used for `<select/>`'s options */
export const CONST_SELECT_SUBMISSION_TYPES = [
  SUBMISSION_WL,
  SUBMISSION_PD
].map(item => {
    return {
      label: item,
      value: item
    };
  }
);

export const CONST_SELECT_CRON_JOB_TYPE = [
  CRON_JOB_TYPE_HOURLY,
  CRON_JOB_TYPE_DAILY,
].map((item) => {
  return {
    label : item,
    value : item
  }
});

export const IGNORE_PARAM_VALUE = "__";

export const DEFAULT_SELECT_OPTION = {
  label: "Select this option to unset...",
  value: ""
};

export const USER_STATUS_ACTIVE = "Active";
export const USER_STATUS_INACTIVE = "Inactive";

export const CONST_SELECT_USER_STATUS = [
  USER_STATUS_ACTIVE, USER_STATUS_INACTIVE
].map(i => ({ label: i, value: i }));
