import { useContext, useEffect, useState } from "react";
import AppUserContext from "../../context/app-user-context";
import AppUser from "../../modules/app-user/app-user";
import DefaultPasswordCheck from "./DefaultPasswordCheck";

/**
 * This component is helpful for following scenario(s):
 * 1. Default password check after login
 * 2. Showing first-login message
 */
export default function PortalJustLoggedCheck() {
  const user = useContext(AppUserContext);
  const [enabled, setEnabled] = useState(false);

  useEffect(() => {
    if (!user.just_logged) return;
    AppUser.setInfo({
      just_logged: false
    }).then(() => {
      setEnabled(true);
    });
  }, []);

  if (!enabled) return <></>;

  return <>
    <DefaultPasswordCheck />
  </>;
}
