import classNames from "classnames";
import React, { useState } from "react";

export default function RoleFilterSelector(props: {
  default: string;
  options: Array<{ label: string; value: any; }>;
  onChange?: (value: string) => void;
}) {
  const [current, setCurrent] = useState(props.default);

  return <div className="select-role-type">
    <div className="srt__inner">
      {props.options.map((item, i) => <React.Fragment key={i}>
        <button
          className={classNames([
            "btn",
            {"btn-primary": item.value === current},
            {"btn-outline-primary": item.value !== current}
          ])}
          onClick={(e) => {
            e.preventDefault();
            setCurrent(item.value);
            if (typeof props.onChange === "function") {
              props.onChange(item.value);
            }
          }}
        >
          {item.label}
        </button>
        {" "}
      </React.Fragment>)}
    </div>
  </div>;
}
