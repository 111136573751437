import { Dispatch, SetStateAction } from "react";
import { Modal } from "react-bootstrap";

export type TTraitScaleConfirmProps = {
  show: boolean;
  setShow:  Dispatch<SetStateAction<boolean>>;
  backdrop?: boolean | "static";
  keyboard?: boolean;
  title?: string|JSX.Element|JSX.Element[];
  description?: string|JSX.Element|JSX.Element[];
  labelYes?: string;
  labelNo?: string;
  onYes?: () => void;
  onNo?: () => void;
  onClose?: () => void;
};

TraitScaleConfirm.defaultProps = {
  show: false,
  setShow: () => {},
  backdrop: "static",
  keyboard: false,
  title: "Confirmation",
  description: "Are you sure?",
  labelYes: "Select",
  labelNo: "Cancel",
  onYes: () => {},
  onNo: () => {},
  onClose: () => {}
};

export default function TraitScaleConfirm(props: TTraitScaleConfirmProps) {
  return <Modal className="modal-generic alert-trait-scale-confirm" show={props.show}
    centered
    backdrop={props.backdrop}
    keyboard={props.keyboard}
    onHide={() => {
      props.setShow(false);
      props.onClose();
    }}
  >
    <Modal.Header closeButton>
      <Modal.Title>{props.title ?? "Confirmation"}</Modal.Title>
    </Modal.Header>
    <Modal.Body className="ts__legend-desc">
      <div className="tsld__box">
        {props.description ?? "Are you sure?"}
      </div>
    </Modal.Body>
    <Modal.Footer>
      <button className="btn btn-mw btn-outline-primary" onClick={() => (props.onNo())}>
        {props.labelNo ?? "Cancel"}
      </button>
      <button className="btn btn-mw btn-primary" onClick={() => (props.onYes())}>
        {props.labelYes ?? "Select"}
      </button>
    </Modal.Footer>
  </Modal>;
}
