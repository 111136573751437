import UserImage from '../../../components/portal/UserImage'
import { usePortalSettingSet } from "../../../context/portal-context";
import usePageTitle from "../../../hooks/use-page-title";
import { Link, useParams } from 'react-router-dom';
import { ROUTES } from '../../../routes';
import { HiArrowLeft } from 'react-icons/hi';
import { useContext, useEffect, useMemo, useState } from 'react';
import ApiUser from '../../../modules/api/user';
import { showLabelInfo } from '../../../config/methods';
import ReportItemListing from '../../../components/portal/ReportItemListing';
import AppUserContext from '../../../context/app-user-context';
import ROLE from '../../../modules/role';
import { SUBMISSION_PD } from '../../../config/constants';
import Utils from '../../../utils/utils';

export default function PageMyGroupDetails() {
  usePageTitle('My Group Details');
  usePortalSettingSet("label_header", "My Group Details");

  const loggedUser = useContext(AppUserContext);
  const { userId } = useParams();
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState<{[key: string]: any}>();
  const submissionType = useMemo(() => {
    const param = Utils.parseQueryString();
    const tab = param["type"];
    return tab ?? SUBMISSION_PD;
  }, []);

  useEffect(() => {
    (async () => {
      const user = await ApiUser.get(userId);

      if (user.status) {
        setUser(user.data);
      }

      setLoading(false);
    })();
  }, []);

  return (
    <div>
      <div className='col-12 mb-lg-5 student-data'>
        <h1 className="fs-title">
          <Link to={ROUTES.PORTAL_MY_GROUP} className="text-primary p-0 me-2 hide-mobile-down" title="Go back">
            <HiArrowLeft />
          </Link>
          My Group
        </h1>

        {!loading && user && <div className='dashboard-profile mb-3 bg-white d-flex flex-lg-row flex-column gap-2 gap-xl-5'>
          <div className='d-flex align-items-center gap-3 flex-row'>
            <UserImage url={user.avatar} />
            <div className='d-flex flex-column dp__info w-auto'>
              <p className='m-0 d-none d-xxl-block'>Name</p>
              <span className='dpi__name fs-4'>{user.name}</span>
            </div>
          </div>
          <div className='d-flex justify-content-between flex-column align-align-items-center flex-fill d-none d-xxl-flex'>
            <div className='d-flex flex-column dp__info'>
              Student ID
              <span className='dpi__name fs-5 fw-normal'>{Utils.ifEmptyOrUndefinedThen(user.studentId, "-")}</span>
            </div>
            <div className='d-flex flex-column dp__info'>
              Group Name
              <span className='dpi__name fs-5 fw-normal'>{Utils.ifEmptyOrUndefinedThen(user.groupName, "-")}</span>
            </div>
          </div>
          <div className='d-flex justify-content-between flex-lg-column align-align-items-center flex-fill gap-3'>
            <div className='d-flex flex-column dp__info flex-xxl-grow-0'>
              Company Name
              <span className='dpi__name fs-5 fw-normal'>{Utils.ifEmptyOrUndefinedThen(user.industryName, "-")}</span>
            </div>
            <div className='d-flex flex-column dp__info flex-xxl-grow-0'>
              Programme
              <span className='dpi__name fs-5 fw-normal'>{Utils.ifEmptyOrUndefinedThen(user.studyProgramName, "-")}</span>
            </div>
          </div>
          <div className='d-flex justify-content-between flex-lg-column align-align-items-center flex-fill gap-3'>
            <div className='d-flex flex-column dp__info flex-xxl-grow-0'>
              Industry Supervisor
              <span className='dpi__name fs-5 fw-normal'>{showLabelInfo(user.supervisors?.industry)}</span>
            </div>
            <div className='d-flex flex-column dp__info flex-xxl-grow-0'>
              SIT/Academic Supervisor
              <span className='dpi__name fs-5 fw-normal'>{showLabelInfo(user.supervisors?.sit)}</span>
            </div>
          </div>
        </div>}

        {!loading && !user && <p className='alert alert-warning mb-0 mt-3'>
          User not found.
        </p>}
      </div>

      {user && <div>
        <h1 className="fs-title mb-3 mt-4">Report(s)</h1>
        <ReportItemListing
          className="mt-3"
          userId={userId}
          itemsPerPage={10}
          pagination={true}
          status="Publish"
          isSupervisor={true}
          type={loggedUser.role === ROLE.INDUSTRY_SUPERVISOR ? SUBMISSION_PD : submissionType}
        />
      </div>}
    </div>
  )
}
