interface User {
  id: number;
  user_picture: string;
  name: string;
  year: string;
  study_program: string;
  batch: number;
  email: string;
  account_type: string;
}

const dummyUsers: User[] = [
  {
    "id": 1,
    "user_picture": "https://via.placeholder.com/150",
    "name": "Jane Smith",
    "year": "2022",
    "study_program": "Business Administration",
    "batch": 3,
    "email": "janesmith@example.com",
    "account_type": "Student"
  },
  {
    "id": 2,
    "user_picture": "https://via.placeholder.com/150",
    "name": "John Doe",
    "year": "2023",
    "study_program": "Computer Science",
    "batch": 2,
    "email": "johndoe@example.com",
    "account_type": "Student"
  },
  {
    "id": 3,
    "user_picture": "https://via.placeholder.com/150",
    "name": "Sarah Lee",
    "year": "2024",
    "study_program": "Marketing",
    "batch": 1,
    "email": "sarahlee@example.com",
    "account_type": "Student"
  },
  {
    "id": 4,
    "user_picture": "https://via.placeholder.com/150",
    "name": "David Kim",
    "year": "2023",
    "study_program": "Finance",
    "batch": 4,
    "email": "davidkim@example.com",
    "account_type": "Student"
  },
  {
    "id": 5,
    "user_picture": "https://via.placeholder.com/150",
    "name": "Emily Chen",
    "year": "2022",
    "study_program": "International Business",
    "batch": 3,
    "email": "emilychen@example.com",
    "account_type": "Student"
  },
  {
    "id": 6,
    "user_picture": "https://via.placeholder.com/150",
    "name": "Michael Wong",
    "year": "2024",
    "study_program": "Accounting",
    "batch": 2,
    "email": "michaelwong@example.com",
    "account_type": "Student"
  },
  {
    "id": 7,
    "user_picture": "https://via.placeholder.com/150",
    "name": "Jessica Park",
    "year": "2023",
    "study_program": "Human Resources",
    "batch": 1,
    "email": "jessicapark@example.com",
    "account_type": "Student"
  },
  {
    "id": 8,
    "user_picture": "https://via.placeholder.com/150",
    "name": "Daniel Lee",
    "year": "2022",
    "study_program": "Operations Management",
    "batch": 4,
    "email": "daniellee@example.com",
    "account_type": "Industry Supervisor"
  },
  {
    "id": 9,
    "user_picture": "https://via.placeholder.com/150",
    "name": "Sophia Kim",
    "year": "2024",
    "study_program": "Entrepreneurship",
    "batch": 3,
    "email": "sophiakim@example.com",
    "account_type": "SIT Supervisor"
  },
  {
    "id": 10,
    "user_picture": "https://via.placeholder.com/150",
    "name": "William Chen",
    "year": "2023",
    "study_program": "Economics",
    "batch": 2,
    "email": "williamchen@example.com",
    "account_type": "Industry Supervisor"
  },
  {
    "id": 11,
    "user_picture": "https://via.placeholder.com/150",
    "name": "Jordy Myheart",
    "year": "2019",
    "study_program": "Information and Digital Technology",
    "batch": 4,
    "email": "jordymyheart@example.com",
    "account_type": "SIT Supervisor"
  },
];

export default dummyUsers;
