import * as aesjs from 'aes-js';
import Config from '../../config/config';

const DEFAULT_KEY = Config.ENCRYPTION_DEFAULT_KEY;

export default class Encryption {

  static encrypt(data: string, key: aesjs.ByteSource = DEFAULT_KEY) {
    let dataBytes = aesjs.utils.utf8.toBytes(data);
    let aesCtr = new aesjs.ModeOfOperation.ctr(key);
    let encryptedBytes = aesCtr.encrypt(dataBytes);

    return aesjs.utils.hex.fromBytes(encryptedBytes);
  }

  static decrypt(data: string, key: aesjs.ByteSource = DEFAULT_KEY) {
    let encryptedBytes = aesjs.utils.hex.toBytes(data);
    let aesCtr = new aesjs.ModeOfOperation.ctr(key);
    let decryptedBytes = aesCtr.decrypt(encryptedBytes);

    return aesjs.utils.utf8.fromBytes(decryptedBytes);
  }

}
