import { Link } from "react-router-dom";
import { HiArrowRight } from "react-icons/hi";
interface ContentItemProps {
  title: string;
  description?: string;
  link?: string;
  small?: boolean;
}

export default function ContentItem({
  title,
  description,
  link,
  small = false
}: ContentItemProps) {
  return (
    <Link
      to={link ?? "#"}
      className={`content__item text-decoration-none text-black ${
        small && !description ? "content__item--small" : ""
      }`}
      data-comp="ContentItem"
    >
      <h3 className="content__item-title">{title}</h3>
      {small && !description ? (
        <span>
          <HiArrowRight className="text-white" />
        </span>
      ) : (
        <div className="content__item-desc">
          {description}
            <HiArrowRight className="text-black" />
        </div>
      )}
    </Link>
  );
}
