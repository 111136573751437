import Utils from "../utils/utils";
import Config from "./config";
import { IGNORE_PARAM_VALUE } from "./constants";

export function getDefaultEmptyApiParam(val: any) {
  return (typeof val === "string" && val.trim() !== "") ? encodeURIComponent(val) : IGNORE_PARAM_VALUE;
}

export function showLabelInfo(txt) {
  return Utils.ifEmptyOrUndefinedThen(txt, Config.CHAR_EMPTY);
}
