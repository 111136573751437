export type TSoundType = 'notif';

const DEFAULT_VOLUME = 0.5;

export type TSFXPlayOptionsParam = {
  /**
   * If we pass the event, then we are expecting to prevent-default the behaviour
   * e.g. if it's an anchor element, where we want to hear the sound for a while
   * then proceed.
   */
  e?: any;
  /** Unit in second(s) */
  defaultAudioDuration?: number;
  /**
   * Unit in ms. This is used for delaying the resolved promised. If we set it
   * to negative numbers, then the promise will resolve faster.
   */
  delay?: number;
  /** the value is from 0 to 1 */
  volume?: number;
};

const SFXInstances: {[key: string]: SFX} = {};

export default class SFX {

  static get(soundName: TSoundType) {
    let instance = SFXInstances[soundName];

    if (typeof instance === 'undefined') {
      instance = SFXInstances[soundName] = new SFX(soundName);
    }

    return instance;
  }

  static stop(soundName: TSoundType) {
    const instance = this.get(soundName);
    const elem = <HTMLMediaElement>instance.$audio[0];
    elem.pause();
    elem.currentTime = 0;
  }

  static play(soundName: TSoundType, userOptions?: TSFXPlayOptionsParam): Promise<void> {
    const options = Object.assign({
      defaultAudioDuration: 0.5,
      volume: DEFAULT_VOLUME,
      delay: 0
    }, userOptions);

    const volume = typeof options.volume !== "number" || options.volume > 1
      ? 1 : options.volume < 0 ? 0 : options.volume;

    const instance = this.get(soundName);
    const elem = <HTMLMediaElement>instance.$audio[0];

    return new Promise((res, rej) => {
      instance.resolver = res;
      const callbackStack = [];

      if (options.e) {
        try {
          options.e.preventDefault();

          const $anchor = $(options.e.target);
          const href = $anchor.attr('href');

          if (typeof href === "string") {
            callbackStack.push(() => window.location.href = href);
          }
        } catch (ignore) {}
      }

      try {
        // 500 is the default delay
        const duration = (elem.duration ?? options.defaultAudioDuration) * 1000;

        clearTimeout(instance.timeout);
        elem.pause();
        elem.volume = volume;
        elem.currentTime = 0;
        instance.timeout = setTimeout(() => {
          callbackStack.forEach(cb => cb());
          instance.resolver();
        }, duration + options.delay);
        elem.play();
      } catch (ignore) {}
    });
  }

  private $audio: JQuery;
  private resolver: any;
  private timeout: any;

  constructor(private soundName: string) {
    this.$audio = $(`<audio id="sfx--${soundName}" src="/assets/sfx/${soundName}.mp3?t=${Date.now()}"/>`).appendTo('body');
  }

}
