/**
 * @ref:
 * - https://fxmediaweb.atlassian.net/browse/IS-39
 * - https://fxmediaweb.atlassian.net/browse/IS-53
 */

import axios from "axios";
import { apiUrl, getAuthHeader } from "./base";
import { IGNORE_PARAM_VALUE, SUBMISSION_PD, SUBMISSION_WL, TIME_FROM_SERVER } from "../../config/constants";
import ModuleDate from "../date";

export type TSubmissionType = typeof SUBMISSION_WL | typeof SUBMISSION_PD;

type TSubmissionItemBasic = {
  /** any valid js date string */
  from: string;
  /** any valid js date string */
  to: string;
  /** submission's type */
  type: string;
};

type TAddParam = TSubmissionItemBasic & {
  /** user.id */
  createdUserId: string;
};

async function add(formData: TAddParam): Promise<{ status: boolean; message: string; }> {
  try {
    const req = await axios.post(apiUrl("/apisubmissiondate"), {
      ...formData,
      from: ModuleDate.startOfDay(formData.from),
      to: ModuleDate.endOfDay(formData.to)
    }, await getAuthHeader());
    if (req.data.result !== "OK") throw new Error(req.data.message ?? "Unable to add submission date");
    return {
      status: true,
      message: req.data.message
    };
  } catch (err) {
    return {
      status: false,
      message: err.message
    };
  }
}

export type TSubmissionDateItem = TSubmissionItemBasic & {
  _id: string;
  month: string;
  year: string;
  createdUserId: string;
  createdDate: string;
  modifiedUserId: string|null;
  modifiedDate: string;
};

async function getByMonthAndYear(year: string = "", month: string = "") {
  let data: TSubmissionDateItem[] = [];

  if (month.trim() === "") {
    month = IGNORE_PARAM_VALUE;
  }

  if (year.trim() === "") {
    year = IGNORE_PARAM_VALUE;
  }

  try {
    const req = await axios.get(apiUrl(`/apisubmissiondate/${month}/${year}`), await getAuthHeader());
    if (req.data.result !== "OK") throw new Error(req.data.message ?? "Unable to get submission(s)");
    data = req.data.data;
  } catch (ignore) {}

  return data;
}

async function get(_id: string) {
  try {
    let data: TSubmissionDateItem;

    const req = await axios.get(apiUrl(`/apisubmissiondate/${_id}`), await getAuthHeader());

    if (req.data.result !== "OK") throw new Error(req.data.message ?? "Unable to get submission(s)");

    data = req.data.data;

    if (data === null || data === undefined) throw new Error("Unable to get submission(s)");

    return {
      status: true as true,
      message: "OK",
      data
    };
  } catch (err) {
    return {
      status: false as false,
      message: err.message
    };
  }
}

export type TValidateDateResult = {status: true, message: string} & {
  from: string;
  to: string;
  submissionDateId: string;
} | {status: false, message: string};

/**
 * This to get latest submission date for the given `submissionType`
 * @param {string} date Date in ISO string format
 */
async function validate(submissionType: TSubmissionType, date?: string|Date): Promise<TValidateDateResult> {
  try {
    let dateParam = "";

    if ((
        (typeof date === "string" && date !== "") ||
        date instanceof Date
      ) && new Date(date).getTime() > 0
    ) {
      dateParam = `/${(new Date(date).toISOString())}`;
    }

    const req = await axios.get(apiUrl(`/apisubmissiondate/validate/${submissionType}${dateParam}`), await getAuthHeader());

    if (req.data.result !== "OK") throw new Error(req.data.message ?? "Unable to get submission date");

    const data = req.data;

    return {
      status: true as true,
      message: "OK",
      from: data.from as string,
      to: data.to as string,
      submissionDateId: data.submissionDateId as string
    };
  } catch (err) {
    return {
      status: false as false,
      message: err.message
    };
  }
}

type TUpdateParam = TSubmissionItemBasic & {
  modifiedUserId: string;
};

async function updateById(submissionDateId: string, formData: TUpdateParam) {
  try {
    const req = await axios.patch(apiUrl(`/apisubmissiondate/${submissionDateId}`), {
      ...formData,
      from: ModuleDate.startOfDay(formData.from),
      to: ModuleDate.endOfDay(formData.to)
    }, await getAuthHeader());
    if (req.data.result !== "OK") throw new Error(req.data.message ?? "Unable to update submission date");
    return {
      status: true,
      message: req.data.message
    };
  } catch (err) {
    return {
      status: false,
      message: err.message
    };
  }
}

async function remove(_id: string) {
  try {
    const req = await axios.delete(apiUrl(`/apisubmissiondate/${_id}`), {
      ...await getAuthHeader()
    });
    if (req.data.result !== "OK") throw new Error(req.data.message ?? "Unable to delete item");
    return {
      status: true,
      message: req.data.message
    };
  } catch (err) {
    return {
      status: false,
      message: err.message
    };
  }
}

/**
 * This endpoint is used to tell the user when the next submission's deadline
 * will be for both WL and PD submissions.
 */
async function getNextDeadlineInfo() {
  const ret = {
    wl: "-",
    pd: "-"
  };

  try {
    const wl = await validate(SUBMISSION_WL);

    if (wl.status) {
      ret.wl = ModuleDate.getDefaultFormat(wl.to);
    } else {
      ret.wl = "Unavailable";
    }

    const pd = await validate(SUBMISSION_PD);

    if (pd.status) {
      ret.pd = ModuleDate.getDefaultFormat(pd.to);
    } else {
      ret.pd = "Unavailable";
    }

  } catch (ignore) {}

  return ret;
}

/**
 * @ref https://fxmediaweb.atlassian.net/browse/IS-73
 * Quite similar to `getNextDeadlineInfo` but this one is only requesting
 * one network resource.
 *
 * The overdue submission will be prioritised to be displayed.
 *
 * @param {string} userId is the `user.referenceUserId`
 */
async function getSubmissionReminder(userId: string) {
  const ret = {
    wl: "",
    pd: ""
  };

  try {
    const req = await axios.get(apiUrl(`/apisubmission/reminder/${userId}`), await getAuthHeader());
    if (req.data.result !== "OK") throw new Error(req.data.message ?? "Unable to get submission reminder");
    const data = req.data.data;
    const wl = data[SUBMISSION_WL];
    const pd = data[SUBMISSION_PD];

    const wlArr = [];
    if (wl.overdue) wlArr.push(`Overdue: ${ModuleDate.getDefaultFormat(wl.overdue.to)}`);
    if (wl.next) wlArr.push(`Next Deadline: ${ModuleDate.getDefaultFormat(wl.next.to)}`);
    ret.wl = wlArr.join(" | ");

    const pdArr = [];
    if (pd.overdue) pdArr.push(`Overdue: ${ModuleDate.getDefaultFormat(pd.overdue.to)}`);
    if (pd.next) pdArr.push(`Next Deadline: ${ModuleDate.getDefaultFormat(pd.next.to)}`);
    ret.pd = pdArr.join(" | ");
  } catch (ignore) {}

  return ret;
}

/**
 * @phase2 improvement
 * TODO this logic shall be change for phase-2 since it will use "cohort" based
 *      currently it will fetch date based on yearly (server time-based).
 */
async function getSubmissionDateListForSubmission(submissionType: string, submissionDateIfEmpty?: any): Promise<{
  label: string;
  value: any;
  toMs: number;
  fromMs: number;
}[]> {
  try {
    const data = (await getByMonthAndYear(TIME_FROM_SERVER.getFullYear().toString(), ""))
      .filter(item => item.type === submissionType)
      .map(item => ({
        ...item,
        toMs: new Date(item.to).getTime(),
        fromMs: new Date(item.from).getTime()
      }));

    data.sort((a, b) => b.toMs - a.toMs);

    const result = data.map(item => ({
      toMs: item.toMs,
      fromMs: item.fromMs,
      label: ModuleDate.formatSubmissionDateRange(item.from, item.to),
      value: item._id
    }));

    if (result.length === 0) {
      return [{
        label: ModuleDate.formatSubmissionDateRange(submissionDateIfEmpty.from, submissionDateIfEmpty.to),
        value: submissionDateIfEmpty._id,
        toMs: (new Date(submissionDateIfEmpty.to)).getTime(),
        fromMs: (new Date(submissionDateIfEmpty.from)).getTime()
      }];
    }

    return result;
  } catch (ignore) {}

  return [];
}

const ApiSubmissionDate = {
  add,
  updateById,
  getByMonthAndYear,
  get,
  getNextDeadlineInfo,
  getSubmissionReminder,
  validate,
  remove,
  getSubmissionDateListForSubmission
};

export default ApiSubmissionDate;
