import { createBrowserRouter, Navigate } from "react-router-dom";
import ROLE from "./modules/role";
import PageLogin from "./pages/auth/PageLogin";
import PageLogout from "./pages/auth/PageLogout";
import PageError from "./pages/PageError";
import PageLanding from "./pages/PageLanding";
import PageDashboard from "./pages/portal/PageDashboard/PageDashboard";
import PageReport from "./pages/portal/PageReport/PageReport";
import PageReportWL from "./pages/portal/PageReport/PageReportWL";
import PageReportPD from './pages/portal/PageReport/PageReportPD';
import PageUsers from "./pages/portal/PageUsers/PageUserList";
import PageUserRegist from "./pages/portal/PageUsers/PageUserRegist";
import PageGroups from "./pages/portal/PageGroups/PageGroupList";
import PageGroupDetails from "./pages/portal/PageGroups/PageGroupDetails";
import PagePortal from "./pages/portal/PagePortal";
import PageReferenceForm from "./pages/reference/PageReferenceForm";
import PagePortalNotFound from "./pages/portal/PagePortalNotFound/PagePortalNotFound";
import PageSitemap from "./pages/PageSitemap";
import PageSetupPassword from "./pages/auth/PageSetupPassword";
import PageForgotPassword from "./pages/auth/PageForgotPassword";
import PageResetPassword from "./pages/auth/PageResetPassword";
import PageSetNewPassword from "./pages/auth/PageSetNewPassword";
import PageAuth from "./pages/auth/PageAuth";
import PageReferenceTypography from "./pages/reference/PageReferenceTypography";
import PageReference from "./pages/reference/PageReference";
import PageMailTemplates from "./pages/portal/PageMailTemplates/PageMailTemplates";
import PageMailTemplatesAdd from "./pages/portal/PageMailTemplates/PageMailTemplatesAdd";
import PageMailTemplatesEdit from "./pages/portal/PageMailTemplates/PageMailTemplatesEdit";
import PageReferenceButton from "./pages/reference/PageReferenceComponent";
import PageStudyProgram from "./pages/portal/PageStudyProgram/PageStudyProgram";
import PageStudyProgramAdd from "./pages/portal/PageStudyProgram/PageStudyProgramAdd";
import PageStudyProgramEdit from "./pages/portal/PageStudyProgram/PageStudyProgramEdit";
import PageIndustry from "./pages/portal/PageIndustry/PageIndustry";
import PageIndustryAdd from "./pages/portal/PageIndustry/PageIndustryAdd";
import PageIndustryEdit from "./pages/portal/PageIndustry/PageIndustryEdit";
import PageGroupAdd from "./pages/portal/PageGroups/PageGroupAdd";
import PageGroupEdit from "./pages/portal/PageGroups/PageGroupEdit";
import PageUserListOld from "./pages/portal/PageUsers/PageUserListOld";
import PageUserEdit from "./pages/portal/PageUsers/PageUserEdit";
import PageNotification from "./pages/portal/PageNotification/PageNotification";
import PageProfile from "./pages/portal/PageProfile/PageProfile";
import PageProgress from "./pages/portal/PageProgress/PageProgress";
import PageProgressWL from "./pages/portal/PageProgress/PageProgressWL";
import PageProgressPD from "./pages/portal/PageProgress/PageProgressPD";
import PagePeers from "./pages/portal/PagePeers/PagePeers";
import PagePeersDetails from './pages/portal/PagePeers/PagePeersDetails';
import PageMyGroup from "./pages/portal/PageMyGroup/PageMyGroup";
import PageMyGroupDetails from './pages/portal/PageMyGroup/PageMyGroupDetails';
import PageMyGroupReportPD from './pages/portal/PageMyGroup/PageMyGroupReportPD';
import PageMyGroupReportWL from './pages/portal/PageMyGroup/PageMyGroupReportWL';
import PageProfileEdit from "./pages/portal/PageProfile/PageProfileEdit";
import PageProfileChangePassword from "./pages/portal/PageProfile/PageProfileChangePassword";
import PageTrait from "./pages/portal/PageTrait/PageTrait";
import PageTraitAdd from "./pages/portal/PageTrait/PageTraitAdd";
import PageTraitEdit from "./pages/portal/PageTrait/PageTraitEdit";
import PageGroupManageMember from "./pages/portal/PageGroups/PageGroupManageMember";
import PageSubmissionDate from "./pages/portal/PageSubmissionDate/PageSubmissionDate";
import PageSubmissionDateAdd from "./pages/portal/PageSubmissionDate/PageSubmissionDateAdd";
import PageSubmissionDateEdit from "./pages/portal/PageSubmissionDate/PageSubmissionDateEdit";
import PageSubmissionAdmin from "./pages/portal/PageSubmissionAdmin/PageSubmissionAdmin";
import PageReportWLEdit from "./pages/portal/PageReport/PageReportWLEdit";
import PageScheduleReminder from "./pages/portal/PageScheduleReminder/PageScheduleReminder";
import PageScheduleReminderAdd from "./pages/portal/PageScheduleReminder/PageScheduleReminderAdd";
import PageScheduleReminderEdit from "./pages/portal/PageScheduleReminder/PageScheduleReminderEdit";
import PageReportPDEdit from "./pages/portal/PageReport/PageReportPDEdit";
import PageYear from "./pages/portal/PageYear/PageYear";
import PageYearAdd from "./pages/portal/PageYear/PageYearAdd";
import PageYearEdit from "./pages/portal/PageYear/PageYearEdit";

export const ROUTES = {
  LANDING: "/",
  AUTH: "/auth",
  AUTH_LOGIN: "/auth/login",
  AUTH_LOGOUT: "/auth/logout",
  AUTH_SETUP_PASSWORD: "/auth/setup-password",
  AUTH_FORGOT_PASSWORD: "/auth/forgot-password",
  AUTH_RESET_PASSWORD: "/auth/reset-password",
  AUTH_SET_NEW_PASSWORD: "/auth/set-new-password",
  PORTAL: "/portal",
  PORTAL_DASHBOARD: "/portal/dashboard",
  PORTAL_REPORT: "/portal/report",
  PORTAL_REPORT_WL: "/portal/report/workplace-learning",
  PORTAL_REPORT_PD: "/portal/report/professional-development",
  PORTAL_USERS: "/portal/user",
  PORTAL_USERS_REGISTER: "/portal/user/register",
  PORTAL_GROUPS: "/portal/group",
  PORTAL_GROUPS_ADD: "/portal/group/add",
  PORTAL_GROUPS_DETAILS: "/portal/group/details",
  PORTAL_YEARS: "/portal/year",
  PORTAL_YEARS_ADD: "/portal/year/add",
  PORTAL_STUDY_PROGRAM: "/portal/study-program",
  PORTAL_STUDY_PROGRAM_ADD: "/portal/study-program/add",

  PORTAL_INDUSTRY: "/portal/industry",
  PORTAL_INDUSTRY_ADD: "/portal/industry/add",

  PORTAL_TRAIT: "/portal/trait",
  PORTAL_TRAIT_ADD: "/portal/trait/add",

  PORTAL_SUBMISSION: "/portal/submission",

  PORTAL_SUBMISSION_DATE: "/portal/submission-date",
  PORTAL_SUBMISSION_DATE_ADD: "/portal/submission-date/add",

  PORTAL_SCHEDULE_REMINDER : "/portal/schedule-reminder",

  PORTAL_MAIL_TEMPLATE: "/portal/mail-templates",

  PORTAL_PROFILE: "/portal/profile",
  PORTAL_PROFILE_EDIT: "/portal/profile/edit",
  PORTAL_PROFILE_CHANGE_PASSWORD: "/portal/profile/change-password",
  PORTAL_PROGRESS: "/portal/progress",
  PORTAL_PROGRESS_WL: "/portal/progress/workplace-learning",
  PORTAL_PROGRESS_PD: "/portal/progress/professional-development",
  PORTAL_PEERS: "/portal/peers",
  PORTAL_PEERS_DETAILS: "/portal/peers/details",
  PORTAL_MY_GROUP: "/portal/my-group",
  PORTAL_MY_GROUP_DETAILS: "/portal/my-group/details",
  PORTAL_MY_GROUP_REPORT_PD: "/portal/my-group/report/professional-development",
  PORTAL_MY_GROUP_REPORT_WL: "/portal/my-group/report/workplace-learning",

  PORTAL_NOTIFICATION: "/portal/notification",

  REF: "/ref",
  REF_FORM: "/ref/form",
  REF_TYPOGRAPHY: "/ref/typography",
  REF_COMPONENT: "/ref/component",
  PAGE_SITEMAP: "/sitemap",
  PAGE_404: "*"
};

const { ADMIN, STUDENT, SUPERVISOR, INDUSTRY_SUPERVISOR } = ROLE;
const ROLE_ALL = [ADMIN, STUDENT, SUPERVISOR, INDUSTRY_SUPERVISOR];
const ROLE_NON_ADMIN = [STUDENT, SUPERVISOR, INDUSTRY_SUPERVISOR];
const ROLE_SPV = [SUPERVISOR, INDUSTRY_SUPERVISOR];

export const routerList = [
  {
    path: ROUTES.LANDING,
    element: <PageLanding />,
    errorElement: <PageError />
  },

  // Portal routes
  {
    path: ROUTES.PORTAL,
    element: <PagePortal />,
    children: [
      {
        index: true,
        element: <Navigate to={ROUTES.PORTAL_DASHBOARD} replace={true} />
      },
      {
        path: ROUTES.PORTAL_DASHBOARD,
        element: <PageDashboard />,
        roles: ROLE_ALL
      },

      {
        path: ROUTES.PORTAL_REPORT,
        element: <PageReport />,
        roles: STUDENT
      },

      {
        path: ROUTES.PORTAL_REPORT_WL,
        element: <PageReportWL />,
        roles: STUDENT
      },
      {
        path: ROUTES.PORTAL_REPORT_WL + "/edit/:submissionId",
        element: <PageReportWLEdit />,
        roles: STUDENT
      },

      {
        path: ROUTES.PORTAL_REPORT_PD,
        element: <PageReportPD />,
        roles: STUDENT
      },
      {
        path: ROUTES.PORTAL_REPORT_PD + "/edit/:submissionId",
        element: <PageReportPDEdit />,
        roles: STUDENT
      },

      {
        path: ROUTES.PORTAL_USERS,
        element: <PageUsers />,
        roles: ADMIN
      },
      {
        path: ROUTES.PORTAL_USERS + "/edit/:_id",
        element: <PageUserEdit />,
        roles: ADMIN
      },
      {
        path: ROUTES.PORTAL_USERS + "/old",
        element: <PageUserListOld />,
        roles: ADMIN
      },
      {
        path: ROUTES.PORTAL_USERS_REGISTER,
        element: <PageUserRegist />,
        roles: ADMIN
      },

      {
        path: ROUTES.PORTAL_GROUPS,
        element: <PageGroups />,
        roles: ADMIN
      },
      {
        path: ROUTES.PORTAL_GROUPS_ADD,
        element: <PageGroupAdd />,
        roles: ADMIN
      },
      {
        path: ROUTES.PORTAL_GROUPS + "/edit/:_id",
        element: <PageGroupEdit />,
        roles: ADMIN
      },
      {
        path: ROUTES.PORTAL_GROUPS + "/manage-member/:_id",
        element: <PageGroupManageMember />,
        roles: ADMIN
      },
      {
        path: ROUTES.PORTAL_GROUPS_DETAILS,
        element: <PageGroupDetails />,
        roles: ADMIN
      },
      {
        path: ROUTES.PORTAL_YEARS,
        element: <PageYear></PageYear>,
        roles: ADMIN,
      },
      {
        path: ROUTES.PORTAL_YEARS_ADD,
        element: <PageYearAdd></PageYearAdd>,
        roles: ADMIN,
      },
      {
        path: ROUTES.PORTAL_YEARS + "/edit/:_id",
        element: <PageYearEdit></PageYearEdit>,
        roles: ADMIN,
      },
      {
        path: ROUTES.PORTAL_STUDY_PROGRAM,
        element: <PageStudyProgram />,
        roles: ADMIN
      },
      {
        path: ROUTES.PORTAL_STUDY_PROGRAM_ADD,
        element: <PageStudyProgramAdd />,
        roles: ADMIN
      },
      {
        path: ROUTES.PORTAL_STUDY_PROGRAM + "/edit/:_id",
        element: <PageStudyProgramEdit />,
        roles: ADMIN
      },

      {
        path: ROUTES.PORTAL_INDUSTRY,
        element: <PageIndustry />,
        roles: ADMIN
      },
      {
        path: ROUTES.PORTAL_INDUSTRY_ADD,
        element: <PageIndustryAdd />,
        roles: ADMIN
      },
      {
        path: ROUTES.PORTAL_INDUSTRY + "/edit/:_id",
        element: <PageIndustryEdit />,
        roles: ADMIN
      },

      {
        path: ROUTES.PORTAL_TRAIT,
        element: <PageTrait />,
        roles: ADMIN
      },
      {
        path: ROUTES.PORTAL_TRAIT_ADD,
        element: <PageTraitAdd />,
        roles: ADMIN
      },
      {
        path: ROUTES.PORTAL_TRAIT + "/edit/:_id",
        element: <PageTraitEdit />,
        roles: ADMIN
      },

      {
        path: ROUTES.PORTAL_SUBMISSION,
        element: <PageSubmissionAdmin />,
        roles: ADMIN
      },

      {
        path: ROUTES.PORTAL_SUBMISSION_DATE,
        element: <PageSubmissionDate />,
        roles: ADMIN
      },
      {
        path: ROUTES.PORTAL_SUBMISSION_DATE_ADD,
        element: <PageSubmissionDateAdd />,
        roles: ADMIN
      },
      {
        path: ROUTES.PORTAL_SUBMISSION_DATE + "/edit/:_id",
        element: <PageSubmissionDateEdit />,
        roles: ADMIN
      },
      {
        path: ROUTES.PORTAL_SCHEDULE_REMINDER,
        element: <PageScheduleReminder />,
        roles: ADMIN,
      },
      {
        path: ROUTES.PORTAL_SCHEDULE_REMINDER + "/add",
        element: <PageScheduleReminderAdd />,
        roles: ADMIN,
      },
      {
        path: ROUTES.PORTAL_SCHEDULE_REMINDER + "/edit/:_id",
        element: <PageScheduleReminderEdit />,
        roles: ADMIN,
      },
      {
        path: ROUTES.PORTAL_MAIL_TEMPLATE,
        element: <PageMailTemplates />,
        roles: ADMIN
      },
      {
        path: ROUTES.PORTAL_MAIL_TEMPLATE + "/add",
        element: <PageMailTemplatesAdd />,
        roles: ADMIN
      },
      {
        path: ROUTES.PORTAL_MAIL_TEMPLATE + "/edit/:templateId",
        element: <PageMailTemplatesEdit />,
        roles: ADMIN
      },

      {
        path: ROUTES.PORTAL_NOTIFICATION,
        element: <PageNotification />,
        roles: ROLE_NON_ADMIN
      },

      {
        path: ROUTES.PORTAL_PROFILE,
        element: <PageProfile />,
        roles: ROLE_ALL
      },
      {
        path: ROUTES.PORTAL_PROFILE_EDIT,
        element: <PageProfileEdit />,
        roles: ROLE_ALL
      },
      {
        path: ROUTES.PORTAL_PROFILE_CHANGE_PASSWORD,
        element: <PageProfileChangePassword />,
        roles: ROLE_ALL
      },

      {
        path: ROUTES.PORTAL_PROGRESS,
        element: <PageProgress />,
        roles: STUDENT
      },
      {
        path: ROUTES.PORTAL_PROGRESS_WL + "/:submissionId",
        element: <PageProgressWL />,
        roles: STUDENT
      },
      {
        path: ROUTES.PORTAL_PROGRESS_PD + "/:submissionId",
        element: <PageProgressPD />,
        roles: STUDENT
      },

      {
        path: ROUTES.PORTAL_PEERS,
        element: <PagePeers />,
        roles: STUDENT
      },
      {
        path: ROUTES.PORTAL_PEERS_DETAILS + "/:peerId",
        element: <PagePeersDetails />,
        roles: STUDENT
      },

      {
        path: ROUTES.PORTAL_MY_GROUP,
        element: <PageMyGroup />,
        roles: ROLE_SPV
      },
      {
        path: ROUTES.PORTAL_MY_GROUP_DETAILS + "/:userId",
        element: <PageMyGroupDetails />,
        roles: ROLE_SPV
      },
      {
        path: ROUTES.PORTAL_MY_GROUP_REPORT_PD + "/:submissionId",
        element: <PageMyGroupReportPD />,
        roles: ROLE_SPV
      },
      {
        path: ROUTES.PORTAL_MY_GROUP_REPORT_WL + "/:submissionId",
        element: <PageMyGroupReportWL />,
        roles: ROLE_SPV
      },

      {
        path: "*",
        element: <PagePortalNotFound />,
        roles: ROLE_ALL
      }
    ]
  },

  // Portal auth
  {
    path: ROUTES.AUTH,
    element: <PageAuth />,
    children: [
      {
        index: true,
        element: <Navigate to={ROUTES.AUTH_LOGIN} replace={true} />
      },
      {
        path: ROUTES.AUTH_LOGIN,
        element: <PageLogin />
      },
      {
        path: ROUTES.AUTH_LOGOUT,
        element: <PageLogout />
      },
      {
        path: ROUTES.AUTH_SETUP_PASSWORD + "/:setupPasswordToken",
        element : <PageSetupPassword />
      },
      {
        path: ROUTES.AUTH_FORGOT_PASSWORD,
        element: <PageForgotPassword />
      },
      {
        path: ROUTES.AUTH_RESET_PASSWORD + "/:resetPasswordToken",
        element: <PageResetPassword />
      },
      {
        path: ROUTES.AUTH_SET_NEW_PASSWORD,
        element: <PageSetNewPassword />
      }
    ]
  },

  // Reference stuff
  {
    path: ROUTES.REF,
    element: <PageReference />,
    children: [
      {
        index: true,
        element: <Navigate to={ROUTES.REF_COMPONENT} replace={true} />
      },
      {
        path: ROUTES.REF_COMPONENT,
        element: <PageReferenceButton />
      },
      {
        path: ROUTES.REF_FORM,
        element: <PageReferenceForm />
      },
      {
        path: ROUTES.REF_TYPOGRAPHY,
        element: <PageReferenceTypography />
      },
      {
        path: "*",
        element: <PagePortalNotFound />
      }
    ]
  },

  {
    path: ROUTES.PAGE_SITEMAP,
    element: <PageSitemap />
  },
  {
    path: ROUTES.PAGE_404,
    element: <PageError code={404} />
  }
];

export const router = createBrowserRouter(routerList);
