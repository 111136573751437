import { Link, useParams } from "react-router-dom";
import usePageTitle from "../../../hooks/use-page-title"
import { ROUTES } from "../../../routes";
import BasicDataTable from "../../../components/common/BasicDataTable";
import Utils from "../../../utils/utils";
import { Dropdown, Modal } from "react-bootstrap";
import { MdDelete, MdSearch } from "react-icons/md";
import React, { useContext, useEffect, useImperativeHandle, useRef, useState } from "react";
import useDebounce from "../../../hooks/use-debounce";
import { usePortalSettingSet } from "../../../context/portal-context";
import Debouncer from "../../../modules/debouncer/debouncer";
import AppUserContext from "../../../context/app-user-context";
import Notifier from "../../../modules/notifier/notifier";
import ApiGroup from "../../../modules/api/group";
import { HiArrowLeft, HiOutlineSearch } from "react-icons/hi";
import ROLE from "../../../modules/role";
import RoleFilterSelector from "../../../components/portal/RoleFilterSelector";
import { CONST_SELECT_ROLES } from "../../../config/constants";
import ApiUser from "../../../modules/api/user";
import UserImage from "../../../components/portal/UserImage";
import ModulePopup from "../../../modules/popup/popup";

type TRefPopupInvite = {
  show: () => void;
  hide: () => void;
};
const PopupInviteNewMember = React.forwardRef((props: {
  existingUserIds: string[];
  onClickBtnInviteMember: (users: any[]) => void;
}, ref) => {
  const refInputSearch = useRef<HTMLInputElement>();
  const [show, setShow] = useState(false);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState<any[]>([]);
  const searchDebounced = useDebounce(search);

  const parseUser = user => {
    const isMember = props.existingUserIds.indexOf(user._id) > -1;

    return {
      ...user,
      checked: isMember,
      isMember
    };
  };

  useImperativeHandle(ref, () => ({
    show() {
      setShow(true);
    },
    hide() {
      setShow(false);
    }
  }));

  useEffect(() => {
    if (show) {
      setSearch("");
    } else {
      setTimeout(() => {
        setSearch("");
        setLoading(true);
        setUsers([]);
      }, 350);
    }
  }, [show]);

  useEffect(() => {
    if (!show) return;

    setLoading(true);
    setUsers([]);
    ApiUser.searchByName(searchDebounced).then(async data => {
      setUsers(data.map(parseUser));
      await Utils.sleep(250);
      setLoading(false);
      setTimeout(() => {
        refInputSearch.current && refInputSearch.current.focus();
      }, 50);
    });
  }, [show, searchDebounced]);

  return <Modal centered show={show} onHide={() => {
    setShow(false);
  }}>
    <Modal.Header closeButton>
      <div>
        <Modal.Title className="fs-4 invite-modal__title">
          Invite New Member
        </Modal.Title>
      </div>
    </Modal.Header>
    <Modal.Body className="invite-modal__body">
      <div className="input-group h-100 invite-modal__search mb-3">
        <span className="input-group-text" id="search">
          <HiOutlineSearch />
        </span>
        <input
          ref={refInputSearch}
          disabled={loading}
          autoFocus
          type="text"
          className="form-control"
          placeholder="Search..."
          aria-label="search"
          aria-describedby="search"
          onChange={(e) => {
            setSearch(e.target.value);
          }}
        />
      </div>
      <div className="invite-list-box">

        {loading && <p className="alert alert-info">Loading user(s)...</p>}

        {!loading && users.length === 0 && <p className="alert alert-not-found">User(s) not found</p>}

        {!loading && users.length > 0 && users.map((user) => <React.Fragment key={user._id}>
          <div className={`invite-list-item ${user.isMember ? "ili--member" : ""}`}>
            <label title={user.isMember ? "This user already a member" : ""}>
              <span>
                <input type="checkbox" disabled={user.isMember} checked={user.checked}
                  onChange={function (e) {
                    setUsers(users => {
                      return users.map(currUser => {
                        if (user._id === currUser._id) {
                          user.checked = e.target.checked;
                        }

                        return currUser;
                      });
                    });
                    user.checked = e.target.checked;
                  }}
                />
              </span>
              <UserImage width={50} url={user.avatar} />
              <span>
                {user.name}
                <i>{user.email}</i>
              </span>
            </label>
          </div>
        </React.Fragment>)}

      </div>
    </Modal.Body>
    <Modal.Footer>
      <button className="btn btn-primary" onClick={(e) => {
        e.preventDefault();
        setShow(false);
        props.onClickBtnInviteMember(users.filter(user => user.checked && !user.isMember));
      }} disabled={users.filter(user => user.checked && !user.isMember).length === 0}>
        Invite Member
      </button>
      <button className="btn btn-outline-primary" onClick={(e) => {
        e.preventDefault();
        setShow(false);
      }}>
        Close
      </button>
    </Modal.Footer>
  </Modal>;
});

export default function PageGroupManageMember() {
  const title = "Manage Group's Member(s)";
  usePageTitle(title);
  usePortalSettingSet("label_header", title);

  const user = useContext(AppUserContext);
  const { _id } = useParams();
  const [refresh, setRefresh] = useState(false);
  const refPopupInvite = useRef<TRefPopupInvite>();
  const refLoading = useRef(false);
  const [group, setGroup] = useState<any>({});
  const [groupFound, setGroupFound] = useState(false);
  const [memberCount, setMemberCount] = useState(0);
  const [role, setRole] = useState<string>(ROLE.STUDENT);
  const [dataApi, setDataApi] = useState<any[]>([]);
  const [data, setData] = useState<any[]>([]);
  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const [loadingGroupInfo, setLoadingGroupInfo] = useState(true);
  const [loadingTable, setLoadingTable] = useState(true);
  const [search, setSearch] = useState("");
  const searchTerm = useDebounce(search, 500);

  useEffect(() => {
    setLoadingGroupInfo(true);
    ApiGroup.get(_id).then(res => {
      if (res.status) {
        setGroup(res.data);
        setGroupFound(true);
      } else {
        setGroupFound(false);
      }

      setLoadingGroupInfo(false);
    });
  }, []);

  useEffect(() => {
    if (refLoading.current) return;
    refLoading.current = true;
    setLoadingTable(true);
    ApiGroup.getUsersByGroupId(_id).then(data => {
      refLoading.current = false;
      setDataApi(data);
      setMemberCount(data.length);
    })
  }, [refresh]);

  useEffect(() => {
    (async () => {
      setLoadingTable(true);
      await Utils.sleep(100);

      const filteredRoleData = dataApi.filter(i => i.role === role);

      if (searchTerm === "") {
        setData(filteredRoleData);
        setLoadingTable(false);
        return;
      }

      const r = new RegExp(Utils.escapeRegExp(searchTerm), "i");
      setData(filteredRoleData.filter(i => {
        return r.test(i.name) || r.test(i.email);
      }));
      setLoadingTable(false);
    })();
  }, [searchTerm, role, dataApi]);

  return <main className="form-generic content-generic content-generic--fluid">

    <h1 className="fs-title hide-mobile-down">{title}</h1>

    {loadingGroupInfo && <p className="alert alert-info mt-3">Loading group detail...</p>}

    {!loadingGroupInfo && !groupFound && <p className="alert alert-danger mt-3">
      Group not found. <Link to={ROUTES.PORTAL_GROUPS}>Click here to go back</Link>.
    </p>}

    {!loadingGroupInfo && groupFound && <React.Fragment>
      <p>
        Group Name: <strong>{group.name}</strong><br/>
        Number of member(s): <strong>{memberCount}</strong>
      </p>

      <div className="cg__actions">
        <div className="cg__action-buttons">
          <Link className="btn btn-primary"
            to={ROUTES.PORTAL_GROUPS}
          >
            <HiArrowLeft/>{" "}Back
          </Link>
          {" "}
          <button className="btn btn-primary"
            onClick={() => refPopupInvite.current.show()}
          >
            Invite New Member
          </button>
          {" "}
          <button className="btn btn-outline-primary"
            onClick={() => setRefresh(i => !i)}
          >
            Refresh
          </button>
        </div>
      </div>

      <div className="cg__actions">
        <div className="cg__action-buttons">
          <RoleFilterSelector default={role} options={CONST_SELECT_ROLES.filter(item => item.value !== ROLE.ADMIN)} onChange={(v) => {
            setRole(v);
          }} />
        </div>
        <div className="cg__action-search">
          <span>
            <MdSearch/>
          </span>
          <input type="text" placeholder="search" onChange={(e) => setSearch(e.target.value)} />
        </div>
      </div>

      <BasicDataTable
        loading={loadingTable}
        columns={[
          {
            id: "name",
            name: "Name",
            cell: (row) => row.name,
            sortable: true,
            sortFunction: Utils.sortByObjectPropNatural("name")
          },
          {
            id: "email",
            name: "Email",
            cell: (row) => row.email,
            sortable: true,
            sortFunction: Utils.sortByObjectPropNatural("email")
          },
          {
            id: "studyProgramName",
            name: "Programme",
            cell: (row) => row.studyProgramName,
            sortable: true,
            sortFunction: Utils.sortByObjectPropNatural("studyProgramName")
          },
          {
            id: "year",
            name: "Cohort",
            cell: (row) => row.year,
            sortable: true,
            sortFunction: Utils.sortByObjectPropNatural("year")
          },
          {
            name: "Action",
            button: true,
            cell: (row) => <div>
              <Dropdown align={"end"} drop={"down"}>
                <Dropdown.Toggle variant="primary" id="dropdown-basic" className="btn-sm">
                  Action
                </Dropdown.Toggle>

                <Dropdown.Menu>

                  <button className="dropdown-item btn-danger" onClick={() => {
                    ModulePopup.showAlertConfirm({
                      title: "Delete Confirmation",
                      description: <>
                        <p>Are you sure to remove this user from current group?
                          <br/> This action can't be undone.
                        </p>
                      </>,
                      onYes: () => Debouncer.execute("DELETE_MEMBER", async () => {
                        const res = await ApiGroup.removeMember(_id, row._id);

                        if (res.status) {
                          setRefresh(i => !i);
                          Notifier.success(res.message);
                        } else {
                          Notifier.error(res.message);
                        }
                      })
                    });
                  }}>
                    <MdDelete/> {" "} Remove member
                  </button>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          }
        ]}
        data={data}
        search={search}
        selectableRows={false}
        onSelectedRowsChange={(e) => {
          setSelectedRows(e.selectedRows);
        }}
      />

      <PopupInviteNewMember ref={refPopupInvite}
        existingUserIds={dataApi.map(item => item._id)}
        onClickBtnInviteMember={(users) => {
          if (users.length === 0) return;
          ApiGroup.addMember(_id, users.map(user => user._id).join(",")).then(res => {
            if (res.status) {
              setRefresh(i => !i);
              Notifier.success(res.message);
            } else {
              Notifier.error(res.message);
            }
          });
        }}
      />
    </React.Fragment>}

  </main>;
}
