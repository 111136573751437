export type TToggleSwitchProps = JSX.IntrinsicElements["input"] & {};

export default function ToggleSwitch(props: TToggleSwitchProps) {
  return (
    <label className="toggle-switch" data-comp="ToggleSwitch">
      <input {...props} type="checkbox" id={props.name} />
      <span className="slider round"></span>
    </label>
  )
}
