import { createContext, useState, useEffect, Dispatch, SetStateAction, useContext } from "react";

export type TPortalContext = {
  portalSetting: TPortalSetting;
  setPortalSetting: Dispatch<SetStateAction<TPortalSetting>>;
  notifCount: number;
  setNotifCount: Dispatch<SetStateAction<number>>;
};

export const PortalContext = createContext<TPortalContext>(undefined);

export type TPortalSetting = {
  is_homepage: boolean,
  mobile_nav_open: boolean;
  label_header: string;
  ready: boolean;
  show_nav: boolean;
  show_user_hero: boolean;
  bottom_nav: boolean;
};

function getDefaultPortalSetting(): TPortalSetting {
  return {
    is_homepage: false,
    mobile_nav_open: false,
    label_header: "",
    ready: false,
    show_nav: true,
    show_user_hero: true,
    bottom_nav: false
  };
}

export function usePortalSettingSet(key: keyof TPortalSetting, value: any) {
  const ctx = useContext(PortalContext);

  useEffect(() => {
    let existingVal;
    ctx && ctx.setPortalSetting(d => {
      existingVal = d[key];
      return ({ ...d, [key]: value });
    });
    return () => {
      ctx.setPortalSetting(d => ({
        ...d,
        [key]: existingVal
      }));
    };
  }, []);
}

/**
 * @example
 * const { counter, setCounter } = useContext(PortalContext);
 */
export const PortalContextProvider = (props: any) => {
  const [portalSetting, setPortalSetting] = useState(getDefaultPortalSetting);
  const [notifCount, setNotifCount] = useState(0);

  useEffect(() => {
    const timer = setTimeout(() => {
      setPortalSetting(d => ({ ...d, ready: true }));
    }, 100);
    return () => clearTimeout(timer);
  }, []);

  return <PortalContext.Provider value={{
    portalSetting,
    setPortalSetting,
    notifCount,
    setNotifCount
  }}>
    {props.children}
  </PortalContext.Provider>
};
