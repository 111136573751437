import StorageCrypt from "./storage/storage-crypt";

const KEY = "__rmbmestore__";

function getDefault(): TRememberMeData {
  return {
    checked: false,
    email: "",
    password: ""
  };
}

export type TRememberMeData = {
  checked: boolean;
  email: string;
  password: string;
}

export default class RememberMe {

  static async store(email: string, password: string) {
    await StorageCrypt.set(KEY, {
      email,
      password,
      checked: true
    });
  }

  static async remove() {
    await StorageCrypt.remove(KEY);
  }

  static async get(): Promise<TRememberMeData> {
    return StorageCrypt.get(KEY, getDefault());
  }

}
