import React, { useState, useEffect, useRef } from 'react';
import { HiOutlineUpload, HiOutlineX, HiOutlineDocumentDuplicate } from 'react-icons/hi';
import Notifier from '../../../modules/notifier/notifier';
import ApiUser from '../../../modules/api/user';
import Debouncer from '../../../modules/debouncer/debouncer';
import { Alert } from 'react-bootstrap';

export default function PageUserRegistCSV() {
  const fileRef = useRef<HTMLInputElement>();
  const [sending, setSending] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [uploadedFile, setUploadedFile] = useState<File | null>(null);
  const [uploadProgress, setUploadProgress] = useState(-1);
  const [error, setError] = useState('');
  const [errorList, setErrorList] = useState<any[]>([]);
  const [sentEmailList, setSentEmailList] = useState<Array<{email: string; isSent: boolean;}>>([]);

  const resetFileValue = (resetUploadedFile = true) => {
    try {
      fileRef.current.value = "";
      setUploadProgress(-1);

      if (resetUploadedFile) {
        setUploadedFile(null);
      }
    } catch (ignore) {}
  };

  const handleDragEnter = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDragging(false);
  };

  // Reference only for now
  const intervalRef = useRef<NodeJS.Timeout>();
  const progressLogic = () => {
    setUploadProgress(0);
    intervalRef.current = setInterval(() => {
      setUploadProgress((prevProgress) => {
        if (prevProgress >= 100) {
          clearInterval(intervalRef.current);
          Notifier.success('File uploaded successfully.');
          return 0;
        } else {
          return prevProgress + 10;
        }
      });
    }, 500);
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDragging(false);
    const file = event.dataTransfer.files[0];

    if (validateFileType(file)) {
      setError('');
      setUploadedFile(file);
    } else {
      resetFileValue();
      setError('Invalid file type. Please upload a CSV file.');
    }
  };

  const handleFileInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];

    if (file && validateFileType(file)) {
      setError("");
      setErrorList([]);
      setSentEmailList([]);
      setUploadedFile(file);
    } else {
      resetFileValue();
      setError('Invalid file type. Please upload a CSV file.');
    }
  };

  const handleUploadAreaClick = () => {
    resetFileValue(false);
    document.getElementById('user-form-upload-file-input')?.click();
  };

  const handleRemoveFile = () => {
    resetFileValue();
    setUploadedFile(null);
    setError('');
    setErrorList([]);
    setSentEmailList([]);
  };

  const validateFileType = (file: File) => {
    const allowedTypes = ['text/csv'];
    return allowedTypes.includes(file.type);
  };

  const handleSubmit = (e) => {
    if (e) {
      e.preventDefault();
    }

    Debouncer.execute("SUBMIT_CSV", async () => {
      setSending(true);
      const res = await ApiUser.addByCsv(uploadedFile);

      if (res.status) {
        handleRemoveFile();
        Notifier.success(res.message);
        setSentEmailList(res.emailSendingStatus);
      } else {
        setErrorList(res.errorList);
        Notifier.error(res.message);
      }

      setSending(false);
    });
  };

  return (
    <div data-comp="PageUserRegistCSV">
      <form className="form-register">
        <div className="user-form-upload">
          <h3 className='fs-5'>Add new CSV File</h3>
          <p><a href="/assets/sample/sample-user.csv?t=20230529" target="_blank">Click here to download the sample (<strong>sample-user.csv</strong>)</a>.</p>
          <div
            className={`upload-area ${isDragging ? 'dragging' : ''} border rounded col-12 text-center`}
            onDragEnter={handleDragEnter}
            onDragOver={handleDragEnter}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
            onClick={handleUploadAreaClick}
          >
            <HiOutlineUpload className="upload-icon mb-3 text-secondary" />
            <p className="mb-1 fs-5">Drag & Drop or Choose file to upload</p>
            <p className="text-secondary fs-5">CSV file</p>
            <input ref={fileRef} type="file" accept=".csv" onChange={handleFileInputChange} id="user-form-upload-file-input" className="d-none" />
          </div>
        </div>
        {error && error !== "" && (
          <div className="alert alert-danger mt-3 mb-0" role="alert">
            {error}
          </div>
        )}
      </form>

      {uploadedFile && (
        <div className="uploaded-file-container mt-3 p-2 rounded">
          <div className="uploaded-file d-flex justify-content-between align-items-center p-2 bg-white rounded">
            <p className="mb-0"><span><HiOutlineDocumentDuplicate className='fs-4 me-2'/></span>{uploadedFile.name}</p>
            <HiOutlineX className="cancel-icon" onClick={handleRemoveFile} />
          </div>
          {uploadProgress > -1 && <div className="upload-progress-bar-container">
            <div className="">
              <div className="progress-bar" role="progressbar" style={{ width: `${uploadProgress}%` }} aria-valuenow={uploadProgress} aria-valuemin={0} aria-valuemax={100} />
            </div>
          </div>}
        </div>
      )}

      {sentEmailList.length > 0 && <Alert variant="info" onClose={() => {
        setSentEmailList([]);
      }} dismissible className="import-csv-email-notify-status">
        <h4>Email Notification Status</h4>
        <p>Below is the list of notifications of each imported user(s). Failed means
          system was unable to notify the user via email.
        </p>
        {sentEmailList.map(item => <Alert variant={item.isSent ? "success" : "danger"}>
          {item.isSent && <p className="mb-0">Notified to <strong>{item.email}</strong></p>}
          {!item.isSent && <p className="mb-0">Failed to notify <strong>{item.email}</strong></p>}
        </Alert>)}
      </Alert>}

      {errorList.length > 0 && <div className="alert alert-danger mt-3">
        <h5>Error found while importing:</h5>
        <ul>
          {errorList.map((item, i) => <React.Fragment key={i}>
            <li className="mb-2"><strong>Row #{i + 2}</strong> {typeof item.email === "string" && item.email.length > 0 ? `(${item.email})` : ""}: <br/><em>{item.error}</em></li>
          </React.Fragment>)}
        </ul>
      </div>}

      <div className="mb-3">
        <button type="button" className="btn btn-primary mt-3" disabled={!uploadedFile || sending}
          onClick={handleSubmit}
        >
          Submit Data
        </button>
      </div>
    </div>
  );
}
