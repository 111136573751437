import React from 'react';

interface TimelineProgressProps {
  value?: number;
  month?: string;
  active?: boolean;
  current?: boolean;
  between?: boolean;
}

const TimelineProgress: React.FC<TimelineProgressProps> = ({
  value,
  month,
  active,
  current,
  between
}) => {
  const dotColor = active || current  ? 'bg-secondary' : '';
  const lineColor = active  ? 'bg-primary' : '';

  return (
    <div className="timeline-progress">
      <div className={`timeline-progress__dot ${active || current ? '' : 'opacity-0'} ${current ? 'timeline-progress__dot--current' : ''} ${between ? 'opacity-0' : ''}`}>
        <span>
          {value}
        </span>
      </div>
      <div className={`timeline-progress__dot--small ${dotColor} ${between ? 'bg-white' : ''}`} />
      <div className={`timeline-progress__line ${lineColor}`} />
      <div className="timeline-progress__month">{month}</div>
    </div>
  );
};

interface TimelineProps {
  data: { month?: string; value?: number; active: boolean, current?: boolean, between?: boolean }[];
}

const Timeline: React.FC<TimelineProps> = ({ data }) => {

  return (
    <div className="d-flex timeline-progress__container position-relative" data-comp="Timeline">
      <div className='tp__info flex-column justify-content-between d-none d-md-flex'>
        <p>Trait</p>
        <div className="timeline-progress__line bg-primary rounded-start" />
        <p>Month</p>
      </div>
      {data.map((p, index) => (
        <React.Fragment key={index}>
          <TimelineProgress
            key={index}
            value={p.value}
            month={p.month}
            active={p.active}
            current={p.current}
            between={p.between}
          />
          {data.length - 1 === index ? null : (
          <TimelineProgress
            key={data.length + index}
            value={0}
            month=""
            active={p.active}
            // current={p.current}
            between={true}
          />
          )}
        </React.Fragment>
      ))}
      <p className='tp__end d-none d-md-block'>End</p>
    </div>
  );
};

export default Timeline;
