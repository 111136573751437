import { useEffect, useState } from "react";

export default function useDebounce<T>(input: T, delay = 250): T {
  const [value, setValue] = useState(input);

  useEffect(() => {
    const timers = setTimeout(() => {
      setValue(input);
    }, delay);
    return () => clearTimeout(timers);
  }, [input, delay]);

  return value;
}
