import { Link, useNavigate } from "react-router-dom";
import UILoadingBlank from "../components/UILoadingBlank";
import useLoading from "../hooks/use-loading";
import usePageTitle from "../hooks/use-page-title";

type TErrorPageProps = {
  code?: number;
};

export default function PageError(props: TErrorPageProps) {
  usePageTitle(`Error${props.code === 404 ? ': Page not found' : ''}`);
  const isLoading = useLoading(350);
  const navigate = useNavigate();

  return <div className="ui-center text-center">
    <UILoadingBlank show={isLoading}/>
    <div>
      <h1>Error</h1>
      {props.code === 404 && <p>The page you are looking for can't be found.</p>}
      {props.code !== 404 && <p>An error occurred.</p>}
      <p>
        {window.history.length === 1 && <Link to="/">Back to home</Link>}
        {window.history.length > 1 && <a href="#" onClick={(e) => { e.preventDefault(); navigate(-1); }}>Back to previous page</a>}
      </p>
    </div>
  </div>
}
