import { useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import PortalTemplate from "../../components/portal/PortalTemplate";
import UILoadingBlank from "../../components/UILoadingBlank";
import Config from "../../config/config";
import AppUserContext from "../../context/app-user-context";
import useAuthUser from "../../hooks/use-auth-user";
import useRefreshTokenUser from "../../hooks/use-refresh-token-user";
import useSessionConfig from "../../hooks/use-session-config";
import AppUser from "../../modules/app-user/app-user";
import Notifier from "../../modules/notifier/notifier";
import CheckRole from "../../roles";
import { Generics } from "../../utils/generics";
import Utils from "../../utils/utils";
import { PortalContextProvider } from "../../context/portal-context";
import { motion } from "framer-motion";
import PortalJustLoggedCheck from "../../components/portal/PortalJustLoggedCheck";
import useAutoLogout from "../../hooks/use-auto-logout";

const pageVariants = {
  initial: { opacity: 0 },
  in: { opacity: 1 },
  out: { opacity: 0 }
};

const pageTransition = {
  type: "tween",
  ease: "linear",
  duration: 0.35
};

export default function PagePortal() {
  const { user, isLoading } = useAuthUser();
  const navigate = useNavigate();
  const token = useRefreshTokenUser();
  const sessConfig = useSessionConfig();
  const { pathname } = useLocation();

  /**
   * This feature is used for capturing what page this logged-out user wants
   * to access, then navigate that user to login page first. After that user
   * logged-in, system will check where the last page this user wants to visit
   * and automatically redirect to that page.
   *
   * Example scenario:
   * Suppose UserX
   */
  useEffect(() => {
    if (!isLoading && !user.logged) {
      sessConfig.set("redirect", Generics.getUrlPathAndParams());
      navigate("/auth/login", { replace: true });
    }
  }, [isLoading, user]);

  /**
   * Don't freak out. What this portion does is to check whether the refresh
   * token feature still giving the refreshed token. If the token is invalid
   * and it's not caused by network error, then immediately log-out the user.
   */
  useEffect(() => {
    if (Config.IS_MOCKUP_MODE) return;

    if (!isLoading && user.logged && token.initialized && !token.isValid && !token.isNetworkError) {
      Notifier.error("Session expired, please login again.");
      AppUser.logout().then(() => Utils.sleep(1000))
        .then(() => navigate("/auth/login"));
    }
  }, [user, token]);

  useEffect(() => {
    const timer = setTimeout(() => window.scrollTo(0, 0), 100);
    return () => clearTimeout(timer);
  }, [pathname]);

  useAutoLogout(3600);

  return <>
    <UILoadingBlank show={isLoading} />
    {!isLoading && user.logged && <AppUserContext.Provider value={user}>
      <PortalContextProvider>
        <PortalTemplate>
          <PortalJustLoggedCheck />
          <motion.div key={pathname}
            initial="initial"
            animate="in"
            variants={pageVariants}
            transition={pageTransition}
          >
            <CheckRole>
              <Outlet/>
            </CheckRole>
          </motion.div>
        </PortalTemplate>
      </PortalContextProvider>
    </AppUserContext.Provider>}
  </>;
}
