import ApiGenericData, { TGenericItem } from "./generic-data";

export type TStudyProgramItem = TGenericItem & {};

const ApiStudyProgram = {
  pagingSearch: ApiGenericData.pagingSearch("StudyProgram"),
  add: ApiGenericData.add<Omit<TStudyProgramItem, "_id">>("StudyProgram"),
  get: ApiGenericData.get,
  update: ApiGenericData.update<Omit<TStudyProgramItem, "_id">>("StudyProgram")
};

export default ApiStudyProgram;
