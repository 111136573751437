import { Link, useNavigate } from "react-router-dom";
import usePageTitle from "../../../hooks/use-page-title";
import { ROUTES } from "../../../routes";

export default function PagePortalNotFound() {
  usePageTitle("Page not found");
  const navigate = useNavigate();

  return <>

    <main className="content-generic content-generic--vh-center">
      <div className="text-center">
        <h1>Page not found</h1>
        <p>The page you are looking for cannot be found.</p>
        <p>
          <Link to={""} onClick={(e) => {
            e.preventDefault();

            if (window.history.length > 1) {
              navigate(-1);
            } else {
              navigate(ROUTES.PORTAL_DASHBOARD);
            }
          }}>
            Click here to go back
          </Link>.
        </p>
      </div>
    </main>

  </>;
}
