
export namespace Regexes {

  export const NOT_ACCEPTED_OBJECT_KEY = /[^a-zA-Z0-9_$\s]+/gim;
  export const NOT_ACCEPTED_FIRST_CHARACTER_OF_OBJECT_KEY = /^[^a-zA-Z_$]+/;
  export const ONE_OR_MORE_PREFIX_SPACES = /^\s+/;
  export const ONE_OR_MORE_SUFFIX_SPACES = /\s+$/;
  export const ONE_OR_MORE_STRIP = /\-+/gim;
  export const ONE_OR_MORE_SPACES_WITH_LOWER_CASE_LETTER = /\s+([a-z])/gim;
  export const ONE_OR_MORE_SPACES_WITH_NON_LOWER_CASE_LETTER = /\s+([^a-z])/gim;
  export const CAMEL_CASE = /([a-z])([A-Z])/g;
  export const NUMBER = /^\d+((\.\d+)|(e\d+))?$/g;

  /**
   * @description
   * This will match ANY characters before `<body...attributes?>` tag
   * '...attributes?' means if there's zero or more attributes
   */
  export const HTML_BEFORE_BODY = /[\s\S]*(?=<body(\s{1,}((((\w|\d)+((-(\w|\d)+)*)?)=(("[^"]*")|('[^']*')|[^\s'"]+))|((\w|\d)+((-(\w|\d)+)*)?)))*\s*>)/gim;

  /**
   * @description
   * This will match the opening `<body...attributes?>` tag
   *
   * @test must be passed:
   * - <body>
   * - <body cool attribute must-be-passed>
   * - <body data-target="#cool-id[name='Faisal']" class='fancy-body'>
   * - Any other crazy case(s)? Please help to test and add to above if passed... Thanks in advance!
   */
  export const HTML_TAG_BODY = /<body(\s{1,}((((\w|\d)+((-(\w|\d)+)*)?)=(("[^"]*")|('[^']*')|[^\s'"]+))|((\w|\d)+((-(\w|\d)+)*)?)))*\s*>/gim;

  /**
   * @description
   * This will match the <script> tag and MUST have src attribute
   *
   * @test must be passed:
   * - <script src=""></script>
   * - <script src=''></script>
   * - <script src="//ajax.googleapis.com/ajax/libs/jquery/3.1.1/jquery.min.js"></script>
   * - <script src='http://fxmweb.com/js/ui/ui.core.js'></script>
   * - <script src="	 http://cool.url/has some spaces	and tabs.js    		    "></script>
   * - Any other crazy case(s)? Please help to test and add to above if passed... Thanks in advance!
   *
   * @note
   * The stuff starting from "(\s{1,}((((\w|\d)+ ..." is used to check attributes that might be exist
   */
  export const HTML_TAG_SCRIPT_SRC = /<script\s+src=(["'])[\w\s:\-.\/]{0,}\1(\s{1,}((((\w|\d)+((-(\w|\d)+)*)?)=(("[^"]*")|('[^']*')|[^\s'"]+))|((\w|\d)+((-(\w|\d)+)*)?)))*\s*>\s{0,}<\/script>/gim;

  /**
   * @description
   * This will match the <script> tag and its content(s) followed by its closing </script> tag
   *
   * @test must be passed:
   * - <script></script>
   * - Any other crazy case(s)? Please help to test and add to above if passed... Thanks in advance!
   *
   * @note
   * The stuff starting from "(\s{1,}((((\w|\d)+ ..." is used to check attributes that might be exist
   *
   * @stilldoing
   * (<script>(([\s]*[\S]*[\s]*){0,})(?!\s*<s)<\/script>){1,}
   */
  export const HTML_TAG_SCRIPT_BODY = /<script(?:\s+src=(["'])[\w\s:\-.\/]{0,}\1(\s{1,}((((\w|\d)+((-(\w|\d)+)*)?)=(("[^"]*")|('[^']*')|[^\s'"]+))|((\w|\d)+((-(\w|\d)+)*)?)))*)?\s*>\s{0,}<\/script>/gim;

}
