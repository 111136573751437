import { usePortalSettingSet } from "../../../context/portal-context";
import usePageTitle from "../../../hooks/use-page-title";
import MyGroupListing from '../../../components/portal/MyGroupListing';
import { SUBMISSION_PD } from '../../../config/constants';

export default function PageMyGroup() {
  const title = "My Group";
  usePageTitle(title);
  usePortalSettingSet("label_header", title);

  return (
  <main className="content-generic content-generic--fluid" data-comp="PageMyGroup">

    <MyGroupListing tabbed={true} defaultSubmissionType={SUBMISSION_PD} />

  </main>
  );
}
