import Config from "../../config/config";
import AppUser from "../app-user/app-user";

const base = Config.API_URL.replace(/\/$/, "");

export function apiUrl(route: string) {
  return `${base}${route}`;
}

export async function getAuthHeader() {
  return {
    headers: {
      Authorization: `Bearer ${(await AppUser.getInfo()).token} `
    }
  };
}
