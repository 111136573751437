import { useEffect, useState } from "react";
import Config from "../config/config";

export default function usePageTitle(title: string, addSuffix = true) {
  const [titleFromUser, setTitle] = useState('');
  const [prevTitle, setPrevTitle] = useState('');

  useEffect(() => {
    setPrevTitle(document.title);
  }, []);

  useEffect(() => {
    document.title = title;
    setTitle(title);
    return () => { document.title = prevTitle; };
  }, [title, prevTitle]);

  useEffect(() => {
    document.title = titleFromUser + (addSuffix ? ` ${Config.TITLE_SUFFIX}` : ``);
  }, [titleFromUser])

  return { title: titleFromUser, setTitle };
}
