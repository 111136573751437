import usePageTitle from "../hooks/use-page-title";
import { ROUTES } from "../routes";

function BadgeDone() {
  return <span className="badge bg-success" title="Ready to integrate">Done</span>;
}

function BadgeWip() {
  return <span className="badge bg-warning" title="Work in progress">WIP</span>;
}

export default function PageSitemap() {
  usePageTitle("Sitemap");

  return <div className="container py-5">
    <h1 className="mb-3">Sitemap</h1>

    <h3>Reference(s)</h3>
    <ol>
      <li>
        <a href={ROUTES.REF_FORM}>Form <BadgeWip/></a>
      </li>
      <li>
        <a href={ROUTES.REF_TYPOGRAPHY}>Typography <BadgeWip/></a>
      </li>
    </ol>

    <h3>Auth</h3>
    <ol>
      <li>
        <a href={ROUTES.AUTH_LOGIN}>Login <BadgeWip/></a>
      </li>
      <li>
        <a href={ROUTES.AUTH_LOGOUT}>Logout <BadgeWip/></a>
      </li>
      <li>
        <a href={ROUTES.AUTH_RESET_PASSWORD}>Reset Password <BadgeWip/></a>
      </li>
      <li>
        <a href={ROUTES.AUTH_SET_NEW_PASSWORD}>Set New Password <BadgeWip/></a>
      </li>
    </ol>

    <h3>Portal</h3>
    <ol>
      <li>
        <a href={ROUTES.PORTAL_DASHBOARD}>Dashboard <BadgeWip/></a>
      </li>
    </ol>
  </div>;
};
