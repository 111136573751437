import { useEffect, useRef, useState } from "react";
import ApiAuth from "../modules/api/auth";
import AppUser from "../modules/app-user/app-user";

// currently it's 30 mins
const INTERVAL_REFRESH_TOKEN_MS = 30 * 60 * 1000;
const INTERVAL_CHECK_USER_MS = 1000;

export default function useRefreshTokenUser() {
  const [isValid, setIsValid] = useState<boolean>(undefined);
  const [isNetworkError, setIsNetworkError] = useState(false);
  const [initialized, setInitialized] = useState(false);
  const userRef = useRef<{[key: string]: any}>({
    checked: false
  });

  useEffect(() => {
    let timers: any;
    let refresh = async () => {
      const user = await AppUser.getInfo();
      const retry = () => {
        timers = setTimeout(() => refresh(), INTERVAL_REFRESH_TOKEN_MS);
      };

      if (user.logged) {
        userRef.current.checked = true;
        const res = await ApiAuth.refreshToken(user.token, user.refreshToken);

        if (res.status) {
          await AppUser.setInfo({
            token: res.data.authToken,
            refreshToken: res.data.refreshToken
          });
          retry();
        }

        if (res.status === false && res.networkError) {
          setIsNetworkError(true);
          retry();
        } else {
          setIsNetworkError(false);
          setIsValid(res.status);
        }
      } else {
        setIsValid(false);
      }

      return;
    };
    let timersUser: any;
    let userChecker = async () => {
      const user = await AppUser.getInfo();

      if (!user.logged) {
        userRef.current.checked = false;
        setIsValid(false);
      }

      if (user.logged && !userRef.current.checked) {
        userRef.current.checked = true;
        clearTimeout(timers);
        refresh();
      }

      timersUser = setTimeout(() => userChecker(), INTERVAL_CHECK_USER_MS);
    };

    refresh().then(() => {
      setInitialized(true);
      userChecker();
    });
    return () => {
      clearTimeout(timersUser);
      clearTimeout(timers);
    };
  }, []);

  return { isValid, isNetworkError, initialized };
}
