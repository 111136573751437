/** Function to unsubscribe the currently subscribed event. */
export type TEventSubscriptionUnsubscribe = () => void;

export type EventSubscriptionOptions<T> = {
  lastEmittedData: T;
  emitLastDataOnSubscribe: boolean;
  executeOnSubscribe: boolean;
}

/**
 * How to use this?
 *
 * This is useful when consumers want listening to specific event e.g. event
 * when new robot is added.
 *
 * @example
 * const publicInbox = new EventSubscription<string>();
 *
 * const person1Unsub = publicInbox.subscribe((message) => {
 *   console.log(`---person1`, message);
 * });
 *
 * const person2Unsub = publicInbox.subscribe((message) => {
 *   console.log(`---person2`, message);
 * });
 *
 * // will log:
 * // ---person1 Hello all
 * // ---person2 Hello all
 * publicInbox.emit('Hello all!');
 *
 * person1Unsub();
 *
 * // will log:
 * // ---person2 Hello again
 * publicInbox.emit('Hello again!');
 */
export default class EventSubscription<T = any> {

  /** unsubscribe callback or array of callbacks */
  static unsub(callbacks: Array<TEventSubscriptionUnsubscribe> | TEventSubscriptionUnsubscribe) {
    if (typeof callbacks == 'function') {
      try {
        callbacks();
      } catch (ignore) {}
    }

    if (Array.isArray(callbacks)) {
      callbacks.forEach(callback => {
        try {
          callback();
        } catch (ignore) {}
      });
    }
  }

  constructor(userOptions?: Partial<EventSubscriptionOptions<T>>) {
    Object.assign(this.options, userOptions);
  }

  private options: EventSubscriptionOptions<T> = {
    lastEmittedData: undefined as T,
    emitLastDataOnSubscribe: false,
    executeOnSubscribe: false
  };
  private subscribers: Array<{ id: string; callback: (data: T) => void; }> = [];
  private counter = 0;
  private prefix = 'EventSubscriptionPrefix_';

  /** This method will return function to unsubscribe from event. */
  public subscribe(callback: (data: T) => void): TEventSubscriptionUnsubscribe {
    if (typeof callback != 'function') {
      return;
    }

    this.counter += 1;
    const id = this.prefix + this.counter.toString();
    const {options} = this;

    this.subscribers.push({ id, callback });

    if (options.executeOnSubscribe) {
      callback(undefined);
    }

    if (
      options.emitLastDataOnSubscribe &&
      typeof options.lastEmittedData != 'undefined'
    )  {
      callback(options.lastEmittedData);
    }

    return () => {
      const index = this.subscribers.findIndex(item => item.id === id);

      if (index > -1) {
        this.subscribers.splice(index, 1);
      }
    };
  }

  public execute(data?: T) {
    this.options.lastEmittedData = data;
    this.subscribers.forEach(item => item.callback(data));
  }

  public emit(data?: T) {
    this.execute(data);
  }

  /** clear all listeners */
  public dispose() {
    this.subscribers = [];
    this.counter = 0;
  }

}
