import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { HiArrowLeft, HiExclamationCircle, HiPlus } from "react-icons/hi";
import { Link, useNavigate, useParams } from "react-router-dom";
import DateRange from "../../../components/form/DateRange";
import { usePortalSettingSet } from "../../../context/portal-context";
import usePageTitle from "../../../hooks/use-page-title";
import { ROUTES } from "../../../routes";
import ApiTrait, { TGetAllTraits, TTraitData, getTraitDataPlaceholder } from "../../../modules/api/trait";
import PopupChooseTrait, { TPopupChooseTraitRef } from "../../../components/popup/PopupChooseTrait";
import FGRichTextEditor from "../../../components/common/FGRichTextEditor";
import classNames from "classnames";
import TraitProgressToggle from "../../../components/common/TraitProgressToggle";
import TraitScale from "../../../components/portal/TraitScale";
import { isHtmlEmpty, scrollTo } from "../../../utils/jquery-helper";
import ApiSubmissionDate, { TValidateDateResult } from "../../../modules/api/submission-date";
import { SUBMISSION_PD, SUBMISSION_STATUS_DRAFT, SUBMISSION_STATUS_PUBLISH } from "../../../config/constants";
import ModuleDate from "../../../modules/date";
import ModulePopup from "../../../modules/popup/popup";
import Debouncer from "../../../modules/debouncer/debouncer";
import ApiSubmission, { TStatisticItem, TStatisticResult } from "../../../modules/api/submission";
import AppUserContext from "../../../context/app-user-context";
import Notifier from "../../../modules/notifier/notifier";
import Utils from "../../../utils/utils";
import PDStatistics from "../../../components/portal/PDStatistics";
import PopupEditTraitGoal, { TPopupEditTraitGoalRef } from "../../../components/popup/PopupEditTraitGoal";
import FGDropdownApi from "../../../components/form/FGDropdownApi";

export default function PageReportPDEdit() {
  usePageTitle("Professional Development Report");
  usePortalSettingSet("label_header", "Professional Development Report");
  usePortalSettingSet("is_homepage", false);

  const navigate = useNavigate();
  const user = useContext(AppUserContext);
  const { submissionId } = useParams();

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [submissionFound, setSubmissionFound] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [dateList, setDateList] = useState<any[]>([]);

  const [statistics, setStatistics] = useState<TStatisticResult>();

  const [lastSelectedDate, setLastSelectedDate] = useState<string>("");
  const [isDateAvailable, setIsDateAvailable] = useState(false);
  const [formData, setFormData] = useState({
    submissionDateId: ""
  });
  const [submissionStartDate, setSubmissionStartDate] = useState<Date|number>();
  const [submissionEndDate, setSubmissionEndDate] = useState<Date|number>();

  const [traits, setTraits] = useState<TGetAllTraits>([]);
  const [selectedTraits, setSelectedTraits] = useState<TGetAllTraits>([]);

  /** This is the trait's data to be submitted */
  const [traitsData, setTraitsData] = useState<TTraitData[]>([]);

  const [activeTrait, setActiveTrait] = useState<number>(-1);
  const [activeTraitId, setActiveTraitId] = useState<string>("");
  const refPopupEditTraitGoal = useRef<TPopupEditTraitGoalRef>();
  const refPopupChooseTrait = useRef<TPopupChooseTraitRef>();
  const currentTraitData = useMemo(() => {
    const res = traits.filter(t => activeTraitId === t._id);

    if (res.length > 0) {
      return res[0];
    } else {
      return;
    }
  }, [activeTraitId, traits]);

  /**
   * key-value pair of Trait ID and boolean. `true` indicated the trait ID
   * passed the validation. `false` otherwise. since it's quite cpu-intensive
   * operation, we shall memo it instead.
   */
  const traitsDataValidation = useMemo<{[key: string]: boolean}>(() => {
    const validations = {};

    traitsData.forEach(trait => {
      let valid = true;

      if (trait.paused) {
        validations[trait.traitId] = true;
        return;
      }

      if (valid && (isHtmlEmpty(trait.reality) || isHtmlEmpty(trait.goal) ||
        isHtmlEmpty(trait.options) || isHtmlEmpty(trait.wayForward)
      )) {
        valid = false;
      }

      if (valid && trait.scaleEndGoal === 0 && trait.scaleEndGoalPrevious === 0) {
        valid = false;
      }

      if (valid && (trait.scale === 0 || trait.scaleNextMonth === 0)) {
        valid = false;
      }

      validations[trait.traitId] = valid;
    });

    return validations;
  }, [traitsData]);

  const handleFormSubmit = (submissionStatus: string) => {
    if (submitting) return;
    setSubmitting(true);

    Debouncer.execute("UPDATE_PD", async () => {
      const result = await ApiSubmission.pdUpdate(submissionId, {
        modifiedUserId: user.id,
        status: submissionStatus,
        studentId: user.referenceUserId,
        ...formData,
        traits: traitsData
      });

      if (result.status) {
        Notifier.success(result.message);

        if (submissionStatus === SUBMISSION_STATUS_PUBLISH) {
          await Utils.sleep(2000);
          navigate(ROUTES.PORTAL_REPORT);
        }
      } else {
        Notifier.error(result.message);
      }

      setSubmitting(false);
    });
  };

  const handleFormChange = (e) => {
    setTraitsData(traitsData => traitsData.map((item, i) => {
      if (i === activeTrait) {
        const value = e.target.type === "checkbox" ? e.target.checked : e.target.value;
        item[e.target.name] = value;
      }

      return item;
    }));
  };

  const openSelectTrait = () => {
    refPopupChooseTrait.current.show();
  };

  const openActiveTrait = (traitId: string) => {
    const index = selectedTraits.findIndex(item => item._id === traitId);

    if (index > -1) {
      ModulePopup.hideAlert();
      Utils.sleep(100).then(() => {
        scrollTo(".form-report").then(async () => {
          await Utils.sleep(350);
          setActiveTrait(index);
          setActiveTraitId(traitId);
        })
      });
    }
  };

  const showCompleteForm = (desc?: any) => {
    return ModulePopup.showAlert({
      title: <><HiExclamationCircle /> Complete your form</>,
      description: desc,
      labelOK: "COMPLETE FORM"
    });
  };

  const checkValidation = () => {
    const keys = Object.keys(traitsDataValidation);
    const isAllPaused = traitsData.every(item => item.paused === true);

    if (keys.length === 0) {
      showCompleteForm("Hey there! To continue, please make sure to complete at least one trait.");
      return false;
    }

    if (isAllPaused) {
      showCompleteForm("It seems all selected trait(s) in pause mode. Please make sure to work at least on one trait to submit.");
      return false;
    }

    const valid = keys.map(i => (traitsDataValidation[i])).every(i => i === true);

    if (!valid) {
      showCompleteForm(<>
        <p>Please make sure to fill out the report's selected trait(s) below:</p>
        <p className="fs-p-85 mb-2 d-none"><strong>Don't forget to check your selected trait(s):</strong></p>
        {selectedTraits.filter(i => traitsDataValidation[i._id] === false).map(item => <React.Fragment key={item._id}>
          <button className="btn btn-outline-primary me-2 mb-2" onClick={(e) => {
            e.preventDefault();
            openActiveTrait(item._id);
          }}>{item.name}</button>
        </React.Fragment>)}
      </>);
    }

    return valid;
  };

  const getSubmission = async (traits: TGetAllTraits) => {
    const res = await ApiSubmission.getSubmission(submissionId);

    if (!res.status) {
      setSubmissionFound(false);
    }

    const data = res.data;

    if (data.studentId !== user.referenceUserId) {
      setError("You are not allowed to edit this submission.");
      setSubmissionFound(true);
      return;
    }

    const status = data.status;
    const isEditMode = (status === SUBMISSION_STATUS_DRAFT) ||
      (status === SUBMISSION_STATUS_PUBLISH && ModuleDate.isBeforeSubmissionDue(data.submissionDate.to));

    if (!isEditMode) {
      setError("You can't edit this past submission.");
      setSubmissionFound(true);
      return;
    }

    const existingTraitsData = data?.traits ?? [];
    setTraitsData(existingTraitsData);
    setSelectedTraits(traits.filter(item => {
      return existingTraitsData.some(existing => existing.traitId === item._id);
    }));
    setFormData({
      submissionDateId: data.submissionDate._id
    });
    setSubmissionStartDate(new Date(data.submissionDate.from));
    setSubmissionEndDate(new Date(data.submissionDate.to));
    setIsDateAvailable(true);
    setSubmissionFound(true);

    return res.data;
  };

  // when `selectedTraits` changed, check the navigation
  useEffect(() => {
    const activeIndex = selectedTraits.findIndex(i => i._id === activeTraitId);

    // the active tab might be removed
    if (activeIndex === -1) {
      if (selectedTraits.length > 0) {
        setActiveTrait(0);
        setActiveTraitId(selectedTraits[0]._id);
      } else {
        setActiveTrait(-1);
        setActiveTraitId("");
      }
    } else {
      setActiveTrait(activeIndex);
    }
  }, [selectedTraits]);

  // on page load, we:
  // 1. fetch list of traits
  // 2. fetch submission
  useEffect(() => {
    (async () => {
      // load data first
      const traits = await ApiTrait.getAll();
      const submission = await getSubmission(traits);

      // load the submission date
      const dateList = await ApiSubmissionDate.getSubmissionDateListForSubmission(SUBMISSION_PD, submission.submissionDate);
      setDateList(dateList);

      // then subsequently set state with the loaded data
      setTraits(traits);
      setLoading(false);
    })();
  }, []);

  useEffect(() => {
    if (!statistics) return;

    setTraitsData(traitsData => {
      const newTraitsData = [];

      selectedTraits.forEach(item => {
        const traitData = traitsData[traitsData.findIndex(i => i.traitId === item._id)];
        const scaleEndGoalPrevious = ApiSubmission.getPreviousScaleEndGoal(statistics?.traitsInfo, item._id);

        if (!traitData) {
          const newTraitData = getTraitDataPlaceholder();
          newTraitData.traitId = item._id;
          newTraitData.scaleEndGoalPrevious = scaleEndGoalPrevious;
          newTraitsData.push(newTraitData);
        } else {
          newTraitsData.push({
            ...traitData,
            scaleEndGoalPrevious
          });
        }
      });

      return newTraitsData;
    });
  }, [statistics, selectedTraits]);

  useEffect(() => {
    if (!statistics || !loading) return;
    const previousSelectedTraitIds = Object.keys(statistics.traitsInfo);
    const previousSelectedTraits = traits.filter(trait => {
      return previousSelectedTraitIds.indexOf(trait._id) > -1;
    });
    setSelectedTraits(s => {
      if (s.length === 0) {
        return previousSelectedTraits;
      } else {
        return s;
      }
    });
  }, [traits, statistics,]);

  return <main className="content-generic content-generic--fluid" data-comp="PageReportPD">

    <div className="d-flex gap-2 hide-mobile-down">
      <h1 className="fs-title">
        <Link to={ROUTES.PORTAL_REPORT} className="text-primary p-0 me-2" title="Go back">
          <HiArrowLeft />
        </Link>
        Professional Development Report
      </h1>
    </div>

    <PopupChooseTrait ref={refPopupChooseTrait}
      traits={traits}
      selectedTraits={selectedTraits}
      onOK={(newSelectedTraits) => {
        setSelectedTraits(newSelectedTraits);
      }}
    />

    {!loading && submissionFound && error.length > 0 && <p className="alert alert-danger">
      {error}
    </p>}

    {loading && <p className="alert alert-info">Loading. Please wait...</p>}

    {!loading && formData.submissionDateId === "" && error === "" && <p className="alert alert-warning">
      Please select the submission date.
    </p>}

    {!loading && submissionFound && error === "" && <form onSubmit={(e) => e.preventDefault()}>
      <FGDropdownApi
        label="Select submission date"
        name="submissionDateId"
        variant="normal"
        options={dateList}
        defaultValue={formData.submissionDateId}
        onChange={(e) => setFormData(f => ({ ...f, [e.target.name]: e.target.value }))}
        disabled
      />

      <div className="box-default box-form-statistics">
        <PDStatistics studentId={user.referenceUserId}
          onStatisticsLoaded={(stat) => {
            setStatistics(stat)
          }}
          onTraitClicked={(traitId) => {
            openActiveTrait(traitId);
          }}
        />
      </div>

      {isDateAvailable && !!statistics && <>
      <h1 className="fs-title">These are the traits you have been working on</h1>

      <div className="form-report">
        <div className="pd-tabs">
          <ol className="pd-tabs__nav">
            {selectedTraits.map((trait, index) => <li key={trait._id}>
              <button type="button"
                className={classNames([
                  "pd-tabs__nav-btn",
                  { "pd-tabs__nav-btn--active": (activeTrait === index) },
                  { "pd-tabs__nav-btn--incomplete": !traitsDataValidation[trait._id] }
                ])}
                onClick={(e) => {
                  e.preventDefault();
                  setActiveTrait(index);
                  setActiveTraitId(trait._id);
                }}
              >{trait.name}</button>
            </li>)}
            <li>
              <button type="button" className="pd-tabs__nav-btn"
                onClick={(e) => {
                  e.preventDefault();
                  openSelectTrait();
                }}
              >Add <HiPlus/></button>
            </li>
          </ol>
          <div className={`pd-tabs__content`}>

            {selectedTraits.length === 0 && <p className="col-text-secondary">
              Choose at least one trait to begin
            </p>}

            {selectedTraits.length > 0 && activeTrait > -1 && selectedTraits[activeTrait] && <div className={`trait-form-box ${traitsData[activeTrait].paused ? "trait-form-box--paused" : ""}`}>

              <div className="trait-pause-wrap">
                <TraitProgressToggle checked={traitsData[activeTrait].paused}
                  name="paused"
                  onChange={handleFormChange}
                />
                <div className="tpw__content">
                  <p>I strive for the highest professional standards required in my work and aim to be the best in all I do</p>
                </div>
              </div>

              {traitsData[activeTrait].paused && <div className="col-text-secondary">
                Press Continue to fill out the report.
              </div>}

              {!traitsData[activeTrait].paused && <>

                <div className="form-report__box">
                  <p className="mb-1">
                    <strong>By the end of IWSP:</strong> Where you're hoping to achieve at the end of programme
                  </p>
                  <p className="mb-2">
                    {traitsData[activeTrait].scaleEndGoalPrevious > 0 && <>
                      <PopupEditTraitGoal ref={refPopupEditTraitGoal} trait={currentTraitData} onSubmit={(data) => {
                        setTraitsData(traitsData => traitsData.map((item, i) => {
                          if (i === activeTrait) item = { ...item, ...data };
                          return item;
                        }));
                        refPopupEditTraitGoal.current.hide();
                      }} />
                      <span className="col-text-secondary">
                        You will only need to fill this scale one time only.
                        <button type='button' className='trait-edit-goal'
                          onClick={(e) => {
                            const currentGoal = traitsData[activeTrait].scaleEndGoalPrevious > 0 && traitsData[activeTrait].scaleEndGoal === 0
                              ? traitsData[activeTrait].scaleEndGoalPrevious
                              : traitsData[activeTrait].scaleEndGoal
                            e.preventDefault();
                            refPopupEditTraitGoal.current.show({
                              scaleEndGoal: currentGoal,
                              scaleEndGoalChangeReason: traitsData[activeTrait].scaleEndGoalChangeReason
                            });
                          }}
                        >Edit goal</button>
                    </span>
                    </>}
                  </p>
                  <TraitScale name="scaleEndGoal"
                    disabled={traitsData[activeTrait].scaleEndGoalPrevious > 0}
                    value={traitsData[activeTrait].scaleEndGoalPrevious > 0 && traitsData[activeTrait].scaleEndGoal === 0 ? traitsData[activeTrait].scaleEndGoalPrevious : traitsData[activeTrait].scaleEndGoal}
                    trait={currentTraitData}
                    onChange={handleFormChange}
                  />
                </div>

                <div className="form-report__box">
                  <p className="mb-1"><strong>My current Reality for this month</strong></p>
                  <p className="mb-2">On a scale of 1 (poor) to 10 (excellent), this is where I rate myself?</p>
                  <TraitScale name="scale"
                    value={traitsData[activeTrait].scale}
                    trait={currentTraitData}
                    onChange={handleFormChange}
                  />
                  <p className="mb-2 mt-2">Describe my current reality?</p>
                  <FGRichTextEditor variant="normal" name="reality"
                    label={<></>}
                    value={traitsData[activeTrait].reality}
                    onChange={handleFormChange}
                  />
                </div>

                <div className="form-report__box">
                  <p className="mb-1"><strong>My Goal for next month</strong></p>
                  <p className="mb-2">On a scale of 1 (poor) to 10 (excellent), this is where I want to progress to</p>
                  <TraitScale name="scaleNextMonth"
                    value={traitsData[activeTrait].scaleNextMonth}
                    trait={currentTraitData}
                    onChange={handleFormChange}
                  />
                  <p className="mb-2 mt-2">Describe what it would be like to achieve this goal</p>
                  <FGRichTextEditor variant="normal" name="goal"
                    label={<></>}
                    value={traitsData[activeTrait].goal}
                    onChange={handleFormChange}
                  />
                </div>

                <div className="form-report__box">
                  <FGRichTextEditor variant="normal" name="options"
                    label={<>
                      <p className="mb-1"><strong>Options</strong></p>
                      <p className="mb-2">What would I do differently to progress to the goal I am committing to? What support and/or resources would I need to achieve this progress</p>
                    </>}
                    value={traitsData[activeTrait].options}
                    onChange={handleFormChange}
                  />
                </div>

                <div className="form-report__box mb-0">
                  <FGRichTextEditor variant="normal" name="wayForward"
                    label={<>
                      <p className="mb-1"><strong>Way forward</strong></p>
                      <p className="mb-2">Summarize the actions you are committed to take to achieve your goal?</p>
                    </>}
                    value={traitsData[activeTrait].wayForward}
                    onChange={handleFormChange}
                  />
                </div>
              </>}

            </div>}

          </div>
        </div>
      </div>

      <div className='my-3'>
        <button type="button" className='text-uppercase mt-1 btn btn-primary btn-mw-centered'
          disabled={submitting}
          onClick={(e) => {
            e.preventDefault();
            if (!checkValidation()) return;
            ModulePopup.showAlertConfirm({
              title: "Confirmation",
              description: <>
                <p>Are you sure to submit the report?</p>
              </>,
              onYes() {
                handleFormSubmit(SUBMISSION_STATUS_PUBLISH);
              }
            });
          }}
        >Submit Report</button> {" "}
        <button type="button" className='text-uppercase mt-1 btn btn-outline-primary btn-mw-centered'
          disabled={submitting}
          onClick={(e) => {
            e.preventDefault();
            if (!checkValidation()) return;
            handleFormSubmit(SUBMISSION_STATUS_DRAFT);
          }}
        >Save as Draft</button>
      </div>

      </>}
    </form>}

  </main>;
}
