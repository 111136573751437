import { Link } from 'react-router-dom';
import { ROUTES } from '../../../routes';
import MyGroupListing from '../../../components/portal/MyGroupListing';
import DashboardNotificationItems from '../../../components/portal/DashboardNotificationItems';
import { SUBMISSION_PD } from '../../../config/constants';

interface PageDashboardSPVProps {
  user: any;
}

export default function PageDashboardSPV(props: PageDashboardSPVProps) {
  return <div className='row' data-comp='PageDashboardSPV'>

    <div className='col-5 flex-grow-1 mb-3'>
      <div className='d-flex justify-content-between align-items-center header-details'>
        <h1 className='fs-title'>Notifications</h1>
        <Link to={ROUTES.PORTAL_NOTIFICATION} className='btn'>View All</Link>
      </div>
      <div className='d-flex flex-column gap-3'>
        <DashboardNotificationItems />
      </div>
    </div>

    <div>
      <div className='mt-3 d-flex justify-content-between align-items-center header-details'>
        <h1 className='fs-title'>Latest Submission from My Group</h1>
        <Link to={ROUTES.PORTAL_MY_GROUP} className='btn'>See All</Link>
      </div>
      <MyGroupListing defaultSubmissionType={SUBMISSION_PD} />
    </div>

  </div>;
}
