import React, { useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from "react";
import ApiSubmission, { TStatisticResult } from "../../modules/api/submission";
import LoadingData from "./LoadingData";
import PDChart from "./PDChart";
import ApiTrait, { TGetAllTraits } from "../../modules/api/trait";

export type TPDStatisticsRef = {
  getStatistic: () => TStatisticResult;
};

export type TPDStatisticsProps = {
  /** a.k.a. `user.referenceUserId` OR _id when we retrieve peer listing. */
  studentId: string;
  onStatisticsLoaded?: (stat: TStatisticResult) => void;
  onTraitClicked?: (traitId: string) => void;
  /** filter which trait should show. by default we show all traits. */
  selectedStatistics?: Array<string>;
};

const PDStatistics = React.forwardRef<TPDStatisticsRef, TPDStatisticsProps>((props, ref) => {
  const [loading, setLoading] = useState(true);
  const [statistics, setStatistics] = useState<TStatisticResult>();
  const traits = useRef<TGetAllTraits>([]);

  // provide API to access data
  useImperativeHandle(ref, () => ({
    getStatistic() {
      return statistics;
    }
  }), [statistics]);

  useEffect(() => {
    (async () => {
      traits.current = await ApiTrait.getAll();
      ApiSubmission.statistics(props.studentId).then(res => {
        setStatistics(res);
        props.onStatisticsLoaded(res);
        setLoading(false);
      });
    })();
  }, []);

  const MemoizedOrderedStatistics = useMemo(() => {
    if (!statistics) return <></>;

    const selectedStatistics = Array.isArray(props.selectedStatistics) && props.selectedStatistics.length > 0
      ? props.selectedStatistics : [];
    const selectedStatisticsLength = selectedStatistics.length;
    const isShown = (traitId) => {
      if (selectedStatisticsLength === 0) return true;
      return selectedStatistics.some(i => i === traitId);
    };
    const widgets: {[key: string]: JSX.Element} = {};

    statistics.parsedTraits.filter(t => isShown(t.traitId)).forEach(trait => {
      widgets[trait.traitId] = <div className="statistic-item" onClick={() => {
        props.onTraitClicked(trait.traitId);
      }}>
        <h1>{trait.name === "" ? "Unknown trait" : trait.name}</h1>
        <PDChart
          color={trait.color}
          labelX={trait.month}
          scale={trait.scale}
          scaleNextMonth={trait.scaleNextMonth}
          goal={trait.scaleEndGoal}
          goalMaxLine={trait.goalMaxLine}
        />
      </div>;
    });

    const render = (traitId: string) => {
      const widget = widgets[traitId];
      return typeof widget === "undefined" ? <></> : widget;
    };

    return <>{traits.current.filter(trait => {
      return typeof widgets[trait._id] !== "undefined"
    }).map(trait => {
      return <React.Fragment key={trait._id}>{render(trait._id)}</React.Fragment>;
    })}</>;
  }, [statistics, props.selectedStatistics]);

  return <>
    <LoadingData show={loading} text="Loading statistics..." />

    {!loading && statistics.items.length === 0 && <>
      <p className="alert alert-not-found">Statistic(s) unavailable.</p>
    </>}

    {!loading && statistics.items.length > 0 && <div className="statistic-wrapper">
      {MemoizedOrderedStatistics}
    </div>}
  </>;
});

PDStatistics.defaultProps = {
  selectedStatistics: [],
  onStatisticsLoaded: (s) => {},
  onTraitClicked: (traitId) => {}
}

export default PDStatistics;
