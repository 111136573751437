import { usePortalSettingSet } from "../../../context/portal-context";
import usePageTitle from "../../../hooks/use-page-title";
import React, { useContext, useEffect, useState } from "react";
import LoadingData from "../../../components/portal/LoadingData";
import AppUser from "../../../modules/app-user/app-user";
import AppUserContext from "../../../context/app-user-context";
import ApiUser, { TMyPeersItem } from "../../../modules/api/user";
import UserImage from "../../../components/portal/UserImage";
import { Link, useNavigate } from "react-router-dom";
import ROLE from "../../../modules/role";
import { ROUTES } from "../../../routes";

type TPeerItemProps = {
  peer: TMyPeersItem;
};

function PeerItem(props: TPeerItemProps) {
  const navigate = useNavigate();

  return  (
    <div className='col-12 col-xl-6 btn latest-submission__container' onClick={(e) => {
      e.preventDefault();
      navigate(ROUTES.PORTAL_PEERS_DETAILS + `/${props.peer._id}`);
    }}>
      <div className='d-flex gap-5 bg-white p-3 latest-submission'>
        <div className='latest-submission__user flex-grow-1 d-flex flex-row justify-content-start align-items-center align-items-lg-center gap-3'>
          <UserImage url={props.peer.avatar} width={64} />
          {props.peer.name}
        </div>
        <div
        className='d-none d-xl-flex flex-row align-items-center  gap-3'>
          <div className='d-flex justify-content-center align-items-center gap-3'>
            <Link to={ROUTES.PORTAL_PEERS_DETAILS + `/${props.peer._id}`} className='btn btn-primary text-nowrap px-4 py-2'>View Details</Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default function PagePeers() {
  const title = "Peers";
  usePageTitle(title);
  usePortalSettingSet("label_header", title);

  const [loading, setLoading] = useState(true);
  const [group, setGroup] = useState({
    _id: "",
    name: ""
  });
  const [peers, setPeers] = useState<TMyPeersItem[]>([]);

  useEffect(() => {
    (async () => {
      await AppUser.refreshUser(["avatar"]);
      const user = await AppUser.getInfo();
      const peers = await ApiUser.myPeers(user.referenceUserId);

      setPeers(peers.filter(i => i.role === ROLE.STUDENT));
      setGroup({
        _id: user.group,
        name: user.groupName
      })
      setLoading(false);
    })();
  }, []);

  return <main className="content-generic content-generic--fluid" data-comp="PagePeers">
    <h1 className="fs-title mb-3">My Peers</h1>

    <LoadingData show={loading} />

    {!loading && <div>
      <h2 className='fs-4'>{group.name}</h2>

      {peers.length === 0 && <p className="alert alert-not-found mt-3">
        Not available.
      </p>}

      {peers.length > 0 && <div className='row g-3'>
        {peers.map(peer => <React.Fragment key={peer._id}>
          <PeerItem peer={peer} />
        </React.Fragment>)}
      </div>}

    </div>}
  </main>
}
