import { Link, useParams } from 'react-router-dom';
import { usePortalSettingSet } from "../../../context/portal-context";
import usePageTitle from "../../../hooks/use-page-title";
import { HiArrowLeft, HiOutlineDocumentDuplicate, HiOutlinePlusCircle } from 'react-icons/hi';
import UserImage from '../../../components/portal/UserImage';
import { Accordion } from 'react-bootstrap';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { ROUTES } from '../../../routes';
import AppUserContext from '../../../context/app-user-context';
import ApiMessage, { TMyPeerDetail } from '../../../modules/api/message';
import LoadingData from '../../../components/portal/LoadingData';
import ModuleDate from '../../../modules/date';
import PopupPeerFeedback, { TPopupPeerFeedbackRef } from '../../../components/popup/PopupPeerFeedback';
import Debouncer from '../../../modules/debouncer/debouncer';
import Notifier from '../../../modules/notifier/notifier';
import Utils from '../../../utils/utils';
import ApiTrait, { TGetAllTraitItem } from '../../../modules/api/trait';
import { LABEL_UNKNOWN_TRAIT } from '../../../config/constants';

export default function PagePeersDetails() {
  const title = "Peers Details";
  usePageTitle(title);
  usePortalSettingSet("label_header", title);

  const {peerId} = useParams();
  const user = useContext(AppUserContext);
  const refPopupPeerFeedback = useRef<TPopupPeerFeedbackRef>();
  const [peer, setPeer] = useState<TMyPeerDetail>();
  const [loading, setLoading] = useState(true);
  const [traits, setTraits] = useState<{[key: string]: TGetAllTraitItem}>();

  useEffect(() => {
    (async () => {
      const traits = await ApiTrait.getAll();
      setTraits(traits.reduce((prev, curr) => ({ ...prev, [curr._id]: curr }), {}));
      ApiMessage.myPeerDetail(peerId, user.referenceUserId).then(res => {
        if (res.status) {
          setPeer(res.data);
        }

        setLoading(false);
      });
    })();
  }, []);

  const feedbacks = useMemo(() => {
    if (!traits || !Array.isArray(peer?.invitations)) return [];

    return peer.invitations.map(submission => {
      let traits = [];
      if (Array.isArray(submission?.traits)) traits = submission?.traits.filter(trait => !!trait.traitId);
      return { ...submission, traits, msTo: (new Date(submission?.submissionDate?.to ?? "")).getTime() };
    }).filter(submission => {
      return Array.isArray(submission?.traits) && submission.traits.length > 0;
    }).sort((a, b) => {
      return b.msTo - a.msTo;
    }).map(submission => {
      const isAllFeedbackSent = submission.traits.every(trait => trait.feedbackSent);
      const defaultActiveKey = isAllFeedbackSent ? [] : [submission._id];

      return <Accordion key={submission._id} className='ptr-accordion shadow shadow-sm mb-3' defaultActiveKey={defaultActiveKey} alwaysOpen={isAllFeedbackSent}>
        <Accordion.Item eventKey={submission._id}>
          <Accordion.Header className="mb-0">
            <HiOutlineDocumentDuplicate className='fs-3 report__icon--success me-2' />
            <span className='fs-5'>{ModuleDate.getTitleFormat(submission.submissionDate.to)}</span>
          </Accordion.Header>
          <Accordion.Body className="peer-list">

            {Array.isArray(submission.traits) && submission.traits.map(trait => <div className="peer-list-item" key={trait.traitId}>
              <h1>{traits[trait.traitId]?.name ?? LABEL_UNKNOWN_TRAIT}</h1>

              {trait.feedbackSent && <div>
                <span className="label-status-sent">Feedback sent to {peer.peerName}.</span>
              </div>}

              {!trait.feedbackSent && <button type="button" className='btn btn-secondary btn-icon'
                onClick={(e) => {
                  e.preventDefault();
                  refPopupPeerFeedback.current.show({
                    owner: {
                      avatar: peer.peerAvatar,
                      name: peer.peerName,
                      email: peer.peerEmail
                    },
                    data: {
                      submissionId: submission._id,
                      traitId: trait.traitId
                    }
                  });
                }}
              >
                Write Feedback {" "}
                <HiOutlinePlusCircle />
              </button>}
            </div>)}

          </Accordion.Body>
        </Accordion.Item>
      </Accordion>;
    });
  }, [traits, peer]);

  const setFeedbackSent = (submissionId, traitId) => {
    setPeer(peer => {
      peer.invitations.some(submission => {
        const isTargetSubmission = submission._id === submissionId;

        if (isTargetSubmission) {
          let traits = [];
          if (Array.isArray(submission?.traits)) traits = submission?.traits.map(trait => {
            if (trait.traitId === traitId) {
              trait.feedbackSent = true;
            }

            return trait;
          })
          submission.traits = traits;
        }

        return isTargetSubmission;
      });

      return { ...peer };
    });
  };

  return <main className="content-generic content-generic--fluid position-relative" data-comp="PagePeersDetails">

    <LoadingData show={loading} text="Loading peer detail..." />

    {!loading && !peer && <>
      <div className="d-flex gap-2 align-items-center mb-3">
        <Link to={ROUTES.PORTAL_PEERS} className="d-none d-md-block text-primary p-0 fs-5">
          <HiArrowLeft />
        </Link>
        <h1 className="fs-title mb-0 mt-1">Go back</h1>
      </div>
      <p className="alert alert-not-found">Peer not found.</p>
    </>}

    {!loading && peer && <>
      <div className="d-flex gap-2 align-items-center mb-3">
        <Link to={ROUTES.PORTAL_PEERS} className="d-none d-md-block text-primary p-0 fs-5">
          <HiArrowLeft />
        </Link>
        <div className='d-flex justify-content-center align-items-center gap-2'>
          <UserImage width={67} url={peer.peerAvatar} />
          <div>
            <h1 className="fs-title m-0">{peer.peerName}</h1>
            <span>{peer.peerEmail}</span>
          </div>
        </div>
      </div>

      <div className="mt-4">
        <PopupPeerFeedback ref={refPopupPeerFeedback}
          onSubmit={(formData, setDisabled) => {
            Debouncer.execute("SUBMIT_PEER_FEEDBACK", async () => {
              setDisabled(true);
              const res = await ApiMessage.submit({
                createdUserId: user.id,
                messageType: "STUDENT",
                message: formData.feedback,
                traitId: formData.traitId,
                anonymous: formData.anonymous,
                receiverId: peer.peerId,
                senderId: user.referenceUserId,
                status: "Unread",
                submissionId: formData.submissionId
              });

              if (res.status) {
                Notifier.success("Feedback submitted");
                refPopupPeerFeedback.current.hide();
                Utils.sleep(1000).then(() => {
                  setFeedbackSent(formData.submissionId, formData.traitId);
                });
              } else {
                Notifier.error(res.message);
              }

              setDisabled(false);
            });
          }}
        />

        {Array.isArray(peer?.invitations) && peer?.invitations.length === 0 && <p className="alert alert-not-found">
          No invitation(s) available from <strong>{peer.peerName}</strong>.
        </p>}

        {feedbacks}
      </div>
    </>}

  </main>
}
