import { useEffect, useMemo, useState } from "react";
import AppUser, { TAppUser } from "../../modules/app-user/app-user";
import FGDropdownApi from "../form/FGDropdownApi";
import { Tab, Tabs } from "react-bootstrap";
import SubmissionCard from "./SubmissionCard";
import { HiOutlineUser } from "react-icons/hi";
import { CONST_SELECT_SUBMISSION_TYPES, SUBMISSION_PD, SUBMISSION_WL } from "../../config/constants";
import LoadingData from "./LoadingData";
import ApiSubmission from "../../modules/api/submission";
import ROLE from "../../modules/role";
import Utils from "../../utils/utils";
import classNames from "classnames";

export type TMyGroupListingProps = {
  tabbed: boolean;
  defaultGroup?: string;
  defaultSubmissionType?: string;
  className?: string;
};

const MyGroupListing = (props: TMyGroupListingProps) => {
  const [user, setUser] = useState<TAppUser>();
  const [fetching, setFetching] = useState(true);
  const [group, setGroup] = useState(props.defaultGroup);
  const [groupOptions, setGroupOptions] = useState<any[]>([]);
  const [submissionType, setSubmissionType] = useState(props.defaultSubmissionType);
  const [submissions, setSubmissions] = useState<any[]>([]);
  const submissionTypeOptions = useMemo(() => {
    if (user && user.role === ROLE.INDUSTRY_SUPERVISOR) {
      return CONST_SELECT_SUBMISSION_TYPES.filter(i => i.value === SUBMISSION_PD);
    }

    return CONST_SELECT_SUBMISSION_TYPES;
  }, [user]);
  const tabList = useMemo(() => ([
    "All",
    "Submitted",
    "Late",
    "Not Yet"
  ]), []);
  const [activeTab, setActiveTab] = useState("All");

  useEffect(() => {
    (async () => {
      await AppUser.refreshUser(["avatar"]);
      const user = await AppUser.getInfo();
      const group = user.group.split(/\s*\,\s*/);

      setUser(user);
      setGroupOptions(Object.keys(user.groupHash).map(key => ({
        label: user.groupHash[key],
        value: key
      })));

      if (group.length > 0) {
        setGroup(group[0]);
      }

      if (!props.tabbed && submissionType === "") {
        if (user.role === ROLE.SUPERVISOR) {
          setSubmissionType(SUBMISSION_WL);
        } else {
          setSubmissionType(SUBMISSION_PD);
        }
      }
    })();
  }, []);

  useEffect(() => {
    if (group === "" || submissionType === "") return;
    setFetching(true);
    ApiSubmission.submissionStatus(group, submissionType).then(data => {
      setSubmissions(data);
      setFetching(false);
    });
  }, [group, submissionType]);

  return <div data-comp="MyGroupListing"
    className={classNames([
      "",
      {[props.className]: !!props.className}
    ])}
  >
    {submissionType !== "" && group !== "" && <>
      {/* for group page */}
      {!props.tabbed && <>
        <div className='row g-3 row-cols-1 row-cols-xl-2'>
          {!fetching && submissions.length > 0 && submissions.map((submission, i) => (
            <div className='col' key={i}>
              <SubmissionCard
                key={i}
                userId={submission._id}
                avatar={submission.avatar}
                user={submission.name}
                status={submission.status}
                to={submission.to}
                submissionType={submissionType}
              />
            </div>
          ))}
        </div>

        <LoadingData show={fetching} />

        {(!fetching && submissions.length === 0) && <p className="alert alert-info mt-3">
          Submission group status unavailable.
        </p>}
      </>}

      {/* usually for dashboard */}
      {props.tabbed && <>
        <div className='d-flex gap-3 m-0'>
          <FGDropdownApi
            options={groupOptions}
            value={group}
            defaultValue={group}
            onChange={(e) => {
              setGroup(e.target.value);
            }}
            className='m-0'
          />
          <FGDropdownApi
            options={submissionTypeOptions}
            className='m-0'
            value={submissionType}
            defaultValue={submissionType}
            onChange={(e) => {
              setSubmissionType(e.target.value);
            }}
          />
        </div>
        <div className=''>
          <Tabs activeKey={activeTab} onSelect={(k) => { setActiveTab(k) }} className='pwl__tabs'>

            {!fetching && tabList.map((tabName, i) => {
              const filteredSubmission = tabName === "All"
                ? submissions.map(item => item)
                : submissions.filter(item => item.status.toLowerCase() === tabName.toLowerCase());

              return <Tab key={i} eventKey={tabName} className=''
                title={<span className='d-flex justify-content-center align-items-center gap-2'>{tabName} {filteredSubmission.length}<HiOutlineUser/></span>}
              >
                <div className='row g-3 row-cols-1 row-cols-xl-2 py-1'>
                  {filteredSubmission.map((submission, i) => (
                    <div className='col' key={i}>
                      <SubmissionCard
                        key={i}
                        userId={submission._id}
                        avatar={submission.avatar}
                        user={submission.name}
                        status={submission.status}
                        to={submission.to}
                        submissionType={submissionType}
                      />
                    </div>
                  ))}
                </div>
              </Tab>
            })}

          </Tabs>

          <LoadingData show={fetching} />

          {!fetching && submissions.length === 0 && <p className="alert alert-info">
            Submission group status unavailable.
          </p>}
        </div>
      </>}
    </>}
  </div>;
};

MyGroupListing.defaultProps = {
  tabbed: false,
  defaultGroup: "",
  defaultSubmissionType: "",
  className: ""
}

export default MyGroupListing;
