import StorageSession from "./storage/storage-session";

const KEY = "sess_store";

async function getAll() {
  return await StorageSession.get(KEY, {});
}

async function get(key: string, defaultIfNotExist: any) {
  const data = await getAll();
  const target = data[key];
  return typeof target === "undefined" ? defaultIfNotExist : target;
}

async function set(key: string, value: any) {
  await StorageSession.set(KEY, Object.assign(await getAll(), {[key]: value}));
}

/**
 * Use this module to store session-related data, for example state of sidebar
 * whether it's in expanded/collapsed mode. The data only stored on specific
 * browser-tab and will automatically cleared once user close the browser.
 *
 * The store will start with empty data whenever user open new tab. This is
 * just a wrapper of `window.sessionStorage`.
 */
const SessionStore = {
  getAll,
  get,
  set
};

export default SessionStore;
