import { merge } from "lodash-es";
import Config from "../../config/config";
import ROLE, { getRoleLabel } from "../role";
import StorageCrypt from "../storage/storage-crypt";
import ApiUser from "../api/user";
import SubscriptionConsumer from "../event-subscription/subscription-consumer";

const KEY_USER_STORAGE = 'app-user-data';

export function getDefaultUserData(): TAppUser {
  return {
    lastUpdate: -1,
    id: "",
    referenceUserId: "",
    logged: false,
    name: "",
    email: "",
    avatar: "",
    role: "",
    year: "",
    yearName : "",
    studentId: "",
    studyProgramName: "",
    industryName: "",
    jobDesk: "",
    group: "",
    groupName: "",
    groupHash: {},
    supervisors: {
      sit: "",
      industry: ""
    },
    notification: false,
    submissionReminder: false,
    token: "",
    refreshToken: "",
    just_logged: false,
    isDefaultPassword: false,
    data: {}
  };
}

export type TAppUser = {
  /** when this data was synchronized with server's data? */
  lastUpdate: number;
  /**
   * @ref https://fxmediaweb.atlassian.net/browse/IS-9?focusedCommentId=26010
   * ID of logged-in user (let's call this account ID)
   * Based on MongoDB, this resides on `accounts` collection on `_id` property
   */
  id: string;
  /**
   * @ref https://fxmediaweb.atlassian.net/browse/IS-9?focusedCommentId=26010
   * This is the actual user ID
   * Based on MongoDB, this resides on `users` collection on `_id` property
   */
  referenceUserId: string;
  /** Is the user logged in? */
  logged: boolean;
  name: string;
  email: string;
  avatar: string;
  role: string;
  year: string|number;
  yearName: string;
  studentId: string;
  studyProgramName: string;
  industryName: string;
  jobDesk: string;
  /** the group IDs */
  group: string;
  /** the group labels/names */
  groupName: string;
  /** key-value pairs of `group` and `groupName`, useful for dropdown-related */
  groupHash: {[key: string]: string};

  /** information about supervisor if current user's role is STUDENT */
  supervisors: {
    sit: string;
    industry: string;
  };

  /** User's settings-related */
  notification: boolean;
  submissionReminder: boolean;

  /** User token to communicate with several back-end services */
  token: string;
  refreshToken: string;

  /**
   * boolean to check whether current user just logged in a page. after login
   * this value must be true then it should be false on subsequent page access.
   *
   * the process will be repeated once user logged out then logged back in.
   */
  just_logged: boolean;
  isDefaultPassword: boolean;

  /** just in case we'd like to store additional stuff on current user */
  data: {[key: string]: any};
};

export default class AppUser {

  static async getInfo(): Promise<TAppUser> {
    let storedUser = await StorageCrypt.get(KEY_USER_STORAGE, getDefaultUserData());

    if (Config.IS_MOCKUP_MODE) {
      Object.assign(storedUser, getMockupUser());
    }

    return storedUser;
  }

  static async setInfo(data: Partial<TAppUser>) {
    const currentData = await this.getInfo();
    const parsedData = {};
    const lastUpdate = data.lastUpdate ?? currentData.lastUpdate;
    Object.keys(currentData).forEach(key => {
      const val = data[key];

      if (typeof val !== "undefined") {
        if (key === "avatar" && typeof val === "string" && val !== "") {
          parsedData[key] = val + "?t=" + lastUpdate
        } else {
          parsedData[key] = val;
        }
      }
    });
    const newData = merge(currentData, parsedData);

    try {
      await StorageCrypt.set(KEY_USER_STORAGE, newData);
      return newData;
    } catch (ignore) {
      return currentData;
    }
  }

  static async getToken(): Promise<string> {
    return (await this.getInfo()).token;
  }

  static async logout() {
    await StorageCrypt.remove(KEY_USER_STORAGE);
  }

  static async setData(key: string, value: any) {
    const currentData = await this.getInfo();

    try {
      if (typeof key !== "string") {
        throw new Error("key must be a string");
      }

      currentData.data[key] = value;
      await StorageCrypt.set(KEY_USER_STORAGE, currentData);
    } catch (ignore) {}

    return currentData;
  }

  static async getData(key: string, defaultReturnIfNotFound: any = "") {
    const currentData = await this.getInfo();

    try {
      if (typeof key !== "string") {
        throw new Error("key must be a string");
      }

      const result = currentData.data[key];

      return typeof result === "undefined" || result === null ? defaultReturnIfNotFound : result;
    } catch (ignore) {
      return defaultReturnIfNotFound;
    }
  }

  static async removeData(key: string) {
    const currentData = await this.getInfo();

    try {
      if (typeof key !== "string") {
        throw new Error("key must be a string");
      }

      delete currentData.data[key];
      await StorageCrypt.set(KEY_USER_STORAGE, currentData);
    } catch (ignore) {}

    return currentData;
  }

  /**
   * @param excludeProps Array of string containing the excluded properties
   *                     e.g. "avatar" property when we want to update other
   *                     properties other than that so the avatar doesn't get
   *                     reloaded
   */
  static async refreshUser(excludeProps?: string[]) {
    const user = await this.getInfo();
    const res = await ApiUser.get(user.referenceUserId);
    const newUserInfo = res.data;

    if (newUserInfo) {
      if (Array.isArray(excludeProps)) {
        excludeProps.forEach(key => {
          delete newUserInfo[key];
        });
      }

      await this.setInfo({
        lastUpdate: Date.now(),
        ...newUserInfo
      });
      SubscriptionConsumer.emit("AppUser.refreshUser");
    }
  }

}

function getMockupUser() {
  let storedRole = localStorage.getItem("mockup_user");
  const role = storedRole === null ? ROLE.ADMIN : storedRole.trim().toLowerCase();

  switch (role) {
    case ROLE.STUDENT:
    case "student":
      return {
        logged: true,
        profile_picture: "/assets/images/dummy/profile-picture/male.png",
        name: "Student",
        email: "student@fxmweb.com",
        id: "63e1c96696dcb78ea168a12f",
        role: ROLE.STUDENT,
        role_label: getRoleLabel(ROLE.STUDENT)
      };
    case ROLE.SUPERVISOR:
    case "supervisor":
    case "spv":
      return {
        logged: true,
        profile_picture: "/assets/images/dummy/profile-picture/male.png",
        name: "Supervisor",
        email: "spv@fxmweb.com",
        id: "63e1c96696dcb78ea168a12e",
        role: ROLE.SUPERVISOR,
        role_label: getRoleLabel(ROLE.SUPERVISOR)
      };
    case ROLE.INDUSTRY_SUPERVISOR:
    case "industry":
    case "industry_spv":
    case "industry_supervisor":
      return {
        logged: true,
        profile_picture: "/assets/images/dummy/profile-picture/female.png",
        name: "Industry Supervisor",
        email: "industry@fxmweb.com",
        id: "63e1c96696dcb78ea168a12d",
        role: ROLE.INDUSTRY_SUPERVISOR,
        role_label: getRoleLabel(ROLE.INDUSTRY_SUPERVISOR)
      };
    case ROLE.ADMIN:
    case "admin":
    default:
      return {
        logged: true,
        profile_picture: "/assets/images/dummy/profile-picture/female.png",
        name: "Administrator",
        email: "admin@fxmweb.com",
        id: "63e1c96696dcb78ea168a12c",
        role: ROLE.ADMIN,
        role_label: getRoleLabel(ROLE.ADMIN)
      };
  }
}
