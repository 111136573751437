import { motion, AnimatePresence } from "framer-motion";

type TFadeBoxProps = {
  show: boolean;
  children: string|JSX.Element|JSX.Element[];
};

export default function FadeBox(props: TFadeBoxProps) {
  return <AnimatePresence>
    {props.show && <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0, visibility: "hidden", position: "absolute" }}
    >{props.children}</motion.div>}
  </AnimatePresence>
}
