import React, { FC, ReactElement, useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import SessionStore from "../../modules/session-store";
import { HiMenu, HiX } from 'react-icons/hi'
import { MdLogout, MdPerson } from 'react-icons/md'
import { ROUTES } from "../../routes";
import { Dropdown } from "react-bootstrap";
import AppUserContext from "../../context/app-user-context";
import PortalNav from "./PortalNav";
import { PortalContext } from "../../context/portal-context";
import classnames from "classnames";
import UserHero from "./UserHero";
import UILoadingBlank from "../UILoadingBlank";
import MobileHeader from "./MobileHeader";
import { motion } from "framer-motion";

export type TPortalTemplate = {
  children: any;
};

const PortalTemplate: FC<TPortalTemplate> = (props): ReactElement => {
  const [sidebarExpanded, setSidebarExpanded] = useState(true);
  const user = useContext(AppUserContext);
  const { portalSetting, setPortalSetting } = useContext(PortalContext);

  useEffect(() => {
    SessionStore.get("sidebarExpanded", sidebarExpanded).then((val) =>
      setSidebarExpanded(val)
    );
  }, []);

  return <>
    <UILoadingBlank show={portalSetting && !portalSetting.ready} />

    <div
      className={classnames([
        "portal-template",
        { "pt--is-home": portalSetting.is_homepage },
        { "pt--is-not-home": !portalSetting.is_homepage },
        { "pt--mobile-show": portalSetting.mobile_nav_open },
        { "pt--bottom-nav": portalSetting.bottom_nav },
        { "pt--expanded": sidebarExpanded },
        { "pt--no-nav": !portalSetting.show_nav }
      ])}
    >

      <div className={classnames([
        "pt__sidebar",
        { "pt__sidebar--collapsed": !sidebarExpanded }
      ])}>
        <div className="pt__sidebar-top">

          <div className="pt__logo">
            <Link to={ROUTES.PORTAL} onClick={() => {
              setPortalSetting(d => ({ ...d, mobile_nav_open: false }));
            }}>
              <img src="/assets/images/logo/logo.png" alt="logo" height={68} />
              <img src="/assets/images/logo/logo-square-small.png" alt="logo" height={68} />
            </Link>
          </div>

          <button className="toggle-btn"
            title={sidebarExpanded ? "Collapse menu" : "Expand menu"}
            onClick={(e) => {
              e.preventDefault();
              SessionStore.set("sidebarExpanded", !sidebarExpanded);
              setSidebarExpanded(!sidebarExpanded);
            }}
          >
            <HiMenu/>
          </button>

          <button className="close-btn"
            title="Close menu"
            onClick={(e) => {
              e.preventDefault();
              setPortalSetting(d => ({ ...d, mobile_nav_open: false }));
            }}
          >
            <HiX/>
          </button>

        </div>
        <div className="pt__sidebar-menu">
          <PortalNav expanded={sidebarExpanded} />
        </div>
      </div>

      {portalSetting.mobile_nav_open && <div className="pt__sidebar-overlay">
        <motion.div initial={{opacity: 0}} animate={{opacity: 1}}>
          <div className="so__content" onClick={(e) => {
          e.preventDefault();
          setPortalSetting(d => ({ ...d, mobile_nav_open: false }));
        }}></div>
        </motion.div>
      </div>}

      <div className="pt__header">
        <MobileHeader/>
        <UserHero/>
      </div>

      <div className="pt__content container-fluid">
        {props.children}
      </div>

      <PortalNav isBottomNav={true} />

    </div>
  </>;
};

export default PortalTemplate;
