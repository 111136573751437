import React, { forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { HiOutlineSearch } from "react-icons/hi";
import useDebounce from "../../hooks/use-debounce";
import Utils from "../../utils/utils";
import ApiMessage, { TQueryPeerItem } from "../../modules/api/message";
import UserImage from "../portal/UserImage";
import ModulePopup from "../../modules/popup/popup";
import { TAppUser } from "../../modules/app-user/app-user";
import Notifier from "../../modules/notifier/notifier";

export type TPopupPeerInviteFeedbackRef = {
  show: () => void;
  hide: () => void;
};

export type TPopupPeerInviteFeedbackProps = {
  /** this is submission's owner */
  studentId: string;
  traitId?: string;
  submissionId: string;
  user: TAppUser;
};

type TQueryPeerItemModified = TQueryPeerItem & {
  visible: boolean;
};

const PopupPeerInviteFeedback = forwardRef<TPopupPeerInviteFeedbackRef, TPopupPeerInviteFeedbackProps>((props, ref) => {
  const [show, setShow] = useState(false);
  const [users, setUsers] = useState<TQueryPeerItemModified[]>([]);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(true);
  const refInputSearch = useRef<HTMLInputElement>();
  const searchDebounced = useDebounce(search);

  useEffect(() => {
    if (!show) {
      setUsers([]);
    }
  }, [show]);

  useImperativeHandle(ref, () => ({
    show() {
      setShow(true);
    },
    hide() {
      setShow(false);
    }
  }), []);

  useEffect(() => {
    if (!show) return;

    setLoading(true);
    ApiMessage.querySubmissionPeers(props.studentId, props.submissionId, props.traitId).then(async (users) => {
      setUsers(users.map(user => ({
        ...user,
        visible: true
      })));
      await Utils.sleep(250);
      setLoading(false);
      setTimeout(() => {
        refInputSearch.current && refInputSearch.current.focus();
      }, 50);
    });
  }, [show]);

  useEffect(() => {
    const keywords = searchDebounced.trim();
    const rSearch = new RegExp(Utils.escapeRegExp(keywords), "i");
    setUsers(users => users.filter(user => {
      if (keywords === "" || rSearch.test(user.name) || rSearch.test(user.email)) {
        user.visible = true;
      } else {
        user.visible = false;
      }

      return user;
    }));
  }, [searchDebounced]);

  const filteredUsers = useMemo(() => {
    return users.filter(user => user.visible);
  }, [users]);

  const setSent = (_id: string) => {
    setUsers(users => users.map(user => ({
      ...user,
      status: user._id === _id ? "Sent" : user.status
    })));
  };

  return <>
    <Modal data-comp="PopupPeerInviteFeedback" className="modal-peer-invite-feedback" show={show} onHide={() => setShow(false)} centered>
      <Modal.Header closeButton>
        <Modal.Title className="fs-4 invite-modal__title">
          Invite Peers to Give Feedback
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>

        <div className="input-group h-100 invite-modal__search mb-3">
          <span className="input-group-text" id="search">
            <HiOutlineSearch />
          </span>
          <input
            ref={refInputSearch}
            disabled={loading}
            autoFocus
            type="text"
            className="form-control"
            placeholder="Search..."
            aria-label="search"
            aria-describedby="search"
            onChange={(e) => {
              setSearch(e.target.value);
            }}
          />
        </div>

        <div className="invite-list-box">
          {loading && <p className="alert alert-info">Loading user(s)...</p>}

          {!loading && users.length === 0 && <p className="alert alert-not-found">User(s) not found</p>}

          {!loading && users.length > 0 && filteredUsers.map((user) => <React.Fragment key={user._id}>
            <div className={`invite-list-item ili--peer`} title={user.name}>
              <UserImage width={50} url={user.avatar} />
              <span>
                {user.name}
                <i>{user.email}</i>
              </span>
              <div>
                {user.status === "Request" && <button className="btn btn-secondary ili__status-request"
                  title="Click to request feedback"
                  onClick={(e) => {
                    e.preventDefault();
                    ModulePopup.showAlertPrompt({
                      title: "Invite peer",
                      description: <p>Invitation message for <strong>{user.name}</strong>:</p>,
                      inputPlaceholder: "Write message...",
                      labelYes: "Send",
                      labelNo: "Cancel",
                      onYes(input) {
                        ApiMessage.invitePeer({
                          createdUserId: props.user.id,
                          senderId: props.user.referenceUserId,
                          submissionId: props.submissionId,
                          receiverId: user._id,
                          traitId: props.traitId,
                          message: input
                        }).then(result => {
                          if (result.status) {
                            setSent(user._id);
                            Notifier.success(result.message);
                          } else {
                            Notifier.error(result.message);
                          }
                        });
                      }
                    })
                  }}
                >
                  Request
                </button>}
                {user.status === "Sent" && <div className="ili__status-sent" title="Feedback request sent">Sent</div>}
              </div>
            </div>
          </React.Fragment>)}

          {!loading && filteredUsers.length === 0 && <p className="alert alert-not-found">The keywords don't match with any user(s)</p>}
        </div>

      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-outline-primary" onClick={(e) => {
          e.preventDefault();
          setShow(false);
        }}>
          Close
        </button>
      </Modal.Footer>
    </Modal>
  </>;
});

PopupPeerInviteFeedback.defaultProps = {
  traitId: ""
};

export default PopupPeerInviteFeedback;
