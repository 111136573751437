import { useCallback, useEffect, useState } from "react";
import Alert from "./Alert";
import AlertConfirmation from "./AlertConfirmation";
import AlertPrompt from "./AlertPrompt";
import TraitScaleConfirm from "./TraitScaleConfirm";
import { TGetAllTraitItem } from '../api/trait';

type TShowAlert = {
  title?: string|JSX.Element|JSX.Element[];
  description?: string|JSX.Element|JSX.Element[];
  labelOK?: string;
  onOK?: () => void;
};

type TShowAlertConfirm = {
  title?: string|JSX.Element|JSX.Element[];
  description?: string|JSX.Element|JSX.Element[];
  labelYes?: string;
  labelNo?: string;
  onYes?: () => void;
  onNo?: () => void;
};

type TShowAlertPrompt = {
  title?: string|JSX.Element|JSX.Element[];
  description?: string|JSX.Element|JSX.Element[];
  inputPlaceholder?: string;
  labelYes?: string;
  labelNo?: string;
  onYes?: (input: string) => void;
  onNo?: () => void;
};

type TShowTraitScaleConfirm = {
  title?: string|JSX.Element|JSX.Element[];
  description?: string|JSX.Element|JSX.Element[];
  labelYes?: string;
  labelNo?: string;
  onYes?: () => void;
  onNo?: () => void;
};

type TShowChangeTraitGoal = {
  labelSubmit?: string;
  onSubmit?: () => void;
  trait: TGetAllTraitItem;
}

export type TModulePopup = {
  showAlert: (p: TShowAlert) => void;
  hideAlert: () => void;
  showAlertConfirm: (p: TShowAlertConfirm) => void;
  showAlertPrompt: (p: TShowAlertPrompt) => void;
  showTraitScaleConfirm: (p: TShowTraitScaleConfirm) => void;
  showChangeTraitGoal: (p: TShowChangeTraitGoal) => void;
};

const ModulePopup: {[key: string]: any} = {};

export function ModulePopupContainer() {
  const [_showAlert, _setShowAlert] = useState(false);
  const [_showAlertInfo, _setShowAlertInfo] = useState<TShowAlert>();
  const showAlert = useCallback((p: TShowAlert) => {
    _setShowAlertInfo(p);
    _setShowAlert(true);
  }, []);
  const hideAlert = useCallback(() => {
    _setShowAlert(false);
  }, []);

  const [_showAlertConfirm, _setShowAlertConfirm] = useState(false);
  const [_showAlertConfirmInfo, _setShowAlertConfirmInfo] = useState<TShowAlertConfirm>();
  const showAlertConfirm = useCallback((p: TShowAlert) => {
    _setShowAlertConfirmInfo(p);
    _setShowAlertConfirm(true);
  }, []);

  const [_showAlertPrompt, _setShowAlertPrompt] = useState(false);
  const [_showAlertPromptInfo, _setShowAlertPromptInfo] = useState<TShowAlertPrompt>();
  const showAlertPrompt = useCallback((p: TShowAlertPrompt) => {
    _setShowAlertPromptInfo(p);
    _setShowAlertPrompt(true);
  }, []);

  const [_showTraitScaleConfirm, _setShowTraitScaleConfirm] = useState(false);
  const [_showTraitScaleConfirmInfo, _setTraitScaleConfirmInfo] = useState<TShowTraitScaleConfirm>();
  const showTraitScaleConfirm = useCallback((p: TShowAlert) => {
    _setTraitScaleConfirmInfo(p);
    _setShowTraitScaleConfirm(true);
  }, []);

  // registers the methods
  useEffect(() => {
    ModulePopup.showAlert = showAlert;
    ModulePopup.hideAlert = hideAlert;
    ModulePopup.showAlertConfirm = showAlertConfirm;
    ModulePopup.showAlertPrompt = showAlertPrompt;
    ModulePopup.showTraitScaleConfirm = showTraitScaleConfirm;
  }, []);

  return <>
    <Alert show={_showAlert} setShow={_setShowAlert}
      onOK={() => {
        _setShowAlert(false);

        if (typeof _showAlertInfo?.onOK === "function") {
          _showAlertInfo?.onOK();
        }
      }}
      title={_showAlertInfo?.title}
      description={_showAlertInfo?.description}
      labelOK={_showAlertInfo?.labelOK}
    />

    <AlertConfirmation show={_showAlertConfirm} setShow={_setShowAlertConfirm}
      onNo={() => {
        _setShowAlertConfirm(false);

        if (typeof _showAlertConfirmInfo?.onNo === "function") {
          _showAlertConfirmInfo?.onNo();
        }
      }}
      onYes={() => {
        _setShowAlertConfirm(false);

        if (typeof _showAlertConfirmInfo?.onYes === "function") {
          _showAlertConfirmInfo?.onYes();
        }
      }}
      title={_showAlertConfirmInfo?.title}
      description={_showAlertConfirmInfo?.description}
      labelNo={_showAlertConfirmInfo?.labelNo}
      labelYes={_showAlertConfirmInfo?.labelYes}
    />

    <AlertPrompt show={_showAlertPrompt} setShow={_setShowAlertPrompt}
      onNo={() => {
        _setShowAlertPrompt(false);

        if (typeof _showAlertPromptInfo?.onNo === "function") {
          _showAlertPromptInfo?.onNo();
        }
      }}
      onYes={(words) => {
        _setShowAlertPrompt(false);

        if (typeof _showAlertPromptInfo?.onYes === "function") {
          _showAlertPromptInfo?.onYes(words);
        }
      }}
      inputPlaceholder={_showAlertPromptInfo?.inputPlaceholder}
      title={_showAlertPromptInfo?.title}
      description={_showAlertPromptInfo?.description}
      labelNo={_showAlertPromptInfo?.labelNo}
      labelYes={_showAlertPromptInfo?.labelYes}
    />

    <TraitScaleConfirm show={_showTraitScaleConfirm} setShow={_setShowTraitScaleConfirm}
      onNo={() => {
        _setShowTraitScaleConfirm(false);

        if (typeof _showTraitScaleConfirmInfo?.onNo === "function") {
          _showTraitScaleConfirmInfo?.onNo();
        }
      }}
      onYes={() => {
        _setShowTraitScaleConfirm(false);

        if (typeof _showTraitScaleConfirmInfo?.onYes === "function") {
          _showTraitScaleConfirmInfo?.onYes();
        }
      }}
      title={_showTraitScaleConfirmInfo?.title}
      description={_showTraitScaleConfirmInfo?.description}
      labelNo={_showTraitScaleConfirmInfo?.labelNo}
      labelYes={_showTraitScaleConfirmInfo?.labelYes}
    />
  </>;
}

export default ModulePopup as TModulePopup;
