import { useContext, useMemo, useEffect } from "react";
import AppUserContext from "../../context/app-user-context";
import UserImage from "./UserImage";
import { getRoleLabel } from "../../modules/role";
import { socket } from "../../socket";
import { PortalContext } from "../../context/portal-context";
import ApiMessage from "../../modules/api/message";

export type TUserHeroProps = {
  hide?: boolean;
};

export default function UserHero(props: TUserHeroProps) {
  const user = useContext(AppUserContext);
  const { setNotifCount } = useContext(PortalContext);
  const setUnreadCount = (studentId) => {
    ApiMessage.getNotificationsUnreadCount(studentId).then(unreadCount => {
      if (unreadCount > 0) {
        setNotifCount(unreadCount);
      }
    });
  };

  useEffect(() => {
    if (!setNotifCount) return;

    if (user.logged) {
      socket.connect();

      const studentId = user.referenceUserId;

      // Listen notification event from server side after connecting
      socket.on("notification", (data: any) => {
        setUnreadCount(studentId);
      });

      // check first load
      setUnreadCount(studentId);

      // Send user id to server side to identify after connecting
      socket.emit("check-in", studentId);
    }

    return () => {
      socket.off("notification");
      socket.disconnect();
    };
  }, [user.logged]);

  const userDesignation = useMemo(() => {
    const role = getRoleLabel(user.role);
    const jobdesk = user.jobDesk;
    return jobdesk === "" || !jobdesk ? role : `${role} | ${jobdesk}`;
  }, [user]);

  return !props.hide && <div className="user-hero" data-comp="UserHero">
        <div className="uh__box">
          <div className="uh__img">
            <UserImage width={60} url={user.avatar} />
          </div>
          <div className="uh__desc">
            <h1>Hello, {user.name}</h1>
        <p>
          {userDesignation}
        </p>
          </div>
        </div>
  </div>;
}
