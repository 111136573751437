import { useContext } from "react";
import { PortalContext } from "../../context/portal-context";
import { HiMenu } from "react-icons/hi";
import { MdArrowBack } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../routes";

export type TMobileHeaderProps = {};

export default function MobileHeader(props: TMobileHeaderProps) {
  const navigate = useNavigate();
  const { portalSetting, setPortalSetting } = useContext(PortalContext);

  return <div className="mobile-header" data-comp="MobileHeader">
    <div className="mh__box">
      <div className="mh__action">

        {!portalSetting.is_homepage && <button className="mh__btn-menu"
          title="Go back"
          onClick={(e) => {
            e.preventDefault();
            if (window.history.length === 1) {
              navigate(ROUTES.PORTAL_DASHBOARD);
            } else {
              navigate(-1);
            }
          }}
        >
          <MdArrowBack/>
        </button>}

        {portalSetting.is_homepage && <button className="mh__btn-menu"
          title="Open menu"
          onClick={() => {
            setPortalSetting(d => ({ ...d, mobile_nav_open: true }));
          }}
        >
          <HiMenu/>
        </button>}

      </div>
      <div className="mh__label" title={portalSetting.label_header}>
        {portalSetting.label_header}
      </div>
    </div>
  </div>;
}
