import { useNavigate } from "react-router-dom";
import classNames from "classnames";
import UserImage from "./UserImage";
import { ROUTES } from "../../routes";
import { TMyPeersItem } from "../../modules/api/user";
import { useContext } from "react";
import AppUserContext from "../../context/app-user-context";
import ROLE from "../../modules/role";

type TUserListSimpleProps = {
  data: TMyPeersItem[];
  labelNotAvailable: string;
  selectedGroup: string;
};

UserAccordListSimple.defaultProps = {
  labelNotAvailable: "Not available."
}

export default function UserAccordListSimple(props: TUserListSimpleProps) {
  const navigate = useNavigate();
  const user = useContext(AppUserContext);

  return <ol className="user-accord-list" data-comp="UserAccordListSimple">
    {props.data.filter(item => item.role === ROLE.STUDENT && item.groupId === props.selectedGroup).map((item, i) => <li key={i} className={classNames([
      "ual__item"
    ])}>
      <button className="ual__btn" title={item.name}
        onClick={(e) => {
          e.preventDefault();
          if (item.role === ROLE.STUDENT &&
            (user.role === ROLE.ADMIN || user.role === ROLE.SUPERVISOR || user.role === ROLE.INDUSTRY_SUPERVISOR)
          ) {
            navigate(ROUTES.PORTAL_MY_GROUP_DETAILS + `/${item._id}`);
          } else {
            if (user.role === ROLE.STUDENT) {
              navigate(ROUTES.PORTAL_PEERS_DETAILS + `/${item._id}`);
            }
          }
        }}
      >
        <div className="ual__img">
          <UserImage url={item.avatar} />
        </div>
        <div className="ual__name">
          {item.name}
        </div>
      </button>
    </li>)}

    {props.data.length === 0 && <li className="ual__item ual__item--not-available">
      {props.labelNotAvailable}
    </li>}
  </ol>
}
