import React from "react";
import toast, { Toast, Toaster } from 'react-hot-toast';
import { HiX } from "react-icons/hi";

export function NotifierContainer() {
  return <>
    <Toaster toastOptions={{

    }} />
  </>
}

type TPropsDismissableToast = {
  content: JSX.Element,
  e: Toast
}

function DismissableToast({ content, e }: TPropsDismissableToast) {
  return <>
    <div className="toast-dismissable">
      {content}
      <button className="btn td__btn-dismiss" onClick={() => toast.dismiss(e.id)}>
        <HiX/>
      </button>
    </div>
  </>
}

const toastManager = {
  ids: [],
  push(id: string) {
    this.ids.push(id);
  },
  show(howMany: number) {
    if (howMany < 1) {
      return;
    }

    const len = this.ids.length;

    if (len < howMany) {
      return;
    }

    for (let id of this.ids.splice(0, len - (howMany - 1))) {
      toast.remove(id);
    }
  }
};

function handleCommonBehaviour(options: TNotifierOptions) {
  if (options.dismissPrevious) {
    toast.dismiss();
  } else {
    if (options.showMaxToast > 0) {
      toastManager.show(options.showMaxToast);
    }
  }
}

const getOptions = (userOptions: any): TNotifierOptions => {
  return Object.assign({
    dismissPrevious: false,
    showMaxToast: 3
  }, userOptions);
}

export type TNotifierOptions = {
  dismissPrevious: boolean;
  /** set to zero or less than zero to disable it */
  showMaxToast: number;
};

export default class Notifier {

  static custom(message: any, userOptions?: Partial<TNotifierOptions>) {
    const options = getOptions(userOptions);

    handleCommonBehaviour(options);
    toastManager.push(toast.custom((e) => <DismissableToast e={e} content={message}></DismissableToast>));
  }

  static info(message: any, userOptions?: Partial<TNotifierOptions>) {
    const options = getOptions(userOptions);

    handleCommonBehaviour(options);
    toastManager.push(toast((e) => <DismissableToast e={e} content={message}></DismissableToast>));
  }

  static error(message: any, userOptions?: Partial<TNotifierOptions>) {
    const options = getOptions(userOptions);

    handleCommonBehaviour(options);
    toastManager.push(toast.error((e) => <DismissableToast e={e} content={message}></DismissableToast>));
  }

  static warn(message: any, userOptions?: Partial<TNotifierOptions>) {
    const options = getOptions(userOptions);

    handleCommonBehaviour(options);
    toastManager.push(toast((e) => <DismissableToast e={e} content={message}></DismissableToast>));
  }

  static success(message: any, userOptions?: Partial<TNotifierOptions>) {
    const options = getOptions(userOptions);

    handleCommonBehaviour(options);
    toastManager.push(toast.success((e) => <DismissableToast e={e} content={message}></DismissableToast>));
  }

  static get toast() {
    return toast;
  }

}
