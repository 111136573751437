import useSessionStore from "./use-session-store";

export type TSessionConfigOptions = "redirect";

/**
 * This is config-related session that will be available as long as user's
 * not closing the tab/browser.
 */
export default function useSessionConfig() {
  return useSessionStore<TSessionConfigOptions>("session_config");
}
