import { usePortalSettingSet } from "../../../context/portal-context";
import usePageTitle from "../../../hooks/use-page-title";
import Timeline from '../../../components/form/TimelineProgress';
import ReportItem from '../../../components/portal/ReportItem';
import { ROUTES } from "../../../routes";
import { useContext, useEffect, useState } from "react";
import AppUserContext from "../../../context/app-user-context";
import AppUser from "../../../modules/app-user/app-user";
import { showLabelInfo } from "../../../config/methods";
import ApiSubmission, { TSubmissionListItem } from "../../../modules/api/submission";
import { SUBMISSION_STATUS_DRAFT } from "../../../config/constants";
import PDStatistics from "../../../components/portal/PDStatistics";
import ReportItemListing from "../../../components/portal/ReportItemListing";

export default function PageProgress() {
  const title = "My Progress";
  usePageTitle(title);
  usePortalSettingSet("label_header", title);

  const user = useContext(AppUserContext);
  const [loading, setLoading] = useState(true);
  const [submissions, setSubmissions] = useState<TSubmissionListItem[]>([]);

  useEffect(() => {
    (async () => {
      await AppUser.refreshUser(["avatar"]);

      const resSubmissionApi = await ApiSubmission.getSubmissions(user.referenceUserId);
      setSubmissions(resSubmissionApi.submissions.filter(s => s.status !== SUBMISSION_STATUS_DRAFT));

      setLoading(false);
    })();
  }, []);

  return <main className="content-generic content-generic--fluid" data-comp="PageProgress">
    <h1 className='fs-title'>My Progress</h1>

    <div className="d-flex gap-3 bg-white p-3 rounded-4 shadow-sm">
      <ul className="contact-detail-list student-profile-list">
        <li>
          <div>Student ID</div>
          <div>{showLabelInfo(user.studentId)}</div>
        </li>
        <li>
          <div>Student Name</div>
          <div>{showLabelInfo(user.name)}</div>
        </li>
        <li>
          <div>Group Name</div>
          <div>{showLabelInfo(user.groupName)}</div>
        </li>
        <li>
          <div>Job title</div>
          <div>{showLabelInfo(user.jobDesk)}</div>
        </li>
        <li>
          <div>Programme</div>
          <div>{showLabelInfo(user.studyProgramName)}</div>
        </li>
        <li>
          <div>Company Name</div>
          <div>{showLabelInfo(user.industryName)}</div>
        </li>
        <li>
          <div>Industry Supervisor</div>
          <div>{showLabelInfo(user?.supervisors?.industry)}</div>
        </li>
        <li>
          <div>SIT/Academic Supervisor</div>
          <div>{showLabelInfo(user?.supervisors?.sit)}</div>
        </li>
      </ul>
    </div>

    <div className='mt-3'>
      <div className='flex-grow-1 bg-white p-3 rounded-4 shadow-sm'>

        <PDStatistics studentId={user.referenceUserId} />

        <ReportItemListing
          className="mt-3 report-item-listing--progress-page"
          userId={user.referenceUserId}
          itemsPerPage={10}
          pagination={true}
          status="Publish"
        />

      </div>
    </div>
  </main>;
}
