import { each, extend } from "lodash-es";
import { Regexes } from "./regexes";

const R = Regexes;

export namespace ObjectQuery {

  export const SORT_ASC = 1;
  export const SORT_DESC = 2;
  export const STANDARD_OBJECT_EMPTY_VALUE = "invalidkey";


  /**
   * @method standardObjectKey
   * @description
   * This method will transform string into standard object key. We lowercase the alphabet so that it will be case
   * insensitive.
   *
   * @rules:
   * - Anything that is not `$`, `_`, alphabet, number, and strip will be removed
   * - Any one or more strip will be replaced with single space
   * - Any single space will be removed and single character of alphabet next to it will be uppercase
   *
   * @example:
   * ObjectQuery.standardObjectKey("This key with ~!@#$%^&*()_+ word will be                     ok!!!$")
   * will return:
   * thisKeyWith_WordWillBeOk$
   *
   * @param {string|all} inputString Can be others
   * @return {string} standardized characters on success. If fail, the returned will be defaultkey
   */
  export function standardObjectKey(inputString) {
    let ret;
    let defaultkey;

    // Making sure `inputString` is string
    if (typeof inputString == 'undefined') {
      inputString = "";
    } else {
      if (typeof inputString != "string") {
        inputString = inputString.toString();
      }
    }

    if (typeof arguments != 'undefined' && Array.isArray(arguments) && arguments[1]) {
      defaultkey = arguments[1];
    } else {
      defaultkey = STANDARD_OBJECT_EMPTY_VALUE;
    }

    ret = inputString.
    toLowerCase().
    replace(R.ONE_OR_MORE_STRIP, " ").
    replace(R.NOT_ACCEPTED_OBJECT_KEY, "").
    replace(R.ONE_OR_MORE_PREFIX_SPACES, "").
    replace(R.ONE_OR_MORE_SUFFIX_SPACES, "").
    replace(R.ONE_OR_MORE_SPACES_WITH_LOWER_CASE_LETTER, function (a, b, c) {
      return b.toUpperCase();
    }).
    replace(R.ONE_OR_MORE_SPACES_WITH_NON_LOWER_CASE_LETTER, "").
    replace(R.NOT_ACCEPTED_FIRST_CHARACTER_OF_OBJECT_KEY, "");

    return ret == "" ? defaultkey : ret;
  }

  /**
   * @method filterObjectBy
   * @description
   * This is like find object in array that satisfies the `keyName` and its value (`valueName`).
   */
  export function filterObjectBy(keyName, valueName, arrObj) {
    let newData = [];
    let i = 0;
    let j = arrObj.length;

    for (; i < j; i++) {
      (function (cur) {
        if (cur[keyName] == valueName) {
          newData.push(cur);
        }
      }(arrObj[i]));
    }

    return newData;
  }

  /**
   * @method filterObjectByProperties
   */
  export function filterObjectByProperties(arrConditional, arrObj, isSearching) {
    let newData = [];

    if (!isSearching) {
      isSearching = false;
    }

    each(arrObj, function (value) {
      let isSatisfied = true,
        result = {};

      each(arrConditional, function (innerValue, innerIndex) {
        innerValue = innerValue ? innerValue : "";

        if (!isSearching) {
          if (value[innerIndex] && value[innerIndex] == innerValue) {
            result = value;
          } else {
            isSatisfied = false;
          }
        } else {
          if (value[innerIndex] && value[innerIndex].toLowerCase().indexOf(innerValue.toLowerCase()) > -1) {
            result = value;
          } else {
            isSatisfied = false;
          }
        }
      });

      if (isSatisfied) {
        newData.push(result);
      }
    });

    return newData;
  }

  /**
   * @method groupObjectBy
   */
  export function groupObjectBy(keyName, arrObj) {
    let newData = {};
    let res = [];
    let i = 0;
    let j = arrObj.length;

    for (; i < j; i++) {
      newData[arrObj[i][keyName]] = arrObj[i][keyName];
    }

    for (let x in newData) {
      res.push(newData[x]);
    }

    return res;
  }

  /**
   * @method getObjectBy
   *
   * @description
   * Get object from the array of object based on specified key/value pairs
   *
   * @usage
   * ObjectQuery.getObjectBy("name", "Faisal", [
   *  { name: "Faisal", age: 17 },
   *  { name: "Thomas", age: 18 }
   * ]);
   *
   * @explain
   * Find object from arrObject WHERE @key=@value
   *
   * @param {} key
   * @param {} value
   * @param {Object[]} arrObject
   * @return {Object} the result (Empty when no matches)
   */
  export function getObjectBy(key, value, arrObject) {
    let ret = {};

    each(arrObject, function (v) {
      if (v[key] && v[key] == value) {
        ret = extend({}, v);
        return false;
      }
    });

    return ret;
  }

  /**
   * @method getObjectByProperties
   *
   * @usage
   * ObjectQuery.getObjectByProperties({
   *   "cond1": true,
   *   "cond2": false
   * }, arrObj);
   *
   * @explain
   * find object from `arrObject` WHERE cond1 = true AND cond2 = false
   *
   * @return {Object} the result (Empty when no matches)
   */
  export function getObjectByProperties(objConditional, arrObject) {
    let ret = {};

    each(arrObject, function (value) {
      let isSatisfied = true,
        result;

      each(objConditional, function (innerValue, innerIndex) {
        if (value[innerIndex] === innerValue) {
          if (!result) {
            result = value;
          }
        } else {
          isSatisfied = false;
        }
      });

      if (isSatisfied) {
        ret = extend({}, result);
        return false;
      }
    });

    return ret;
  }

  /**
   * @method sortBy
   * @description
   * Sort Array of objects by its key
   */
  export function sortBy(objectKey, arrObject, sortType) {
    if (typeof sortType == 'undefined') {
      sortType = SORT_ASC;
    }

    return arrObject.sort(function (a, b) {
      a = a[objectKey].toString().toLowerCase();
      b = b[objectKey].toString().toLowerCase();

      if (sortType == SORT_ASC) {
        if (a < b) {
          return -1;
        } else if (a > b) {
          return 1;
        } else {
          return 0;
        }
      }

      if (sortType == SORT_DESC) {
        if (a < b) {
          return 1;
        } else if (a > b) {
          return -1;
        } else {
          return 0;
        }
      }
    });
  }

  /**
   * @method sortObjectsByKey
   * @description
   * This method will create a new object with sorted-by key from given object.
   *
   * @example
   * let testObjects = { c: 3, a: 1, b: 2 }
   *
   * // Will return
   * // { a: 1, b: 2, c: 3 }
   * ObjectQuery.sortObjectsByKey(testObjects);
   *
   * Got that?
   *
   * @param {Object} obj
   */
  export function sortObjectsByKey(obj) {
    let arr = [];
    let newObj = {};

    each(obj, function (v, k) {
      arr.push(k);
    });

    arr.sort(function (a, b) {
      if (a < b) {
        return -1;
      } else if (a > b) {
        return 1;
      } else {
        return 0;
      }
    });

    each(arr, function (v) {
      newObj[v] = obj[v];
    });

    return newObj;
  };

  /**
   * @method FilterObjectByArray
   *
   * @example
   * let obj = [
   *  { name: "Faisal", hobbies: ["computer"] },
   *  { name: "Azwar", hobbies: ["computer"] },
   *  { name: "Toni", hobbies: ["cooking"] }
   * ]
   *
   * // Will return
   * // [ { name: "Faisal", ... }, { name: "Azwar", ... } ]
   * let filter = FilterObjectByArray(obj, "hobbies", "computer");
   *
   * @note
   * Parameter `strInArrayToSearch` is case sensitive
   *
   * @param {Array} arrObj
   * @param {string} objKey
   * @param {string} strInArrayToSearch
   * @param {Boolean} isDeepSearching - we will use regex, no need to be exact same
   * @return {Array} of filtered object. empty when no matches...
   */
  export function filterObjectByArray(arrObj, objKey, strInArrayToSearch, isDeepSearching) {
    let newObj = [];

    // Checking ~
    isDeepSearching = typeof isDeepSearching == 'undefined' ? false : !!isDeepSearching;

    each(arrObj, function (obj) {
      if (obj[objKey] && Array.isArray(obj[objKey])) {
        each(obj[objKey], function (cat) {
          if (isDeepSearching) {
            if ((new RegExp(strInArrayToSearch, "gim")).test(cat)) {
              newObj.push(obj);
            }
          } else {
            if (strInArrayToSearch == cat) {
              newObj.push(obj);
            }
          }
        });
      }
    });

    return newObj;
  };

  let r = function () {
    let x = (new Date()).getTime().toString();

    return parseFloat("0." + x[x.length - 1]);
  };

  /**
   * @method fromTo
   * @description
   * This method will return random integer based on given arguments. If `to` argument is not specified,
   * it will be generated from 0 to `from`.
   *
   * @example
   * // We are using `was`, because yours might be different ~ haha
   * // Was returning 108
   * ObjectQuery.fromTo(100, 110);
   *
   * // Was returning 4
   * ObjectQuery.fromTo(10);
   *
   * // If both params are equal, the random integer will produce from 0 to the given `from` parameter
   * ObjectQuery.fromTo(10, 10);
   *
   * @param {Number} from
   * @param {Number} to
   * @return {Number}
   */
  export function fromTo(from: number, to: number): number {
    return from < to ? from + (Math.floor(r() * (to - from))) : (Math.floor(r() * from));
  };

  /**
   * @method checkDuplication
   * @description
   * Removing duplicated object based on specified `keyId`
   *
   * @param {string} keyId
   * @param {Array} arrObj
   * @return {void} since we're passing by reference
   */
  export function checkDuplication(keyId, arrObj) {
    let indices = {};
    let removedIndices = [];

    each(arrObj, function (v, k) {
      if (typeof indices[v[keyId]] == 'undefined') {
        indices[v[keyId]] = true;
      } else {
        // Duplication detected!
        // Store the `k` ~ aha!
        removedIndices.push(k);
      }
    });

    each(removedIndices.sort(function (a, b) {
      return a < b ? -1 : a > b ? 1 : 0;
    }).reverse(), function (v, k) {
      arrObj.splice(v, 1);
    });
  }

}
