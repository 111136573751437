export const Multilingual_EN = {

  OK: "OK",

  UNAUTHORIZED_TO_PERFORM_ACTION: "You are not authorized to perform this action.",
  INSUFFICIENT_ROLE: "Insufficient role.",

  JWT_PARSE_ERROR: "Error parsing token.",

  UNKNOWN_ERROR: "Unknown error occurred.",

  CONFIRM_ACTION_CANT_BE_UNDONE: "Are you sure? This action can't be undone."

} as const;
