export type TUserImageProps = {
  url?: string;
  alt?: string;
  /** number in pixel */
  width?: number;
};

UserImage.defaultProps = {
  alt: "User's profile picture",
  url: "/assets/images/default-profile.png",
  width: 100
};

function resolveUrl(url?: string) {
  let resolved = UserImage.defaultProps.url;

  if (typeof url === "string") {
    url = url.trim();

    if (url !== "") {
      resolved = url;
    }
  }

  return resolved;
}

export default function UserImage(props: TUserImageProps) {
  return <div className="user-image" data-comp="UserImage" style={{
    width: props.width
  }}>
    <div className="ui__frame">
      <img src={resolveUrl(props.url)} alt={props.alt} />
    </div>
  </div>;
}
