import React, { useState, useContext, useMemo } from "react";
import { Link } from "react-router-dom";
import usePageTitle from "../../../hooks/use-page-title";
import { usePortalSettingSet } from "../../../context/portal-context";
import AppUserContext from "../../../context/app-user-context";
import { ROUTES } from "../../../routes";
import { MdDelete, MdModeEditOutline, MdSearch } from "react-icons/md";
import { Dropdown } from "react-bootstrap";
import Debouncer from "../../../modules/debouncer/debouncer";
import ServerSideGenericDataTable from "../../../components/common/ServerSideGenericDataTable";
import ApiStudyProgram from "../../../modules/api/study-program";
import ApiGenericData from "../../../modules/api/generic-data";
import Notifier from "../../../modules/notifier/notifier";
import ROLE, { getExistingRoleUrlParam } from "../../../modules/role";
import { CONST_SELECT_ROLES } from "../../../config/constants";
import classNames from "classnames";
import ApiUser from "../../../modules/api/user";
import ServerSideUserDataTable from "../../../components/common/ServerSideUserDataTable";
import Utils from "../../../utils/utils";
import RoleFilterSelector from "../../../components/portal/RoleFilterSelector";
import ModulePopup from "../../../modules/popup/popup";

const trim = Utils.trimString;

export default function PageUserList() {
  const title = "Manage User";
  usePageTitle(title);
  usePortalSettingSet("label_header", title);

  const user = useContext(AppUserContext);
  const [filterBy, setFilterBy] = useState("name");
  // get the query string param of "role", if value not found, fallback to `ROLE.STUDENT`
  const roleParam = useMemo(() => getExistingRoleUrlParam("role", ROLE.STUDENT), []);
  const [role, setRole] = useState<string>(roleParam);
  const [refresh, setRefresh] = useState(false);
  const [search, setSearch] = useState("");
  const [selectedUsers, setSelectedUsers] = useState<any[]>([]);

  const ROLES_FILTER = useMemo(() => {
    return Array.from(CONST_SELECT_ROLES).concat([{
      label: "All",
      value: ""
    }]);
  }, []);

  return <main className="form-generic content-generic content-generic--fluid">

    <h1 className="fs-title hide-mobile-down">{title}</h1>

    <div className="cg__actions">
      <div className="cg__action-buttons">
        <Link to={ROUTES.PORTAL_USERS_REGISTER} className="btn btn-primary">
          Add New User
        </Link>
        {" "}
        <button className="btn btn-outline-primary"
          onClick={() => setRefresh(i => !i)}
        >
          Refresh
        </button>
        {" "}
        {selectedUsers.length > 0 && <button className="btn btn-outline-primary"
          onClick={(e) => {
            const deletedUserIds = selectedUsers.filter(i => typeof i._id !== "undefined").map(i => i._id);
            ModulePopup.showAlertConfirm({
              title: "Delete Confirmation",
              description: <>
                <p>Are you sure to delete {deletedUserIds.length} users?
                  <br/> This action can't be undone.
                </p>
              </>,
              onYes: () => Debouncer.execute("DELETE_USERS", async () => {
                ApiUser.remove(deletedUserIds).then(res => {
                  if (res.status) {
                    setSelectedUsers([]);
                    setRefresh(i => !i);
                    Notifier.success(res.message);
                  } else {
                    Notifier.error(res.message);
                  }
                });
              })
            })
          }}
        >
          Delete Selected
        </button>}
      </div>
    </div>

    <div className="cg__actions">
      <div className="cg__action-buttons">
        <RoleFilterSelector default={role} options={ROLES_FILTER} onChange={(v) => {
          // when changing role, we reset the search box
          setSearch("");
          setRole(v);
        }} />
      </div>
      <div className="cg__action-advance-search">
        <div className="cg__action-dropdown me-2">
          <select defaultValue={filterBy} onChange={(e) => {
            setFilterBy(e.target.value);
          }}>
            <option value="name">Name</option>
            <option value="email">Email</option>
          </select>
        </div>
        <div className="cg__action-search">
          <span>
            <MdSearch/>
          </span>
          <input type="text" placeholder="search" value={search} onChange={(e) => setSearch(e.target.value)} />
        </div>
      </div>
    </div>

    <ServerSideUserDataTable
      refresh={refresh}
      api={ApiUser.pagingSearch}
      selectableRows
      onSelectedRowsChange={(e) => setSelectedUsers(e.selectedRows)}
      role={role}
      filterBy={filterBy}
      search={search}
      columns={[
        {
          id: "name",
          name: "Name",
          cell: (row) => row.name
        },
        {
          id: "year",
          name: "Cohort",
          cell: (row) => trim(row.yearName)
        },
        {
          id: "studyProgram",
          name: "Programme",
          cell: (row) => row.studyProgramName
        },
        {
          id: "groupName",
          name: "Group",
          cell: (row) => row.groupName
        },
        {
          id: "email",
          name: "Email",
          cell: (row) => row.email
        },
        {
          name: "Action",
          button: true,
          cell: (row) => <div>
            <Dropdown align={"end"} drop={"down"}>
              <Dropdown.Toggle variant="primary" id="dropdown-basic" className="btn-sm">
                Action
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Link className="dropdown-item" to={`edit/${row._id}`}>
                  <MdModeEditOutline/> {" "} Edit
                </Link>

                <Dropdown.Divider />

                <button className="dropdown-item btn-danger" onClick={() => {
                  ModulePopup.showAlertConfirm({
                    title: "Delete Confirmation",
                    description: <>
                      <p>Are you sure to delete this user (<strong>{row.name}</strong>)?
                        <br/> This action can't be undone.
                      </p>
                    </>,
                    onYes: () => Debouncer.execute("DELETE_USER", async () => {
                      ApiUser.remove([row._id]).then(res => {
                        if (res.status) {
                          setRefresh(i => !i);
                          Notifier.success(res.message);
                        } else {
                          Notifier.error(res.message);
                        }
                      });
                    })
                  });
                }}>
                  <MdDelete/> {" "} Delete
                </button>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        }
      ]}
    />

  </main>;
}
