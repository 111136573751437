import { useEffect, useState } from "react";
import Contents from "../../../components/portal/Contents";
import { ROUTES } from "../../../routes";
import LoadingData from "../../../components/portal/LoadingData";
import ApiDashboard, { TDashboardAdminStatistic } from "../../../modules/api/dashboard";

export default function PageDashboardAdmin() {
  const [ready, setReady] = useState(false);
  const [data, setData] = useState<TDashboardAdminStatistic>();

  useEffect(() => {
    ApiDashboard.getStatistic().then(data => {
      setReady(true);
      setData(data);
    });
  }, []);

  return <div className="row" data-comp="PageDashboardAdmin">
    <LoadingData show={!ready} text="Loading dashboard information... Please wait." />

    {ready && <>
      <Contents
        title={`Users (${data.totalUsers.all})`}
        actionText="Add new user"
        oneColumn
        data={[
          {
            id: 1,
            title: `Student (${data.totalUsers.STUDENT})`,
            link: ROUTES.PORTAL_USERS + '?role=student'
          },
          {
            id: 2,
            title: `SIT Supervisor (${data.totalUsers["SIT SUPERVISOR"]})`,
            link: ROUTES.PORTAL_USERS + '?role=supervisor'
          },
          {
            id: 3,
            title: `Industry Supervisor (${data.totalUsers["INDUSTRY SUPERVISOR"]})`,
            link: ROUTES.PORTAL_USERS + '?role=industry'
          }
        ]}
        linkTo={ROUTES.PORTAL_USERS_REGISTER}
      />

      <div className="content col-lg-6 mb-3 content-statistic">
        <h1 className="fs-title">Statistics for Current Month</h1>

        <h2>Total user login: {data.totalUserLogin}</h2>

        <h2>Feedback</h2>
        <ol>
          <li>
            <div>
              <span>
                <em>Peer</em>
                <strong>{data.totalFeedbacks.STUDENT}</strong>
              </span>
            </div>
          </li>
          <li>
            <div>
              <span>
                <em>SIT Supervisor</em>
                <strong>{data.totalFeedbacks["SIT SUPERVISOR"]}</strong>
              </span>
            </div>
          </li>
          <li>
            <div>
              <span>
                <em>Industry Supervisor</em>
                <strong>{data.totalFeedbacks["INDUSTRY SUPERVISOR"]}</strong>
              </span>
            </div>
          </li>
        </ol>

        <h2>Submission</h2>
        <ol>
          <li>
            <div>
              <span>
                <em>Professional<br/> Development</em>
                <strong>{data.totalSubmissions["Professional Development"]}</strong>
              </span>
            </div>
          </li>
          <li>
            <div>
              <span>
                <em>Workplace<br/> Learning</em>
                <strong>{data.totalSubmissions["Workplace Learning"]}</strong>
              </span>
            </div>
          </li>
        </ol>
      </div>
    </>}
  </div>;
}
