import { forwardRef, useEffect, useImperativeHandle, useMemo, useState } from "react"
import { Modal } from "react-bootstrap";
import TraitScale from "../portal/TraitScale";
import FGTextarea from "../dumb/FGTextarea";
import { TGetAllTraitItem } from "../../modules/api/trait";
import ModulePopup from "../../modules/popup/popup";

type TFormFields = {
  scaleEndGoal: number;
  scaleEndGoalChangeReason: string;
};

export type TPopupEditTraitGoalRef = {
  show: (initialData?: TFormFields) => void;
  hide: () => void;
};

export type TPopupEditTraitGoalProps = {
  trait: TGetAllTraitItem;
  title?: string;
  labelSubmit?: string;
  backdrop?: boolean|"static";
  keyboard?: boolean;
  onClose?: () => void;
  onSubmit?: (data: TFormFields) => void;
};

const PopupEditTraitGoal = forwardRef<TPopupEditTraitGoalRef, TPopupEditTraitGoalProps>((props, ref) => {
  const [show, setShow] = useState(false);
  const [formData, setFormData] = useState({
    scaleEndGoal: 0,
    scaleEndGoalChangeReason: ""
  });
  const isValid = useMemo(() => {
    let valid = false;

    if (formData.scaleEndGoal > 0 && formData.scaleEndGoalChangeReason.trim() !== "") {
      valid = true;
    }

    return valid;
  }, [formData]);

  const handleOnChange = (e) => {
    setFormData(f => ({ ...f, [e.target.name]: e.target.value }));
  };

  useImperativeHandle(ref, () => ({
    show(data) {
      if (!!data) {
        setFormData(f => ({ ...f, ...data }));
      }
      setShow(true);
    },
    hide() {
      setShow(false);
    }
  }));

  useEffect(() => {
    if (!show) {
      setFormData({
        scaleEndGoal: 0,
        scaleEndGoalChangeReason: ""
      });
    }
  }, [show]);

  return <Modal className="modal-generic modal-change-trait-goal" show={show}
    centered
    backdrop={props.backdrop}
    keyboard={props.keyboard}
    onHide={() => {
      setShow(false);
      props.onClose();
    }}
  >
    <Modal.Header closeButton>
      <Modal.Title>Change Trait Goal</Modal.Title>
    </Modal.Header>
    <Modal.Body className="ts__legend-desc">
      <div className="ctg__content">
        <TraitScale name="scaleEndGoal" trait={props.trait} value={formData.scaleEndGoal} onChange={handleOnChange} />
        <FGTextarea name="scaleEndGoalChangeReason"
          value={formData.scaleEndGoalChangeReason} onChange={handleOnChange}
          placeholder="What's the reason behind wanting to change the value? Why do you want to see yourself at that value?"
          variant='normal'
          label={<>Please share your reason with us!</>}
        />
      </div>
    </Modal.Body>
    <Modal.Footer>
      <button className="btn btn-mw btn-primary" onClick={() => {
        if (isValid) {
          props.onSubmit(formData);
        } else {
          ModulePopup.showAlert({
            title: "Unable to proceed",
            description: "Please fill-in goal and reason."
          });
        }
      }}>
        {props.labelSubmit ?? "Submit"}
      </button>
    </Modal.Footer>
  </Modal>;
});

PopupEditTraitGoal.defaultProps = {
  title: "Choose Trait",
  labelSubmit: "Submit",
  backdrop: "static",
  keyboard: false,
  onClose: () => {},
  onSubmit: () => {}
};

export default PopupEditTraitGoal;
