import { useEffect, useState } from 'react';
import DatePicker from "react-datepicker";
import { HiOutlineCalendar } from 'react-icons/hi';
import "react-datepicker/dist/react-datepicker.css";

export type TDateRangeProps = {
  title: string;
  label_to: string;
  placeholder_start_date: string;
  placeholder_end_date: string;
  date_format: string;
  onStartEndDateSelected: (start: Date, end: Date) => void;
  onStartDateChange: (date: Date) => void;
  onEndDateChange: (date: Date) => void;
  disabled?: boolean;
  className?: string;

  // Give random negative number to reset the date
  start_date?: Date|number;
  default_start_date?: Date|string|number;
  end_date?: Date|string|number;
  default_end_date?: Date|string|number;
};

DateRange.defaultProps = {
  title: "Select Date",
  label_to: "to",
  placeholder_start_date: "Select start date",
  placeholder_end_date: "Select end date",
  date_format: "MMMM d, yyyy",
  className: "",
  onStartEndDateSelected: () => {},
  onStartDateChange: () => {},
  onEndDateChange: () => {}
} as Partial<TDateRangeProps>;

export default function DateRange(props: TDateRangeProps) {
  const [startDate, setStartDate] = useState<any>(props.default_start_date);
  const [endDate, setEndDate] = useState<any>(props.default_end_date);

  useEffect(() => {
    if (startDate instanceof Date && endDate instanceof Date) {
      props.onStartEndDateSelected(startDate, endDate);
    }
  }, [startDate, endDate]);

  useEffect(() => {
    if (props.end_date instanceof Date && !isNaN(props.end_date.getTime())) {
      setEndDate(props.end_date);
    } else {
      if (
        (typeof props.end_date === "string" && props.end_date === "") ||
        (typeof props.end_date === "number" && props.end_date < 0)
      ) {
        setEndDate(null);
      }
    }
  }, [props.end_date]);

  useEffect(() => {
    if (props.start_date instanceof Date && !isNaN(props.start_date.getTime())) {
      setStartDate(props.start_date);
    } else {
      if (
        (typeof props.start_date === "string" && props.start_date === "") ||
        (typeof props.start_date === "number" && props.start_date < 0)
      ) {
        setStartDate(null);
      }
    }
  }, [props.start_date]);

  return <div className={`date-range ${props.className}`} data-comp='DateRange'>
    <div className='dr__container'>
      <div className='dr__title'>
        {props.title}
        <HiOutlineCalendar />
      </div>
      <div className="dr__date-area">
        <div>
          <DatePicker
            disabled={props.disabled}
            className="dr__input dr__date-start"
            selected={startDate}
            onChange={(date) => {
              setStartDate(date);
              props.onStartDateChange(date);
            }}
            selectsStart
            dateFormat={props.date_format}
            startDate={startDate}
            endDate={endDate}
            placeholderText={props.placeholder_start_date}
          />
        </div>
        <span className='dr__to'>{props.label_to}</span>
        <div>
          <DatePicker
            disabled={props.disabled}
            className="dr__input dr__date-end"
            selected={endDate}
            onChange={(date) => {
              setEndDate(date);
              props.onEndDateChange(date);
            }}
            selectsEnd
            dateFormat={props.date_format}
            startDate={startDate}
            endDate={endDate}
            minDate={startDate}
            placeholderText={props.placeholder_end_date}
          />
        </div>
      </div>
    </div>
  </div>;
}
