import moment from "moment";
import classNames from "classnames";
import { MdSave } from "react-icons/md";
import { useState, useEffect } from "react";
import { ROUTES } from "../../../routes";
import { Link, useParams } from "react-router-dom";
import Notifier from "../../../modules/notifier/notifier";
import { Modal, Button } from "react-bootstrap";
import { HiArrowLeft } from "react-icons/hi";
import { BsFillInfoSquareFill } from "react-icons/bs";
import DataTable from "react-data-table-component";
import FGField from "../../../components/dumb/FGField";
import FGDropdownApi from "../../../components/form/FGDropdownApi";
import ApiScheduleReminder, {
  UpdateJobPayload,
} from "../../../modules/api/schedule-reminder";
import Multilingual from "../../../modules/multilingual/multilingual";
import {
  CRON_JOB_TYPE_HOURLY,
  CRON_JOB_TYPE_DAILY,
  CONST_SELECT_CRON_JOB_TYPE,
} from "../../../config/constants";

export default function PageScheduleReminderEdit() {
  const [formData, setFormData] = useState(undefined);
  const [contentChanged, setContentChanged] = useState(false);
  const [jobHistories, setJobHistories] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [sending, setSending] = useState(false);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [show, setShow] = useState(false);
  const [historyDetail, setHistoryDetail] = useState(undefined);

  const { _id } = useParams();

  useEffect(() => {
    getJobById();
    getJobHistories();
  }, []);

  useEffect(() => {
    if (formData) {
      setStartDateTime(formData.typeCronJob, formData.startTime);
    }
  }, [formData?.typeCronJob, formData?.startTime]);

  useEffect(() => {
    getJobHistories();
  }, [page, perPage]);

  const getJobById = async () => {
    const { data, error } = await ApiScheduleReminder.getJobById(_id);
    if (error) {
      Notifier.error(error);
      return;
    }
    const formData = JSON.parse(JSON.stringify(data));
    formData.startTime = moment(data.startDateTime).format("HH:mm");
    setFormData(formData);
  };

  const getJobHistories = async () => {
    setLoading(true);
    const { data, totalRows, error } =
      await ApiScheduleReminder.getJobHistories(_id, page, perPage);
    if (error) {
      Notifier.error(error);
      return;
    }
    setJobHistories(data);
    setTotalRows(totalRows);
    setLoading(false);
  };

  const setStartDateTime = (type: string, startAt: string) => {
    let datetime = new Date(
      `${moment(new Date()).format("YYYY-MM-DD").toString()} ${startAt}:00`
    );
    const now = new Date();
    if (datetime < now && type === CRON_JOB_TYPE_HOURLY) {
      datetime = new Date(datetime.getTime() + 86400000);
    } else if (type === CRON_JOB_TYPE_DAILY) {
      datetime = new Date(datetime.getTime() + 86400000);
    }
    setFormData((formData) => ({
      ...formData,
      startDateTime: datetime,
    }));
  };

  const handleOnChange = (e: any) => {
    if (e.target.name === "startTime" && !e.target.value) {
      return;
    }
    if (
      e.target.name === "stepSize" &&
      (parseInt(e.target.value) < 1 || parseInt(e.target.value) > 100)
    ) {
      return;
    }
    if (
      e.target.name === "beforeDays" &&
      (parseInt(e.target.value) < 0 || parseInt(e.target.value) > 100)
    ) {
      return;
    }
    setContentChanged(true);
    setFormData((d) => ({
      ...d,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setSending(true);
    const payload: UpdateJobPayload = {
      typeCronJob: formData.typeCronJob,
      stepSize: formData.stepSize,
      startDateTime: formData.startDateTime,
      beforeDays: formData.beforeDays,
      description: formData.description,
    };
    const { success, error } = await ApiScheduleReminder.updateJob(
      _id,
      payload
    );
    if (!success) {
      Notifier.error(error);
    } else {
      Notifier.success("Task has been updated");
    }
    setSending(false);
    setContentChanged(false);
  };

  const handleCancel = (e: any) => {
    if (
      contentChanged &&
      !window.confirm(Multilingual.get("CONFIRM_ACTION_CANT_BE_UNDONE"))
    ) {
      e.preventDefault();
    }
  };

  const handleClose = () => {
    setShow(false);
    setHistoryDetail(undefined);
  };

  const handleShow = (completedDate: string, historyLogs: any[]) => {
    setShow(true);
    setHistoryDetail({
      completedDate: moment(completedDate).format("YYYY-MM-DD hh:mm A"),
      historyLogs: historyLogs,
    });
  };

  const onChangeRowsPerPage = (perPage: number, page: number) => {
    setPerPage(perPage);
    setPage(page);
  };

  const onChangePage = (page: number) => {
    setPage(page);
  };

  const getFooterTitle = () => {
    if (
      historyDetail &&
      historyDetail.historyLogs &&
      historyDetail.historyLogs.length
    ) {
      return `${historyDetail.historyLogs.length} message(s) sent`;
    } else {
      return "";
    }
  };

  return (
    <div>
      <Modal show={show} onHide={handleClose} className="history-detail-modal">
        <Modal.Header closeButton>
          <Modal.Title>
            {formData && formData.taskName}
            {" - "}
            {historyDetail && historyDetail.completedDate}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="history-detail-modal-body">
          {historyDetail && (
            <DataTable
              columns={[
                {
                  name: "Email",
                  width: "300px",
                  cell: (row: any) => <>{row?.email}</>,
                },
                {
                  name: "Name",
                  width: "200px",
                  cell: (row: any) => <>{row?.name}</>,
                },
                {
                  name: "Student ID",
                  width: "200px",
                  cell: (row: any) => <>{row?.studentId}</>,
                },
                {
                  name: "Message",
                  cell: (row: any) => <>{row?.message}</>,
                },
              ]}
              data={historyDetail.historyLogs}
            ></DataTable>
          )}
        </Modal.Body>
        <Modal.Footer className="history-detail-modal-footer">
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          {getFooterTitle()}
        </Modal.Footer>
      </Modal>
      <main className="form-generic content-generic">
        {formData && (
          <>
            <h2 className="fs-title mb-3 hide-mobile-down">
              Edit - {formData.taskName}
            </h2>
            <Link
              to={ROUTES.PORTAL_SCHEDULE_REMINDER}
              className="btn btn-primary"
              onClick={(e) => {
                if (
                  contentChanged &&
                  !window.confirm(
                    Multilingual.get("CONFIRM_ACTION_CANT_BE_UNDONE")
                  )
                ) {
                  e.preventDefault();
                }
              }}
            >
              <HiArrowLeft /> Back
            </Link>
            <form onSubmit={handleSubmit}>
              <FGDropdownApi
                label="Type Cron Job"
                name="typeCronJob"
                options={CONST_SELECT_CRON_JOB_TYPE}
                defaultValue={formData.typeCronJob}
                onChange={handleOnChange}
              />
              <FGField
                label={"Step Size"}
                name={"stepSize"}
                comp="FGInput"
                className={classNames([
                  "fg__input",
                  { "fg__control--normal": true },
                ])}
              >
                <input
                  min={1}
                  max={100}
                  id="stepSize"
                  name="stepSize"
                  type="number"
                  required
                  value={formData.stepSize}
                  onChange={handleOnChange}
                />
              </FGField>
              <FGField
                name={"startTime"}
                label={`Start Time ( ${moment(formData.startDateTime).format(
                  "MM/DD/YYYY hh:mm A"
                )} )`}
                comp="FGInput"
                className={classNames([
                  "fg__input",
                  { "fg__control--normal": true },
                ])}
              >
                <input
                  id="startTime"
                  name="startTime"
                  type="time"
                  required
                  value={formData.startTime}
                  onChange={handleOnChange}
                />
              </FGField>
              <FGField
                name={"beforeDays"}
                label={`Start before ${formData.beforeDays} day(s)`}
                comp="FGInput"
                className={classNames([
                  "fg__input",
                  { "fg__control--normal": true },
                ])}
              >
                <input
                  type="number"
                  id="beforeDays"
                  name="beforeDays"
                  required
                  min={0}
                  value={formData.beforeDays}
                  onChange={handleOnChange}
                />
              </FGField>
              <button
                type="submit"
                className="btn btn-success"
                disabled={sending}
              >
                <MdSave /> Update
              </button>{" "}
              <Link
                to={ROUTES.PORTAL_SCHEDULE_REMINDER}
                className="btn btn-primary"
                onClick={handleCancel}
              >
                {" "}
                Cancel
              </Link>
            </form>
          </>
        )}
      </main>
      <h2 className="fs-title mb-3 hide-mobile-down">Scheduled Task History</h2>
      <DataTable
        columns={[
          {
            name: "Started Date",
            cell: (row: any) =>
              moment(row.startedDate).format("YYYY-MM-DD hh:mm A"),
          },
          {
            name: "Completed Date",
            cell: (row: any) =>
              moment(row.completedDate).format("YYYY-MM-DD hh:mm A"),
          },
          {
            name: "Status Result",
            cell: (row: any) => (
              <div
                className={`${
                  row.status === "Successful" ? "label-success" : "label-fail"
                } job-history-status`}
              >
                {row.status}
              </div>
            ),
          },
          {
            name: "",
            right: true,
            cell: (row: any) => {
              return (
                <BsFillInfoSquareFill
                  className="history-info-icon"
                  onClick={() =>
                    handleShow(row.completedDate, JSON.parse(row.message))
                  }
                ></BsFillInfoSquareFill>
              );
            },
          },
        ]}
        data={jobHistories}
        progressPending={loading}
        pagination
        paginationServer
        paginationTotalRows={totalRows}
        paginationPerPage={perPage}
        onChangePage={onChangePage}
        onChangeRowsPerPage={onChangeRowsPerPage}
      />
    </div>
  );
}
