import Cookies from "js-cookie";

const cookies = Cookies;

interface ICookieSetOptions {
  /**
   * If we set it in number, it will be expired in given number of day(s).
   *
   * If we set it using Date object, cookie expires based on given Date object.
   *
   * @example
   * var inFifteenMinutes = new Date(new Date().getTime() + 15 * 60 * 1000);
   * Cookies.set('foo', 'bar', {
   *     expires: inFifteenMinutes
   * });
   *
   * @reference https://github.com/js-cookie/js-cookie/wiki/Frequently-Asked-Questions#expire-cookies-in-less-than-a-day
   *
   * Default: Cookie is removed when the user closes the browser.
   */
  expires?: number|Date;

  /**
   * A String indicating the path where the cookie is visible.
   *
   * Default: /
   */
  path?: string;

  /**
   * A String indicating a valid domain where the cookie should be visible.
   * The cookie will also be visible to all subdomains.
   *
   * Default: Cookie is visible only to the domain or subdomain of the page
   * where the cookie was created, except for Internet Explorer.
   */
  domain?: string;
}

export namespace UtilsCookies {

  export function set(name: string, value: string|object, options?: ICookieSetOptions) {
    return cookies.set(name, value, <Cookies.CookieAttributes>options)
  }

  export function get(name?: string) {
    return cookies.get(name);
  }

  export function remove(name) {
    return cookies.remove(name);
  }

}
