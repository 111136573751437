const statesIsLoading: {[key: string]: boolean} = {};

export default class Debouncer {

  /**
   * This method will guard against executing `callback` parameter multiple
   * times before it has finished processing.
   * @param stateName string
   * @param callback Function
   * @returns void
   */
   static async execute(stateName: string = '', callback: Function, onError?: Function): Promise<void> {
    if (typeof callback != 'function') {
      return;
    }

    if (statesIsLoading[stateName] === true) {
      return;
    }

    statesIsLoading[stateName] = true;

    try {
      await callback();
      statesIsLoading[stateName] = false;
    } catch (ignore) {
      statesIsLoading[stateName] = false;

      if (typeof onError == 'function') {
        onError(ignore);
      }
    }

    return;
  }

}
