import { useEffect, useState } from "react";

/** @param delay in ms */
export default function useLoading(delay: number) {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    let timer = setTimeout(() => {
      setIsLoading(false);
    }, delay);

    return () => clearTimeout(timer);
  }, []);

  return isLoading;
}
