import { Link, useNavigate } from "react-router-dom";
import FGInput from "../../../components/dumb/FGInput";
import usePageTitle from "../../../hooks/use-page-title";
import { HiArrowLeft, HiX } from "react-icons/hi";
import { ROUTES } from "../../../routes";
import Notifier from "../../../modules/notifier/notifier";
import { MdSave } from "react-icons/md";
import { useState } from "react";
import Multilingual from "../../../modules/multilingual/multilingual";
import { usePortalSettingSet } from "../../../context/portal-context";
import Debouncer from "../../../modules/debouncer/debouncer";
import Utils from "../../../utils/utils";
import ApiYear from "../../../modules/api/year";
import FGTextarea from "../../../components/dumb/FGTextarea";

export default function PageYearAdd() {
  const title = "Add New Cohort";
  const navigate = useNavigate();
  usePageTitle(title);
  usePortalSettingSet("label_header", title);

  const [contentChanged, setContentChanged] = useState(false);
  const [sending, setSending] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    description: "",
  });

  const handleOnChange = (e) => {
    setContentChanged(true);
    setFormData((d) => ({
      ...d,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    Debouncer.execute("SUBMIT_DATA", async () => {
      setSending(true);
      const res = await ApiYear.add(formData);

      if (res.status) {
        Notifier.success(res.message);
        await Utils.sleep(2000);
        setSending(false);
        navigate(ROUTES.PORTAL_YEARS);
      } else {
        setSending(false);
        Notifier.error(res.message);
      }
    });
  };

  return (
    <main className="form-generic content-generic">
      <h1 className="fs-title hide-mobile-down">{title}</h1>

      <div className="cg__actions hide-mobile-down">
        <Link
          to={ROUTES.PORTAL_YEARS}
          className="btn btn-primary"
          onClick={(e) => {
            if (
              contentChanged &&
              !window.confirm(Multilingual.get("CONFIRM_ACTION_CANT_BE_UNDONE"))
            ) {
              e.preventDefault();
            }
          }}
        >
          <HiArrowLeft /> Back
        </Link>
      </div>

      <form onSubmit={handleSubmit}>
        <FGInput
          name="name"
          id="name"
          type="text"
          label="Name*"
          required
          autoFocus
          onChange={handleOnChange}
        />

        <FGTextarea
          name="description"
          id="description"
          label="Description"
          onChange={handleOnChange}
        />

        <div>
          <button type="submit" className="btn btn-success" disabled={sending}>
            <MdSave /> Add
          </button>{" "}
          <Link
            to={ROUTES.PORTAL_YEARS}
            className="btn btn-primary"
            onClick={(e) => {
              if (
                contentChanged &&
                !window.confirm(
                  Multilingual.get("CONFIRM_ACTION_CANT_BE_UNDONE")
                )
              ) {
                e.preventDefault();
              }
            }}
          >
            <HiX /> Cancel
          </Link>
        </div>
      </form>
    </main>
  );
}
