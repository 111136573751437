import { usePortalSettingSet } from "../../../context/portal-context";
import usePageTitle from "../../../hooks/use-page-title";
import ProfileBox from "../../../components/portal/ProfileBox";
import AppUserContext from "../../../context/app-user-context";
import { useContext, useState } from "react";
import FGInput from "../../../components/dumb/FGInput";
import Debouncer from "../../../modules/debouncer/debouncer";
import ApiAuth from "../../../modules/api/auth";
import Notifier from "../../../modules/notifier/notifier";
import AppUser from "../../../modules/app-user/app-user";
import ModuleValidation from "../../../modules/validation";

export default function PageProfileChangePassword() {
  const title = "Change Password";
  usePageTitle(title);
  usePortalSettingSet("label_header", title);

  const user = useContext(AppUserContext);
  const [reset, setReset] = useState(false);
  const [sending, setSending] = useState(false);
  const [error, setError] = useState("");
  const [formData, setFormData] = useState({
    currentPassword: "",
    newPassword: "",
    confirmNewPassword: ""
  });
  const resetForm = () => {
    setFormData({
      currentPassword: "",
      newPassword: "",
      confirmNewPassword: ""
    });
    setReset(i => !i);
  };
  const handleOnChange = (e) => {
    setFormData(d => ({
      ...d,
      [e.target.name]: e.target.value.trim()
    }));
  };
  const handleOnSubmit = (e) => {
    e.preventDefault();

    const validate = ModuleValidation.isChangePasswordFormValid(formData);

    if (!validate.valid) {
      setError(validate.message);
      return;
    }

    setError("");
    setSending(true);
    Debouncer.execute("CHANGE_PASSWORD", async () => {
      const result = await ApiAuth.updatePassword({ ...formData, accountId: user.id });

      if (result.status) {
        AppUser.setInfo({
          lastUpdate: Date.now(),
          isDefaultPassword: false
        });
        Notifier.success(result.message);
        resetForm();
      } else {
        Notifier.error(result.message);
      }

      setSending(false);
    });
  };

  return <main className="content-generic content-generic--fluid" data-comp="PageProfile">

    <ProfileBox/>

    <div className="row">
      <div className="col-md-5">
        <form onSubmit={handleOnSubmit}>
          <h1 className="fs-title color-black">Change Password</h1>

          {error.trim().length > 0 && <p className="alert alert-danger">
            {error}
          </p>}

          <FGInput label="Current Password" type="password" placeholder="*****"
            name="currentPassword"
            onChange={handleOnChange}
            value={formData.currentPassword}
            required
            autoFocus
            triggerFocus={reset}
          />

          <FGInput label="New Password" type="password" placeholder="*****"
            name="newPassword"
            onChange={handleOnChange}
            value={formData.newPassword}
            required
          />

          <FGInput label="Confirm New Password" type="password" placeholder="*****"
            name="confirmNewPassword"
            onChange={handleOnChange}
            value={formData.confirmNewPassword}
            required
          />

          <div className="mb-3">
            <button type="submit" className="btn btn-primary" disabled={sending}>
              Reset Password
            </button>
          </div>
        </form>
      </div>
    </div>

  </main>
}
