import ApiGenericData, { TGenericItem } from "./generic-data";

export type TIndustryItem = TGenericItem & {};

const ApiIndustry = {
  pagingSearch: ApiGenericData.pagingSearch("Industry"),
  add: ApiGenericData.add<Omit<TIndustryItem, "_id">>("Industry"),
  get: ApiGenericData.get,
  update: ApiGenericData.update<Omit<TIndustryItem, "_id">>("Industry")
};

export default ApiIndustry;
