import React, { Suspense } from "react";
import FGField from "../dumb/FGField";
import classNames from "classnames";

const RichTextWrapper = React.lazy(() => import("../lazy/RichTextWrapper"));

/** @see `<FGField/>` */
export default function FGRichTextEditor(props: JSX.IntrinsicElements["input"] & {
  label: string|JSX.Element|JSX.Element[];
  desc?: string|JSX.Element|JSX.Element[];
  variant?: "normal" | "inset";
}) {
  return <FGField name={props.name} label={props.label} desc={props.desc}
    className={classNames([
      "fg__rte",
      {"fg__control--normal": props.variant === "normal"}
    ])}
  >
    <Suspense fallback={<div className="fst-italic">Loading editor...</div>}>
      <RichTextWrapper value={props.value} name={props.name} onChange={props.onChange} placeholder={props.placeholder} />
    </Suspense>
  </FGField>;
}
