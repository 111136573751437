import { HiArrowLeft } from "react-icons/hi";
import { Link, useNavigate, useParams } from "react-router-dom";
import UserImage from "../../../components/portal/UserImage";
import { usePortalSettingSet } from "../../../context/portal-context";
import usePageTitle from "../../../hooks/use-page-title";
import { useEffect, useState } from "react";
import ApiSubmission from "../../../modules/api/submission";
import ModuleDate from "../../../modules/date";
import { ROUTES } from "../../../routes";
import Utils from "../../../utils/utils";
import { showLabelInfo } from "../../../config/methods";
import LoadingData from "../../../components/portal/LoadingData";
import ApiMessage, { TMessageItem } from "../../../modules/api/message";
import PageProgressTabReportWL from "../PageProgress/PageProgressTabReportWL";
import { SUBMISSION_WL } from "../../../config/constants";

export default function PageMyGroupWL() {
  const title = "Student's Workplace Learning Report";
  usePageTitle(title);
  usePortalSettingSet("label_header", title);

  const { submissionId } = useParams();
  const navigate = useNavigate();
  const [reportTitle, setReportTitle] = useState("Workplace Learning Report");
  const [loading, setLoading] = useState(true);
  const [submission, setSubmission] = useState<any>();
  const [messages, setMessages] = useState<TMessageItem[]>([]);
  const [isRefreshingMessages, setIsRefreshingMessages] = useState(false);
  const [refreshMessages, setRefreshMessages] = useState(false);

  useEffect(() => {
    ApiSubmission.getSubmission(submissionId).then((res) => {
      if (res.status) {
        setSubmission(res.data);
        setReportTitle(
          `Workplace Learning Report, ${ModuleDate.getTitleFormat(
            res.data.submissionDate.to
          )}`
        );
      }

      setLoading(false);
    });
  }, []);

  useEffect(() => {
    if (loading) return;
    (async () => {
      setIsRefreshingMessages(true);
      const messages = await ApiMessage.getMessagesBySubmission(submissionId);
      setMessages(messages.messages);
      await Utils.sleep(1000);
      setIsRefreshingMessages(false);
    })();
  }, [loading, refreshMessages]);

  return (
    <>
      <div className="d-flex gap-2 mb-3">
        <h1 className="fs-title">
          <Link
            to={""}
            className="text-primary p-0 me-2 hide-mobile-down"
            title="Go back"
            onClick={(e) => {
              e.preventDefault();
              if (typeof submission?.owner?._id === "string") {
                navigate(
                  ROUTES.PORTAL_MY_GROUP_DETAILS +
                    `/${submission?.owner?._id}?type=${SUBMISSION_WL}`
                );
              } else {
                navigate(ROUTES.PORTAL_MY_GROUP);
              }
            }}
          >
            <HiArrowLeft />
          </Link>
          {reportTitle}
        </h1>
      </div>

      {!loading && submission && (
        <div className="d-flex gap-2 align-items-center mb-3">
          <div className="d-flex justify-content-center align-items-center gap-2">
            <UserImage width={67} url={submission?.owner?.avatar} />
            <div className="ms-2">
              <h1 className="fs-title m-0">
                {showLabelInfo(submission?.owner?.name)}
              </h1>
              <span>{showLabelInfo(submission?.owner?.email)}</span>
            </div>
          </div>
        </div>
      )}

      <LoadingData show={loading} />

      {!loading && !submission && (
        <p className="alert alert-warning">Submission not found.</p>
      )}

      {!loading && submission && (
        <div className="box-default bg-white mb-3 mt-4">
          <PageProgressTabReportWL
            submission={submission}
            messages={messages}
            setMessages={setMessages}
            refreshMessages={setRefreshMessages}
            isRefreshingMessages={isRefreshingMessages}
          />
        </div>
      )}
    </>
  );
}
