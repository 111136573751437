import { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import FadeBox from "../../components/animate/FadeBox";
import FGInput from "../../components/dumb/FGInput";
import Config from "../../config/config";
import useAuthUser from "../../hooks/use-auth-user";
import usePageTitle from "../../hooks/use-page-title";
import { ROUTES } from "../../routes";
import ApiAuth from "../../modules/api/auth";
import Debouncer from "../../modules/debouncer/debouncer";
import Notifier from "../../modules/notifier/notifier";

export default function PageForgotPassword() {
  usePageTitle("Forgot Password");
  const auth = useAuthUser();
  const navigate = useNavigate();
  const refInputEmail = useRef<HTMLInputElement>();
  const [showSubmitted, setShowSubmitted] = useState(false);

  useEffect(() => {
    if (Config.IS_MOCKUP_MODE) return;

    if (!auth.isLoading && auth.user.logged) {
      navigate(ROUTES.PORTAL_DASHBOARD);
    }
  }, [auth]);

  const handleOnSubmit = (e) => {
    e.preventDefault();

    if (Config.IS_MOCKUP_MODE) {
      return;
    }

    Debouncer.execute("FORGOT_PASSWORD", async () => {
      const req = await ApiAuth.forgotPassword(refInputEmail.current.value);

      if (req.status) {
        refInputEmail.current.value = "";
        Notifier.success(req.message);
      } else {
        Notifier.error(req.message);
      }
    });
  };

  return (
    <>
      <FadeBox show={!showSubmitted}>
        <form className="form-default" onSubmit={handleOnSubmit}>
          <h1>Forgot Password</h1>

          <p>
            Enter the email address associated with your account and
            we&#x2019;ll send you the link to reset password.
          </p>

          <FGInput
            label="Email"
            placeholder="example@student.com"
            autoFocus
            required
            type="email"
            ref={refInputEmail}
            autoComplete="off"
          />

          <p className="text-center">
            <button type="submit" className="btn btn-primary">
              Submit
            </button>
          </p>

          <p className="text-center mb-0">
            <Link to={ROUTES.AUTH_LOGIN}>Back to login</Link>
          </p>
        </form>
      </FadeBox>

      <FadeBox show={showSubmitted}>
        <div>
          <h1>Check Your Email</h1>
          <p className="mb-5">
            We have sent you a reset password link to <br />
            <strong>example@student.com</strong>
          </p>
          <p className="text-center mb-0">
            Did not receive the email? Check your spam filter <br />
            or{" "}
            <a
              href="javascript:void(0);"
              onClick={(e) => {
                e.preventDefault();
                setShowSubmitted(false);
              }}
            >
              try another email address
            </a>
          </p>
        </div>
      </FadeBox>
    </>
  );
}
