import { isPlainObject } from "lodash-es";
import { Generics } from "./generics";
import { Strings } from "./strings";

const trim = Strings.trimString;

export namespace QueryStrings {

  /**
   * @description
   * This function will take the query string (stuff after `?` sign on URL e.g. http://stuff.to/the?querystring=ok),
   * we also convert '+' sign to space. When @param {String} inputUrl is not given, we will take
   * `window.location.search` and convert it into {String}
   *
   * @tests
   * 1. > FXM.parseQueryString("?==weird-query-string&query==string&string=query");
   * 1. > Object {=: "=weird-query-string", query: "=string", string: "query"}
   *
   * 2. > FXM.parseQueryString("?iam=query-string&live=in-world&born=on-april-30-1993");
   * 2. > Object {iam: "query-string", live: "in-world", born: "on-april-30-1993"}
   *
   * 3. > FXM.parseQueryString("???another-weird=query-string&by==testers=");
   * 3. > Object {??another-weird: "query-string", by: "=testers="}
   *
   * @param {String} inputUrl -- optional
   * @return {Object} -- Empty when no query strings present
   */
  export function parseQueryString(inputUrl?: string): {[key: string]: any} {
    let url = ((val) => {
      let questionMarkIndex = val.indexOf("?");

      if (questionMarkIndex > -1) {
        val = val.substr(questionMarkIndex);
      } else {
        val = "";
      }

      return val;
    })(Generics.ifUndefinedThen(inputUrl, (window.location.search).toString()));;
    let arrQs = url.replace(/^\?/, "").split("&");
    let curr;
    let currAssignmentIndex = -1;
    let currentKey = ""; // set to empty string at first
    let currentValue = ""; // set to empty string at first
    let allowedToInsert = false;
    let ret = {};
    let i = 0;
    let j = arrQs.length;

    for (; i < j; i++) {
      curr = trim(arrQs[i]);

      if (curr.indexOf("=") > -1 && (currentKey == "" && currentValue == "")) {
        currAssignmentIndex = curr.indexOf("=");

        if (currAssignmentIndex == 0) {
          currentKey = "=";
          // check next occurrence of "=" just in case the first one as the key
          currAssignmentIndex = curr.substr(currAssignmentIndex + 1).indexOf("=");

          if (currAssignmentIndex > -1) {
            currentValue = curr.substr(currAssignmentIndex + 1);
            allowedToInsert = true;
          }
        } else {
          currentKey = curr.substr(0, currAssignmentIndex).toLowerCase();
          currentValue = curr.substr(currAssignmentIndex + 1);
          allowedToInsert = true;
        }

        if (allowedToInsert) {
          ret[currentKey] = decodeURIComponent(currentValue);
        }

        allowedToInsert = false;
        currentKey = "";
        currentValue = "";
        currAssignmentIndex = -1;
      }
    }

    return ret;
  }

  /**
   * @description
   * This function will strip the query string (starting from `?` sign). For example, suppose we have:
   * http://stuff.to/the?querystring=ok
   * When we call this function, it will return http://stuff.to/the
   *
   * @example
   * // Assume the given param is "http://localhost/FX-BOILERPLATE/docs/html/index.html??????sdsdsdsample-query-sting=ok&status=true-again-?"
   * // Was returning "http://localhost/FX-BOILERPLATE/docs/html/index.html"
   * stripQueryString(window.location);
   *
   * @return {String}
   */
  export function stripQueryString(inputUrl: string): string {
    return trim(inputUrl).replace(/\?.*$/, '');
  }

  /**
   * @description
   * This will add query string to the given url. User can input key-value or object containing key-value pair.
   *
   * @example
   * // Key-Value sample
   * // Returns "http://google.com?q=FXMedia%20Internet%20Pte.%20Ltd."
   * addQueryString('http://google.com', 'q', 'FXMedia Internet Pte. Ltd.");
   *
   * // Object Key-Value sample
   * // Returns "http://fxmweb.com/submit-form.php?name=Faisal&age=21&location=Indonesia&comment=This%20place%20is%20cool"
   * addQueryString("http://fxmweb.com/submit-form.php", { name: "Faisal", age: 21, location: "Indonesia", comment: "This place is cool" });
   *
   *
   * @param {String} inputUrl
   * @param {String|Object} key
   * @param {any} value
   *
   * @return {String}
   */
  export function addQueryString(inputUrl: string, key: Object): string;
  export function addQueryString(inputUrl: string, key: string, value?: any): string {
    let firstQmark = -1;

    // check question mark greater than specified index
    let qmgt = function (str, i) {
      let cpstr = trim(str);
      let j = cpstr.length;
      let ret = false;

      i += 1;

      for (; i < j; i += 1) {
        if (cpstr[i] == "?") {
          ret = true;
          break;
        }
      }

      return ret;
    };

    inputUrl = trim(inputUrl);
    let originalUrl = inputUrl;
    let existingQs = QueryStrings.parseQueryString(inputUrl);
    inputUrl = QueryStrings.stripQueryString(inputUrl);

    let valueExists = function (v) {
      return typeof v != 'undefined';
    };

    let addQs = function (iurl, k, v) {
      let anotherQuestionMarkExist = qmgt(iurl, firstQmark);
      let lastChar = iurl[iurl.length - 1];
      let value = valueExists(v) ? "=" + encodeURIComponent(v) : '';

      if ((lastChar == "?" && !anotherQuestionMarkExist) || (lastChar == "&") || (lastChar == "")) {
        iurl += encodeURIComponent(k) + value;
      } else {
        iurl += "&" + encodeURIComponent(k) + value;
      }

      return iurl;
    };

    let countUserQs = function (qs) {
      var c = 0;

      Object.keys(qs).forEach(key => {
        if (typeof key == 'string') {
          c += 1;
        }
      });

      return c;
    };

    if (!QueryStrings.existQueryString(inputUrl)) {
      inputUrl += "?";
    }

    firstQmark = inputUrl.indexOf("?");

    var userQs: any = {};
    var userQsCount = 0;

    if (isPlainObject(key)) {
      userQsCount = countUserQs(key);

      Object.keys(existingQs).forEach((key) => {
        userQs[key] = existingQs[key];
      });

      Object.keys(key).forEach((keyString) => {
        userQs[keyString] = key[keyString];
      })
    } else {
      if (typeof key == 'string') {
        userQs[key] = value;
        userQsCount += 1;
      }
    }

    if (userQsCount > 0) {
      Object.keys(userQs).forEach((k) => {
        inputUrl = addQs(inputUrl, k, userQs[k]);
      });
    } else {
      inputUrl = originalUrl;
    }

    return inputUrl;
  }

  /**
   * @description
   * This function will check whether the given URL contains query string
   *
   * @example
   * // Returns FALSE
   * existQueryString("http://fxmweb.com");
   *
   * @tests (All of these tests must return TRUE)
   * 1. !existQueryString("http://fxmweb.com");
   * 2. existQueryString("http://fxmweb.com/index.html?");
   * 3. existQueryString("http://fxmweb.com/index.html?state=true&deep=true?#hash");
   *
   * @return {Boolean}
   */
  export function existQueryString(inputUrl: string): boolean {
    return Strings.trimString(inputUrl).indexOf('?') > -1;
  }

}
