import { usePortalSettingSet } from "../../../context/portal-context";
import usePageTitle from "../../../hooks/use-page-title";
import ProfileBox from "../../../components/portal/ProfileBox";
import AppUserContext from "../../../context/app-user-context";
import { useContext, useEffect, useRef, useState } from "react";
import Utils from "../../../utils/utils";
import Config from "../../../config/config";
import { Link } from "react-router-dom";
import Debouncer from "../../../modules/debouncer/debouncer";
import Notifier from "../../../modules/notifier/notifier";
import FGInput from "../../../components/dumb/FGInput";
import ROLE from "../../../modules/role";
import UserImage from "../../../components/portal/UserImage";
import { HiPencil } from "react-icons/hi";
import ModuleValidation from "../../../modules/validation";
import ApiUser from "../../../modules/api/user";
import AppUser from "../../../modules/app-user/app-user";

const showLabel = (txt) => Utils.ifEmptyOrUndefinedThen(txt, Config.CHAR_EMPTY);

export default function PageProfileEdit() {
  const title = "Edit Profile";
  usePageTitle(title);
  usePortalSettingSet("label_header", title);

  const user = useContext(AppUserContext);
  const refInputFilePicture = useRef<HTMLInputElement>();
  const [formChanged, setFormChanged] = useState(false);
  const [reset, setReset] = useState(false);
  const [sending, setSending] = useState(false);
  const [formData, setFormData] = useState({
    name: user.name,
    studentId: user.studentId,
    email: user.email,
    avatar: user.avatar
  });
  const resetForm = () => {
    setFormChanged(false);
    setFormData({
      avatar: user.avatar,
      name: user.name,
      studentId: user.studentId,
      email: user.email
    });
    setReset(i => !i);
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();
    setSending(true);
    Debouncer.execute("CHANGE_AVATAR", async () => {
      const res = await ApiUser.updateAvatar({
        avatar: formData.avatar,
        modifiedUserId: user.id,
        referenceUserId: user.referenceUserId
      });

      if (res.status) {
        await AppUser.refreshUser();
        Notifier.success("Profile updated");
      } else {
        Notifier.error(res.message);
      }

      setSending(false);
    });
  };

  const handleSelectPicture = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];

    if (!file) return;

    const validationResult = await ModuleValidation.isImageValid(file);

    if (!validationResult.valid) {
      return Notifier.error(validationResult.message);
    }

    Utils.getBase64FromFile(file).then(img => {
      setFormChanged(true);
      setFormData(r => ({ ...r, avatar: img }));
    });
  };

  return <main className="content-generic content-generic--fluid" data-comp="PageProfile">

    <ProfileBox showEditIcon={false} />

    <div className="row">
      <div className="col-md-12 col-lg-9">
        <h1 className="fs-title">Edit Profile</h1>

        <p className="alert alert-info">The only data that can be edited is the profile picture.
          Please make sure the profile picture is in <strong>JPG/PNG</strong> format and <strong>no bigger than 1MB</strong>.
        </p>

        <div className="edit-profile mb-3">
          <form onSubmit={handleOnSubmit}>
            <div className="ep__row">
              <div className="ep__col ep__col-picture"
                title="Click to select your profile picture"
                onClick={() => {
                  refInputFilePicture.current.value = null;
                  refInputFilePicture.current.click();
                }}
              >
                <input ref={refInputFilePicture} type="file" onChange={handleSelectPicture} accept=".png, .jpg, .jpeg" />
                <UserImage url={formData.avatar} />
                <div className="ep__icon-edit">
                  <HiPencil />
                </div>
              </div>
              <div className="ep__col">
                <FGInput label="Name" type="text"
                  name="name"
                  value={formData.name}
                  disabled
                />

                {user.role === ROLE.STUDENT && <FGInput label="Student ID" type="text"
                  name="name"
                  value={formData.studentId}
                  disabled
                />}

                <FGInput label="Email" type="text"
                  name="email"
                  value={formData.email}
                  disabled
                />
              </div>
            </div>
            <button type="submit" className="btn btn-primary"
              disabled={!formChanged}
            >Save Changes</button>
          </form>
        </div>
      </div>
    </div>

  </main>
}
