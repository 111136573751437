import { HiArrowLeft } from 'react-icons/hi';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import UserImage from '../../../components/portal/UserImage';
import { usePortalSettingSet } from "../../../context/portal-context";
import usePageTitle from "../../../hooks/use-page-title";
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import ApiTrait, { TGetAllTraitItem } from '../../../modules/api/trait';
import { ROUTES } from '../../../routes';
import ApiSubmission, { TStatisticResult } from '../../../modules/api/submission';
import ModuleDate from '../../../modules/date';
import { showLabelInfo } from '../../../config/methods';
import LoadingData from '../../../components/portal/LoadingData';
import PDStatistics from '../../../components/portal/PDStatistics';
import PageProgressTabReportPD from '../PageProgress/PageProgressTabReportPD';
import AppUserContext from '../../../context/app-user-context';
import ApiMessage, { TMessageItem } from '../../../modules/api/message';
import Utils from '../../../utils/utils';
import { scrollTo } from '../../../utils/jquery-helper';
import { SUBMISSION_PD } from '../../../config/constants';

export default function PageProgressPD() {
  const title = "Student's Professional Development Report"
  usePageTitle(title);
  usePortalSettingSet("label_header", title);

  const user = useContext(AppUserContext);
  const navigate = useNavigate();
  const { submissionId } = useParams();
  const [reportTitle, setReportTitle] = useState("Professional Development Report");
  const [activeTab, setActiveTab] = useState("");
  const [loading, setLoading] = useState(true);
  const [submission, setSubmission] = useState<any>();
  const [traits, setTraits] = useState<{[key: string]: TGetAllTraitItem}>({});
  const [studentId, setStudentId] = useState("");
  const [messages, setMessages] = useState<TMessageItem[]>([]);
  const [isRefreshingMessages, setIsRefreshingMessages] = useState(false);
  const [refreshMessages, setRefreshMessages] = useState(false);

  // param related to open default tab
  const defaultTab = useMemo(() => {
    const param = Utils.parseQueryString();
    const tab = param["tab"];
    return tab ?? "";
  }, []);
  const refDefaultTab = useRef({ consumed: false });
  const scrollToFeedback = useMemo(() => (Utils.parseQueryString()["feedback"] ?? "") === "1", []);

  const [statistics, setStatistics] = useState<TStatisticResult>();

  useEffect(() => {
    (async () => {
      const submissionRes = await ApiSubmission.getSubmission(submissionId);

      if (submissionRes.status) {
        const traits = await ApiTrait.getAll();

        setTraits(traits.reduce((p, c) => ({ ...p, [c._id]: c }), {}));
        setSubmission(submissionRes.data);
        setStudentId(submissionRes.data.owner._id);
        setReportTitle(`Professional Development Report, ${ModuleDate.getTitleFormat(submissionRes.data.submissionDate.to)}`);
      }

      setLoading(false);
      setRefreshMessages(r => !r);
    })();
  }, []);

  useEffect(() => {
    if (loading) return;
    (async () => {
      setIsRefreshingMessages(true);
      const messages = await ApiMessage.getMessagesBySubmission(submissionId);
      setMessages(messages.messages);
      await Utils.sleep(1000);
      setIsRefreshingMessages(false);
    })();
  }, [loading, refreshMessages]);

  const tabbedTraits = useMemo(() => {
    if (loading || !submission && !traits) {
      return [];
    }

    const tabbed = submission.traits.filter(trait => {
      return typeof traits[trait.traitId] !== "undefined";
    });

    if (tabbed.length > 0) {
      setTimeout(() => {
        let activeTab = tabbed[0].traitId;

        if (!refDefaultTab.current.consumed && tabbed.some(t => t.traitId === defaultTab)) {
          activeTab = defaultTab;
          refDefaultTab.current.consumed = true;
          if (scrollToFeedback) setTimeout(() => scrollTo(`#feedback-${activeTab}`, 350), 100);
        }

        setActiveTab(activeTab);
      }, 100);
    }

    return tabbed;
  }, [submission, traits, loading]);

  return <>
    <div className="d-flex gap-2 mb-3">
      <h1 className="fs-title">
        <Link to={""} className="text-primary p-0 me-2 hide-mobile-down" title="Go back"
          onClick={(e) => {
            e.preventDefault();
            if (typeof submission?.owner?._id === "string") {
              navigate(
                ROUTES.PORTAL_MY_GROUP_DETAILS +
                  `/${submission?.owner?._id}?type=${SUBMISSION_PD}`
              );
            } else {
              navigate(ROUTES.PORTAL_MY_GROUP);
            }
          }}
        >
          <HiArrowLeft />
        </Link>
        {reportTitle}
      </h1>
    </div>

    {!loading && submission && <div className="d-flex gap-2 align-items-center mb-4">
      <div className='d-flex justify-content-center align-items-center gap-2'>
        <UserImage width={67} url={submission?.owner?.avatar} />
        <div className='ms-2'>
          <h1 className="fs-title m-0">{showLabelInfo(submission?.owner?.name)}</h1>
          <span>{showLabelInfo(submission?.owner?.email)}</span>
        </div>
      </div>
    </div>}

    <LoadingData show={loading} />

    {!loading && !submission && <p className='alert alert-warning'>
      Submission not found.
    </p>}

    {!loading && submission && studentId !== "" && <div className="box-default box-form-statistics">
      <PDStatistics studentId={studentId}
        onStatisticsLoaded={(stat) => {
          setStatistics(stat)
        }}
        onTraitClicked={(traitId) => setActiveTab(traitId)}
        selectedStatistics={submission?.traits?.map(trait => trait.traitId) ?? []}
      />
    </div>}

    {!loading && submission && <div className="box-default bg-white mb-3" data-comp="page-progress-pd">
      <Tabs activeKey={activeTab} className='pwl__tabs pt-0' onSelect={(k) => { setActiveTab(k) }}>

        {tabbedTraits.map((data, i) => {
          const trait = traits[data.traitId];

          return <Tab key={data.traitId} eventKey={data.traitId} title={trait?.name} className="">
            <PageProgressTabReportPD submission={submission} data={data}
              trait={trait} messages={messages} setMessages={setMessages}
              refreshMessages={setRefreshMessages}
              isRefreshingMessages={isRefreshingMessages}
            />
          </Tab>
        })}

      </Tabs>
    </div>}
  </>;
}
