import React, { useMemo, useState } from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { OverlayTriggerType } from 'react-bootstrap/esm/OverlayTrigger';
import { HiQuestionMarkCircle, HiX } from "react-icons/hi";
import useViewport from "../../hooks/use-viewport";

type PauseContinueProps = {
  className?: string;
  name?: string;
  labelChecked?: string;
  labelUnchecked?: string;
  checked?: boolean;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
};

const TraitProgressToggle: React.FC<PauseContinueProps> = (props) => {
  const viewport = useViewport();
  const [isPopoverOpen, setPopoverOpen] = useState(false);

  const handlePopoverClose = () => {
    setPopoverOpen(false);
  };

  const popover = useMemo(() => (
    <Popover>
      <Popover.Body>
        <div className="d-flex gap-2 align-items-center justify-content-between mb-3">
          <div className="d-flex align-items-center gap-2">
            <HiQuestionMarkCircle className="text-primary fs-4" />
            <h3 className="fs-5 m-0">Trait Progress</h3>
          </div>
          <button
            type="button"
            className="btn d-lg-none"
            onClick={handlePopoverClose}
            aria-label="Close"
          >
            <HiX className="fs-4 cursor-pointer" />
          </button>
        </div>
        <div className="d-flex flex-column gap-3">
          <div className="d-flex justify-content-center align-items-start popover-content">
            <span className="popover-status popover--paused">Pause</span>
            <p className="m-0">Click to temporarily stop tracking your trait progress</p>
          </div>
          <div className="d-flex justify-content-center align-items-start popover-content">
            <span className="popover-status popover--continue">Continue</span>
            <p className="m-0">Click to update your trait progress</p>
          </div>
        </div>
      </Popover.Body>
    </Popover>
  ), []);

  const trigger: OverlayTriggerType[] = useMemo(() => viewport.isMobile ? ["click"] : ["hover", "focus"], [viewport]);

  return (
    <div data-comp="TraitProgressToggle" className={`trait-progress-toggle-box ${props.className ?? ""}`}>
      <label className={`trait-progress-toggle ${props.checked ? "" : "active"}`}>
        <input
          type="checkbox"
          name={props.name}
          id={props.name}
          checked={props.checked}
          onChange={props.onChange}
        />
        <div className="label-container">
          <span className="label">{props.labelChecked}</span>
          <span className="label">{props.labelUnchecked}</span>
        </div>
      </label>
      <OverlayTrigger
        trigger={trigger}
        placement="top-end"
        overlay={popover}
        show={isPopoverOpen}
        onToggle={setPopoverOpen}
      >
        <button
          className="btn popover-button"
          onMouseEnter={() => {
            if (!viewport.isMobile) setPopoverOpen(true);
          }}
          onMouseLeave={() => {
            if (!viewport.isMobile) setPopoverOpen(false);
          }}
        >
          <HiQuestionMarkCircle className="text-primary fs-3" />
        </button>
      </OverlayTrigger>
    </div>
  );
};

TraitProgressToggle.defaultProps = {
  name: "trait-progress-checkbox",
  labelChecked: "Pause",
  labelUnchecked: "Continue",
  checked: false,
  onChange(e) {}
};

export default TraitProgressToggle;

