import { useEffect, useRef, useState } from "react";
import AppUser, { getDefaultUserData, TAppUser } from "../modules/app-user/app-user";
import Utils from "../utils/utils";
import SubscriptionConsumer from "../modules/event-subscription/subscription-consumer";



/**
 * This hook must only be called in 1 place at the root of the template.
 */
export default function useAuthUser() {
  const [user, setUser] = useState<TAppUser>(getDefaultUserData());
  const [isLoading, setIsLoading] = useState(true);
  const [startCheck, setStartCheck] = useState(false);
  const refLastUpdate = useRef(-1);
  const checkAndUpdate = async () => {
    const user = await AppUser.getInfo();

    if (user.lastUpdate !== refLastUpdate.current) {
      refLastUpdate.current = user.lastUpdate;
      setUser(user);
    }
  };

  useEffect(() => {
    AppUser.getInfo().then(async (user) => {
      setUser(user);
      setIsLoading(false);
      setStartCheck(true);
      refLastUpdate.current = user.lastUpdate;
    });
  }, []);

  // passive checks for user
  useEffect(() => {
    if (!startCheck) return;

    let alive = true;

    async function check() {
      await Utils.sleep(100);
      if (!alive) return;

      await checkAndUpdate();

      // let's wait for 10 seconds for the next check
      await Utils.sleep(10000);
      if (alive) check();
    }

    check();

    return () => {
      alive = false;
    };
  }, [startCheck]);

  // reactive checks for user
  useEffect(() => {
    return SubscriptionConsumer.subscribe("AppUser.refreshUser", () => {
      checkAndUpdate();
    });
  }, []);

  return { user, isLoading };
}
