import { useEffect, useRef } from "react";
import FGField from "./FGField";
import classNames from "classnames";

/** @see `<FGField/>` */
export default function FGTextarea(props: JSX.IntrinsicElements["textarea"] & {
  label: string|JSX.Element|JSX.Element[];
  desc?: string|JSX.Element|JSX.Element[];
  /** just keep toggling this value will autofocus the element */
  triggerFocus?: boolean;
  /** defaults to "inset" */
  variant?: "normal" | "inset";
}) {
  const ref = useRef<HTMLTextAreaElement>();

  useEffect(() => {
    if (typeof props.triggerFocus !== "undefined") {
      setTimeout(() => (ref.current && ref.current.focus()), 50);
    }
  }, [props.triggerFocus]);

  const newProps = {...props};
  delete newProps.variant;
  delete newProps.triggerFocus;

  return <FGField name={props.name} label={props.label} desc={props.desc} comp="FGTextarea"
    className={classNames([
      "fg__textarea",
      {"fg__control--normal": props.variant === "normal"}
    ])}
  >
    <textarea
      ref={ref}
      rows={5}
      id={props.name}
      {...newProps}
    />
  </FGField>;
}
