import { Outlet } from "react-router-dom";
import { publicDir } from "../../config/config";

export default function PageAuth() {
  return <div className="PageAuth" data-comp="PageAuth">
    <div className="PA__row">
      <div className="PA__col PA__col--desc">
        <div className="PA__col--desc-inner">
          <img className="PA__logo mx-auto" src={publicDir("/assets/images/logo/logo.png")} alt="SIT Logo" />
          <h1>Welcome to GROW-Pro</h1>
          <h2>Your place to manage coaching conversation for your professional growth</h2>
          <img className="PA__img mx-auto" src={publicDir("/assets/images/auth-image.png")} alt="" />
        </div>
      </div>
      <div className="PA__col PA__col--main">
        <div className="PA__main">
          <Outlet/>
        </div>
      </div>
    </div>
  </div>
}
