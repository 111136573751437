import React, { FC, InputHTMLAttributes, MouseEvent } from 'react';

interface FGCheckboxProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  onClick?: (event: MouseEvent<HTMLInputElement>) => void;
}

const FGCheckbox: FC<FGCheckboxProps> = ({ label, onClick, ...rest }) => {
  const handleClick = (event: MouseEvent<HTMLInputElement>) => {
    event.stopPropagation();
    if (onClick) {
      onClick(event);
    }
  };

  return (
    <label className="checkbox">
      {label && label}
      <input type="checkbox" {...rest} onClick={handleClick} />
      <span className="checkmark"></span>
    </label>
  );
};

export default FGCheckbox;
