import { useState } from "react";
import PageUserRegistManual from './PageUserRegistManual';
import PageUserRegistCSV from './PageUserRegistCSV';
import usePageTitle from "../../../hooks/use-page-title";
import { usePortalSettingSet } from "../../../context/portal-context";

export default function PageUserRegist() {
  const title = "Register New Account";
  usePageTitle(title);
  usePortalSettingSet("label_header", title);

  const [activeTab, setActiveTab] = useState("manual");

  const handleTabClick = (tab: string) => {
    setActiveTab(tab);
  };

  return <div className="row" data-comp="PageUserRegist">
    <h2 className="fs-title mb-3 hide-mobile-down">{title}</h2>

    <div className="col-12">
      <div className="tabs mb-3">
        <button
          type="button"
          className={`tabs__button ${
            activeTab === "manual" ? "active" : ""
          }`}
          onClick={() => handleTabClick("manual")}
        >
          Input manual
        </button>
        <button
          type="button"
          className={`tabs__button ${activeTab === "csv" ? "active" : ""}`}
          onClick={() => handleTabClick("csv")}
        >
          Input from CSV
        </button>
      </div>
    </div>

    {activeTab === "manual" ? (
      <PageUserRegistManual />
    ) : (
      <PageUserRegistCSV />
    )}
  </div>;
}
