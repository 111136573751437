import axios from "axios";
import { apiUrl, getAuthHeader } from "./base";
import { IGNORE_PARAM_VALUE, MAX_PD_SUBMISSION, MONTH_ARR_SHORT, SUBMISSION_PD, SUBMISSION_STATUS_PUBLISH, SUBMISSION_WL } from "../../config/constants";
import { getDefaultEmptyApiParam } from "../../config/methods";
import ApiTrait, { TGetAllTraitItem, TGetAllTraits } from "./trait";
import SubmissionStatisticConverter from "../submission-statistic-converter";
import SubmissionStatisticConverterResearch from '../submission-statistic-converter-research';
import SubmissionStatisticConverterResearchLooping from '../submission-statistic-converter-research-looping';

export type TSubmissionItemBase = {
  submissionDateId: string;
  /** user.referenceUserId */
  studentId: string;
  status: string;
  /** can be `SUBMISSION_WL` or `SUBMISSION_PD` */
  type?: string;
  /** `user.id` */
  createdUserId?: string;
  /** `user.id` */
  modifiedUserId?: string;
};

export type TSubmissionWL = TSubmissionItemBase & {
  summaryKeyActivities: string;
  detailsLearning: string;
  reflection: string;
};

export type TWlAddParam = TSubmissionWL & {
  /** `user.id` */
  createdUserId: string;
};

export type TWlUpdateParam = TSubmissionWL & {
  /** `user.id` */
  modifiedUserId: string;
};

async function wlAdd(formData: TWlAddParam) {
  try {
    const req = await axios.post(apiUrl("/apisubmission"), {
      ...formData,
      type: SUBMISSION_WL
    }, await getAuthHeader());
    if (req.data.result !== "OK") throw new Error(req.data.message ?? "Unable to add submission");
    return {
      status: true,
      message: req.data.message,
      data: req.data.data
    };
  } catch (err) {
    return {
      status: false,
      message: err.message
    };
  }
}

/**
 * @ref https://fxmediaweb.atlassian.net/browse/IS-46?focusedCommentId=26225
 */
async function wlUpdate(_id: string, formData: TWlUpdateParam) {
  try {
    const req = await axios.patch(apiUrl(`/apisubmission/${_id}`), {
      ...formData,
      type: SUBMISSION_WL
    }, await getAuthHeader());
    if (req.data.result !== "OK") throw new Error(req.data.message ?? "Unable to update submission");
    return {
      status: true,
      message: req.data.message,
      data: req.data.data
    };
  } catch (err) {
    return {
      status: false,
      message: err.message
    };
  }
}

export type TSubmissionPD = TSubmissionItemBase & {
  traits: any[];
};

export type TPdAddParam = TSubmissionPD & {
  /** `user.id` */
  createdUserId: string;
};

export type TPdUpdateParam = TSubmissionPD & {
  /** `user.id` */
  modifiedUserId: string;
};

async function pdAdd(formData: TPdAddParam) {
  try {
    const req = await axios.post(apiUrl("/apisubmission"), {
      ...formData,
      type: SUBMISSION_PD
    }, await getAuthHeader());
    if (req.data.result !== "OK") throw new Error(req.data.message ?? "Unable to add submission");
    return {
      status: true,
      message: req.data.message,
      data: req.data.data
    };
  } catch (err) {
    return {
      status: false,
      message: err.message
    };
  }
}

/**
 * @ref https://fxmediaweb.atlassian.net/browse/IS-46?focusedCommentId=26225
 * @ref https://fxmediaweb.atlassian.net/browse/IS-47
 */
async function pdUpdate(_id: string, formData: TPdUpdateParam) {
  try {
    const req = await axios.patch(apiUrl(`/apisubmission/${_id}`), {
      ...formData,
      type: SUBMISSION_PD
    }, await getAuthHeader());
    if (req.data.result !== "OK") throw new Error(req.data.message ?? "Unable to update submission");
    return {
      status: true,
      message: req.data.message,
      data: req.data.data
    };
  } catch (err) {
    return {
      status: false,
      message: err.message
    };
  }
}

/**
 * @ref https://fxmediaweb.atlassian.net/browse/IS-46?focusedCommentId=26225
 */
async function remove(_id: string) {
  try {
    const req = await axios.delete(apiUrl(`/apisubmission/${_id}`), {
      ...await getAuthHeader()
    });
    if (req.data.result !== "OK") throw new Error(req.data.message ?? "Unable to delete submission");
    return {
      status: true,
      message: req.data.message
    };
  } catch (err) {
    return {
      status: false,
      message: err.message
    };
  }
}

async function getSubmission(_id: string) {
  try {
    const req = await axios.get(apiUrl(`/apisubmission/${_id}`), await getAuthHeader());

    if (req.data.result !== "OK") throw new Error(req.data.message ?? "Submission detail not found");

    return {
      status: true as true,
      message: "OK",
      data: req.data.data
    };
  } catch (err) {
    return {
      status: false as false,
      message: err.message
    };
  }
}

export type TSubmissionListItem = {
  _id: string;
  type: string;
  status: string;
  submitTimes: number;
  submissionDateId: string;
  studentId: string;
  modifiedDate: string;
  from: string;
  to: string;
};

/**
 * @ref https://fxmediaweb.atlassian.net/browse/IS-46?focusedCommentId=26248
 * @ref https://fxmediaweb.atlassian.net/browse/IS-46?focusedCommentId=26515
 * @param {string} studentId is the `user.referenceUserId`
 * @param {number} skip Start from zero
 * @param {number} take Enter zero if we want to query all
 * @param {string} status We can filter submission by its status e.g. show
 *                 "Publish" or "Draft" only. If we set to empty then show all
 *                 submissions
 * @param {string} type either `SUBMISSION_PD` or `SUBMISSION_WL` if empty
 *                 then will query for both
 */
async function getSubmissions(studentId: string, skip = 0, take = 0, status: string = "", type: string = "") {
  const info = {
    submissions: [] as TSubmissionListItem[],
    total: 0
  };

  try {
    const req = await axios.get(apiUrl(`/apisubmission/mysubmissions/${studentId}/${skip}/${take}?status=${status}&type=${type}`), {
      ...await getAuthHeader()
    });

    if (req.data.result !== "OK") throw new Error("Error getting submissions");

    info.submissions = req.data.data;
    info.total = req.data.total;
  } catch (ignore) {}

  return info;
}

export type TSubmissionPagingSearchParam = {
  submissionType: string;
  /** can be either "name", "email", "group" */
  filterBy: string;
  name: string;
  email: string;
  group: string;
  page: number;
  perPage: number;
}

/**
 * ROLE: ADMINISTRATOR
 * @ref https://fxmediaweb.atlassian.net/browse/IS-70
 */
async function pagingSearch(param: TSubmissionPagingSearchParam) {
  const ret = {
    data: [],
    total: 0
  };

  try {
    const submissionType = param.submissionType;
    const filterBy = param.filterBy;
    let name = param.filterBy === "name" ? getDefaultEmptyApiParam(param.name) : IGNORE_PARAM_VALUE;
    let email = param.filterBy === "email" ? getDefaultEmptyApiParam(param.email) : IGNORE_PARAM_VALUE;
    let group = param.filterBy === "group" ? getDefaultEmptyApiParam(param.group) : IGNORE_PARAM_VALUE;

    const targetUrl = `/apisubmission/byusers/${submissionType}/${name}/${email}/${group}/${filterBy}/${param.page}/${param.perPage}`;
    const req = await axios.get(apiUrl(targetUrl), await getAuthHeader());
    const res = req.data;

    if (!(
      res.result === "OK" &&
      Array.isArray(res.data) &&
      typeof res.total === "number"
    )) throw new Error();

    ret.data = res.data;
    ret.total = res.total;
  } catch (ignore) {}

  return ret;
}

export type TStatisticItem = {
  _id: string;
  status: string;
  submissionDate: {
    from: string;
    to: string;
    type: string;
    month: number;
    year: number;

    // below properties are post-processed on FE side `ApiSubmission.statistics` method
    // the Date.getTime() representation of `from`
    ms_from: number;
    ms_to: number;
  };
  traits: Array<{
    _id: string;
    traitId: string;
    scale: number;
    scaleNextMonth: number;
    scaleEndGoal: number;
    scaleEndGoalChangeReason: number;
    paused: boolean;
  }>
};

export type TStatisticResult = {
  // this is the filtered item(s) that can be transformed into chart
  items: TStatisticItem[];
  /** key is `traitId`, value is latest scale end goal */
  traitsInfo: {[key: string]: {
    goal: number;
  }};
  parsedTraits: Array<{
    traitId: string;
    name: string;
    color: string;
    goal: number,
    goalChangeReason: string;
    monthSubmitted: boolean[];
    monthIndex: number[];
    month: string[];
    year: number[];
    goalHistory: number[];
    scale: number[];
    scaleNextMonth: number[];
    scaleEndGoal: number[];
    goalMaxLine: number[]
  }>;
};

export type TRawStatisticResult = Omit<TStatisticResult, "items" | "traitsInfo">;

/**
 * @param string studentId alias of `user.referenceUserId`
 */
async function statistics(studentId: string): Promise<TStatisticResult> {
  let data: TStatisticItem[] = [];

  try {
    const req = await axios.get(apiUrl(`/apisubmission/student/${SUBMISSION_PD}/${studentId}`), await getAuthHeader());

    if (req.data.result !== "OK") throw new Error(req.data.message ?? "Statistics unavailable.");

    data = req.data.data.filter(item => {
      return typeof item?.submissionDate?.from === "string" && typeof item?.submissionDate?.to === "string" &&
        item?.status == SUBMISSION_STATUS_PUBLISH;
    }).map(item => {
      item.submissionDate.ms_from = new Date(item.submissionDate.from).getTime();
      item.submissionDate.ms_to = new Date(item.submissionDate.to).getTime();
      return item;
    }).sort((a, b) => {
      return a.submissionDate.ms_to - b.submissionDate.ms_to;
    });
  } catch (ignore) {}

  // The second parameter is either 'range' or 'max_months'
  const statistics = (await SubmissionStatisticConverter(data));
  // const statistics = (await SubmissionStatisticConverterResearch(data, 'range'));
  // const statistics = (await SubmissionStatisticConverterResearchLooping(data, 'max_months'));

  return {
    items: data,
    traitsInfo: statistics.parsedTraits.reduce((p, c) => {
      return {
        ...p,
        [c.traitId]: {
          goal: c.goal
        }
      };
    }, {}),
    ...statistics
  };
}

function getPreviousScaleEndGoal(traitsInfo: TStatisticResult["traitsInfo"], traitId: string) {
  if (!traitsInfo) return 0;
  let ret = traitsInfo[traitId]?.goal;

  if (typeof ret === "number" && ret > 0) {
    return ret;
  } else {
    return 0;
  }
}

/**
 * @ref https://fxmediaweb.atlassian.net/browse/IS-69?focusedCommentId=26479
 * @param groupIds can be single or multiple with comma between each of them (643ea0942078641f6c09c412,643ea0942078641f6c09cbbb)
 * @param submissionType
 * @returns
 */
async function submissionStatus(groupIds: string, submissionType: string = SUBMISSION_PD) {
  let data = [];

  try {
    const req = await axios.get(apiUrl(`/apisubmission/submission-status/${groupIds}/${submissionType}`), await getAuthHeader());

    if (req.data.result !== "OK") throw new Error(req.data.message ?? "Submission status unavailable.");

    data = req.data.data;
  } catch (ignore) {}

  return data;
}

const ApiSubmission = {
  pagingSearch,
  wlAdd,
  wlUpdate,
  pdAdd,
  pdUpdate,
  remove,
  getSubmission,
  getSubmissions,
  statistics,
  getPreviousScaleEndGoal,
  submissionStatus
};

export default ApiSubmission;
