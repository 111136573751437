import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";

export type TAlertConfirmationProps = {
  show: boolean;
  setShow:  Dispatch<SetStateAction<boolean>>;
  backdrop?: boolean | "static";
  keyboard?: boolean;
  title?: string|JSX.Element|JSX.Element[];
  description?: string|JSX.Element|JSX.Element[];
  labelYes?: string;
  labelNo?: string;
  onYes?: () => void;
  onNo?: () => void;
  onClose?: () => void;
};

AlertConfirmation.defaultProps = {
  show: false,
  setShow: () => {},
  backdrop: "static",
  keyboard: false,
  title: "Confirmation",
  description: "Are you sure?",
  labelYes: "Yes",
  labelNo: "No",
  onYes: () => {},
  onNo: () => {},
  onClose: () => {}
};

export default function AlertConfirmation(props: TAlertConfirmationProps) {
  return <Modal className="modal-generic alert-confirmation" show={props.show}
    backdrop={props.backdrop}
    keyboard={props.keyboard}
    onHide={() => {
      props.setShow(false);
      props.onClose();
    }}
  >
    <Modal.Header closeButton>
      <Modal.Title>{props.title ?? "Confirmation"}</Modal.Title>
    </Modal.Header>
    <Modal.Body>{props.description ?? "Are you sure?"}</Modal.Body>
    <Modal.Footer>
      <button className="btn btn-mw btn-secondary" onClick={() => (props.onNo())}>
        {props.labelNo ?? "No"}
      </button>
      <button className="btn btn-mw btn-primary" onClick={() => (props.onYes())}>
        {props.labelYes ?? "Yes"}
      </button>
    </Modal.Footer>
  </Modal>;
}
