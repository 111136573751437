import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { Modal } from "react-bootstrap";
import { TGetAllTraitItem, TGetAllTraits } from "../../modules/api/trait";
import ModulePopup from "../../modules/popup/popup";
import { MAX_TRAITS_IN_A_SUBMISSION } from "../../config/constants";
import Utils from "../../utils/utils";

export type TPopupChooseTraitRef = {
  show: () => void;
  hide: () => void;
};

export type TPopupChooseTraitProps = {
  traits?: TGetAllTraits;
  selectedTraits?: TGetAllTraits;
  title?: string;
  labelOK?: string;
  backdrop?: boolean;
  keyboard?: boolean;
  onClose?: () => void;
  onOK?: (selectedTraits: TGetAllTraits) => void;
};

const PopupChooseTrait = forwardRef<TPopupChooseTraitRef, TPopupChooseTraitProps>((props, ref) => {
  const [show, setShow] = useState(false);
  const [selectedTraits, setSelectedTraits] = useState<TGetAllTraits>([]);

  useImperativeHandle(ref, () => ({
    show() {
      setShow(true);
    },
    hide() {
      setShow(false);
    }
  }));

  useEffect(() => {
    if (show) {
      setSelectedTraits(props.selectedTraits);
    } else {
      setSelectedTraits([]);
    }
  }, [show]);

  const isSelected = (trait: TGetAllTraitItem) => {
    return selectedTraits.some(currTrait => currTrait._id === trait._id);
  };

  const toggleTrait = (trait: TGetAllTraitItem) => {
    if (isSelected(trait)) {
      setSelectedTraits(traits => traits.filter(currTrait => currTrait._id !== trait._id));
    } else {
      setSelectedTraits(traits => traits.concat(trait));
    }
  };

  return <Modal className="modal-generic modal-choose-trait" show={show}
    backdrop={props.backdrop}
    keyboard={props.keyboard}
    onHide={() => {
      setShow(false);
      props.onClose();
    }}
  >
    <Modal.Header closeButton>
      <Modal.Title>{props.title ?? "Confirmation"}</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      {selectedTraits.length >= MAX_TRAITS_IN_A_SUBMISSION && <p>
        Maximum selected {Utils.pluralize("trait", selectedTraits.length)} ({MAX_TRAITS_IN_A_SUBMISSION}) reached.</p>
      }
      <div className="c-trait-buttons">
        {props.traits.map((item) => <button type="button" key={item._id}
          disabled={!isSelected(item) && selectedTraits.length >= MAX_TRAITS_IN_A_SUBMISSION}
          className={`c-trait-button${isSelected(item) ? " c-trait-button--active" : ""}`}
          onClick={(e) => {
            e.preventDefault();
            toggleTrait(item);
          }}
        >
          {item.name}
        </button>)}
      </div>
    </Modal.Body>
    <Modal.Footer>
      <button className="btn btn-mw btn-primary" onClick={() => {
        const previousTraitsStillSelected = props.selectedTraits.every(x => selectedTraits.some(y => x._id === y._id));

        // check whether internal trait's options have been changed, if no
        // change then just simply hide the popup.
        if (
          props.selectedTraits.length === selectedTraits.length &&
          previousTraitsStillSelected
        ) {
          return setShow(false);
        }

        if (!previousTraitsStillSelected) {
          ModulePopup.showAlertConfirm({
            description: <p>Are you sure? <br/>Unchecked trait(s) with its data will be removed.</p>,
            onYes() {
              setShow(false);
              props.onOK(props.traits.filter(i => selectedTraits.some(j => j._id === i._id)));
            }
          });
        } else {
          setShow(false);
          props.onOK(props.traits.filter(i => selectedTraits.some(j => j._id === i._id)));
        }
      }}>
        {props.labelOK ?? "OK"}
      </button>
    </Modal.Footer>
  </Modal>;
});

PopupChooseTrait.defaultProps = {
  traits: [],
  selectedTraits: [],
  title: "Choose Trait",
  labelOK: "Continue",
  backdrop: true,
  keyboard: false,
  onClose: () => {},
  onOK: () => {}
};

export default PopupChooseTrait;
