import React, { useEffect, useImperativeHandle, useState } from "react";
import { Modal } from "react-bootstrap";
import UserImage from "../portal/UserImage";
import FGTextarea from "../dumb/FGTextarea";
import ModulePopup from "../../modules/popup/popup";
import Notifier from "../../modules/notifier/notifier";

export type TFeedbackFields = {
  messageId: string;
  feedback: string;
};

export type TPopupSupervisorFeedbackRef = {
  show: (opts?: { messageId?: string; feedback?: string }) => void;
  hide: () => void;
};

export type TPopupSupervisorFeedbackProps = {
  submission: {
    owner: {
      name: string;
      avatar: string;
      email: string;
    };
  };
  onSubmit?: (
    formData: TFeedbackFields,
    setDisabled: any,
    isDraft: boolean
  ) => void;
};

const PopupSupervisorFeedback = React.forwardRef<
  TPopupSupervisorFeedbackRef,
  TPopupSupervisorFeedbackProps
>((props, ref) => {
  const [show, setShow] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [formData, setFormData] = useState<TFeedbackFields>({
    messageId: "",
    feedback: "",
  });

  useEffect(() => {
    if (!show) {
      setDisabled(false);
      setFormData({
        messageId: "",
        feedback: "",
      });
    }
  }, [show]);

  useImperativeHandle(
    ref,
    () => ({
      show(opts) {
        if (opts) {
          setFormData((f) => ({ ...f, ...opts }));
        }

        setShow(true);
      },
      hide() {
        setShow(false);
      },
    }),
    []
  );

  const validate = () => {
    let valid = true;

    if (formData.feedback === "") {
      valid = false;
      Notifier.error("Feedback is required");
    }

    return valid;
  };

  const handleOnChange = (e: any) => {
    setFormData((v) => ({ ...v, [e.target.name]: e.target.value }));
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (!validate()) return;
    ModulePopup.showAlertConfirm({
      description: "Are you sure to submit?",
      onYes() {
        props.onSubmit(formData, setDisabled, e.target.name === "saveAsDraft");
      },
    });
  };

  return (
    <>
      <Modal
        data-comp="PopupSupervisorFeedback"
        className="modal-supervisor-feedback"
        show={show}
        onHide={() => setShow(false)}
        centered
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="feedback-modal">
            <div className="d-flex flex-column align-items-center feedback-modal__user-details mb-3 mt-3">
              <UserImage url={props.submission.owner.avatar} />
              <p className="fs-4 fw-medium m-0">
                {props.submission.owner.name}
              </p>
              <span>{props.submission.owner.email}</span>
            </div>
            <div className="w-100">
              <FGTextarea
                name="feedback"
                autoFocus
                variant="normal"
                label="Feedback"
                placeholder="Write your feedback"
                rows={5}
                value={formData.feedback}
                onChange={handleOnChange}
              />
            </div>
            <button
              type="button"
              name="submit"
              className="btn btn-primary w-100"
              disabled={disabled}
              onClick={handleSubmit}
            >
              Submit
            </button>
            <button
              type="button"
              name="saveAsDraft"
              className="btn btn-outline-primary w-100 mt-1"
              onClick={handleSubmit}
            >
              Save as Draft
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
});

PopupSupervisorFeedback.defaultProps = {
  onSubmit: () => {},
};

export default PopupSupervisorFeedback;
