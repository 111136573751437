import "./PageReferenceTypography.scss";
import { useEffect } from "react";
import usePageTitle from "../../hooks/use-page-title";

const $window = $(window);

function init() {
  const $root = $(".js-typography");

  if ($root.data("init")) return;

  $root.data("init", true);

  const sectionHeading = () => {
    var $target = $('.js-section-headings');
    var $fontSizes = $target.find('.js-font-size');
    var refreshSize = function ($target, $item) {
      $item.text('(' + $target.css('font-size').toLowerCase() + ')');
    };
    var onResize = function () {
      $fontSizes.each(function () {
        var $this = $(this);
        var $parent = $this.parent();

        $parent.css('font-size', '');
        refreshSize($parent, $this);
      });
    };

    $target.find('.js-fs-reset').on('click', function (e) {
      e.preventDefault();
      if (window.confirm('Are you sure? This will reset the font-size to their original state...')) {
        $fontSizes.each(function () {
          var $this = $(this);
          var $parent = $this.parent();

          $parent.css('font-size', '');
          refreshSize($parent, $this);
        });
      }
    });

    setTimeout(function () {
      $window.on('resize', onResize).trigger('resize');
    }, 50);
  }

  sectionHeading();
}

function TypoSection(props: any) {
  return <div className={`typo-sect ${props.className ?? ""}`.trim()} data-comp="TypoSection">
    <div className="ts__title">{props.title}</div>
    <div className="ts__content">
      {props.children}
    </div>
  </div>
}

function TypoColor(props: {color: string; label: string; var?: string;}) {
  return <span className="typo-color">
    <span className="tc__box" style={{backgroundColor: props.color}}></span>
    <span className="tc__label">
      {`${props.label} -- ${props.color}`}
      {props.var && <>
        {" -- "}
        <code>{props.var}</code>
      </>}
    </span>
  </span>
}

export default function PageReferenceTypography() {
  usePageTitle("Page Reference (Typography)");

  useEffect(() => {
    setTimeout(() => init(), 100);
  }, []);

  return <>

    <main className="content-generic content-generic--medium js-typography">
      <h1>Page Reference (Typography)</h1>
      <p>This is a test page. We can use this as reference.</p>
      <hr />

      <TypoSection title="Fonts">
        <p>Mainly uses <a href="https://fonts.google.com/specimen/Inter" target="_blank">Inter by Google Fonts</a>{" "}
          as <strong>default font</strong>.</p>
        <ol>
          <li className="fw-light">Inter - Light (300)</li>
          <li className="fw-reg">Inter - Regular (400)</li>
          <li className="fw-medium">Inter - Medium (500)</li>
          <li className="fw-bold">Inter - Bold (700)</li>
        </ol>
      </TypoSection>

      <TypoSection title="Colors">
        <p>
          See <code>_variables.scss</code>. Import this file if we want to reference
          the color variable.
        </p>
        <ol>
          <li><TypoColor label="Primary" color="#D23638" var="$col-primary" /></li>
          <li><TypoColor label="Secondary" color="#2EC4B6" var="$col-secondary" /></li>
          <li><TypoColor label="Text Primary" color="#424247" var="$col-text-primary" /></li>
          <li><TypoColor label="Text Secondary" color="#9A9A9A" var="$col-text-secondary" /></li>
          <li><TypoColor label="Button Tosca" color="#2EC4B6" var="$col-btn-tosca" /></li>
          <li><TypoColor label="Button Tosca (Light)" color="#E1F4ED" var="$col-btn-tosca-light" /></li>
          <li><TypoColor label="Bg White" color="#FFF" var="$bg-white" /></li>
          <li><TypoColor label="Bg Grey" color="#EFEFEF" var="$bg-grey" /></li>
          <li><TypoColor label="Bg Dark" color="#363636" var="$bg-dark" /></li>
        </ol>
      </TypoSection>

      <TypoSection title="Headings" className="js-section-headings">
        <h1>Heading 1 <code className="js-font-size"></code></h1>
        <h2>Heading 2 <code className="js-font-size"></code></h2>
        <h3>Heading 3 <code className="js-font-size"></code></h3>
        <h4>Heading 4 <code className="js-font-size"></code></h4>
        <h5>Heading 5 <code className="js-font-size"></code></h5>
        <h6>Heading 6 <code className="js-font-size"></code></h6>
        <p>
          This is a paragraph of <strong>the quick brown fox</strong>
          <em>jumps</em> over the lazy dog. <code className="js-font-size"></code>
        </p>
      </TypoSection>

      <TypoSection title="Example">
        <h1>1. Lorem Ipsum</h1>

        <h2>1.1. Definition</h2>
        <h3>1.1.1 What is Lorem Ipsum</h3>
        <p><strong>Lorem Ipsum</strong> <em>is <u>simply</u> dummy text</em> of the printing and typesetting industry. Lorem
          Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of
          type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap
          into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of
          Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus
          PageMaker including versions of Lorem Ipsum.</p>

        <h2>1.2. Source</h2>
        <h3>1.2.1 Where does it come from?</h3>
        <p>You can see the sample link on <a href="https://fxmweb.com" target="_blank">https://fxmweb.com</a>.</p>
        <p>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin
          literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney
          College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and
          going through the cites of the word in classical literature, discovered the undoubtable source. Lorem Ipsum comes
          from sections 1.10.32 and 1.10.33 of "de Finibus Bonorum et Malorum" (The Extremes of Good and Evil) by Cicero,
          written in 45 BC. This book is a treatise on the theory of ethics, very popular during the Renaissance. The first
          line of Lorem Ipsum, "Lorem ipsum dolor sit amet..", comes from a line in section 1.10.32.</p>
        <p>The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested. Sections 1.10.32
          and 1.10.33 from "de Finibus Bonorum et Malorum" by Cicero are also reproduced in their exact original form,
          accompanied by English versions from the 1914 translation by H. Rackham.</p>

        <h2>1.3. FAQs</h2>
        <h3>1.3.1 Why do we use it?</h3>
        <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at
          its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as
          opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing
          packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will
          uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by
          accident, sometimes on purpose (injected humour and the like).</p>

        <h3>1.3.2 Where can I get some?</h3>
        <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some
          form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a
          passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All
          the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first
          true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model
          sentence structures, to generate Lorem Ipsum which looks reasonable. The generated Lorem Ipsum is therefore always
          free from repetition, injected humour, or non-characteristic words etc.</p>

        <blockquote>
          <p>No grand idea was ever born in a conference, but a lot of foolish ideas have died there. <del>You should not
              this part, because this part is deleted.</del></p>
        </blockquote>

        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Commodi labore laudantium nisi quibusdam voluptatum!
          Beatae deserunt dolor explicabo impedit modi perferendis ratione. Autem harum minus saepe soluta? Enim,
          laudantium, odit.</p>

        <h2>1.4 Another Sample</h2>
        <h3>1.4.1 Sub Another Sample</h3>
        <h4>1.4.1.1 Sub Sub Another Sample</h4>
        <h5>1.4.1.1.1 Sub Sub Sub Another Sample</h5>
        <h6>1.4.1.1.1.1 Sub Sub Sub Sub Another Sample</h6>
        <p>Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem
          Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true
          generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence
          structures, to generate Lorem Ipsum which looks reasonable. The generated Lorem Ipsum is therefore always free
          from repetition, injected humour, or non-characteristic words etc.</p>

        <h3>1.4.2 Special Lists</h3>
        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ab aperiam at beatae consequuntur corporis eaque illum
          ipsam iste, laudantium non officia quis repellendus, sequi suscipit unde, velit veniam. Placeat, recusandae.</p>
        <ul>
          <li>
            Banana
            <ol type="a">
              <li>
                High Quality
                <ul>
                  <li>
                    Import from Indonesia
                    <ol type="i">
                      <li>Aceh</li>
                      <li>Bandung</li>
                      <li>Jakarta</li>
                    </ol>
                  </li>
                  <li>Import from Singapore</li>
                  <li>Import from South Africa</li>
                </ul>
              </li>
              <li>Medium Quality</li>
              <li><strong>Low Quality</strong></li>
            </ol>
          </li>
          <li>Cherry</li>
          <li>Grape</li>
        </ul>
        <p>Below is the ordered list:</p>
        <ol>
          <li>Go to market</li>
          <li>Buy some <strong><em>apples</em></strong></li>
          <li>Back to home</li>
        </ol>
      </TypoSection>
    </main>

  </>;
}
