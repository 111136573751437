import { useState, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import FadeBox from "../../components/animate/FadeBox";
import FGInput from "../../components/dumb/FGInput";
import Config from "../../config/config";
import usePageTitle from "../../hooks/use-page-title";
import ApiAuth from "../../modules/api/auth";
import Debouncer from "../../modules/debouncer/debouncer";
import Notifier from "../../modules/notifier/notifier";
import { ROUTES } from "../../routes";
import ModuleValidation from "../../modules/validation";

export default function PageResetPassword() {
  const { title } = usePageTitle("Setup Password");
  const { setupPasswordToken } = useParams();
  const [showSubmitted, setShowSubmitted] = useState(false);
  const [error, setError] = useState("");
  const refPassword = useRef<HTMLInputElement>();
  const refConfirmPassword = useRef<HTMLInputElement>();

  const handleOnSubmit = (e: any) => {
    e.preventDefault();

    if (Config.IS_MOCKUP_MODE) {
      return;
    }

    if (!setupPasswordToken) {
      return;
    }

    const password = refPassword.current.value;
    const confirmPassword = refConfirmPassword.current.value;
    const validate = ModuleValidation.isChangePasswordFormValid({
      newPassword: password,
      confirmNewPassword: confirmPassword,
    });

    if (!validate.valid) {
      setError(validate.message);
      return;
    }

    Debouncer.execute("SETUP_PASSWORD", async () => {
      const payload = {
        password,
        confirmPassword,
      };
      const req = await ApiAuth.setupPassword(setupPasswordToken, payload);

      if (req.status) {
        Notifier.success(req.message);
        refPassword.current.value = "";
        refConfirmPassword.current.value = "";
        setShowSubmitted(true);
      } else {
        Notifier.error(req.message);
      }
    });
  };

  return (
    <>
      <FadeBox show={!showSubmitted}>
        <form className="form-default" onSubmit={handleOnSubmit}>
          <h1>{title}</h1>

          {error.trim().length > 0 && (
            <p className="alert alert-danger">{error}</p>
          )}

          <FGInput
            label="Password"
            placeholder="Type your password"
            type="password"
            autoFocus
            required
            ref={refPassword}
          />

          <FGInput
            label="Confirm Password"
            placeholder="Retype your password"
            type="password"
            required
            ref={refConfirmPassword}
          />

          <p className="text-center">
            <button type="submit" className="btn btn-primary">
              Submit
            </button>
          </p>

          <p className="text-center mb-0">
            <Link to={ROUTES.AUTH_LOGIN}>Back to login</Link>
          </p>
        </form>
      </FadeBox>

      <FadeBox show={showSubmitted}>
        <div>
          <h1>Password Setup Success</h1>
          <p>Your password has been setup successfully.</p>
          <p className="text-center mb-0">
            <Link to={ROUTES.AUTH_LOGIN} className="btn btn-primary">
              Click here to login
            </Link>
          </p>
        </div>
      </FadeBox>
    </>
  );
}