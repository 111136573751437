import React, { useMemo, useState } from "react";
import { TGetAllTraitItem } from "../../modules/api/trait";
import useViewport from "../../hooks/use-viewport";
import { HiExclamationCircle } from "react-icons/hi";
import ModulePopup from "../../modules/popup/popup";

const getTraitId = (() => {
  let i = 0;
  return () => `trait-scale-${(i += 1)}`;
})();

export type TTraitScaleRef = {};

export type TTraitScaleProps = {
  onChange?: (e: any) => void;
  trait: TGetAllTraitItem;
  className?: string;
  name?: string;
  value?: number|string;
  disabled?: boolean;
  readonly?: boolean;
};

const TraitScale = React.forwardRef<TTraitScaleRef, TTraitScaleProps>((props, ref) => {
  const viewport = useViewport();
  const traitId = useMemo(() => props.name ?? getTraitId(), [props.name]);
  const traitInfo = useMemo(() => {
    const exists = Array.isArray(props.trait?.parsedConfig?.scales);
    const length = exists ? props.trait?.parsedConfig?.scales.length : 0 ;
    const maxIndex = length - 1;

    return {
      exists,
      length,
      maxIndex
    };
  }, [props.trait]);
  const [legendDesc, setLegendDesc] = useState({
    show: false,
    content: <></>
  });

  return <div className={`trait-scale ${props.disabled ? "trait-scale--disabled" : ""} ${props.readonly ? "trait-scale--readonly" : ""} ${props.className}`} data-comp="TraitScale">
    {traitInfo.exists && <div className="ts__box">
      <ol onMouseLeave={() => setLegendDesc(c => ({ ...c, show: false }))}>
        {props.trait.parsedConfig.scales.map((item, i) => {
          const isActive = (props.value == item.level);
          const isLast = i === traitInfo.maxIndex;
          const contentLegend = <>
            <span className="tsld__circle" style={{background: item.color}}></span>
            <h1>{item.title}</h1>
            <p>{item.description}</p>
          </>;

          return <li key={item.level}
            className={`ts__item ${isActive ? "ts__item-active" : ""} ${isLast ? "ts__item-last" : ""}`}
          >
            <label className="ts__item-label"
              onMouseEnter={() => {
                if (viewport.isMobile) return;
                setLegendDesc({
                  show: true,
                  content: contentLegend
                });
              }}
              style={{
                borderColor: item.color,
                color: isActive  ? "#fff" : item.color,
                background: isActive ? item.color : ""
              }}
            >
              <span className="ts__item-hover-style" style={{
                background: item.color
              }}></span>
              <input type="radio" value={item.level} name={traitId}
                disabled={props.disabled || props.readonly}
                onChange={(e) => {
                  const proceed = () => props.onChange({
                    target: {
                      name: e.target.name,
                      type: e.target.type,
                      value: parseFloat(e.target.value)
                    }
                  });

                  if (viewport.isMobile) {
                    ModulePopup.showTraitScaleConfirm({
                      title: `Selected value: ${e.target.value}`,
                      description: contentLegend,
                      onYes: () => proceed()
                    });
                    return;
                  }

                  proceed();
                }}
                checked={isActive}
              />
              <span>{item.level}</span>
            </label>
          </li>;
        })}
      </ol>
      <div className="ts__legend-info">
        <HiExclamationCircle /> Hover to show color information
      </div>
    </div>}
    <div className="ts__legend-desc">
      {legendDesc.show && <div className="tsld__box">
        {legendDesc.content}
      </div>}
    </div>
  </div>;
});

TraitScale.defaultProps = {
  onChange() {},
  className: "",
  readonly: false
};

export default TraitScale;
