import { isPlainObject } from 'lodash-es';
import { IStorageItem, IStorageOptions, StorageHelper } from "./storage";

const instanceType = 'session';

export default class StorageSession {

  static async set(key, value, options?: IStorageOptions) {
    let store = await StorageHelper.getInstance(instanceType);
    await store.setItem(key, await StorageHelper.forgePayload(value, options));
    return true;
  }

  static async get(key, valueIfNotExists = undefined) {
    let store = await StorageHelper.getInstance(instanceType);
    try {
      let data = <IStorageItem>await store.getItem(key);

      if (isPlainObject(data)) {
        let value = data.data;
        let expiration = data.expiration;
        let now = (new Date()).getTime();

        if (expiration == -1) {
          return value;
        } else {
          if (now <= expiration) {
            return value;
          } else {
            await this.remove(key);
            return valueIfNotExists;
          }
        }
      } else {
        return valueIfNotExists;
      }
    } catch (ignore) {
      return valueIfNotExists;
    }
  }

  static async clear() {
    let store = await StorageHelper.getInstance(instanceType);
    try {
      await store.clear();
      return true;
    } catch (ignore) {
      return false;
    }
  }

  static async remove(key) {
    let store = await StorageHelper.getInstance(instanceType);
    try {
      await store.removeItem(key);
      return true;
    } catch (ignore) {
      return false;
    }
  }

}
