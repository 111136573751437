import { useEffect, useRef, useState } from "react";
import DataTable, { TableColumn, TableStyles } from "react-data-table-component";
import { TSubmissionPagingSearchParam } from "../../modules/api/submission";

export type TServerSideSubmissionDTApiResult = { data: any[]; total: number; };

export type TServerSideSubmissionDataTableProps = {
  api: (opts: TSubmissionPagingSearchParam) => Promise<TServerSideSubmissionDTApiResult>;
  columns: TableColumn<any>[];
  loading?: boolean;
  /**
   * This is to add / modify stuff before rendering happens, e.g. we want
   * to add specific props to the existing API response.
   *
   * @example
   * <ServerSideDataTable
   *   api={apiSource}
   *   search={searchKeywords}
   *   mapper={(item) => Object.assign(item, {
   *     modified_id: `ID:  ${item._id}`
   *   })}
   *   ...
   * />
   */
  mapper?: (items: any) => any;
  search?: string;
  searchDelay?: number;
  selectableRows?: boolean;
  onSelectedRowsChange?: (selected: {
    allSelected: boolean;
    selectedCount: number;
    selectedRows: any[];
  }) => void;
  customStyles?: TableStyles;
  /** just create a state toggler (false/true) to refresh */
  refresh?: boolean;
  filterBy: string;
  submissionType: string;
};

/**
 * WIP documentation, currently don't have time to do this. 😁
 */
export default function ServerSideSubmissionDataTable(props: TServerSideSubmissionDataTableProps) {
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState<any[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRows, setTotalRows] = useState(0);
  const [rowPerPage, setRowPerPage] = useState(10);
  const [keywords, setKeywords] = useState("");
  const mapper = props.mapper;

  useEffect(() => {
    let timers = setTimeout(() => {
      setKeywords(props.search.trim())
    }, typeof props.searchDelay !== "number" ? 500 : props.searchDelay);
    return () => clearTimeout(timers);
  }, [props.search]);

  useEffect(() => {
    setLoading(true);

    props.api({
      submissionType: props.submissionType,
      filterBy: props.filterBy,
      name: keywords,
      email: keywords,
      group: keywords,
      page: currentPage,
      perPage: rowPerPage
    }).then(res => {
      setLoading(false);
      setTotalRows(res.total);

      if (typeof mapper === "function") {
        setItems(res.data.map(mapper));
      } else {
        setItems(res.data);
      }
    });
  }, [keywords, props.filterBy, rowPerPage, currentPage, props.refresh, props.submissionType]);

  const handlePageChange = async (page) => {
    setCurrentPage(page);
  };

  const handlePerRowsChange = async (rowPerPage, page) => {
    setRowPerPage(rowPerPage);
    setCurrentPage(page);
  };

  return <div className="basic-rdt" data-comp="ServerSideUserDataTable">
    <DataTable
      progressPending={props.loading}
      progressComponent={<></>}
      data={items}
      pagination
      paginationServer
      paginationTotalRows={totalRows}
      paginationPerPage={rowPerPage}
      onChangePage={handlePageChange}
      onChangeRowsPerPage={handlePerRowsChange}
      columns={props.columns}
      selectableRows={props.selectableRows}
      onSelectedRowsChange={props.onSelectedRowsChange}
      customStyles={props.customStyles}
    />
    {loading && <div className="ssd__loading">
      <div className="ssd__loading__text">Loading...</div>
    </div>}
  </div>;
};

