import { useState, useEffect, useRef } from "react";
import HeaderTools from "../../../components/portal/HeaderTools";
import dummyUsers from "../../../data-dummy";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { ContextMenu } from "primereact/contextmenu";
import {
  HiOutlineSearch,
  HiChevronDown,
  HiOutlineFolderAdd,
  HiOutlineTrash,
} from "react-icons/hi";
import { Modal, Button } from "react-bootstrap";
import FGCheckbox from "../../../components/dumb/FGCheckbox";

const nestedMenuItems = [
  {
    label: "Move to",
    disabled: true,
  },
  {
    separator: true,
  },
  {
    label: "Batch 1",
    command: () => console.log("Batch 1 clicked"),
  },
  {
    label: "Batch 2",
    command: () => console.log("Batch 2 clicked"),
  },
  {
    label: "Batch 3",
    command: () => console.log("Batch 3 clicked"),
  },
  {
    label: "Batch 4",
    command: () => console.log("Batch 4 clicked"),
  },
  {
    separator: true,
  },
  {
    label: "2019",
    command: () => console.log("2019 clicked"),
  },
  {
    label: "2020",
    command: () => console.log("2020 clicked"),
  },
  {
    label: "2021",
    command: () => console.log("2021 clicked"),
  },
  {
    label: "2022",
    command: () => console.log("2022 clicked"),
  },
  {
    separator: true,
  },
  {
    label: "Student",
    command: () => console.log("Student clicked"),
  },
  {
    label: "SIT Supervisor",
    command: () => console.log("SIT Supervisor clicked"),
  },
  {
    label: "Industry Supervisor",
    command: () => console.log("Industry Supervisor clicked"),
  },
  {
    label: "Super Admin",
    command: () => console.log("Super Admin clicked"),
  },
];

const menuItems = [
  {
    icon: <HiOutlineSearch />,
    label: "View Profile",
    command: () => console.log("Item 1 clicked"),
  },
  {
    icon: <HiOutlineFolderAdd />,
    label: "Manage Account",
    command: () => console.log("Item 2 clicked"),
    items: nestedMenuItems,
  },
  {
    icon: <HiOutlineTrash />,
    label: "Delete User",
    command: () => console.log("Item 3 clicked"),
  },
];

interface GroupListProps {
  name: string;
  isChecked: boolean;
  onCheckboxClick: (index: number) => void;
  onComponentClick: (index: number) => void;
  index: number;
}

const GroupList = ({
  name,
  isChecked,
  onCheckboxClick,
  onComponentClick,
  index,
}: GroupListProps) => {
  return (
    <div className="mb-4 user-list" onClick={() => onComponentClick(index)}>
      <div className="bg-white p-3 rounded d-flex align-items-center justify-content-between text-decoration-none text-black">
        <div className="d-flex justify-content-center align-items-center gap-4">
          <FGCheckbox
            className="active-groups__checkbox"
            checked={isChecked}
            onClick={() => onCheckboxClick(index)}
          />
          <p className="m-0 active-groups__name">{name}</p>
        </div>
      </div>
    </div>
  );
};

export default function PageGroupDetails() {
  const [selectedButton, setSelectedButton] = useState("Student");
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedContextUser, setSelectedContextUser] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedSort, setSelectedSort] = useState(null);
  const [loading, setLoading] = useState(true);
  const cm = useRef(null);
  const [showInviteModal, setShowInviteModal] = useState(false);
  const [checkedItems, setCheckedItems] = useState([]);

  const handleCheckboxClick = (index) => {
    const newCheckedItems = [...checkedItems];
    newCheckedItems[index] = !newCheckedItems[index];
    setCheckedItems(newCheckedItems);
  };

  const handleComponentClick = (index) => {
    const newCheckedItems = [...checkedItems];
    newCheckedItems[index] = !newCheckedItems[index];
    setCheckedItems(newCheckedItems);
  };

  useEffect(() => {
    setLoading(true);
    const filteredUsers = dummyUsers.filter(
      (user) => user.account_type === selectedButton
    );
    const sortedUsers = filteredUsers.sort((a, b) => {
      if (a[selectedSort] < b[selectedSort]) {
        return -1;
      }
      if (a[selectedSort] > b[selectedSort]) {
        return 1;
      }
      return 0;
    });
    setUsers(sortedUsers);
    setLoading(false);
  }, [selectedButton, selectedSort]);

  useEffect(() => {
    const filteredUsers = dummyUsers.filter(
      (user) =>
        user.account_type === selectedButton &&
        user.name.toLowerCase().includes(searchQuery.toLowerCase())
    );
    const sortedUsers = filteredUsers.sort((a, b) => {
      if (a[selectedSort] < b[selectedSort]) {
        return -1;
      }
      if (a[selectedSort] > b[selectedSort]) {
        return 1;
      }
      return 0;
    });
    setUsers(sortedUsers);
  }, [selectedButton, selectedSort, searchQuery]);

  const handleButtonClick = (label: string) => {
    setSelectedButton(label);
  };

  const handleSearchChange = (searchQuery: string) => {
    setSearchQuery(searchQuery);
  };

  const handleSortChange = (selectedSort: string) => {
    setSelectedSort(selectedSort);
  };

  const handleInviteModalClose = () => {
    setShowInviteModal(false);
  };
  const handleInviteModalSubmit = () => {
    // handle form submission here setShowInviteModal(false); };
  };
  return (
    <div className="row" data-comp="PageGroupList">
      <h2 className="fs-title mb-3">Group Name 1</h2>
      <HeaderTools
        selectableButtons={[
          {
            label: "Student",
            selected: selectedButton === "Student",
            onClick: () => handleButtonClick("Student"),
          },
          {
            label: "Industry Supervisor",
            selected: selectedButton === "Industry Supervisor",
            onClick: () => handleButtonClick("Industry Supervisor"),
          },
          {
            label: "SIT Supervisor",
            selected: selectedButton === "SIT Supervisor",
            onClick: () => handleButtonClick("SIT Supervisor"),
          },
        ]}
        onSearchChange={handleSearchChange}
        selectedSort={selectedSort}
        onSortChange={handleSortChange}
        buttonLink="/portal/groups/details"
        buttonLinkName="Invite New Member"
        onButtonClick={() => setShowInviteModal(true)}
      />
      <div>
        <ContextMenu
          model={menuItems}
          ref={cm}
          onHide={() => setSelectedUser(null)}
        />
        <DataTable
          value={users}
          loading={loading}
          className="user-list__table"
          emptyMessage="No data found"
          selectionMode="multiple"
          selection={selectedUser}
          onSelectionChange={(e) => setSelectedUser(e.value)}
          dataKey="id"
          height={500}
          onContextMenu={(e) => cm.current.show(e.originalEvent)}
          contextMenuSelection={selectedContextUser}
          onContextMenuSelectionChange={(e) => setSelectedContextUser(e.value)}
          paginator
          rows={10}
          rowsPerPageOptions={[10, 25, 50]}
        >
          <Column
            selectionMode="multiple"
            style={{ width: "3rem" }}
            header={<HiChevronDown className="ms-2 fs-4" />}
          />
          <Column
            field="image"
            header=""
            style={{ width: "3rem" }}
            body={(rowData) => (
              <img
                src="/assets/images/dummy/profile-picture/male.png"
                width={40}
                alt={rowData.name}
                className="user-list__image rounded-circle"
              />
            )}
          />
          <Column field="name" header="Name" />
          <Column field="year" header="Cohort" />
          <Column
            field="study_program"
            header="Programme"
            bodyClassName="fw-normal"
          />
          <Column
            field="batch"
            header="Batch"
            body={(rowData) => (
              <span className="fw-normal">Batch {rowData.batch}</span>
            )}
          />
          <Column field="email" header="Email" bodyClassName="fw-normal" />
        </DataTable>
      </div>
      <Modal show={showInviteModal} onHide={handleInviteModalClose}>
        <Modal.Header>
          <div>
            <Modal.Title className="fs-4 invite-modal__title">
              Invite New Member
            </Modal.Title>
            <span className="fs-5">Class of 2020 Group 7</span>
          </div>
        </Modal.Header>
        <Modal.Body className="invite-modal__body">
          <div className="input-group h-100 invite-modal__search mb-3">
            <span className="input-group-text" id="search">
              <HiOutlineSearch />
            </span>
            <input
              type="text"
              className="form-control"
              placeholder="Search..."
              aria-label="search"
              aria-describedby="search"
            />
          </div>
          <div>
            {dummyUsers.map((user) => (
              <GroupList
                key={user.id}
                name={user.name}
                onCheckboxClick={handleCheckboxClick}
                onComponentClick={handleComponentClick}
                isChecked={checkedItems[user.id]}
                index={user.id}
              />
            ))}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleInviteModalSubmit}>
            Invite Member
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
