import moment from "moment";
import { useState, useRef } from "react";
import { FaPlay, FaStop, FaEdit, FaPause, FaTrashAlt } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import { ROUTES } from "../../../routes";
import ModulePopup from "../../../modules/popup/popup";
import Notifier from "../../../modules/notifier/notifier";
import ApiScheduleReminder from "../../../modules/api/schedule-reminder";
import ServerSideScheduleReminderTable from "../../../components/common/ServerSideScheduleReminderTable";
import {
  CRON_JOB_TYPE_DAILY,
  CRON_JOB_STATUS_SCHEDULED,
  CRON_JOB_STATUS_RUNNING,
  CRON_JOB_STATUS_STOPPED,
} from "../../../config/constants";

export default function PageScheduleReminder() {
  const [refresh, setRefresh] = useState(false);

  const dataTableRef = useRef(null);

  const navigate = useNavigate();

  const getNextTimeRun = (setting: any) => {
    let time = 60 * 60 * 1000;
    if (setting.typeCronJob === CRON_JOB_TYPE_DAILY) {
      time = time * 24;
    }
    let from = new Date(setting.startDateTime);
    let to = new Date(
      from.setTime(from.getTime() + time * setting.stepSize * setting.timeRun)
    );
    return to;
  };

  const updateJobStatus = async (id: string, status: string) => {
    const { success, data, error } = await ApiScheduleReminder.updateJobStatus(
      id,
      status
    );
    if (!success) {
      Notifier.error(error);
    } else {
      Notifier.success("Task has been updated");
      dataTableRef.current.updateJobStatus(id, status, data.startDateTime);
    }
  };

  const runJob = async (id: string) => {
    const { success, error } = await ApiScheduleReminder.runJob(id);
    if (!success) {
      Notifier.error(error);
    } else {
      Notifier.success("Task has run successfully");
      dataTableRef.current.updateJobStatus(id, CRON_JOB_STATUS_RUNNING);
    }
  };

  const deleteJob = async (id: string) => {
    const { success, error } = await ApiScheduleReminder.deleteJob(id);
    if (!success) {
      Notifier.error(error);
    } else {
      Notifier.success("Task has been deleted");
      dataTableRef.current.deleteJob(id);
    }
  };

  return (
    <main className="form-generic content-generic content-generic--fluid">
      <div className="cg__actions">
        <div className="cg__action-buttons">
          <Link
            to={ROUTES.PORTAL_SCHEDULE_REMINDER + "/add"}
            className="btn btn-primary"
          >
            Add New Task
          </Link>{" "}
          <button
            className="btn btn-outline-primary"
            onClick={() => setRefresh(!refresh)}
          >
            Refresh
          </button>
        </div>
      </div>
      <ServerSideScheduleReminderTable
        ref={dataTableRef}
        api={ApiScheduleReminder.getJobs}
        columns={[
          {
            id: "name",
            name: "Name",
            cell: (row) => (
              <Link
                className="task-name-link job-columns"
                to={`${ROUTES.PORTAL_SCHEDULE_REMINDER}/edit/${row._id}`}
              >
                {row.taskName}
              </Link>
            ),
          },
          {
            id: "type",
            name: "Type",
            cell: (row) => <div className="job-columns">{row.typeCronJob}</div>,
          },
          {
            id: "beforeDays",
            name: "Before Day(s)",
            cell: (row) => <div className="job-columns">{row.beforeDays}</div>,
          },
          {
            id: "nextTimeRun",
            name: "Next Run At",
            cell: (row, index) =>
              row.status !== CRON_JOB_STATUS_STOPPED && (
                <div key={index} className="job-columns">
                  {moment(getNextTimeRun(row)).format("YYYY-MM-DD hh:mm A")}
                </div>
              ),
          },
          {
            id: "stepSize",
            name: "Step Size",
            cell: (row) => <div className="job-columns">{row.stepSize}</div>,
          },
          {
            id: "status",
            name: "Status",
            cell: (row) => {
              let className = "";
              if (row.status === CRON_JOB_STATUS_STOPPED) {
                className = "stopped-status-label";
              }
              if (row.status === CRON_JOB_STATUS_SCHEDULED) {
                className = "scheduled-status-label";
              }
              if (row.status === CRON_JOB_STATUS_RUNNING) {
                className = "running-status-label";
              }
              return (
                <div className={`${className} job-status-label`}>
                  {row.status}
                </div>
              );
            },
          },
          {
            id: "action",
            name: "",
            right: true,
            cell: (row) => (
              <div style={{ display: "flex" }}>
                {row.status !== CRON_JOB_STATUS_STOPPED ? (
                  <FaPause
                    title="Stop job"
                    className="job-update-icons"
                    onClick={() =>
                      updateJobStatus(row._id, CRON_JOB_STATUS_STOPPED)
                    }
                  ></FaPause>
                ) : (
                  <FaStop
                    title="Schedule job"
                    className="job-update-icons"
                    onClick={() =>
                      updateJobStatus(row._id, CRON_JOB_STATUS_SCHEDULED)
                    }
                  ></FaStop>
                )}
                {row.status !== CRON_JOB_STATUS_STOPPED && (
                  <FaPlay
                    title="Run immediately"
                    className="job-update-icons"
                    onClick={() => runJob(row._id)}
                  ></FaPlay>
                )}
                <FaEdit
                  title="Detail job"
                  className="job-update-icons"
                  onClick={() =>
                    navigate(
                      `${ROUTES.PORTAL_SCHEDULE_REMINDER}/edit/${row._id}`
                    )
                  }
                ></FaEdit>
                <FaTrashAlt
                  title="Delete job"
                  className="job-update-icons"
                  onClick={() => {
                    ModulePopup.showAlertConfirm({
                      title: "Delete Confirmation",
                      description: (
                        <>
                          <p>Are you sure to delete this?</p>
                        </>
                      ),
                      onYes: () => {
                        deleteJob(row._id);
                      },
                    });
                  }}
                ></FaTrashAlt>
              </div>
            ),
          },
        ]}
        refresh={refresh}
      />
    </main>
  );
}
