import { useContext, useEffect, useState } from "react";
import { HiArrowLeft } from "react-icons/hi";
import { Link, useParams } from "react-router-dom";
import PageProgressTabReportWL from "./PageProgressTabReportWL";
import { usePortalSettingSet } from "../../../context/portal-context";
import usePageTitle from "../../../hooks/use-page-title";
import LoadingData from "../../../components/portal/LoadingData";
import ApiSubmission from "../../../modules/api/submission";
import { ROUTES } from "../../../routes";
import ModuleDate from "../../../modules/date";
import ApiMessage, { TMessageItem } from "../../../modules/api/message";
import Utils from "../../../utils/utils";

export default function PageProgressWL() {
  const title = "My Workplace Learning Report";
  usePageTitle(title);
  usePortalSettingSet("label_header", title);

  const { submissionId } = useParams();
  const [reportTitle, setReportTitle] = useState("Workplace Learning Report");
  const [loading, setLoading] = useState(true);
  const [submission, setSubmission] = useState();
  const [messages, setMessages] = useState<TMessageItem[]>([]);
  const [isRefreshingMessages, setIsRefreshingMessages] = useState(false);
  const [refreshMessages, setRefreshMessages] = useState(false);

  useEffect(() => {
    ApiSubmission.getSubmission(submissionId).then((res) => {
      if (res.status) {
        setSubmission(res.data);
        setReportTitle(
          `Workplace Learning Report, ${ModuleDate.getTitleFormat(
            res.data.submissionDate.to
          )}`
        );
      }

      setLoading(false);
    });
  }, []);

  useEffect(() => {
    if (loading) return;
    (async () => {
      setIsRefreshingMessages(true);
      const messages = await ApiMessage.getMessagesBySubmission(submissionId);
      setMessages(messages.messages);
      await Utils.sleep(1000);
      setIsRefreshingMessages(false);
    })();
  }, [loading, refreshMessages]);

  return (
    <>
      <div className="d-flex gap-2 mb-3">
        <h1 className="fs-title">
          <Link
            to={ROUTES.PORTAL_PROGRESS}
            className="text-primary p-0 me-2 hide-mobile-down"
            title="Go back"
          >
            <HiArrowLeft />
          </Link>
          {reportTitle}
        </h1>
      </div>

      <LoadingData show={loading} />

      {!loading && !submission && (
        <p className="alert alert-warning">Submission not found.</p>
      )}

      {!loading && submission && (
        <div className="box-default bg-white mb-3">
          <PageProgressTabReportWL
            submission={submission}
            messages={messages}
            setMessages={setMessages}
            refreshMessages={setRefreshMessages}
            isRefreshingMessages={isRefreshingMessages}
          />
        </div>
      )}
    </>
  );
}
