import React, { useContext, useEffect, useImperativeHandle, useState } from "react";
import { Dropdown, Modal } from "react-bootstrap";
import { showLabelInfo } from "../../config/methods";
import BasicDataTable from "../common/BasicDataTable";
import DateTime from "../common/DateTime";
import { HiExclamationCircle, HiOutlineEye } from "react-icons/hi";
import Notifier from "../../modules/notifier/notifier";
import Debouncer from "../../modules/debouncer/debouncer";
import ApiSubmission from "../../modules/api/submission";
import ModulePopup from "../../modules/popup/popup";
import { MdDelete } from "react-icons/md";

export type TPopupSubmissionListRef = {
  open: (user: any, type: string) => void;
};

export type TPopupSubmissionListProps = {};

type TUserItem = {
  studentId?: string;
  group?: {
    groupId: string;
    groupName: string;
  },
  _id?: string;
  name?: string;
  email?: string;
  status?: string;
  submissions?: any[];
};

const PopupSubmissionList = React.forwardRef<TPopupSubmissionListRef, TPopupSubmissionListProps>((props, ref) => {
  const [user, setUser] = useState<TUserItem>({});
  const [show, setShow] = useState(false);
  const [submissionType, setSubmissionType] = useState("");
  const [submissions, setSubmissions] = useState<any[]>([]);

  useEffect(() => {
    if (!show) {
      setSubmissions([]);
    }
  }, [show]);

  useEffect(() => {
    if (!Array.isArray(user.submissions)) return;
    setSubmissions(user.submissions.filter(item => {
      // only filter non-error submission
      return typeof item?.from === "string" && typeof item?.to === "string"
        && typeof item?.modifiedDate === "string" && typeof item?._id === "string";
    }));
  }, [user]);

  useImperativeHandle(ref, () => ({
    open(user, type) {
      setUser({
        ...user,
        name: showLabelInfo(user.name),
        email: showLabelInfo(user.email),
        group: {
          group: showLabelInfo(user.group?.group),
          groupName: showLabelInfo(user.group?.groupName)
        }
      });
      setSubmissionType(type);
      setShow(true);
    }
  }));

  return <Modal className="modal-generic popup-submission-list" show={show} size="lg"
    backdrop={"static"}
    keyboard={false}
    onHide={() => {
      setShow(false);
    }}
  >
    <Modal.Header closeButton>
      <Modal.Title>Submission Items</Modal.Title>
    </Modal.Header>
    <Modal.Body>

      <ol className="contact-detail-list mb-3">
        <li>
          <div>Name</div>
          <div>{user.name}</div>
        </li>
        <li>
          <div>Email</div>
          <div>{user.email}</div>
        </li>
        <li>
          <div>Group</div>
          <div>{user.group?.groupName}</div>
        </li>
        <li>
          <div>Type</div>
          <div>{submissionType}</div>
        </li>
      </ol>

      {submissions.length === 0 && <p className="alert alert-info">This user doesn't have any submission(s).</p>}

      {submissions.length > 0 && <>
        <BasicDataTable
          data={submissions}
          columns={[
            {
              id: "period",
              name: "Period",
              cell: (row) => <>
                <DateTime date={row?.from} format="DD MMM" />
                {" "}-{" "}
                <DateTime date={row?.to} format="DD MMM YYYY" />
              </>
            },
            {
              id: "status",
              name: "Status",
              cell: (row) => row?.status ?? "Unknown"
            },
            {
              id: "action",
              button: true,
              name: "Action",
              cell: (row) => <div>
                <Dropdown align={"end"} drop={"down"}>
                  <Dropdown.Toggle variant="primary" id="dropdown-basic" className="btn-sm">
                    Action
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <button className="dropdown-item btn-danger" onClick={() => {
                      if (!row?._id) {
                        return ModulePopup.showAlert({
                          title: <>
                            <HiExclamationCircle/> Error
                          </>,
                          description: <p>No submission found for this user. <br/>
                            This user might have not submitted one.</p>
                        });
                      }

                      ModulePopup.showAlertConfirm({
                        title: "Delete Confirmation",
                        description: <>
                          <p>
                            Are you sure to delete this submission?
                          </p>
                          <ul>
                            <li>Name: <strong>{user.name}</strong></li>
                            <li>Email: <strong>{user.email}</strong></li>
                            <li>Type: <strong>{submissionType}</strong></li>
                            <li>Period: <strong>
                                <DateTime date={row?.from} format="DD MMM" />
                                {" "}-{" "}
                                <DateTime date={row?.to} format="DD MMM YYYY" />
                              </strong>
                            </li>
                          </ul>
                          <p>
                            This action can't be undone.
                          </p>
                        </>,
                        onYes: () => Debouncer.execute("DELETE_SUBMISSION", async () => {
                          const res = await ApiSubmission.remove(row?._id);

                          if (res.status) {
                            setSubmissions(items => items.filter(sub => sub._id !== row?._id));
                            Notifier.success(res.message);
                          } else {
                            Notifier.error(res.message);
                          }
                        })
                      });
                    }}>
                      <MdDelete/> {" "} Delete
                    </button>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            }
          ]}
        />
      </>}

    </Modal.Body>
    <Modal.Footer>
      <button className="btn btn-mw btn-outline-primary" onClick={() => {
        setShow(false);
      }}>
        Close
      </button>
    </Modal.Footer>
  </Modal>;
});

export default PopupSubmissionList;
