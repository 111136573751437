import { Dispatch, SetStateAction, useEffect, useRef } from "react";
import { Modal } from "react-bootstrap";

export type TAlertProps = {
  show: boolean;
  setShow:  Dispatch<SetStateAction<boolean>>;
  backdrop?: boolean | "static";
  keyboard?: boolean;
  title?: string|JSX.Element|JSX.Element[];
  description?: string|JSX.Element|JSX.Element[];
  labelOK?: string;
  onOK?: () => void;
  onClose?: () => void;
};

Alert.defaultProps = {
  show: false,
  setShow: () => {},
  backdrop: "static",
  keyboard: false,
  title: "Alert Title",
  description: "Content...",
  labelOK: "OK",
  onClose: () => {}
};

export default function Alert(props: TAlertProps) {
  const refBtnOK = useRef<HTMLButtonElement>();

  useEffect(() => {
    if (!props.show) return;
    setTimeout(() => {
      try {
        refBtnOK.current.focus();
      } catch (ignore) {}
    }, 500);
  }, [props.show]);

  return <Modal className="modal-generic alert-simple" show={props.show}
    backdrop={props.backdrop}
    keyboard={props.keyboard}
    onHide={() => {
      props.setShow(false);
      props.onClose();
    }}
  >
    <Modal.Header closeButton>
      <Modal.Title>{props.title ?? "Confirmation"}</Modal.Title>
    </Modal.Header>
    <Modal.Body>{props.description ?? "Are you sure?"}</Modal.Body>
    <Modal.Footer>
      <button ref={refBtnOK} className="btn btn-mw btn-primary" onClick={() => {
        if (typeof props.onOK === "function") {
          props.onOK();
        } else {
          props.setShow(false);
        }
      }}>
        {props.labelOK ?? "OK"}
      </button>
    </Modal.Footer>
  </Modal>;
}
