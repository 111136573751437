import React, { useState } from "react";

interface RangeSelectorProps {
  from: number;
  to: number;
  selected: number;
  onSelect?: (value: number) => void;
  readonly?: boolean;
}

const RangeSelector: React.FC<RangeSelectorProps> = ({ from, to, selected, onSelect, readonly = false }) => {
  const [value, setValue] = useState<number>(selected - from + 1);

  const handleClick = (index: number) => {
    if (!readonly) {
      setValue(index);
      onSelect(index);
    }
  };

  if (from > to) {
    return <div className="range-selector">Invalid rating range</div>;
  }

  return (
    <div className="range-selector" data-comp="RangeSelector">
      {[...Array(to - from + 1)].map((_, index) => (
        <span
          key={index + 1}
          className={`range-selector__point ${
            index + 1 <= value ? "selected" : ""
          } ${!readonly ? "rsp--clickable" : ""}`}
          onClick={() => handleClick(index + 1)}
        >
          {index + 1}
        </span>
      ))}
    </div>
  );
};

export default RangeSelector;
