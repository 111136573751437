import { usePortalSettingSet } from "../../../context/portal-context";
import usePageTitle from "../../../hooks/use-page-title";
import { Accordion } from "react-bootstrap";
import UserAccordListSimple from "../../../components/portal/UserAccordListSimple";
import ProfileBox from "../../../components/portal/ProfileBox";
import AppUserContext from "../../../context/app-user-context";
import { useContext, useEffect, useMemo, useState } from "react";
import Utils from "../../../utils/utils";
import Config from "../../../config/config";
import AppSettingsList from "../../../components/portal/AppSettingsList";
import ApiUser from "../../../modules/api/user";
import ROLE from "../../../modules/role";
import FGDropdownApi from "../../../components/form/FGDropdownApi";

const showLabel = (txt) => {
  const ret = Utils.ifEmptyOrUndefinedThen(txt, Config.CHAR_EMPTY);
  // eslint-disable-next-line eqeqeq
  return ret == "0" ? Config.CHAR_EMPTY : ret;
};

export default function PageProfile() {
  const title = "My Profile";
  usePageTitle(title);
  usePortalSettingSet("label_header", title);

  const user = useContext(AppUserContext);
  const userGroups = useMemo(() => {
    return user.group.split(/\s*,\s*/);
  }, [user]);
  const [myGroup, setMyGroup] = useState<any[]>([]);
  const [myGroupLoading, setMyGroupLoading] = useState(true);
  const [selectedGroup, setSelectedGroup] = useState("");

  useEffect(() => {
    if (userGroups.length > 0) setSelectedGroup(userGroups[0]);
  }, [userGroups]);

  useEffect(() => {
    ApiUser.myPeers(user.referenceUserId, user.role === ROLE.STUDENT ? ROLE.STUDENT : "__").then(students => {
      setMyGroupLoading(false);
      setMyGroup(students);
    });
  }, []);

  return <main className="content-generic content-generic--fluid" data-comp="PageProfile">

    <ProfileBox/>

    <div className="row">

      <div className="col-lg-6">
        <Accordion className="profile-accordion" defaultActiveKey={['0']} alwaysOpen>
          <Accordion.Item eventKey="0" >
            <Accordion.Header>My Contact Detail</Accordion.Header>
            <Accordion.Body>
              <ol className="contact-detail-list">
                <li>
                  <div>Email</div>
                  <div>{user.email}</div>
                </li>
                <li>
                  <div>Programme</div>
                  <div>{showLabel(user.studyProgramName)}</div>
                </li>
                <li>
                  <div>Cohort</div>
                  <div>{showLabel(user.yearName)}</div>
                </li>
                <li>
                  <div>Group</div>
                  <div>{showLabel(user.groupName)}</div>
                </li>
                <li>
                  <div>Job title</div>
                  <div>{showLabel(user.jobDesk)}</div>
                </li>
              </ol>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>

        <Accordion className="profile-accordion" defaultActiveKey={['0']} alwaysOpen>
          <Accordion.Item eventKey="0" >
            <Accordion.Header>App Settings</Accordion.Header>
            <Accordion.Body>
              <AppSettingsList />
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>

      <div className="col-lg-6">
        <Accordion className="profile-accordion" defaultActiveKey={['0']} alwaysOpen>
          <Accordion.Item eventKey="0">
            <Accordion.Header>My Group</Accordion.Header>
            <Accordion.Body>
              {myGroupLoading && <p className="text-center py-3">Loading...</p>}
              {!myGroupLoading && <>
                {userGroups.length > 1 && <FGDropdownApi
                  label="Filter by group"
                  variant="normal"
                  options={userGroups.map(i => ({
                    value: i,
                    label: user.groupHash[i]
                  }))}
                  defaultValue={selectedGroup}
                  onChange={(e) => {
                    setSelectedGroup(e.target.value);
                  }}
                />}
                <UserAccordListSimple data={myGroup} selectedGroup={selectedGroup} />
              </>}
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>

    </div>

  </main>
}
