import { useMemo } from "react";
import { TNotificationItem } from "../../modules/api/message";
import DateTime from "../common/DateTime";
import ModuleNotification from "../../modules/notification";
import Utils from "../../utils/utils";

type TNotificationItemProps = {
  notification: TNotificationItem;
  trimMessage?: boolean;
};

const NotificationItem: React.FC<TNotificationItemProps> = ({ notification, trimMessage }) => {
  const processed = useMemo(() => {
    let { title, date, description } = ModuleNotification.parse(notification);
    if (trimMessage) description = Utils.trimExceedChars(description, 100, "... (more)");

    return {
      title,
      date: <DateTime date={date} format="DD/MM/YY" className={`d-flex justify-content-end ${notification.status === "Unread" ? "notif-unread" : ""}`} />,
      description
    };
  }, [notification]);

  return (
    <div className='bg-white position-relative rounded-3 p-3 pb-2 notification__item'>
      {processed.title}
      <p className='m-0'>
        {processed.description}
      </p>
      {processed.date}
    </div>
  );
};

NotificationItem.defaultProps = {
  trimMessage: false
};

export default NotificationItem;
