import { useContext, useEffect, useState } from "react";
import AppUserContext from "../../context/app-user-context";
import ApiMessage, { TNotificationItem } from "../../modules/api/message";
import NotificationItem from "./NotificationItem";

export default function DashboardNotificationItems() {
  const user = useContext(AppUserContext);
  const [notifications, setNotifications] = useState<TNotificationItem[]>();

  useEffect(() => {
    (async () => {
      const resNotif = await ApiMessage.getNotifications(user.referenceUserId, 0, 5);
      setNotifications(resNotif.data);
    })();
  }, []);

  if (!Array.isArray(notifications)) {
    return <p className="alert alert-info">Loading notification(s)</p>
  }

  if ((Array.isArray(notifications) && notifications.length === 0)) {
    return <p className="alert alert-not-found">Notification not available.</p>
  }

  return <>
    {notifications.map(notification => (
      <NotificationItem
        trimMessage={true}
        key={notification._id}
        notification={notification}
      />
    ))}
  </>;
}
