import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { HiArrowLeft } from 'react-icons/hi';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import PageProgressTabReportPD from './PageProgressTabReportPD';
import { usePortalSettingSet } from "../../../context/portal-context";
import usePageTitle from "../../../hooks/use-page-title";
import AppUserContext from '../../../context/app-user-context';
import ApiSubmission, { TStatisticResult } from '../../../modules/api/submission';
import ModuleDate from '../../../modules/date';
import PDStatistics from '../../../components/portal/PDStatistics';
import ApiTrait from '../../../modules/api/trait';
import LoadingData from '../../../components/portal/LoadingData';
import { ROUTES } from '../../../routes';
import { TGetAllTraitItem } from '../../../modules/api/trait';
import ApiMessage, { TMessageItem } from '../../../modules/api/message';
import TabFeedback from '../../../components/portal/TabFeedback';
import Utils from '../../../utils/utils';
import { scrollTo } from '../../../utils/jquery-helper';

export default function PageMyGroupReportPD() {
  const title = "My Professional Development Report";
  usePageTitle(title);
  usePortalSettingSet("label_header", title);

  const user = useContext(AppUserContext);
  const navigate = useNavigate();
  const { submissionId } = useParams();
  const [reportTitle, setReportTitle] = useState("Professional Development Report");
  const [activeTab, setActiveTab] = useState("");
  const [loading, setLoading] = useState(true);
  const [submission, setSubmission] = useState<any>();
  const [traits, setTraits] = useState<{[key: string]: TGetAllTraitItem}>({});
  const [studentId, setStudentId] = useState("");
  const [messages, setMessages] = useState<TMessageItem[]>([]);
  const [isRefreshingMessages, setIsRefreshingMessages] = useState(false);
  const [refreshMessages, setRefreshMessages] = useState(false);

  // param related to open default tab
  const defaultTab = useMemo(() => {
    const param = Utils.parseQueryString();
    const tab = param["tab"];
    return tab ?? "";
  }, []);
  const refDefaultTab = useRef({ consumed: false });
  const scrollToFeedback = useMemo(() => (Utils.parseQueryString()["feedback"] ?? "") === "1", []);

  // byproduct of `<PDStatistics/>` useful for post-processing (if needed)
  const [statistics, setStatistics] = useState<TStatisticResult>();

  useEffect(() => {
    (async () => {
      const submissionRes = await ApiSubmission.getSubmission(submissionId);

      if (submissionRes.status) {
        setStudentId(submissionRes.data?.owner?._id);

        if (submissionRes?.data?.owner?._id === user.referenceUserId) {
          const traits = await ApiTrait.getAll();

          setTraits(traits.reduce((p, c) => ({ ...p, [c._id]: c }), {}));
          setSubmission(submissionRes.data);
          setReportTitle(`Professional Development Report, ${ModuleDate.getTitleFormat(submissionRes.data.submissionDate.to)}`);
        } else {
          setSubmission(submissionRes.data);
        }
      }

      setLoading(false);
    })();
  }, []);

  useEffect(() => {
    if (loading) return;
    (async () => {
      setIsRefreshingMessages(true);
      const messages = await ApiMessage.getMessagesBySubmission(submissionId);
      setMessages(messages.messages);
      await Utils.sleep(1000);
      setIsRefreshingMessages(false);
    })();
  }, [loading, refreshMessages]);

  const tabbedTraits = useMemo(() => {
    if (loading || !submission && !traits) {
      return [];
    }

    if (!Array.isArray(submission?.traits)) {
      return [];
    }

    const tabbed = submission.traits.filter(trait => {
      return typeof traits[trait.traitId] !== "undefined";
    });

    if (tabbed.length > 0) {
      setTimeout(() => {
        let activeTab = tabbed[0].traitId;

        if (!refDefaultTab.current.consumed && tabbed.some(t => t.traitId === defaultTab)) {
          activeTab = defaultTab;
          refDefaultTab.current.consumed = true;
          if (scrollToFeedback) setTimeout(() => scrollTo(`#feedback-${activeTab}`, 350), 100);
        }

        setActiveTab(activeTab);
      }, 100);
    }

    return tabbed;
  }, [submission, traits, loading]);

  return <>
    <div className="d-flex gap-2 mb-3">
      <h1 className="fs-title">
        <Link to={ROUTES.PORTAL_PROGRESS} className="text-primary p-0 me-2 hide-mobile-down" title="Go back">
          <HiArrowLeft />
        </Link>
        {reportTitle}
      </h1>
    </div>

    <LoadingData show={loading} />

    {!loading && !submission && <p className='alert alert-not-found'>
      Submission not found.
    </p>}

    {!loading && submission && studentId !== user.referenceUserId && <p className='alert alert-not-found'>
      You are not allowed to view this submission.
    </p>}

    {!loading && submission && studentId === user.referenceUserId && <div className="box-default box-form-statistics">
      <PDStatistics studentId={studentId}
        onStatisticsLoaded={(stat) => {
          setStatistics(stat);
        }}
        onTraitClicked={(traitId) => setActiveTab(traitId)}
        selectedStatistics={submission?.traits?.map(trait => trait.traitId) ?? []}
      />
    </div>}

    {!loading && submission && studentId === user.referenceUserId && <div className="box-default bg-white" data-comp="page-progress-pd">
      <Tabs activeKey={activeTab} className='pwl__tabs pt-0' onSelect={(k) => { setActiveTab(k) }}>

        {tabbedTraits.map((data, i) => {
          const trait = traits[data.traitId];

          return <Tab key={data.traitId} eventKey={data.traitId} title={trait.name} className="">
            <PageProgressTabReportPD submission={submission} data={data}
              trait={trait}
              messages={messages} setMessages={setMessages}
              refreshMessages={setRefreshMessages}
              isRefreshingMessages={isRefreshingMessages}
            />
          </Tab>
        })}

      </Tabs>
    </div>}
  </>;
}
