import { isDebugMode } from "../config/config";
import { LABEL_UNKNOWN_TRAIT, MONTH_ARR_SHORT } from "../config/constants";
import { TRawStatisticResult, TStatisticItem } from "./api/submission";
import ApiTrait, { TGetAllTraitItem } from "./api/trait";

/**
 * if `range`: We use `RANGE_FROM` and `RANGE_TO`. submission that is not within
 *             range will be ignored.
 * if `max_months`: We use first submission date to `MAX_MONTHS
 */
type CONVERT_MODE = "range" | "max_months";

const MAX_MONTHS = 12;
const RANGE_FROM = new Date("2023-06-15T00:00:00+0800");
const RANGE_TO = new Date("2024-06-15T00:00:00+0800");

const MicroBench = {
  startDate: -1,
  start() {
    if (!isDebugMode) return;
    this.startDate = Date.now();
  },
  stop() {
    if (!isDebugMode) return;
    console.log(`Statistic converter takes: ${Date.now() - this.startDate}ms`);
  }
};

/**
 * @param index
 * @param trait
 * @param monthIndex ranging from 1 to 12 (NOT zero-based)
 * @param year
 */
function inject(index, trait, monthIndex: number, year: number) {
  trait.goalHistory.splice(index, 0, null);
  trait.monthSubmitted.splice(index, 0, false);
  trait.scale.splice(index, 0, null);
  trait.scaleEndGoal.splice(index, 0, null);
  trait.scaleNextMonth.splice(index, 0, null);

  trait.month.splice(index, 0, MONTH_ARR_SHORT[monthIndex]);
  trait.monthIndex.splice(index, 0, monthIndex);
  trait.year.splice(index, 0, year);
}

export default async function SubmissionStatisticConverter(submissions: TStatisticItem[], mode: CONVERT_MODE = "max_months"): Promise<TRawStatisticResult> {
  // don't remove this
  MicroBench.start();
  const ret: TRawStatisticResult = {
    parsedTraits: []
  };

  const traits = {};
  const submissionsMaxIndex = submissions.length - 1;
  const firstSubmission = submissionsMaxIndex >= 0 ? submissions[0] : undefined;
  const lastSubmission = submissionsMaxIndex > 0 ? submissions[submissionsMaxIndex] : undefined;

  // process nothing when no submissions exists
  if (!firstSubmission) {
    return ret;
  }

  const traitsInfoData = await ApiTrait.getAll();
  const traitsInfo = (() => {
    const data = traitsInfoData.reduce((prev, current) => {
      return {
        ...prev,
        [current._id]: current
      };
    }, {}) as {[key: string]: TGetAllTraitItem};

    return {
      getLabel(traitId: string) {
        const label = data[traitId]?.name;
        return typeof label === "string" ? label : LABEL_UNKNOWN_TRAIT;
      },
      getColor(traitId: string) {
        const label = data[traitId]?.parsedConfig.color;
        return typeof label === "string" ? label : "";
      }
    }
  })();

  submissions.forEach((submission, submissionIndex) => {
    const monthIndex = submission.submissionDate.month;
    const month = MONTH_ARR_SHORT[monthIndex];
    const year = submission.submissionDate.year;

    Array.isArray(submission.traits) && submission.traits.forEach(itemTrait => {
      // skip the trait if current submission for this specific trait is paused
      if (itemTrait.paused) return;

      const {
        traitId, scale, scaleEndGoal, scaleNextMonth, scaleEndGoalChangeReason
      } = itemTrait;
      const trait = traits[traitId] ?? {};

      trait.traitId = traitId;

      if (typeof trait.name != "string") trait.name = traitsInfo.getLabel(traitId);
      if (typeof trait.color != "string") trait.color = traitsInfo.getColor(traitId);
      if (typeof trait.goal != "number") trait.goal = 0;
      if (typeof trait.goalChangeReason != "string") trait.goalChangeReason = "";

      if (!Array.isArray(trait.monthSubmitted)) trait.monthSubmitted = [];
      if (!Array.isArray(trait.monthIndex)) trait.monthIndex = [];
      if (!Array.isArray(trait.month)) trait.month = [];
      if (!Array.isArray(trait.year)) trait.year = [];
      if (!Array.isArray(trait.goalHistory)) trait.goalHistory = [];
      if (!Array.isArray(trait.scale)) trait.scale = [];
      if (!Array.isArray(trait.scaleNextMonth)) trait.scaleNextMonth = [];
      if (!Array.isArray(trait.scaleEndGoal)) trait.scaleEndGoal = [];

      if (scaleEndGoal > 0) {
        trait.goal = scaleEndGoal;
        trait.goalChangeReason = scaleEndGoalChangeReason;
      }

      trait.monthSubmitted.push(true);
      trait.monthIndex.push(monthIndex);
      trait.month.push(month);
      trait.year.push(year);
      trait.scale.push(scale);
      trait.scaleNextMonth.push(scaleNextMonth);
      trait.scaleEndGoal.push(null);
      trait.goalHistory.push(scaleEndGoal);

      traits[traitId] = trait;
    });
  });

  const markNext = (() => {
    let cb;

    return {
      store(_cb) {
        cb = _cb;
      },
      execute() {
        if (typeof cb === "function") {
          cb();
        }

        cb = undefined;
      }
    };
  })();

  function fillMissing(trait) {
    const months = trait.month;

    if (!Array.isArray(months)) {
      return;
    }

    // stop the recursive call once `MAX_MONTHS` reached
    if (months.length === 0 || months.length >= MAX_MONTHS) {
      return;
    }

    if (
      trait.monthIndex[0] !== firstSubmission.submissionDate.month ||
      trait.year[0] !== firstSubmission.submissionDate.year
    ) {
      inject(0, trait, firstSubmission.submissionDate.month, firstSubmission.submissionDate.year);
      fillMissing(trait);
      return;
    }

    let from = 0;
    let to = trait.monthIndex.length;

    if (MAX_MONTHS > to) {
      to = MAX_MONTHS;
    }

    for (; from < to - 1; from += 1) {
      const nextIndex = from + 1;
      const currMonth = trait.monthIndex[from];
      const currYear = trait.year[from];
      const nextMonth = trait.monthIndex[nextIndex];
      const nextYear = trait.year[nextIndex];

      if (nextMonth !== currMonth % 12 + 1 || nextYear !== currYear + Math.floor(currMonth / 12)) {
        let nextMonthIndex = currMonth % 12 + 1;
        let nextYearValue = currYear + Math.floor(currMonth / 12);

        inject(nextIndex, trait, nextMonthIndex, nextYearValue);
        fillMissing(trait);
        break;
      }
    }
  }

  ret.parsedTraits = Object.keys(traits).map(traitId => {
    const trait = traits[traitId];

    // shift the "next month scale"
    if (Array.isArray(trait.scaleNextMonth) && trait.scaleNextMonth.length > 0) {
      trait.scaleNextMonth.unshift(null);
      trait.scaleNextMonth.pop();
    }

    // console.log(`BeforeConvert(${trait.name})`, JSON.parse(JSON.stringify(trait)));

    // fill-in missing info
    fillMissing(trait);

    // set last index to `trait.goal`
    if (
      trait.goal >= 0 && Array.isArray(trait.scaleEndGoal) &&
      trait.scaleEndGoal.length > 0
    ) {
      const endMonthIndex = trait.month.indexOf(MONTH_ARR_SHORT[lastSubmission.submissionDate.month]);
      trait.scaleEndGoal[endMonthIndex] = trait.goal;
      trait.goalMaxLine = Array.from(trait.scaleEndGoal);
      trait.goalMaxLine[endMonthIndex] = 10; // 10 is maximum score for Trait
    }

    // console.log(`AfterConvert(${trait.name})`, JSON.parse(JSON.stringify(trait)));

    return trait;
  });

  MicroBench.stop();
  return ret;
}
