import { Link } from "react-router-dom";
import { HiPlus } from "react-icons/hi";
import ContentItem from "./ContentItem";
import classNames from "classnames";

interface ContentsProps {
  title: string;
  actionText: string;
  oneColumn?: boolean;
  data?: any;
  linkTo: string;
  className?: string;
}

export default function Contents({
  className,
  title,
  actionText,
  oneColumn = false,
  data,
  linkTo
}: ContentsProps) {
  return (
    <div className={`content col-lg-6 mb-3 ${className ?? ""}`} data-comp="Contents">
      <h1 className="fs-title">{title}</h1>
      <Link to={linkTo} className="content__link">
        <div className="content__action">
          <div className="d-flex justify-content-between text-white">
            {actionText}
            <span className="content__link-icon">
              <HiPlus />
            </span>
          </div>
        </div>
      </Link>
      <div className={classNames([
        "content__items",
        {"content__items--one-column": oneColumn}
      ])}>
        {data?.map((item: any) => (
          <ContentItem
            key={item.id}
            title={item.title}
            description={item?.desc}
            small={oneColumn}
            link={item.link}
          />
        ))}
      </div>
    </div>
  );
}
