export type TParsedTraitConfig = {
  description: string;
  // color for the chart
  color: string;
  // scale 1-10
  scales: Array<{
    level: number;
    color: string;
    title: string;
    description: string;
  }>;
  // parsed description for popup (possibly merging same title+description)
  legends: Array<{
    color: string;
    title: string;
    description: string;
  }>;
};

const sampleTextConfig = JSON.stringify({
  "description": "Trait's description",
  "color": "#FC8F00",
  "scales": {
    "1-4": {
      "color": "#F04C50",
      "title": "Beginner",
      "description": `Demonstrated early developments of professional traits, with some inconsistencies in application, and sometimes lacking in confidence.`
    },
    "5-7": {
      "color": "#FDD500",
      "title": "Competent",
      "description": `Demonstrated increasing competence in professional traits with steady confidence and consistent accomplishments, with the ability to perform and adapt to most situations.`
    },
    "8-10": {
      "color": "#6CE74E",
      "title": "Proficient",
      "description": `"Demonstrate consistent and outstanding competency in professional traits at work. Highly adaptable whilst maintaining high standards with ease.   A person others look up to as an exemplary leader.`
    }
  }
});

const defaultConfig = parse(sampleTextConfig);

/**
 * Scale of trait is exactly 1 to 10.
 * If the `config` is empty, then we fallback to default trait config.
 */
function parse(config: string = "") {
  let parsedConfig;

  try {
    parsedConfig = JSON.parse(config);

    if (!parsedConfig.scales) {
      throw new Error("");
    }
  } catch (err) {
    return defaultConfig;
  }

  const scales = parsedConfig.scales;
  const parsed: TParsedTraitConfig = {
    color: parsedConfig.color ?? "#FC8F00",
    description: parsedConfig.description ?? "",
    scales: [],
    legends: []
  };
  const rScale = /^\d+$/;
  const rScaleRange = /^\d+\-\d+$/;

  Object.keys(scales).forEach(scale => {
    const value = scales[scale];

    if (rScale.test(scale)) {
      // just "push" it
      parsed.scales.push({
        level: parseInt(scale),
        ...value
      });
    } else if (rScaleRange.test(scale)) {
      // loop over it
      const split = scale.split("-");
      let from = parseInt(split[0]);
      let to = parseInt(split[1]);

      for (; from <= to; from += 1) {
        parsed.scales.push({
          level: from,
          ...value
        });
      }
    }
  });

  // sort `parsed.scales` first based on level's order
  parsed.scales.sort((a, b) => {
    return a["level"] - b["level"];
  });

  // then loop thru `parsed.scales` to construct the legends
  let lastIndex = parsed.scales.length - 1;
  parsed.scales.forEach((item, currIndex) => {
    const nextIndex = currIndex + 1;
    const currItem = parsed.scales[currIndex];
    const nextItem = parsed.scales[nextIndex];

    if (nextIndex <= lastIndex) {
      if (
        currItem.title === nextItem.title &&
        currItem.description === nextItem.description
      ) {
        // skip it
      } else {
        // else push it
        parsed.legends.push(currItem);
      }
    }

    if (nextIndex > lastIndex && currIndex === lastIndex) {
      parsed.legends.push(currItem);
    }
  });

  return parsed;
}

const TraitConfig = {
  parse,
  defaultConfig,
  sampleTextConfig
};

export default TraitConfig;
