import classNames from "classnames";

/**
 * FG means Form Generic.
 *
 * They call this "dumb component" since the purpose is for presentational, not
 * intended to be having its own states.
 * @ref https://www.digitalocean.com/community/tutorials/react-smart-dumb-components
 */
export default function FGField(props: {
  name?: string;
  label?: string|JSX.Element|JSX.Element[];
  desc?: string|JSX.Element|JSX.Element[];
  children?: JSX.Element|JSX.Element[];
  variant?: "normal" | "inset";
  comp?: string;
  className?: string;
}) {
  return <div className={classNames([
    "fg__field",
    {"fg__field--normal": props.variant === "normal"}
  ].concat([props.className]))} data-comp={props.comp ?? "FGField"}>
    {props.label && <label htmlFor={props.name}>{props.label}</label>}
    {(
      (typeof props.desc === "string" && props.desc.length > 0) ||
      (typeof props.desc === "object")
    ) &&
    <div className="fg__field-desc">
      {props.desc}
    </div>}
    {props.children}
  </div>;
}
