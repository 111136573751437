import { HiOutlineDocumentDuplicate, HiPencil } from "react-icons/hi";
import { Link, useNavigate } from "react-router-dom";
import { TSubmissionListItem } from "../../modules/api/submission";
import { useEffect, useMemo, useState } from "react";
import { SUBMISSION_STATUS_DRAFT, SUBMISSION_STATUS_PUBLISH, SUBMISSION_WL } from "../../config/constants";
import ModuleDate from "../../modules/date";
import { ROUTES } from "../../routes";

interface ReportItemProps {
  isReportPage?: boolean;
  isSupervisor?: boolean;
  submission?: TSubmissionListItem;

  // these below are legacy props, should be ignored/removed once API implemented
  title?: string;
  status?: string;
  to?: string;
  editDeadline?: string;
}

const ReportItem = (props: ReportItemProps) => {
  const navigate = useNavigate();

  const [isEditReport, setIsEditReport] = useState(false);
  const [url, setUrl] = useState(useMemo(() => props?.to ?? "", []));
  const [status, setStatus] = useState(useMemo(() => props?.status ?? "", []));
  const [title, setTitle] = useState(useMemo(() => props?.title ?? "", []));
  const [description, setDescription] = useState(useMemo(() => props?.editDeadline ?? "", []));

  useEffect(() => {
    if (!props.submission) return;

    const { _id, status, type, from, to, modifiedDate } = props.submission;
    const dateDue = ModuleDate.getDefaultFormat(to);
    const dateModified = ModuleDate.getDefaultFormat(modifiedDate);

    setStatus(status === SUBMISSION_STATUS_DRAFT
      ? status.toLowerCase()
      : type === SUBMISSION_WL ? "danger" : "success"
    );

    setTitle(type === SUBMISSION_WL
      ? `Workplace Learning Report, ` + dateDue
      : `Professional Development Report, ` + dateDue
    );

    let isEditReport = (status === SUBMISSION_STATUS_DRAFT) ||
      (props.isReportPage && status === SUBMISSION_STATUS_PUBLISH && ModuleDate.isBeforeSubmissionDue(to));

    setIsEditReport(isEditReport);

    if (isEditReport) {
      setDescription(`Edit before ${dateDue}`);
      setUrl(type === SUBMISSION_WL
        ? ROUTES.PORTAL_REPORT_WL + `/edit/${_id}`
        : ROUTES.PORTAL_REPORT_PD + `/edit/${_id}`
      );
    } else {
      setDescription(`Submitted on ${dateModified}`);

      if (props.isSupervisor) {
        setUrl(type === SUBMISSION_WL
          ? ROUTES.PORTAL_MY_GROUP_REPORT_WL + `/${_id}`
          : ROUTES.PORTAL_MY_GROUP_REPORT_PD + `/${_id}`);
      } else {
        setUrl(type === SUBMISSION_WL
          ? ROUTES.PORTAL_PROGRESS_WL + `/${_id}`
          : ROUTES.PORTAL_PROGRESS_PD + `/${_id}`);
      }
    }
  }, [props.submission]);

  return <div className="d-flex justify-content-between align-items-center flex-fill gap-3 past-report" onClick={() => navigate(url)}>
    <div className="d-flex gap-3 flex-grow-1 align-items-center">
      <div>{status === "draft"
        ? <HiPencil className={`fs-1 report__icon--${status}`} />
        : <HiOutlineDocumentDuplicate className={`fs-1 report__icon--${status}`} />}
      </div>

      <div className="d-flex flex-column fs-5 report__desc">
        <div>
          {title}
          <span className='text-primary ms-2 fw-medium fs-5'>
            {status === "draft" && "[Draft]"}
          </span>
        </div>
        <span className="fs-6">{description}</span>
      </div>
    </div>

    {isEditReport && <Link to={url} type="button" className="d-none d-md-block btn btn-outline-primary report__button-details">
      Edit Report
    </Link>}

    {!isEditReport && <Link to={url} type="button" className="d-none d-md-block btn btn-primary report__button-details">
      View Details
    </Link>}
  </div>;
};

ReportItem.defaultProps = {
  isSupervisor: false,
  isReportPage: false
};

export default ReportItem;
