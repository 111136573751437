export function isHtmlEmpty(str: string) {
  let $div = $("<div/>").html(str);
  let isEmpty = $div.text().trim() === "";
  $div.remove();
  $div = null;
  return isEmpty;
}

export function nl2br(str: string) {
  let $data = $("<div/>");
  $data.text(str);
  let ret = $data.html().replace(/\n/g, "<br/>");
  $data.remove();
  $data = null;
  return ret;
}

export async function scrollTo(selector: string, duration = 0): Promise<void> {
  return new Promise((res, rej) => {
    try {
      $("html, body").stop(true, true).animate({
        scrollTop: $(selector).offset().top
      }, duration, () => {
        res();
      });
    } catch (ignore) {
      res();
    }
  });
}
