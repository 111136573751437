import ApiGenericData, { TGenericItem } from "./generic-data";

export type TYearItem = TGenericItem & {};

const ApiYear = {
  pagingSearch: ApiGenericData.pagingSearch("Year"),
  add: ApiGenericData.add<Omit<TYearItem, "_id">>("Year"),
  get: ApiGenericData.get,
  update: ApiGenericData.update<Omit<TYearItem, "_id">>("Year"),
};

export default ApiYear;
