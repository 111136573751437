import { useEffect, useState } from "react";
import ApiTrait, { TTraitItem } from "../../modules/api/trait";

LabelTrait.defaultProps = {
  prefix: "Trait: "
};

export default function LabelTrait(props: { traitId?: string; prefix?: string; }) {
  const [trait, setTrait] = useState<TTraitItem>();

  useEffect(() => {
    if (!props.traitId) return;
    ApiTrait.getCached(props.traitId).then(res => {
      if (res.status) setTrait(res.data);
    })
  }, [props.traitId]);

  return <>{trait && <>{props.prefix} {trait.name}</>}</>;
}
