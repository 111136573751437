import { Link, useNavigate } from "react-router-dom";
import UserImage from "./UserImage";
import { HiPencil } from "react-icons/hi";
import { useContext } from "react";
import AppUserContext from "../../context/app-user-context";
import { ROUTES } from "../../routes";
import classNames from "classnames";
import { showLabelInfo } from "../../config/methods";
import ROLE from "../../modules/role";

export type TProfileBoxProps = {
  className?: string;
  showEditIcon?: boolean;
};

ProfileBox.defaultProps = {
  showEditIcon: true
};

export default function ProfileBox(props: TProfileBoxProps) {
  const navigate = useNavigate();
  const user = useContext(AppUserContext);

  return <div className={classNames(["profile-box"].concat(props.className))}>
    <div className="pb__title">
      <h1 className="fs-title">Profile</h1>
    </div>
    <div className="pb__inner">
      <div className="pb__desc">
        <div className={`pb__img ${props.showEditIcon ? "" : "pb__img-no-edit"}`}>
          <button title={props.showEditIcon ? "Click to change profile picture on Edit Profile page" : ""}
            onClick={(e) => {
              e.preventDefault();
              navigate(ROUTES.PORTAL_PROFILE_EDIT);
            }}
          >
            <UserImage url={user.avatar} />
            <div className={`pb__img-icon ${props.showEditIcon ? "" : "d-none"}`}>
              <HiPencil/>
            </div>
          </button>
        </div>
        <div className="pb__prop">
          <ol>
            <li>
              <span className="pb__prop-title">Name</span>
              <span className="pb__prop-value" title={user.name}>{user.name}</span>
            </li>
            <li>
              <span className="pb__prop-title">Email</span>
              <span className="pb__prop-value" title={user.email}>{user.email}</span>
            </li>
            {user.role === ROLE.STUDENT && <li>
              <span className="pb__prop-title">Student ID</span>
              <span className="pb__prop-value">{showLabelInfo(user.studentId)}</span>
            </li>}
          </ol>
        </div>
      </div>
      <div className="pb__action">
        <Link to={ROUTES.PORTAL_PROFILE_CHANGE_PASSWORD} className="btn btn-primary">Change Password</Link>
        {" "}
        <Link to={ROUTES.PORTAL_PROFILE_EDIT} className="btn btn-primary">Edit Profile</Link>
      </div>
    </div>
  </div>;
}
