import { motion, AnimatePresence } from "framer-motion";

export interface IUILoadingBlankProps {
  show: boolean;
}

export default function UILoadingBlank(props: IUILoadingBlankProps) {
  return <AnimatePresence>
    {props.show && (
    <motion.div
      initial={{ opacity: 1 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="loading-blank-ui"
    />)}
  </AnimatePresence>
}
