import { useMemo, useState } from "react";
import usePageTitle from "../../hooks/use-page-title";
import RangeSelector from '../../components/form/RangeSelector';
import Timeline from '../../components/form/TimelineProgress';
import DateRange from '../../components/form/DateRange';
import Utils from "../../utils/utils";
import PDChart from '../../components/portal/PDChart';
import TraitProgressToggle from '../../components/common/TraitProgressToggle';
import ModulePopup from "../../modules/popup/popup";
import { HiExclamationCircle, HiPlus } from "react-icons/hi";
import { MONTH_ARR_SHORT } from "../../config/constants";
import TraitScale from "../../components/portal/TraitScale";
import { TGetAllTraitItem } from "../../modules/api/trait";
import TraitConfig from "../../config/trait-config";

Section.defaultProps = {
  title: "Section Title",
  children: <>...</>
};

function Section(props: {title: string; children: any}) {
  return <div className="mt-5">
    <h3>{props.title}</h3>
    {props.children}
  </div>
}

export default function PageReferenceComponent() {
  const title = "Page Reference (Components)";
  usePageTitle(title);

  const [selectedScale, setSelectedScale] = useState<number>(3);

  const handleSelectedScaleChange = (value: number) => {
    setSelectedScale(value);
  };

  const [traitProgress, setTraitProgress] = useState(true);

  const [sampleTraitValue, setSampleTraitValue] = useState(0);
  const sampleTrait = useMemo<TGetAllTraitItem>(() => {
    const config = "{\n  \"color\": \"#3D85F2\",\n  \"description\": \"Refers to the ability to adjust positively to changing circumstances and environments. \",\n  \"scales\": {\n    \"1-4\": {\n      \"color\": \"#F04C50\",\n      \"title\": \"Beginner\",\n      \"description\": \"It is not easy for me to quickly adjust to changing circumstances and environments.  I am open to change, but someimtes struggle to cope with uncertainty and ambiguity. I can be become overwhelmed by changes.   I  struggle to think creatively and come up with innovative solutions to problems, preferring to rely on tried and tested methods.\"\n    },\n    \"5-7\": {\n      \"color\": \"#FDD500\",\n      \"title\": \"Competent\",\n      \"description\": \"I am able to adapt and adjust my approach and mindet to fit different situations and are comfortable with change. I am able to learn new skills and technologies as necessary.  I am skilled at managing uncertainty and ambiguity and can think creatively to solve problems. I am able to work effectively in teams and can adapt their communication style to different team dynamics.\"\n    },\n    \"8-10\": {\n      \"color\": \"#6CE74E\",\n      \"title\": \"Proficient\",\n      \"description\": \"I am able to learn, unlearn and relearn, embracing change and adapting to dynamic demands at the workplace.   I have high situational awareness of the state of my mind (mindfulness), the work environment and able to perform to the highest standards safely.  I anticipate change and proactively adapt to it.  I am able to remain calm and focused under pressure, making them effective in high-stress environments.\"\n    }\n  }\n}";
    const traitItem = {
      "studyProgramId": null,
      "config": config,
      "_id": "6450858468076e25d07bb9b8",
      "name": "Adaptability",
      "studyProgramName": "",
      "description": "",
      "parsedConfig": TraitConfig.parse(config)
    };

    return traitItem;
  }, []);

  return <main className="content-generic content-generic--medium">
    <h1>{title}</h1>
    <p>This is a test page. We can use this as reference.</p>

    <Section title="Buttons">
      <button className="btn btn-primary">Primary</button> {" "}
      <button className="btn btn-outline-primary">Primary Outline</button> {" "}
      <button className="btn btn-secondary">Secondary</button> {" "}
      <button className="btn btn-outline-secondary">Secondary Outline</button> {" "}
      <button className="btn btn-dark">Dark</button> {" "}
      <button className="btn btn-outline-dark">Dark Outline</button> {" "}
    </Section>

    <Section title="Buttons with Icons">
      <button className="btn btn-icon btn-primary">Primary <HiExclamationCircle/></button> {" "}
      <button className="btn btn-icon btn-outline-primary"><HiExclamationCircle/> Primary</button> {" "}
      <button className="btn btn-icon btn-secondary"><HiPlus/> Secondary</button> {" "}
      <button className="btn btn-icon btn-outline-secondary"><HiPlus/> Secondary</button> {" "}
    </Section>

    <Section title="Alert">
      <button className="btn btn-primary"
        onClick={() => ModulePopup.showAlert({
          onOK: () => alert("You clicked OK!"),
          title: "Hello there!",
          description: <>
            <p>This is the description.</p>
            <p>We can put any valid HTML/React component here.</p>
          </>
        })}
      >
        Open Alert (Simple)
      </button>
    </Section>

    <Section title="Alert with Icon">
      <button className="btn btn-primary"
        onClick={() => ModulePopup.showAlert({
          onOK: () => alert("You clicked OK!"),
          title: <>
            <HiExclamationCircle/>
            Warning!
          </>,
          description: <>
            <p>This is the description.</p>
            <p>We can put any valid HTML/React component here.</p>
          </>
        })}
      >
        Open Alert (Simple)
      </button>
    </Section>

    <Section title="Alert Confirmation">
      <button className="btn btn-primary"
        onClick={() => ModulePopup.showAlertConfirm({
          onNo: () => alert("You clicked no!"),
          onYes: () => alert("You clicked yes!"),
          title: "Confirmation Title",
          description: <>
            <p>This is the description.</p>
            <p>We can put any valid HTML/React component here.</p>
          </>
        })}
      >
        Open Alert Confirmation
      </button>
    </Section>

    <Section title="Alert Prompt">
      <button className="btn btn-primary"
        onClick={() => ModulePopup.showAlertPrompt({
          onNo: () => alert("You clicked no!"),
          onYes: (words) => alert(`You clicked yes with this value: ${words}`),
          title: "Show Word(s) Prompt",
          description: <p>Please enter any word(s):</p>
        })}
      >
        Open Alert Prompt
      </button>
    </Section>

    <Section title="Deep Alert Prompt">
      <button className="btn btn-primary"
        onClick={() => ModulePopup.showAlert({
          title: "Begin Survey",
          description: "Just click stuff!",
          labelOK: "START",
          onOK: async () => {
            // wait current modal to close first
            await Utils.sleep(350);
            ModulePopup.showAlertPrompt({
              title: "Confirm Age",
              description: "Please enter your age:",
              onYes: async (age) => {
                if (/\d+/.test(age) && parseInt(age) > 0) {
                  // wait current modal to close first
                  await Utils.sleep(350);
                  ModulePopup.showAlertConfirm({
                    title: "Proceed",
                    description: "View your age?",
                    onYes: () => {
                      alert(`Your age is: ${age}`);
                    },
                    onNo: () => {
                      alert(`You decided not to proceed`)
                    }
                  })
                } else {
                  alert(`You entered wrong format!`);
                }
              }
            })
          }
        })}
      >
        Open Deep Alert
      </button>
    </Section>

    <Section title="Trait Scale">
      <TraitScale
        value={sampleTraitValue}
        onChange={(e) => setSampleTraitValue(e.target.value)}
        trait={sampleTrait}
      />
    </Section>

    <Section title="Trait Scale (Read only)">
      <TraitScale
        readonly
        value={5}
        trait={sampleTrait}
      />
    </Section>

    <Section title="Trait Scale (Disabled)">
      <TraitScale
        disabled
        value={8}
        trait={sampleTrait}
      />
    </Section>

    <Section title="Scale Range (Deprecated)">
      <RangeSelector
        from={1}
        to={5}
        selected={selectedScale}
        onSelect={handleSelectedScaleChange}
      />
    </Section>

    <Section title="Scale Range Read Only (Deprecated)">
      <RangeSelector
        from={1}
        to={5}
        selected={selectedScale}
        onSelect={handleSelectedScaleChange}
        readonly
      />
    </Section>

    <Section title="Timeline (Deprecated)">
      <Timeline
        data={[
          { month: 'Jan', value: 1 , active: true},
          { month: 'Feb', value: 2 , active: true},
          { month: 'Mar', value: 4 , active: false, current: true},
          { month: 'Apr', value: 0 , active: false},
          { month: 'May', value: 0 , active: false},
          { month: 'Jun', value: 0 , active: false},
          { month: 'Jul', value: 0 , active: false},
          { month: 'Aug', value: 0 , active: false},
          { month: 'Sep', value: 0 , active: false},
          { month: 'Okt', value: 0 , active: false},
          { month: 'Nov', value: 0 , active: false},
          { month: 'Dec', value: 0 , active: false},
        ]}
      />
    </Section>

    <Section title="Date Range">
      <DateRange />
    </Section>

    <Section title="Chart">
      <PDChart
        color="#FF0051"
        labelX={MONTH_ARR_SHORT.slice(1, 6)}
        scale={[1, 3, 3, 4, 7]}
        scaleNextMonth={[null, 3, 3, 5, 7]}
        goal={[null, null, null, null, 8]}
        goalMaxLine={[null, null, null, null, 10]}
      />
    </Section>

    <Section title="Trait Progress Toggle">
      <TraitProgressToggle checked={traitProgress} onChange={(e) => setTraitProgress(e.target.checked)} />
    </Section>
  </main>
}
