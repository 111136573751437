import axios from "axios";
import { apiUrl, getAuthHeader } from "./base";

export type TMailTemplateItem = {
  "isAllowCustom": boolean;
  "_id": string;
  "name": string;
  "token": string;
  "subject": string;
  "message": string;
  "cc": string;
  "bcc": string;
  "createdUserId": string;
  "createdDate": string;
  "modifiedUserId": string;
  "modifiedDate": string;
  "__v": number;
};

async function getAllTemplates(): Promise<TMailTemplateItem[]> {
  let data = [];

  try {
    const req = await axios.get(apiUrl("/apimailtemplate"), await getAuthHeader());
    if (req.data.result !== "OK") throw new Error(req.data.message ?? "Error getting list of template");
    data = req.data.data;
  } catch (ignore) {};

  return data;
}

type TAddParam = {
  name: string;
  subject: string;
  message: string;
  cc: string;
  bcc: string;
  createdUserId: string;
};

async function add(formData: TAddParam): Promise<{ status: boolean; message: string; }> {
  try {
    const req = await axios.post(apiUrl("/apimailtemplate"), formData, await getAuthHeader());
    if (req.data.result !== "OK") throw new Error(req.data.data ?? "Unable to add email template");
    return {
      status: true,
      message: req.data.data
    };
  } catch (err) {
    return {
      status: false,
      message: err.message
    };
  }
}

async function remove(mailId, userId): Promise<{ status: boolean; message: string; }> {
  try {
    const req = await axios.delete(apiUrl(`/apimailtemplate/${mailId}`), {
      ...await getAuthHeader(),
      data: {
        userId
      }
    });
    if (req.data.result !== "OK") throw new Error(req.data.message ?? "Unable to delete email template");
    return {
      status: true,
      message: req.data.message
    };
  } catch (err) {
    return {
      status: false,
      message: err.message
    };
  }
}

type TUpdateParam = Omit<TAddParam, "createdUserId"> & {
  modifiedUserId: string;
};

async function update(mailId: string, formData: TUpdateParam) {
  try {
    const req = await axios.patch(apiUrl(`/apimailtemplate/${mailId}`), formData, await getAuthHeader());
    if (req.data.result !== "OK") throw new Error(req.data.message ?? "Unable to update email template");
    return {
      status: true,
      message: req.data.data
    };
  } catch (err) {
    return {
      status: false,
      message: err.message
    };
  }
}

async function get<T = any>(mailId: string): Promise<{ status: boolean; message: string; data: T; }> {
  try {
    const req = await axios.get(apiUrl(`/apimailtemplate/${mailId}`), {
      ...await getAuthHeader()
    });
    if (req.data.result !== "OK" || req.data.data === null) throw new Error(req.data.message ?? "Unable to get email template");
    return {
      status: true,
      message: req.data.message,
      data: req.data.data
    };
  } catch (err) {
    return {
      status: false,
      message: err.message,
      data: null
    };
  }
}

const ApiMailTemplate = {
  add,
  remove,
  update,
  get,
  getAllTemplates
};

export default ApiMailTemplate;
