import { LABEL_ANONYMOUS_USER, SUBMISSION_PD, SUBMISSION_WL } from "../config/constants";
import { ROUTES } from "../routes";
import { TNotificationItem } from "./api/message";
import ModuleDate from "./date";

function parse(notification: TNotificationItem) {
  const type = notification.messageType;
  const feedbackSent = notification.feedbackSent;
  const isFeedback = type === "INDUSTRY SUPERVISOR" || type === "STUDENT" || type === "SIT SUPERVISOR";
  const isReminder = type === "Reminder";
  const isForSupervisor = type === "First Time Submit" && ["object", "boolean"].includes(typeof feedbackSent);
  const submissionType = notification?.submissionType ?? "";
  const submissionId = notification?.submissionId ?? "";
  const traitId = notification?.traitId ?? "";

  /** This is mainly for when `isForSupervisor` --> `true` */
  const feedbackDetail = {
    traitCount: 0,
    traitFeedbackGiven: 0,
  };

  const title = isReminder
    ? "Report submission reminder"
    : notification.anonymous ? LABEL_ANONYMOUS_USER : notification.senderName;

  const avatar = isReminder
    ? "/assets/images/icon/icon-report.png"
    : notification.anonymous // if anon user, prohibits to display the avatar
      ? ""
      : notification.senderAvatar;

  let description = notification.message;

  if (type === "Invite Peer" && !notification.message) {
    description = `Would you write me a feedback, please?`;
  }

  let submissionBaseUrl = submissionType === SUBMISSION_WL ? ROUTES.PORTAL_PROGRESS_WL : ROUTES.PORTAL_PROGRESS_PD;

  let date = notification.createdDate;

  if (isReminder) {
    date = notification.modifiedDate;
  }

  if (isForSupervisor) {
    if (submissionType === SUBMISSION_PD && typeof notification.feedbackSent === "object") {
      feedbackDetail.traitCount = Object.keys(notification.feedbackSent as any).length;
      feedbackDetail.traitFeedbackGiven = Object.values(notification.feedbackSent as any).reduce((pv: number, cv: boolean) => {
        return pv + (cv === true ? 1 : 0);
      }, 0) as number;
    }

    description = `${notification.senderName} submitted new${submissionType ? ` ${submissionType}` : ``} report for ${ModuleDate.getDefaultFormat(notification.to)}.`;

    submissionBaseUrl = submissionType === SUBMISSION_WL ? ROUTES.PORTAL_MY_GROUP_REPORT_WL : ROUTES.PORTAL_MY_GROUP_REPORT_PD;
  }

  return {
    type,
    feedbackSent,
    isFeedback,
    isReminder,
    isForSupervisor,
    title,
    avatar,
    description,
    date,
    submissionType,
    submissionId,
    traitId,
    feedbackDetail,
    submissionBaseUrl,
  };
}

const ModuleNotification = {
  parse,
};

export default ModuleNotification;
