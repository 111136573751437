import ToggleSwitch from "../form/ToggleSwitch";
import FGField from "./FGField";

/** @see `<FGField/>` */
export default function FGToggle(props: JSX.IntrinsicElements["input"] & {
  label?: string|JSX.Element|JSX.Element[];
  desc?: string|JSX.Element|JSX.Element[];
}) {
  return <FGField {...props} comp="FGToggle">
    <ToggleSwitch {...props} id={props.name}/>
  </FGField>
}
