import { useState } from "react";
import { MdCancel, MdSave } from "react-icons/md";
import ToggleSwitch from "../../components/form/ToggleSwitch";
import usePageTitle from "../../hooks/use-page-title";
import Notifier from "../../modules/notifier/notifier";
import FGRichTextEditor from "../../components/common/FGRichTextEditor";
import FGDatePicker from "../../components/common/FGDatePicker";
import FGInput from "../../components/dumb/FGInput";
import FGTextarea from "../../components/dumb/FGTextarea";
import FGDropdown from "../../components/dumb/FGDropdown";
import FGCheckbox from "../../components/dumb/FGCheckbox";
import FGDropdownApi from "../../components/form/FGDropdownApi";

export default function PageReferenceForm() {
  usePageTitle("Page Reference (Form)");

  const [def, setDef] = useState("Default <strong>value</strong>...");
  return <>

    <main className="form-generic content-generic content-generic--medium">
      <h1>Page Reference (Form)</h1>
      <p>This is a test page. We can use this as reference.</p>
      <form onSubmit={(e) => e.preventDefault()}>
        <h3>Variant: Inset</h3>

        <FGInput type="text" name="ref-input-text-1" id="ref-input-text-1"
          label={<>This is <code>input[text]</code> field</>}
        />

        <FGInput type="text" name="ref-input-text-2" id="ref-input-text-2"
          label={<>This is <code>input[text]</code> field with description</>}
          desc={<>This is additional (optional) description about <strong>current field</strong>.</>}
        />

        <FGTextarea
          label={<>This is <code>textarea</code> field</>}
          name="ref-textarea-1"
          id="ref-textarea-1"
          defaultValue={"Default value..."}
        />

        <FGTextarea
          label={<>This is <code>textarea</code> field with description</>}
          desc={<>This is additional (optional) description about <strong>current field</strong>.</>}
          name="ref-textarea-2"
          id="ref-textarea-2"
          defaultValue={"Default value..."}
        />

        <FGRichTextEditor
          label={<>This is Rich Text Editor field</>}
          desc="This is additional description about current field."
          name="ref-rte-1"
          id="ref-rte-1"
          onChange={(e) => console.log(e)}
          value={def}
        />

        <FGDatePicker
          label={<>This is Date Picker field</>}
          desc="This is additional description about current field."
          onChange={(e) => console.log(e)}
        />

        <div className="fg__field">
          <label htmlFor="toggle-switch">Toggle Switch Checkbox</label>
          <div className="fg__field-desc">
            Should this enabled? Click to toggle.
          </div>
          <ToggleSwitch name="toggle-switch" id="toggle-switch" onChange={(e) => Notifier.info(`Clicked! ${e.target.checked}`)} />
        </div>

        <div className="fg__field">
          <label>Custom Input Checbox</label>
          <div className="fg__field-desc">
            Please select some:
          </div>
          <FGCheckbox label="Option 1" name="checkbox-1" id="checkbox-1" onChange={(e) => Notifier.info(`Clicked! ${e.target.checked}`)} />
          <FGCheckbox label="Option 2" name="checkbox-2" id="checkbox-2" onChange={(e) => Notifier.info(`Clicked! ${e.target.checked}`)} />
          <FGCheckbox label="Option 3" name="checkbox-3" id="checkbox-3" onChange={(e) => Notifier.info(`Clicked! ${e.target.checked}`)} />
        </div>

        <div className="fg__field">
          <label htmlFor="text">Custom Input Radio</label>
          <div className="fg__field-desc">
            Please choose one of these options.
          </div>
          <label className="radio">
            Option 1
            <input type="radio" name="radio" />
            <span className="checkmark"></span>
          </label>
          <label className="radio">
            Option 2
            <input type="radio" name="radio" />
            <span className="checkmark"></span>
          </label>
          <label className="radio">
            Option 3
            <input type="radio" name="radio" />
            <span className="checkmark"></span>
          </label>
        </div>

        <FGDropdownApi
          label="Dropdown"
          options={[
            { value: "1", label: "Option 1" },
            { value: "2", label: "Option 2" },
            { value: "3", label: "Option 3" },
            { value: "4", label: "Option 4" },
            { value: "5", label: "Option 5", disabled: true  },
          ]}
          defaultValue="2"
          onChange={(e) => Notifier.info(`Selected: ${e}`)}
        />

        <FGDropdownApi
          label="Dropdown Multiple"
          options={[
            { value: "1", label: "Option 1"},
            { value: "2", label: "Option 2" },
            { value: "3", label: "Option 3" },
          ]}
          multiple
          defaultValue="2"
          onChange={(e) => Notifier.info(`Selected: ${e}`)}
        />

        <hr/>

        <h3>Variant: Normal</h3>

        <FGInput type="text" name="ref-input-text-1" id="ref-input-text-1"
          variant="normal"
          label={<>This is <code>input[text]</code> field (Normal)</>}
        />

        <FGInput type="text" name="ref-input-text-2" id="ref-input-text-2"
          variant="normal"
          label={<>This is <code>input[text]</code> field with description (Normal)</>}
          desc={<>This is additional (optional) description about <strong>current field</strong>.</>}
        />

        <FGTextarea
          variant="normal"
          label={<>This is <code>textarea</code> field with description (Normal)</>}
          desc={<>This is additional (optional) description about <strong>current field</strong>.</>}
          name="ref-textarea-2"
          id="ref-textarea-2"
          defaultValue={"Default value..."}
        />

        <FGRichTextEditor
          variant="normal"
          label={<>This is Rich Text Editor field (Normal)</>}
          desc="This is additional description about current field."
          name="ref-rte-1"
          id="ref-rte-1"
          onChange={(e) => console.log(e)}
          value={def}
        />

        <FGDropdownApi
          label="Dropdown (Normal)"
          variant="normal"
          options={[
            { value: "1", label: "Option 1" },
            { value: "2", label: "Option 2" },
            { value: "3", label: "Option 3" },
          ]}
          defaultValue="2"
          onChange={(e) => Notifier.info(`Selected: ${e}`)}
        />

        <FGDropdownApi
          label="Dropdown Multiple (Normal)"
          variant="normal"
          options={[
            { value: "1", label: "Option 1"},
            { value: "2", label: "Option 2" },
            { value: "3", label: "Option 3" },
          ]}
          multiple
          defaultValue="2"
          onChange={(e) => Notifier.info(`Selected: ${e}`)}
        />

        <FGDatePicker
          variant="normal"
          label={<>This is Date Picker field</>}
          desc="This is additional description about current field."
          onChange={(e) => console.log(e)}
        />

        <div className="fg__field fg__field--normal">
          <label htmlFor="toggle-switch">Toggle Switch Checkbox</label>
          <div className="fg__field-desc">
            Should this enabled? Click to toggle.
          </div>
          <ToggleSwitch name="toggle-switch" id="toggle-switch" onChange={(e) => Notifier.info(`Clicked! ${e.target.checked}`)} />
        </div>

        <div className="fg__field fg__field--normal">
          <label>Custom Input Checbox</label>
          <div className="fg__field-desc">
            Please select some:
          </div>
          <FGCheckbox label="Option 1" name="checkbox-1" id="checkbox-1" onChange={(e) => Notifier.info(`Clicked! ${e.target.checked}`)} />
          <FGCheckbox label="Option 2" name="checkbox-2" id="checkbox-2" onChange={(e) => Notifier.info(`Clicked! ${e.target.checked}`)} />
          <FGCheckbox label="Option 3" name="checkbox-3" id="checkbox-3" onChange={(e) => Notifier.info(`Clicked! ${e.target.checked}`)} />
        </div>

        <div className="fg__field fg__field--normal">
          <label htmlFor="text">Custom Input Radio</label>
          <div className="fg__field-desc">
            Please choose one of these options.
          </div>
          <label className="radio">
            Option 1
            <input type="radio" name="radio" />
            <span className="checkmark"></span>
          </label>
          <label className="radio">
            Option 2
            <input type="radio" name="radio" />
            <span className="checkmark"></span>
          </label>
          <label className="radio">
            Option 3
            <input type="radio" name="radio" />
            <span className="checkmark"></span>
          </label>
        </div>

        <div>
          <button type="submit" className="btn btn-success" onClick={() => Notifier.success("Submitted!")}>
            <MdSave/>{" "}Submit
          </button>
          {" "}
          <button type="submit" className="btn btn-danger" onClick={() => Notifier.error("Cancelled!")}>
            <MdCancel/>{" "}Cancel
          </button>
        </div>

      </form>
    </main>

  </>
}
