import { useContext } from "react";
import { PortalContext, usePortalSettingSet } from "../../../context/portal-context";
import usePageTitle from "../../../hooks/use-page-title";
import classNames from "classnames";
import { isDebugMode } from "../../../config/config";
import NotifItemListing from "../../../components/portal/NotifItemListing";
import AppUserContext from "../../../context/app-user-context";

function NotifDebug() {
  const { setNotifCount } = useContext(PortalContext);

  return <div className={classNames([
    "mb-3",
    { "d-none": !isDebugMode }
  ])} data-comp="NotifDebug" style={{
    backgroundColor: "#ff0",
    border: "solid 1px #ccc",
    padding: "0.5rem 1rem",
    borderRadius: "10px"
  }}>
    <div style={{marginBottom: "0.5rem"}}>
      <em>This box only enabled on debug mode or localhost.</em>
    </div>
    <button className="btn btn-primary mb-2" onClick={() => setNotifCount(i => i + 1)}>Increase Notif</button>{" "}
    <button className="btn btn-primary mb-2" onClick={() => setNotifCount(i => i > 0 ? i - 1 : i)}>Decrease Notif</button>
  </div>
}

export default function PageNotification() {
  const title = "Notifications";
  usePageTitle(title);
  usePortalSettingSet("label_header", title);

  const user = useContext(AppUserContext);

  return <main className="content-generic content-generic--fluid">

    <h1 className="fs-title hide-mobile-down">{title}</h1>

    {/* Just enable this when necessary for testing */}
    {/* <NotifDebug/> */}

    <div className="notif-list">
      <NotifItemListing userId={user.referenceUserId}
        currentPage={1}
        itemsPerPage={10}
        maxPages={5}
        pagination={true}
      />
    </div>

  </main>
}
