import { Generics } from "./generics";

const pad = Generics.pad;

export namespace Times {

  /**
   * @param strIsoTime
   * @returns string The default formatted one e.g. `2021-07-27 09:19:41`
   */
  export function isoTimeToDefault(strIsoTime: string): string {
    let result = '0000-00-00 00:00:00';

    try {
      let time = new Date(strIsoTime);
      result = time.getFullYear() + '-' + pad(time.getMonth() + 1) + '-' + pad(time.getDate()) +
        ' ' + pad(time.getHours()) + ':' + pad(time.getMinutes()) + ':' + pad(time.getSeconds());
    } catch (ignore) { }

    return result;
  }

  // credit: https://stackoverflow.com/a/17415677
  export function toIsoString(date): string {
    var tzo = -date.getTimezoneOffset()
    var dif = tzo >= 0 ? '+' : '-';

    return date.getFullYear() +
      '-' + pad(date.getMonth() + 1) +
      '-' + pad(date.getDate()) +
      'T' + pad(date.getHours()) +
      ':' + pad(date.getMinutes()) +
      ':' + pad(date.getSeconds()) +
      dif + pad(tzo / 60) +
      ':' + pad(tzo % 60);
  }

  /**
   * Get current timezone in `+[offset hour]:[offset minute]` format
   * 
   * @example running this function from (Asia/Jakarta) timezone 
   * will return "+07:00"
   * @returns {string}
   */
  export function getTimezone(): string {
    const date = new Date();
    let zone = -date.getTimezoneOffset();
    let sign = zone >= 0 ? '+' : '-';

    return sign + pad(zone / 60) + ':' + pad(zone % 60);
  }

  /**
   * Get current date in `year-month-date` format
   * @returns {string}
   */
  export function getCurrentDateYmd(): string {
    const date = new Date();
    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    let dateNumber = date.getDate();

    return year + '-' + pad(month) + '-' + pad(dateNumber);
  }

  /**
   * Get current time in `hour:minute:second` format
   * 
   * HIS = Hour, mInute, Second
   * @returns {string}
   */
  export function getCurrentDateHis(): string {
    const date = new Date();
    let hours = date.getHours();
    let minutes = date.getMinutes();
    let seconds = date.getSeconds();
    return pad(hours) + ':' + pad(minutes) + ':' + pad(seconds);
  }

}
