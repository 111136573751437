import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useAuthUser from "../hooks/use-auth-user";
import { ROUTES } from "../routes";

export default function PageLanding() {
  const { isLoading, user } = useAuthUser();
  const navigate = useNavigate();

  useEffect(() => {
    if (isLoading) return;
    if (!user.logged) return navigate(ROUTES.AUTH_LOGIN, { replace: true });
    if (user.logged) return navigate(ROUTES.PORTAL_DASHBOARD, { replace: true });
  }, [user, isLoading]);

  return <></>;
};
