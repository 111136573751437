import * as localforage from 'localforage';
import { isPlainObject } from 'lodash-es';
import Config from '../../config/config';
import { UtilsCookies } from '../../utils/cookies';
import Utils from '../../utils/utils';

const DB_NAME = Config.LOCAL_STORAGE_NAME;
const DEFAULT_STORAGE_OPTIONS: IStorageOptions = {
  expiration: -1
};
const IS_LOCALSTORAGE_AVAILABLE = isLocalStorageAvailable();
let instance: IStorageDriver;
let customInstanceList: {[key: string]: IStorageDriver} = {};

type TCustomInstance = 'session';

async function _getInstance(customInstance?: TCustomInstance): Promise<IStorageDriver> {
  const isCustomInstance = typeof customInstance != 'undefined';

  if (isCustomInstance) {
    switch (customInstance) {
      case 'session':
        if (typeof customInstanceList[customInstance] == 'undefined') {
          customInstanceList[customInstance] = new SessionStorageWrapper(`${DB_NAME}_${customInstance}`);
        }

        return customInstanceList[customInstance];
        break;
    }

    // prevent executing other thing if custom instance is requested
    return;
  }

  if (typeof instance == 'undefined') {
    // If `localStorage` is not available (like in older version of Safari)
    // they don't allow us to store data on `localStorage`. That's why we need
    // to fallback to cookie storage instead.
    if (IS_LOCALSTORAGE_AVAILABLE) {
      instance = localforage.createInstance({
        'name': DB_NAME,
        'storeName': `${DB_NAME}_store`,
        'driver': localforage.LOCALSTORAGE
      });
    } else {
      console.error('localStorage not available, storing on cookie with storage limitation.');
      instance = new CookieStorageWrapper(DB_NAME);
    }
  }

  return instance;
}

async function _forgePayload(value, options?: IStorageOptions) {
  const opts = Object.assign({}, DEFAULT_STORAGE_OPTIONS, options);
  const forged = {
    data: value,
    expiration: typeof opts.expiration == 'number' && opts.expiration > 0
      ? (new Date()).getTime() + (opts.expiration * 1000) : -1
  };

  return forged;
}

function isLocalStorageAvailable() {
  try {
    if ('localStorage' in window) {
      const testKey = '___temp';

      localStorage.setItem(testKey, 'value');
      localStorage.getItem(testKey);
      localStorage.removeItem(testKey);

      return true;
    } else {
      return false;
    }
  } catch (e) {
    return false;
  }
}

class CookieStorageWrapper implements IStorageDriver {

  private KEY_STORE: string;
  private data;
  private store: typeof UtilsCookies;

  constructor(storageName) {
    // `fs` stands for fallback storage, just in case we forgot
    this.KEY_STORE = `_fs_${storageName}`;
    this.store = Utils.cookies;

    this.refresh();
  }

  private refresh() {
    const existingData = this.store.get(this.KEY_STORE);

    if (existingData === undefined || existingData === null) {
      this.data = {};
    } else {
      try {
        this.data = JSON.parse(existingData);
      } catch (e) {
        this.data = {};
      }
    }
  }

  private log() {
    if (false) {
      console.log(`[log_CookieStorageWrapper] data length for storing is: ${JSON.stringify(this.data).length}`);
    }
  }

  async setItem(key: any, value: any) {
    this.refresh();
    this.data[key] = value;
    this.log();
    this.store.set(this.KEY_STORE, JSON.stringify(this.data));
  }

  async getItem(key: any) {
    this.refresh();
    return this.data[key];
  }

  async clear() {
    this.data = {};
    this.store.set(this.KEY_STORE, JSON.stringify(this.data));
  }

  async removeItem(key: any) {
    this.refresh();
    delete this.data[key];
    this.log();
    this.store.set(this.KEY_STORE, JSON.stringify(this.data));
  }

}

class SessionStorageWrapper implements IStorageDriver {

  private storage = window.sessionStorage;
  private rPrefix: RegExp;

  constructor(private storageName: string) {
    this.rPrefix = new RegExp('^' + Utils.escapeRegExp(storageName), 'i');
  }

  async setItem(key: any, value: any) {
    key = `${this.storageName}_${key}`;

    try {
      this.storage.setItem(key, JSON.stringify(value));
    } catch (ignore) {}
  }

  async getItem(key: any) {
    key = `${this.storageName}_${key}`;

    try {
      return JSON.parse(this.storage.getItem(key));
    } catch (ignore) {
      return undefined;
    }
  }

  async removeItem(key: any) {
    key = `${this.storageName}_${key}`;
    this.storage.removeItem(key);
  }

  async clear() {
    let length = this.storage.length;

    for (let i = 0; i < length; i += 1) {
      let key = this.storage.key(i);

      if (this.rPrefix.test(key)) {
        // the reason why we don't use `this.removeItem` is because that method
        // will add prefix to existing key. if we use it, the item that we
        // want to delete will not be deleted.
        this.storage.removeItem(key);
      }
    }
  }

}

export interface IStorageDriver {
  setItem: (key: any, value: any) => Promise<any>;
  getItem: (key: any) => Promise<any>;
  clear: () => Promise<any>;
  removeItem: (key: any) => Promise<any>;
}

export interface IStorageItem {
  data: any;
  expiration: number;
};

export interface IStorageOptions {
  /** Expiration in seconds */
  expiration: number;
};

export class StorageHelper {

  static forgePayload = _forgePayload;

  static getInstance = _getInstance;

}

export default class Storage {

  static async set(key, value, options?: IStorageOptions) {
    let store = await _getInstance();
    await store.setItem(key, await _forgePayload(value, options));
    return true;
  }

  static async get(key, valueIfNotExists = undefined) {
    let store = await _getInstance();
    try {
      let data = <IStorageItem>await store.getItem(key);

      if (isPlainObject(data)) {
        let value = data.data;
        let expiration = data.expiration;
        let now = (new Date()).getTime();

        if (expiration == -1) {
          return value;
        } else {
          if (now <= expiration) {
            return value;
          } else {
            await this.remove(key);
            return valueIfNotExists;
          }
        }
      } else {
        return valueIfNotExists;
      }
    } catch (ignore) {
      return valueIfNotExists;
    }
  }

  static async clear() {
    let store = await _getInstance();
    try {
      await store.clear();
      return true;
    } catch (ignore) {
      return false;
    }
  }

  static async remove(key) {
    let store = await _getInstance();
    try {
      await store.removeItem(key);
      return true;
    } catch (ignore) {
      return false;
    }
  }

}
