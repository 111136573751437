import { useCallback, useRef } from "react";
import Config from "../config/config";

const PREFIX = Config.LOCAL_STORAGE_NAME;

/**
 * This hook is used as a `sessionStorage` wrapper that we can set/get data that
 * is grouped into `storeName` namespace. This hook is useful for something like
 * when we want to store temporary session data of specific feature on specific
 * page.
 *
 * One of the examples maybe for storing user's last toggle collapse/expand
 * selection of side menu. So when page gets refreshed, it "remembers" whether
 * previously user set collapsed/expanded mode.
 *
 * Serialization and de-serialization are handled out of the box, so we just set
 * with any value type as we wished then retrieve it in its original type.
 *
 * @example
 * function MyCounter() {
 *   const [counter, setCounter] = useState(0);
 *   const sessionStore = useSessionStore("MyCounter");
 *
 *   // on mounted, get any stored counter
 *   useEffect(() => {
 *     // The param value of `0` is fallback when data doesn't exist
 *     setCounter(sessionStore.get("counter", 0));
 *   }, []);
 *
 *   return <>
 *     Count: {counter}
 *     <button onClick={() => {
 *       setCounter(counter + 1);
 *       sessionStore.set("counter", counter + 1);
 *     }}>Increment</button>
 *   </>;
 * }
 */
export default function useSessionStore<T extends string>(storeName: string) {
  const data = useRef<{[key in T]?: any}>({});

  const read = useCallback(() => {
    try {
      data.current = JSON.parse(sessionStorage.getItem(`${PREFIX}_${storeName}`));
      if (!(typeof data.current === "object" && data.current !== null)) {
        throw new Error("Incorrect format");
      }
    } catch (ignore) {
      data.current = {};
    }
  }, []);

  const write = useCallback(() => {
    sessionStorage.setItem(`${PREFIX}_${storeName}`, JSON.stringify(data.current));
  }, []);

  const get = useCallback((key: T, defaultIfNotFound: any = "") => {
    read();
    const val = data.current[key];
    return typeof val === "undefined" ? defaultIfNotFound : val;
  }, []);

  const set = useCallback((key: T, value: any) => {
    read();
    data.current[key] = value;
    write();
  }, []);

  const remove = useCallback((key: T) => {
    read();
    delete data.current[key];
    write();
  }, []);

  const clear = useCallback(() => {
    sessionStorage.removeItem(`${PREFIX}_${storeName}`);
  }, []);

  return {
    get,
    set,
    remove,
    clear
  };
}
