import axios from "axios";
import { apiUrl, getAuthHeader } from "./base";
import { TServerSideGenericDTApi, TServerSideGenericDTApiResult } from "../../components/common/ServerSideGenericDataTable";

export type TGenericItem = {
  _id: string;
  name: string;
  description: string;
};

export type TGenericType = "Group" | "Industry" | "StudyProgram" | "Trait" | "Year";

function pagingSearch(type: TGenericType) {
  return async function (param: TServerSideGenericDTApi): Promise<TServerSideGenericDTApiResult> {
    const ret = {
      data: [],
      totalCount: 0
    };

    try {
      const searchParam = !param.search ? "__" : param.search;
      const targetUrl = `/apigenericdata/querypaging/${type}/${encodeURIComponent(searchParam)}/${param.page}/${param.item_per_page}`;
      const req = await axios.get(apiUrl(targetUrl), await getAuthHeader());
      const res = req.data;

      if (!(
        res.result === "OK" &&
        Array.isArray(res.data) &&
        typeof res.totalCount === "number"
      )) throw new Error();

      ret.data = res.data;
      ret.totalCount = res.totalCount;
    } catch (ignore) {}

    return ret;
  }
}

function add<T>(type: TGenericType) {
  return async function (formData: T): Promise<{ status: boolean; message: string; }> {
    try {
      const req = await axios.post(apiUrl("/apigenericdata"), {
        ...formData,
        type: type
      }, await getAuthHeader());
      if (req.data.result !== "OK") throw new Error(req.data.message ?? "Unable to add programme");
      return {
        status: true,
        message: req.data.message
      };
    } catch (err) {
      return {
        status: false,
        message: err.message
      };
    }
  }
}

async function get<T = any>(_id: string): Promise<{ status: boolean; message: string; data: T; }> {
  try {
    const req = await axios.get(apiUrl(`/apigenericdata/${_id}`), {
      ...await getAuthHeader()
    });
    if (req.data.result !== "OK" || req.data.data === null) throw new Error(req.data.message ?? "Unable to get email template");
    return {
      status: true,
      message: req.data.message,
      data: req.data.data
    };
  } catch (err) {
    return {
      status: false,
      message: err.message,
      data: null
    };
  }
}

const getCached = (() => {
  let isFetching: {[key: string]: boolean} = {};
  let cached: {[key: string]: any} = {};
  let callbackStack: {[key: string]: any[]} = {};

  /**
   * This will return cached version so we don't request previously requested
   * data with ID of `id`.
   */
  return async <T = any>(id: string): Promise<{
    status: true;
    data: T;
  } | {
    status: false
  }> => {
    return new Promise(async (res, rej) => {
      const fetching = isFetching[id];
      const data = cached[id];

      if (!fetching && typeof data !== "undefined") {
        res(data);
        return;
      }

      if (!Array.isArray(callbackStack[id])) {
        callbackStack[id] = [];
      }

      if (fetching) {
        callbackStack[id].push(res);
        return;
      }

      isFetching[id] = true;
      const apiRes = await get(id);
      callbackStack[id].push(res);

      if (apiRes.status) {
        cached[id] = apiRes;
      }

      callbackStack[id].forEach(cb => cb(apiRes));

      isFetching[id] = false;
      callbackStack[id] = [];
    });
  };
})();

async function remove(_id: string): Promise<{ status: boolean; message: string; }> {
  try {
    const req = await axios.delete(apiUrl(`/apigenericdata/${_id}`), {
      ...await getAuthHeader()
    });
    if (req.data.result !== "OK") throw new Error(req.data.message ?? "Unable to delete item");
    return {
      status: true,
      message: req.data.message
    };
  } catch (err) {
    return {
      status: false,
      message: err.message
    };
  }
}

function update<T>(type: TGenericType) {
  return async function (_id: string, formData: T) {
    try {
      const req = await axios.patch(apiUrl(`/apigenericdata/${_id}`), {
        ...formData,
        type: type
      }, await getAuthHeader());
      if (req.data.result !== "OK") throw new Error(req.data.message ?? "Unable to update data");
      return {
        status: true,
        message: req.data.message
      };
    } catch (err) {
      return {
        status: false,
        message: err.message
      };
    }
  }
}

export type TSelectQueryItem = {
  label: string;
  value: string|boolean|number;
};

/**
 * TODO: Maybe we cache the data?
 *   Pros:
 *   - Less network resource
 *   Cons:
 *   - Old data still cached as long as we haven't reloaded the page
 */
function selectQuery<T = TSelectQueryItem>(type: TGenericType, labelType: string = "", prependDefaultOption?: TSelectQueryItem) {
  return async (): Promise<T[]> => {
    let data = [];
    let selectLabel = [];

    if (
      prependDefaultOption &&
      typeof prependDefaultOption?.label === "string" &&
      typeof prependDefaultOption?.value !== "undefined"
    ) {
      selectLabel.push(prependDefaultOption);
    }

    try {
      const req = await axios.get(apiUrl(`/apigenericdata/queryall/${type}`), {
        ...await getAuthHeader()
      });

      if (req.data && Array.isArray(req.data.data)) {
        data = req.data.data;
      }
    } catch (ignore) {}

    return selectLabel.concat(data.map(item => ({
      label: item.name,
      value: item._id
    }))) as T[];
  };
}

const ApiGenericData = {
  pagingSearch,
  add,
  get,
  getCached,
  update,
  remove,
  selectQuery
};

export default ApiGenericData;
