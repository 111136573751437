import 'chart.js/auto';
import { Chart } from 'react-chartjs-2';
import useViewport from '../../hooks/use-viewport';
import { useCallback, useMemo, useState } from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { HiQuestionMarkCircle, HiX } from 'react-icons/hi';
import { OverlayTriggerType } from 'react-bootstrap/esm/OverlayTrigger';

interface PDChartProps {
  color: string;
  labelX: any[];
  scale: number[];
  scaleNextMonth: number[];
  goal: number[];
  goalMaxLine: number[];
}

export default function PDChart({ color, labelX, scale, scaleNextMonth, goal, goalMaxLine }: PDChartProps) {
  const labels = labelX;
  const viewport = useViewport();
  const [isPopoverOpen, setPopoverOpen] = useState(false);
  const flagIcon = useMemo(() => {
    const svgString = `
      <svg xmlns="http://www.w3.org/2000/svg" width="26" height="15" viewBox="0 0 26 15">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M12 3C12 2.20435 12.3161 1.44129 12.8787 0.87868C13.4413 0.316071 14.2044 0 15 0H25C25.1857 0 25.3678 0.0517147 25.5257 0.149349C25.6837 0.246984 25.8114 0.386681 25.8944 0.552786C25.9775 0.718892 26.0126 0.904844 25.996 1.08981C25.9793 1.27477 25.9114 1.45143 25.8 1.6L23.25 5L25.8 8.4C25.9114 8.54857 25.9793 8.72523 25.996 8.91019C26.0126 9.09516 25.9775 9.28111 25.8944 9.44721C25.8114 9.61332 25.6837 9.75302 25.5257 9.85065C25.3678 9.94829 25.1857 10 25 10H15C14.7348 10 14.4804 10.1054 14.2929 10.2929C14.1054 10.4804 14 10.7348 14 11V14C14 14.2652 13.8946 14.5196 13.7071 14.7071C13.5196 14.8946 13.2652 15 13 15C12.7348 15 12.4804 14.8946 12.2929 14.7071C12.1054 14.5196 12 14.2652 12 14V3Z" fill="${color}" />
      </svg>
    `;
    const image = new Image();
    image.src = `data:image/svg+xml;charset=utf-8,${encodeURIComponent(svgString)}`;

    return image;
  }, [color]);

  const data = useMemo(() => ({
    labels,
    datasets: [
      {
        type: 'line' as const,
        label: 'Current',
        borderColor: color,
        borderWidth: viewport.isMobile ? 2 : 3,
        backgroundColor: color,
        radius: viewport.isMobile ? 2 : 3,
        fill: false,
        data: scale,
        spanGaps: true,
        hitRadius: 10,
      },
      {
        type: 'scatter' as const,
        label: 'Expected',
        backgroundColor: '#fff',
        data: scaleNextMonth,
        pointStyle: 'circle',
        borderColor: color,
        borderWidth: viewport.isMobile ? 2 : 3,
        radius: viewport.isMobile ? 5 : 8,
        hitRadius: viewport.isMobile ? 5 : 10,

      },
      {
        type: 'scatter' as const,
        label: 'Final',
        backgroundColor: color,
        fill: false,
        pointStyle: flagIcon,
        borderWidth: 3,
        hitRadius: 20,
        pointBorderColor: '#00000',
        data: goal,
      },
      {
        type: 'bar' as const,
        label: '',
        backgroundColor: color,
        barPercentage: 0.08,
        data: goalMaxLine,
        barThickness: viewport.isMobile ? 2 : 3,
      }
    ],
  }), [color, viewport, labels, flagIcon, goal, scale, scaleNextMonth, goalMaxLine]);

  const options = useMemo(() => ({
    responsive: true,
    plugins: {
      tooltip: {
        padding: 10,
        enabled: true,
        filter: ({ datasetIndex }) => datasetIndex !== 3,
        bodySpacing: 10,
        callbacks: {
          label: ({ datasetIndex, dataIndex }) => {
            if (datasetIndex === 0) {
              return `  Current: ${data.datasets[0].data[dataIndex]}`;
            }
            if (datasetIndex === 1) {
              return `  Expected: ${data.datasets[1].data[dataIndex]}`;
            }
            if (datasetIndex === 2) {
              return `  Final: ${data.datasets[2].data[dataIndex]}`;
            }
          }
        }
      },
      legend: {
        display: false,
      }
    },
  }), []);

  const handlePopoverClose = useCallback(() => {
    setPopoverOpen(false);
  }, []);

  const popover = useMemo(() => (
    <Popover>
      <Popover.Body>
        <div className="d-flex gap-2 align-items-center justify-content-between mb-3">
          <div className="d-flex align-items-center gap-2">
            <HiQuestionMarkCircle className="text-primary fs-4" />
            <h3 className="fs-5 m-0">Chart Info</h3>
          </div>
          <button
            type="button"
            className="btn d-lg-none"
            onClick={handlePopoverClose}
            aria-label="Close"
          >
            <HiX className="fs-4 cursor-pointer" />
          </button>
        </div>
        <div className="d-flex flex-column gap-3">
          <div className='d-flex'>
            <div>
            <span
              className="legend__monthly-goal"
              style={{ borderColor: color }}
            ></span>
            </div>
            <div>
              <h4 className="fs-6 m-0">Monthly Goal</h4>
              <p className="m-0 lh-1-25">The monthly goal is the target score you want to achieve by the end of the month.</p>
            </div>
          </div>
          <div className='d-flex'>
            <div>
              <span
                className="legend__trait-score"
                style={{ backgroundColor: color }}
                ></span>
            </div>
            <div>
              <h4 className="fs-6 m-0">Trait Score</h4>
              <p className="m-0 lh-1-25">The trait score is the score you have achieved so far.</p>
            </div>
          </div>
          <div className='d-flex align-items-start justify-content-start'>
            <img src={flagIcon.src} alt='flag' className='legend__final-goal me-2 mt-1' />
            <div>
              <h4 className="fs-6 m-0">Final Goal</h4>
              <p className="m-0 lh-1-25">The final goal is the score you need to achieve in the end.</p>
            </div>
          </div>
        </div>
      </Popover.Body>
    </Popover>
  ), []);

  const trigger: OverlayTriggerType[] = useMemo(() => viewport.isMobile ? ["click"] : ["hover", "focus"], [viewport]);

  return (
    <>
      <Chart type='bar' data={data} options={options}/>
      <div className='d-flex justify-content-center align-items-center legend'>
        <div className="me-2 d-flex justify-content-center align-items-center d-xl-block">
          <span
            className="legend__monthly-goal"
            style={{ borderColor: color }}
          ></span>
          Monthly Goal
        </div>
        <div className="d-flex justify-content-center align-items-center d-xl-block">
          <span
            className="legend__trait-score"
            style={{ backgroundColor: color }}
          ></span>
          Trait Score
        </div>
        <div className='legend__final-goal d-flex justify-content-center align-items-center d-xl-block'>
          <img src={flagIcon.src} alt='flag' className='.legend__final-goal ms-2' />
          Final Goal
        </div>
        <OverlayTrigger
        trigger={trigger}
        placement="top-end"
        overlay={popover}
        show={isPopoverOpen}
        onToggle={setPopoverOpen}
      >
        <button
          className="btn"
          onMouseEnter={() => {
            if (!viewport.isMobile) setPopoverOpen(true);
          }}
          onMouseLeave={() => {
            if (!viewport.isMobile) setPopoverOpen(false);
          }}
        >
          <HiQuestionMarkCircle className="text-primary fs-3" />
        </button>
      </OverlayTrigger>
      </div>
    </>
  );
}
