import EventSubscription, { TEventSubscriptionUnsubscribe } from "./event-subscription";

const cachedChannels: {[key: string]: EventSubscription} = {};

/**
 * This method will create or using cached one (when exists).
 */
function getChannel(channelName: string): EventSubscription {
  const channel = cachedChannels[channelName];

  if (typeof channel == 'undefined') {
    return (cachedChannels[channelName] = new EventSubscription());
  } else {
    return channel;
  }
}

/**
 * This is the simplified wrapper of `EventSubscription` class. Suppose we'd like
 * to listen for several channels' data, then it's good to use this.
 *
 * The `subscribe` method will return unsubscribe function that can be invoked
 * when we don't want to listen for new event.
 *
 * @example
 * // If we are using React's functional component, use it like this:
 * useEffect(() => {
 *   return SubscriptionConsumer.subscribe('robot_detail_pose', (data) => {
 *     // do something with `data`
 *     console.log(data);
 *   });
 * }, []);
 */
export default class SubscriptionConsumer {

  static emit(channelName: string, data?: any) {
    getChannel(channelName).emit(data);
  }

  static subscribe<T>(channelName: string, callback: (data: T) => void): TEventSubscriptionUnsubscribe {
    return getChannel(channelName).subscribe((data) => {
      callback(<T>data);
    });
  }

}
