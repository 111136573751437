import axios from "axios";
import AppUser from "../app-user/app-user";
import { apiUrl, getAuthHeader } from "./base";

const defaultDashboardAdminStatistic = {
  "totalUserLogin": 0,
  "totalSubmissions": {
    "Workplace Learning": 0,
    "Professional Development": 0
  },
  "totalFeedbacks": {
    "STUDENT": 0,
    "SIT SUPERVISOR": 0,
    "INDUSTRY SUPERVISOR": 0
  },
  "totalUsers": {
    "all": 0,
    "SIT SUPERVISOR": 0,
    "INDUSTRY SUPERVISOR": 0,
    "STUDENT": 0
  }
} as const;

export type TDashboardAdminStatistic = typeof defaultDashboardAdminStatistic;

const getStatistic = async () => {
  let data: any;

  try {
    const req = await axios.get(apiUrl(`/apidashboard/statistic`), {
      ...await getAuthHeader()
    });

    if (req?.data?.data) data = req.data.data;
  } catch (ignore) {}

  return {
    ...(JSON.parse(JSON.stringify(defaultDashboardAdminStatistic))),
    ...data
  } as TDashboardAdminStatistic;
};

const ApiDashboard = {
  getStatistic
};

export default ApiDashboard;
