import { useContext, useEffect, useState } from "react";
import { useLocation, matchPath, Location } from "react-router";
import AppUserContext from "./context/app-user-context";
import { TAppUser } from "./modules/app-user/app-user";
import { ROUTES, routerList } from "./routes";
import ROLE from "./modules/role";

let parsedRoutes: any[];
let parseRoutes = () => {
  if (typeof parsedRoutes === "undefined") {
    parsedRoutes = [];
    traverseRoutes(routerList, parsedRoutes);
  }
};

function traverseRoutes(routes: any[], parsed: any[]) {
  if (!Array.isArray(routes)) {
    return;
  }

  routes.forEach(item => {
    if (Array.isArray(item.children)) {
      traverseRoutes(item.children, parsed);
    }

    if (typeof item.path === "string" && (
      Array.isArray(item.roles) || typeof item.roles === "string"
    )) {
      parsed.push({
        path: item.path,
        roles: item.roles
      });
    }
  });
}

export function filterNav(user: TAppUser, navItem: any) {
  // Authorized for all pages
  if (navItem.nocheck === true) {
    return true;
  }

  if (typeof navItem.url === "undefined") {
    return false;
  }

  parseRoutes();

  for (const route of parsedRoutes) {
    if (route.path === navItem.url) {
      return route.roles.indexOf(user.role) > -1;
    }
  }

  return false;
}

async function checkRole(user: TAppUser, currentLocation: Location): Promise<boolean> {
  // Authorized for all pages
  if (user.role === ROLE.ADMIN || currentLocation.pathname === ROUTES.PORTAL) {
    return true;
  }

  parseRoutes();

  const path = currentLocation.pathname;

  for (const route of parsedRoutes) {
    if (matchPath(route.path, path)) {
      return route.roles.indexOf(user.role) > -1;
    }
  }

  return false;
}

function NotAuthorized() {
  return <main className="content-generic content-generic--vh-center">
    <div className="text-center">
      <h1>Not authorized</h1>
      <p>You are not authorized to view this page.</p>
    </div>
  </main>;
}

export default function CheckRole(props: any) {
  const user = useContext(AppUserContext);
  const [checking, setChecking] = useState(true);
  const [allowed, setAllowed] = useState(false);
  const location = useLocation();

  useEffect(() => {
    setChecking(true);
    checkRole(user, location).then((isAllowed) => {
      setChecking(false);
      setAllowed(isAllowed);
    });
  }, [location]);

  return <>
    {!checking && !allowed && <NotAuthorized/>}
    {!checking && allowed && props.children}
  </>;
}
