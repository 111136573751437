import { useEffect, useRef, useState } from "react";
import usePageTitle from "../../../hooks/use-page-title";
import { usePortalSettingSet } from "../../../context/portal-context";
import { Link, useNavigate, useParams } from "react-router-dom";
import Debouncer from "../../../modules/debouncer/debouncer";
import ApiUser from "../../../modules/api/user";
import Notifier from "../../../modules/notifier/notifier";
import Utils from "../../../utils/utils";
import { ROUTES } from "../../../routes";
import FGInput from "../../../components/dumb/FGInput";
import FGDropdownApi from "../../../components/form/FGDropdownApi";
import { CONST_SELECT_ROLES, CONST_SELECT_USER_STATUS, CONST_SELECT_YEAR, DEFAULT_SELECT_OPTION, USER_STATUS_ACTIVE } from "../../../config/constants";
import ApiGenericData from "../../../modules/api/generic-data";
import { HiArrowLeft } from "react-icons/hi";
import Multilingual from "../../../modules/multilingual/multilingual";

const UPDATED_FIELDS = ["name", "email", "role", "group", "industry", "jobDesk",
  "studentId", "year", "studyProgram", "status"] as const;
const FIELDS: {[key in typeof UPDATED_FIELDS[number]]: string} = UPDATED_FIELDS.reduce((curr, item) => ({ ...curr, [item]: "" }), {}) as any;

export default function PageUserEdit() {
  const title = "Edit User";
  usePageTitle(title);
  usePortalSettingSet("label_header", title);

  const navigate = useNavigate();
  const { _id } = useParams();
  const refDataLoaded = useRef(false);
  const [contentChanged, setContentChanged] = useState(false);
  const [loading, setLoading] = useState(true);
  const [found, setFound] = useState(false);
  const [sending, setSending] = useState(false);
  const [formData, setFormData] = useState({ ...FIELDS });
  const onChange = (e) => {
    const value = e.target.type === "checkbox" ? e.target.checked : e.target.value;
    setFormData((data) => ({ ...data, [e.target.name]: value }));

    if (refDataLoaded.current) {
      setContentChanged(true);
    }
  };
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    Debouncer.execute("SUBMIT_UPDATE_USER", async () => {
      setSending(true);
      const res = await ApiUser.update(
        _id,
        Object.keys(formData)
          .filter(i => UPDATED_FIELDS.some(j => j === i))
          .map(i => ({
            key: i,
            value: formData[i]
          }))
          .reduce((curr, item) => ({ ...curr, [item.key]: item.value }), {})
      );

      if (res.status) {
        Notifier.success(res.message);
        setSending(false);
        setContentChanged(false);
      } else {
        setSending(false);
        Notifier.error(res.message);
      }
    });
  };
  const checkStatusValue = (obj: any) => {
    return {
      ...obj,
      status: obj.status ?? USER_STATUS_ACTIVE
    };
  };

  useEffect(() => {
    setLoading(true);
    ApiUser.get(_id).then(res => {
      if (res.status) {
        setFound(true);
        setFormData(checkStatusValue(res.data));
        setTimeout(() => {
          refDataLoaded.current = true;
        }, 250);
      } else {
        setFound(false);
      }

      setLoading(false)
    });
  }, []);

  return <div data-comp="PageUserRegist">
    <h2 className="fs-title mb-3 hide-mobile-down">{title}</h2>

    <div className="cg__actions hide-mobile-down">
      <Link to={ROUTES.PORTAL_USERS + `?role=${formData.role}`} className="btn btn-primary" onClick={(e) => {
        if (contentChanged && !window.confirm(Multilingual.get("CONFIRM_ACTION_CANT_BE_UNDONE"))) {
          e.preventDefault();
        }
      }}>
        <HiArrowLeft/>{" "}Back
      </Link>
    </div>

    {loading && <p className="alert alert-info">
      Loading data...
    </p>}

    {!loading && !found && <p className="alert alert-info">
      User not found.
    </p>}

    {!loading && found && <form className="form-register" onSubmit={handleSubmit}>
      <div className='row' data-comp="PageuserRegistManual">
        <div className='col-md-6'>
          <FGInput
            label="Name"
            name="name"
            value={formData.name}
            onChange={onChange}
            required
            autoFocus
          />
        </div>
        <div className='col-md-6'>
          <FGInput
            label="Email"
            name="email"
            type="email"
            value={formData.email}
            onChange={onChange}
            required
          />
        </div>
        <div className='col-md-6'>
          <FGInput
            label="Student ID"
            name="studentId"
            value={formData.studentId}
            onChange={onChange}
          />
        </div>
        <div className='col-md-6'>
          <FGInput
            label="Job title"
            name="jobDesk"
            value={formData.jobDesk}
            onChange={onChange}
          />
        </div>
        <div className="col-md-6">
          <FGDropdownApi
            label="Cohort"
            name="year"
            defaultValue={formData.year?.toString()}
            api={ApiGenericData.selectQuery("Year", "year", { ...DEFAULT_SELECT_OPTION })}
            onChange={onChange}
          />
        </div>
        <div className="col-md-6">
          <FGDropdownApi
            label="Roles"
            name="role"
            options={CONST_SELECT_ROLES}
            defaultValue={formData.role}
            onChange={onChange}
          />
        </div>
        <div className="col-md-6">
          <FGDropdownApi
            label="Group"
            name="group"
            defaultValue={formData.group}
            api={ApiGenericData.selectQuery("Group", "group")}
            onChange={onChange}
            multiple
          />
        </div>
        <div className="col-md-6">
          <FGDropdownApi
            label="Company"
            name="industry"
            defaultValue={formData.industry}
            api={ApiGenericData.selectQuery("Industry", "industry", { ...DEFAULT_SELECT_OPTION })}
            onChange={onChange}
          />
        </div>
        <div className="col-md-6">
          <FGDropdownApi
            label="Programme"
            name="studyProgram"
            defaultValue={formData.studyProgram}
            api={ApiGenericData.selectQuery("StudyProgram", "programme", { ...DEFAULT_SELECT_OPTION })}
            onChange={onChange}
          />
        </div>
        <hr/>
        <div className="col-md-12">
          <FGDropdownApi
            variant="normal"
            label="Status"
            desc={`Set to "Inactive" if we'd like to disable this user.`}
            name="status"
            defaultValue={formData.status}
            options={CONST_SELECT_USER_STATUS}
            onChange={onChange}
          />
        </div>
      </div>
      <div>
        <button type="submit" className="btn btn-primary" disabled={sending}>
          Submit Data
        </button>
        {" "}
        <button type="button" className="btn btn-outline-primary"
          onClick={(e) => {
            e.preventDefault();
            if (window.history.length === 1) {
              navigate(ROUTES.PORTAL_USERS + `?role=${formData.role}`);
            } else {
              navigate(-1);
            }
          }}
        >
          Cancel
        </button>
      </div>
    </form>}
  </div>;
}
