import React, { useEffect, useImperativeHandle, useState } from "react";
import { Modal } from "react-bootstrap";
import UserImage from "../portal/UserImage";
import FGTextarea from "../dumb/FGTextarea";
import ModulePopup from "../../modules/popup/popup";
import Notifier from "../../modules/notifier/notifier";
import FGToggle from "../dumb/FGToggle";
import LabelTrait from "../portal/LabelTrait";

type TFeedbackOwner = {
  name: string;
  email?: string;
  avatar: string;
};

type TFeedbackFields = {
  submissionId: string;
  traitId: string;
  messageId?: string;
  receiverId?: string;
  feedback?: string;
  anonymous?: boolean;
  /** these are optionals as helper for submit purpose */
  notificationId?: string;
};

export type TPopupPeerFeedbackRef = {
  show: (opts: {
    owner: TFeedbackOwner;
    data: TFeedbackFields;
  }) => void;
  hide: () => void;
};

export type TPopupPeerFeedbackProps = {
  onSubmit?: (formData: TFeedbackFields, setDisabled: any) => void;
};

const DEFAULT_OWNER = {
  avatar: "",
  name: "",
  email: ""
};

const DEFAULT_FORM_DATA = {
  notificationId: "",
  traitId: "",
  messageId: "",
  receiverId: "",
  feedback: "",
  anonymous: false,
  submissionId: ""
};

const PopupPeerFeedback = React.forwardRef<TPopupPeerFeedbackRef, TPopupPeerFeedbackProps>((props, ref) => {
  const [show, setShow] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [owner, setOwner] = useState<TFeedbackOwner>({ ...DEFAULT_OWNER });
  const [formData, setFormData] = useState<TFeedbackFields>({ ...DEFAULT_FORM_DATA });

  useEffect(() => {
    if (!show) {
      setDisabled(false);
      setOwner({ ...DEFAULT_OWNER });
      setFormData({ ...DEFAULT_FORM_DATA });
    }
  }, [show]);

  useImperativeHandle(ref, () => ({
    show(opts) {
      setOwner({ ...DEFAULT_OWNER, ...opts.owner })
      setFormData({ ...DEFAULT_FORM_DATA, ...opts.data })
      setShow(true);
    },
    hide() {
      setShow(false);
    }
  }), []);

  const validate = () => {
    let valid = true;

    if (formData.feedback === "") {
      valid = false;
      Notifier.error("Feedback is required");
    }

    return valid;
  };

  const handleOnChange = (e) => {
    setFormData(v => ({
      ...v,
      [e.target.name]: e.target.type === "checkbox"
        ? e.target.checked
        : e.target.value
    }));
  };

  const handleSubmit = () => {
    props.onSubmit(formData, setDisabled);
  };

  return <>
    <Modal data-comp="PopupPeerFeedback" className="modal-supervisor-feedback" show={show} onHide={() => setShow(false)} centered>
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        {!owner && <p className="alert alert-not-found">Unknown feedback.</p>}
        {owner && <div className='feedback-modal'>
          <div className='d-flex flex-column align-items-center feedback-modal__user-details mb-3 mt-3'>
            <UserImage url={owner.avatar} />
            <p className='fs-4 fw-medium m-0'>{owner.name}</p>
            <span className={`${(owner.email ?? "") === "" ? "d-none" : ""}`}>{owner.email ?? ""}</span>
            <span><LabelTrait traitId={formData.traitId} /></span>
          </div>
          <div className='w-100'>
            <FGTextarea name="feedback"
              autoFocus
              variant="normal"
              label="Feedback"
              placeholder="Write your feedback"
              rows={5}
              value={formData.feedback}
              onChange={handleOnChange}
            />
          </div>
          <div className='w-100'>
            <FGToggle name="anonymous"
              label="Send as anonymous user"
              checked={formData.anonymous}
              onChange={handleOnChange}
            />
          </div>
          <button type='button' className="btn btn-primary w-100" disabled={disabled} onClick={(e) => {
            e.preventDefault();
            if (!validate()) return;
            ModulePopup.showAlertConfirm({
              description: "Are you sure to submit?",
              onYes() {
                handleSubmit();
              }
            })
          }}>
            Submit
          </button>
        </div>}
      </Modal.Body>
    </Modal>
  </>;
});

PopupPeerFeedback.defaultProps = {
  onSubmit: () => {}
}

export default PopupPeerFeedback;
