/**
 * Currently this page not used.
 */

import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import FadeBox from "../../components/animate/FadeBox";
import FGInput from "../../components/dumb/FGInput";
import Config from "../../config/config";
import useAuthUser from "../../hooks/use-auth-user";
import usePageTitle from "../../hooks/use-page-title";
import { ROUTES } from "../../routes";

export default function PageSetNewPassword() {
  usePageTitle("Set New Password");
  const auth = useAuthUser();
  const navigate = useNavigate();
  const [showSubmitted, setShowSubmitted] = useState(false);

  useEffect(() => {
    if (Config.IS_MOCKUP_MODE) return;

    if (!auth.isLoading && auth.user.logged) {
      navigate(ROUTES.PORTAL_DASHBOARD);
    }
  }, [auth]);

  return <>

    <FadeBox show={!showSubmitted}>
      <form className="form-default" onSubmit={(e) => {
        setShowSubmitted(true);

        if (Config.IS_MOCKUP_MODE) {
          e.preventDefault();
          return;
        }
      }}>
        <h1>Set New Password</h1>

        <p>Your new password must be different to previously used password.</p>

        <FGInput type="password" label="Password" placeholder="******" autoFocus />

        <FGInput type="password" label="Confirm password" placeholder="******" />

        <p className="text-center">
          <button type="submit" className="btn btn-primary">Reset Password</button>
        </p>

        <p className="text-center mb-0">
          <Link to={ROUTES.AUTH_LOGIN}>Back to login</Link>
        </p>
      </form>
    </FadeBox>

    <FadeBox show={showSubmitted}>
      <div>
        <h1>Password Reset Success</h1>
        <p>Your password has been reset successfully.</p>
        <p className="text-center mb-0">
          <Link to={ROUTES.AUTH_LOGIN} className="btn btn-primary">
            Click here to login
          </Link>
        </p>
      </div>
    </FadeBox>

  </>;
}
