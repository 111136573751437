export default class Config {

  static BASE_URL = "/";
  static PAGE_TITLE_SUFFIX = "";

  static TITLE_SUFFIX = " | GROW-Pro";

  // pre computed of:
  // 'SITIWSP_App$2023'.split('').map(c => c.charCodeAt(0));
  static ENCRYPTION_DEFAULT_KEY = [83, 73, 84, 73, 87, 83, 80, 95, 65, 112, 112, 36, 50, 48, 50, 51];

  static LOCAL_STORAGE_NAME = "sitiwsp";

  static API_URL = `${process.env.REACT_APP_API_URL}`;

  // set this to `true` so that we can navigate to all pages...
  static IS_MOCKUP_MODE = process.env.REACT_APP_MODE === "mockup";

  // anything that is empty will fallback to this value
  static CHAR_EMPTY = "-";

}

export const isLocalhost = (() => {
  const host = window.location.hostname;
  return host.indexOf("localhost") > -1 || host.indexOf("127.0.0.1") > -1;
})();

export const isDebugMode = (() => {
  return window.location.href.indexOf("debug=1") > -1 || isLocalhost;
})();

export const publicDir = (() => {
  let base = $("html").attr("data-public-url");

  if (typeof base != "string") {
    base = "";
  } else {
    base = base.trim();
  }

  return (path: string) => {
    return base + path;
  };
})();
