import React, { Dispatch, SetStateAction, useRef, useState } from "react";
import { Button, Modal } from "react-bootstrap";

export type TAlertPrompt = {
  show: boolean;
  setShow:  Dispatch<SetStateAction<boolean>>;
  backdrop?: boolean | "static";
  keyboard: boolean;
  disableActions?: boolean;
  title?: string|JSX.Element|JSX.Element[];
  description?: string|JSX.Element|JSX.Element[];
  labelYes?: string;
  labelNo?: string;
  onYes?: (input: string, inputRef: React.MutableRefObject<HTMLInputElement>) => void;
  onNo?: () => void;
  onClose?: () => void;
  inputPlaceholder?: string;
};

AlertPrompt.defaultProps = {
  show: false,
  setShow: () => {},
  backdrop: "static",
  keyboard: false,
  disableActions: false,
  title: "Prompt",
  description: "Enter value:",
  labelYes: "OK",
  labelNo: "Cancel",
  onYes: () => {},
  onNo: () => {},
  onClose: () => {},
  inputPlaceholder: "Write something..."
};

export default function AlertPrompt(props: TAlertPrompt) {
  const [input, setInput] = useState("");
  const inputRef = useRef<HTMLInputElement>(null);

  return <Modal className="modal-generic alert-prompt" show={props.show}
    backdrop={props.backdrop}
    keyboard={props.keyboard}
    onHide={() => {
      props.setShow(false);
      props.onClose();
    }}
  >
    <Modal.Header closeButton>
      <Modal.Title>{props.title ?? "Prompt"}</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      {props.description ?? "Enter the value:"}
      <div className="mt-3">
        <form onSubmit={(e) => {
          e.preventDefault();
          (typeof props.onYes === "function" && props.onYes(input, inputRef));
        }}>
          <input
            ref={inputRef}
            type="text"
            className="form-control"
            autoFocus
            disabled={props.disableActions}
            onChange={(e) => setInput(e.target.value)}
            placeholder={props.inputPlaceholder}
          />
        </form>
      </div>
    </Modal.Body>
    <Modal.Footer>
      <button className="btn btn-mw btn-secondary" onClick={() => props.onNo()} disabled={props.disableActions}>
        {props.labelNo ?? "No"}
      </button>
      <button className="btn btn-mw btn-primary" onClick={() => props.onYes(input, inputRef)} disabled={props.disableActions}>
        {props.labelYes ?? "Yes"}
      </button>
    </Modal.Footer>
  </Modal>;
}
