import { SUBMISSION_PD, SUBMISSION_WL } from "../config/constants";
import { isHtmlEmpty } from "../utils/jquery-helper";

export type TValidationResult = {
  valid: boolean;
  message: string;
};

async function getImageResolution(file: File): Promise<{ width: number; height: number; }> {
  return new Promise((res, rej) => {
    let img = document.createElement("img");
    img.onload = () => {
      res({
        width: img.width,
        height: img.height
      });
      img.src = null;
      img = null;
    };
    img.onerror = () => res({ width: -1, height: -1 });
    img.src = URL.createObjectURL(file);
  });
}

const ONE_MB = 1024 * 1024;

async function isImageValid(file: File): Promise<TValidationResult> {
  const ALLOWED_FORMAT = [
    "image/png",
    "image/jpg",
    "image/jpeg"
  ];
  // As of now, the maximum size is 1MB
  const MAX_SIZE = 1024 * 1024;
  // Image must be square
  const MAX_RESOLUTION = 640;

  if (ALLOWED_FORMAT.indexOf(file.type) === -1) {
    return {
      valid: false,
      message: "Image must be in JPG/PNG format"
    };
  }

  if (file.size > MAX_SIZE) {
    return {
      valid: false,
      message: `Image size must be less than or equal to ${MAX_SIZE / ONE_MB} MB`
    };
  }

  // check the resolution
  // as of 1st UAT Feedback on Jul 2023, we removed the resolution-checking
  // validation, leaving only check for image format and size.
  /*
  const image = await getImageResolution(file);

  if (image.width !== image.height) {
    return {
      valid: false,
      message: `Image must be square (1:1)`
    };
  }

  if (!(
    image.width <= MAX_RESOLUTION &&
    image.height <= MAX_RESOLUTION
  )) {
    return {
      valid: false,
      message: `Image maximum resolution must be ${MAX_RESOLUTION}x${MAX_RESOLUTION} pixels`
    };
  }
  */

  return {
    valid: true,
    message: "OK"
  };
}

function isDateCorrect(date: any): TValidationResult {
  try {
    if (typeof date === "string" && date.trim() === "") {
      throw new Error("Date should not be empty.");
    }

    const valid = /\d+/.test(new Date(date).getTime().toString());

    if (!valid) {
      throw new Error("Date is in invalid format.");
    }

    return {
      valid,
      message: "OK"
    };
  } catch (err) {
    return {
      valid: false,
      message: err.message
    }
  }
}

function isSubmissionDateValid(data: {
  type: string;
  from: string;
  to: string;
}) {
  const from = isDateCorrect(data.from);

  if (!from.valid) {
    return from;
  }

  const to = isDateCorrect(data.to);

  if (!to.valid) {
    return to;
  }

  if (data.type !== SUBMISSION_PD && data.type !== SUBMISSION_WL) {
    return {
      valid: false,
      message: "Unknown submission type."
    };
  }

  return {
    valid: true,
    message: "OK"
  };
}

type TSubmissionWLFields = {
  submissionDateId: string;
  summaryKeyActivities: string;
  detailsLearning: string;
  reflection: string;
};

function isSubmissionWLValid(data: TSubmissionWLFields) {
  if (data.submissionDateId === "") {
    return {
      valid: false,
      message: "Submission date is required. Make sure you have selected the period."
    };
  }

  if (isHtmlEmpty(data.summaryKeyActivities)) {
    return {
      valid: false,
      message: "Summary of Key Activities is required."
    };
  }

  if (isHtmlEmpty(data.detailsLearning)) {
    return {
      valid: false,
      message: "Details of Learning is required."
    };
  }

  if (isHtmlEmpty(data.reflection)) {
    return {
      valid: false,
      message: "Reflection is required."
    };
  }

  return {
    valid: true,
    message: "Valid"
  };
}

function isPasswordValid(password: string) {
  let valid = true;
  let message = "OK";

  if (!(
    (typeof password === "string") &&
    (password.length >= 12) &&
    (/[A-Z]/.test(password)) &&
    (/[a-z]/.test(password)) &&
    (/[0-9]/.test(password))
  )) {
    valid = false;
    message = `Make sure the password contains at least 12 characters and
      must be consisted of alphabets (both lowercase [a to z] and uppercase [A to Z]) and numeric.
      It's even better if we include special characters as well (such as ~!@#$%^&*-+=).`;
  }

  return { valid, message };
}

function isChangePasswordFormValid(formData: any) {
  const data = {
    currentPassword: "",
    newPassword: "",
    confirmNewPassword: "",
    ...formData
  };
  let valid = true;
  let message = "OK";

  const newPass = isPasswordValid(data.newPassword);
  if (!newPass.valid) {
    valid = false;
    message = newPass.message;
  }

  if (valid && (data.newPassword !== data.confirmNewPassword)) {
    valid = false;
    message = `New Password and Confirm New Password mismatched.`;
  }

  return { valid, message };
}

const ModuleValidation = {
  isPasswordValid,
  isImageValid,
  isDateCorrect,
  isSubmissionDateValid,
  isSubmissionWLValid,
  isChangePasswordFormValid
};

export default ModuleValidation;
