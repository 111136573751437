import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import useAuthUser from '../../hooks/use-auth-user';
import Notifier from '../../modules/notifier/notifier';
import AppUser from "../../modules/app-user/app-user";
import RememberMe from "../../modules/remember-me";
import Utils from "../../utils/utils";
import ApiAuth from "../../modules/api/auth";
import usePageTitle from "../../hooks/use-page-title";
import useSessionConfig from "../../hooks/use-session-config";
import Config from "../../config/config";
import { ROUTES } from "../../routes";
import FGInput from "../../components/dumb/FGInput";

type TLoginFormData = {
  email: string;
  password: string;
  rememberMe: boolean;
};

type TSubmitResult = { status: boolean; message: string; };

async function submit(formData: TLoginFormData, e): Promise<TSubmitResult> {
  if (e) {
    try {
      e.preventDefault();
    } catch (ignore) {}
  }

  if (formData.email === "" || formData.password === "") {
    return {
      status: false,
      message: "Email or password is required."
    };
  }

  const auth = await ApiAuth.login(formData.email, formData.password);

  if (!auth.status) {
    return {
      status: false,
      message: auth.message
    };
  }

  const userId = auth.data._id;
  delete auth.data._id;

  await AppUser.setInfo(Object.assign({
    lastUpdate: Date.now(),
    id: userId,
    logged: true,
    email: formData.email,
    token: auth.data.authToken,
    just_logged: true
  }, auth.data));

  if (formData.rememberMe) {
    RememberMe.store(formData.email, formData.password);
  } else {
    RememberMe.remove();
  }

  return {
    status: true,
    message: auth.message
  }
}

export default function PageLogin() {
  usePageTitle("Login");

  const sessConfig = useSessionConfig();
  const auth = useAuthUser();
  const navigate = useNavigate();
  const [formData, setFormData] = useState<TLoginFormData>({
    email: "",
    password: "",
    rememberMe: false
  });
  const [submitting, setSubmitting] = useState(false);
  const [rememberMeInitialized, setRememberMeInitialized] = useState(false);

  const handleChange = function (e) {
    const value = e.target.type === "checkbox" ? e.target.checked : e.target.value;
    setFormData(data => ({ ...data, [e.target.name]: value }));
  };

  const onSubmitted = function (result: TSubmitResult) {
    if (result.status) {
      Notifier.success(result.message);

      const previousPage = sessConfig.get("redirect", "");
      if (previousPage !== "") {
        sessConfig.remove("redirect");
      }

      setTimeout(() => {
        setSubmitting(false);
        navigate((previousPage === "" ? "/portal" : previousPage), { replace: true });
      }, 1000);
    } else {
      Notifier.error(result.message);
      setSubmitting(false);
    }
  };

  useEffect(() => {
    if (Config.IS_MOCKUP_MODE) return;

    if (!auth.isLoading && auth.user.logged) {
      navigate(ROUTES.PORTAL_DASHBOARD);
    }
  }, [auth]);

  // initialize remember me
  useEffect(() => {
    RememberMe.get().then(async (data) => {
      if (data.checked) {
        // This sleep is intentional to avoid browser's autocomplete stuff
        // overwriting the fields' value
        await Utils.sleep(350);
        setFormData({
          email: data.email,
          password: data.password,
          rememberMe: data.checked
        });
      }

      setRememberMeInitialized(true);
    });
  }, []);

  // if user unchecks remember me, then remove from storage
  useEffect(() => {
    if (rememberMeInitialized && !formData.rememberMe) {
      RememberMe.remove();
    }
  }, [rememberMeInitialized, formData])

  return <>

    <form className="form-default" onSubmit={(e) => {
      if (Config.IS_MOCKUP_MODE) {
        e.preventDefault();
        setSubmitting(true);
        localStorage.setItem("mockup_user", formData.email);
        AppUser.getInfo().then(user => {
          onSubmitted({
            status: true,
            message: `Logged as mockup user: ${user.role}`
          });
        });
        return;
      }

      setSubmitting(true);
      submit(formData, e).then(onSubmitted);
    }}>
      <h1 className="mb-4">Welcome Back</h1>

      <FGInput label="Email" name="email" autoFocus value={formData.email}
        placeholder="example@student.com"
        onChange={(e) => handleChange(e)}
        autoComplete="off"
      />

      <FGInput type="password" name="password" label="Password"
        value={formData.password}
        placeholder="******"
        onChange={(e) => handleChange(e)}
        autoComplete="off"
      />

      <div className="mb-3 form-check">
        <input type="checkbox" className="form-check-input" id="rememberMe" name="rememberMe" checked={formData.rememberMe} onChange={(e) => handleChange(e)}/>
        <label className="form-check-label" htmlFor="rememberMe">Remember me</label>
      </div>

      <p className="text-center">
        <button type="submit" className="btn btn-primary" disabled={submitting}>Log in</button>
      </p>

      <p className="text-center mb-0">
        Forgot password? {" "}
        <Link to={ROUTES.AUTH_FORGOT_PASSWORD}>Reset password</Link>.
      </p>
    </form>

  </>
}
