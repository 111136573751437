import Storage, { IStorageOptions } from './storage';
import { isPlainObject } from 'lodash-es';
import Encryption from '../encryption/encryption';

export default class StorageCrypt {

  static async set(key, value, options?: IStorageOptions) {
    try {
      await Storage.set(key, Encryption.encrypt(JSON.stringify({
        value: value
      })), options);
      return true;
    } catch (ignore) {
      return false;
    }
  }

  static async get(key, valueIfNotExists = undefined) {
    let data = await Storage.get(key);

    if (typeof data != 'undefined') {
      try {
        let parsed = JSON.parse(Encryption.decrypt(data));

        if (isPlainObject(parsed)) {
          return parsed.value;
        } else {
          return valueIfNotExists;
        }
      } catch (ignore) {
        return valueIfNotExists;
      }
    } else {
      return valueIfNotExists;
    }
  }

  static async clear() {
    await Storage.clear();
  }

  static async remove(key) {
    await Storage.remove(key);
  }

}
