import usePageTitle from "../../../hooks/use-page-title";
import { PortalContext, usePortalSettingSet } from "../../../context/portal-context";
import ROLE from '../../../modules/role';
import PageDashboardAdmin from './PageDashboardAdmin';
import PageDashboardStudent from './PageDashboardStudent';
import PageDashboardSPV from './PageDashboardSPV';
import PageDashboardIndustry from './PageDashboardIndustry';
import { useContext } from "react";
import AppUserContext from "../../../context/app-user-context";

export default function PageDashboard() {
  usePageTitle("Dashboard");
  usePortalSettingSet("label_header", "Home");
  usePortalSettingSet("is_homepage", true);

  const user = useContext(AppUserContext);

  return <main className="content-generic content-generic--fluid page-dashboard" data-comp="PageDashboard">

    {user.role === ROLE.ADMIN && <PageDashboardAdmin />}

    {user.role === ROLE.STUDENT && <PageDashboardStudent user={user} />}

    {user.role === ROLE.SUPERVISOR && <PageDashboardSPV user={user} />}

    {user.role === ROLE.INDUSTRY_SUPERVISOR && <PageDashboardIndustry user={user} />}

  </main>;
}
