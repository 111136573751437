import moment from "moment-timezone";

export default function DateTime(props: {
  date: string|number|Date;
  format?: string;
  className?: string;
}) {
  const date = moment(props.date).format(props.format ?? "MMM DD, YYYY HH:mm");
  const isInvalid = date.toLowerCase().indexOf("invalid") > -1;

  return <>
    {isInvalid && <span className={props.className ?? ""} data-date-invalid="true">-</span>}
    {!isInvalid && <span className={props.className ?? ""}>{date}</span>}
  </>;
}
