import axios from "axios";
import { apiUrl, getAuthHeader } from "./base";

export type JobPayload = {
  taskName: string;
  typeCronJob: string;
  stepSize: string;
  startDateTime: Date;
  beforeDays: string;
  description?: string;
};

export type UpdateJobPayload = {
  typeCronJob: string;
  stepSize: string;
  startDateTime: Date;
  beforeDays: string;
  description?: string;
};

const getJobs = async () => {
  let data: any[] = [];
  let error: string = ``;
  try {
    const targetUrl = `/apicronjobsetting`;
    const req = await axios.get(apiUrl(targetUrl), await getAuthHeader());
    const res = req.data;
    if (res.result === "OK") {
      data = res.data;
    } else {
      error = res.message;
    }
  } catch (exception) {
    error = exception.message;
  }
  return {
    data,
    error,
  };
};

const getUnregisteredJobs = async () => {
  let data: any[] = [];
  let error: string = ``;
  try {
    const targetUrl = `/apicronjobsetting/tasknames/unregistered`;
    const req = await axios.get(apiUrl(targetUrl), await getAuthHeader());
    const res = req.data;
    if (res.result === "OK") {
      data = res.data;
    } else {
      error = res.message;
    }
  } catch (exception) {
    error = exception.message;
  }
  return {
    data,
    error,
  };
};

const getJobById = async (id: string) => {
  let data: any = undefined;
  let error: string = ``;
  try {
    const url = `${apiUrl("/apicronjobsetting")}/${id}`;
    const req = await axios.get(url, await getAuthHeader());
    const res = req.data;
    if (res.result === "OK") {
      data = res.data;
    } else {
      error = res.message;
    }
  } catch (exception) {
    error = exception.message;
  }
  return {
    data,
    error,
  };
};

const getJobHistories = async (
  id: string,
  page: number = 1,
  perPage: number = 10
) => {
  let data: any[] = [];
  let error: string = ``;
  let totalRows: number = 0;
  try {
    const url = `${apiUrl(
      "/apicronjobhistory"
    )}/${page}/${perPage}?taskId=${id}`;
    const req = await axios.get(url, await getAuthHeader());
    const res = req.data;
    if (res.result === "OK") {
      data = res.data;
      totalRows = res.total;
    } else {
      error = res.message;
    }
  } catch (exception) {
    error = exception.message;
  }
  return {
    data,
    totalRows,
    error,
  };
};

const postJob = async (payload: JobPayload) => {
  let success: boolean = true;
  let error: string = ``;
  let data: any;
  try {
    const req = await axios.post(
      apiUrl("/apicronjobsetting"),
      payload,
      await getAuthHeader()
    );
    const res = req.data;
    if (res.result === "OK") {
      data = res.data;
    } else {
      success = false;
      error = res.message;
    }
  } catch (exception) {
    success = false;
    error = exception.message;
  }
  return {
    success,
    error,
    data,
  };
};

const updateJob = async (id: string, payload: UpdateJobPayload) => {
  let success: boolean = true;
  let error: string = ``;
  try {
    const url = `${apiUrl("/apicronjobsetting")}/${id}`;
    const req = await axios.patch(url, payload, await getAuthHeader());
    const res = req.data;
    if (res.result !== "OK") {
      success = false;
      error = res.message;
    }
  } catch (exception) {
    success = false;
    error = exception.message;
  }
  return {
    success,
    error,
  };
};

const updateJobStatus = async (id: string, status: string) => {
  let success: boolean = true;
  let data: any = undefined;
  let error: string = ``;
  try {
    const url = `${apiUrl("/apicronjobsetting")}/block/${id}`;
    const req = await axios.patch(url, { status }, await getAuthHeader());
    const res = req.data;
    if (res.result !== "OK") {
      success = false;
      error = res.message;
    } else {
      data = res.data;
    }
  } catch (exception) {
    success = false;
    error = exception.message;
  }
  return {
    success,
    data,
    error,
  };
};

const runJob = async (id: string) => {
  let success: boolean = true;
  let error: string = ``;
  try {
    const url = `${apiUrl("/apicronjobsetting")}/run/${id}`;
    const req = await axios.patch(url, {}, await getAuthHeader());
    const res = req.data;
    if (res.result !== "OK") {
      success = false;
      error = res.message;
    }
  } catch (exception) {
    success = false;
    error = exception.message;
  }
  return {
    success,
    error,
  };
};

const deleteJob = async (id: string) => {
  let success: boolean = true;
  let error: string = ``;
  try {
    const url = `${apiUrl("/apicronjobsetting")}/${id}`;
    const req = await axios.delete(url, await getAuthHeader());
    const res = req.data;
    if (res.result !== "OK") {
      success = false;
      error = res.message;
    }
  } catch (exception) {
    success = false;
    error = exception.message;
  }
  return {
    success,
    error,
  };
};

const ApiScheduleReminder = {
  getJobs,
  getUnregisteredJobs,
  getJobById,
  getJobHistories,
  postJob,
  updateJob,
  updateJobStatus,
  runJob,
  deleteJob,
};

export default ApiScheduleReminder;
