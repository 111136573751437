import React, { useEffect, useState } from "react";
import { Link, NavLink, Outlet } from "react-router-dom";
import SessionStore from "../../modules/session-store";
import { HiMenu } from 'react-icons/hi'
import { ROUTES } from "../../routes";
import classnames from "classnames";
import { MdBookmark } from "react-icons/md";

export type TNavItem = {
  title?: string;
  info?: string;
  url?: string;
  icon?: any;
  children?: TNavItem[];
  separator_top?: boolean;
  separator_bottom?: boolean;
  show?: boolean;
  /** no need to check role in order to show this nav item */
  nocheck?: boolean;
};

function getNavList(): TNavItem[] {
  return [
    {
      title: "Reference (Components)",
      info: "Click to open components reference page",
      url: ROUTES.REF_COMPONENT,
      icon: <MdBookmark />
    },
    {
      title: "Reference (Form)",
      info: "Click to open form reference page",
      url: ROUTES.REF_FORM,
      icon: <MdBookmark />
    },
    {
      title: "Reference (Typography) Test very long",
      info: "Click to open typography reference page",
      url: ROUTES.REF_TYPOGRAPHY,
      icon: <MdBookmark />
    },
  ];
}

type TRefNavProps = {
  expanded?: boolean;
};

function RefNav(props: TRefNavProps) {
  useEffect(() => {
    const element = document.querySelector(".active");
    element?.scrollIntoView({ block: "nearest", behavior: "smooth" });
  }, []);

  return <>
    {getNavList().map((item, i) => <React.Fragment key={i}>
      {item.separator_top && (
        <div className="menu-sep menu-sep--top"></div>
      )}

      {item.title && (
        <NavLink
          title={item.info}
          to={item.url}
          className={({ isActive }) => {
            return `menu-link ${
              isActive && item.url !== "" ? "active" : ""
            }`;
          }}
        >
          {item.icon}
          <span
            className={
              props.expanded
                ? "menu-link__name"
                : "menu-link__name--collapsed"
            }
          >
            {item.title}
          </span>
        </NavLink>
      )}

      {Array.isArray(item.children) &&
        item.children.map((childItem, j) => (
          <React.Fragment key={`${i}-${j}`}></React.Fragment>
        ))}

      {item.separator_bottom && (
        <div className="menu-sep menu-sep--bottom"></div>
      )}
    </React.Fragment>)}
  </>;
}

export default function PageReference(props: any) {
  const [sidebarExpanded, setSidebarExpanded] = useState(true);

  useEffect(() => {
    SessionStore.get("sidebarExpanded_Ref", sidebarExpanded).then((val) => {
      setSidebarExpanded(val)
    });
  }, []);

  return <>
    <div
      className={classnames([
        "portal-template",
        { "pt--expanded": sidebarExpanded }
      ])}
    >

      <div className={classnames([
        "pt__sidebar",
        { "pt__sidebar--collapsed": !sidebarExpanded }
      ])}>
        <div className="pt__sidebar-top">
          <div className="pt__logo">
            <Link to={ROUTES.PORTAL}>
              <img src="/assets/images/logo/logo.png" alt="logo" height={68} />
              <img src="/assets/images/logo/logo-square-small.png" alt="logo" height={68} />
            </Link>
          </div>
          <button className="toggle-btn"
            title={sidebarExpanded ? "Collapse menu" : "Expand menu"}
            onClick={() => {
              SessionStore.set("sidebarExpanded_Ref", !sidebarExpanded);
              setSidebarExpanded(!sidebarExpanded);
            }}
          >
            <HiMenu/>
          </button>
        </div>
        <div className="pt__sidebar-menu">
          <RefNav expanded={sidebarExpanded} />
        </div>
      </div>

      <div className="pt__content container-fluid">
        <Outlet/>
      </div>
    </div>
  </>;
}
