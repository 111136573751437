import { Generics } from "./generics";

export namespace Strings {

  export function removeInitialSlash(str: string = ''): string {
    return str.replace(/^\//, '');
  }

  /**
   * Usage:
   * interpolate('{subject} {verb} {object} {removed_automatically}', { subject: 'I', verb: 'eat', object: 'food!' });
   */
  export function interpolate(strings: string, data: {[key: string]: string|number|boolean}, trimNotFound = true): string {
    if (typeof strings != 'string') {
      return '';
    }

    let r = /\{([^{]+)\}/g;

    strings.match(r)?.forEach(item => {
      let key = item.replace(r, '$1');
      let value = data[key];
      let rReplace = new RegExp(Generics.escapeRegExp(item), 'g');

      if (typeof value != 'undefined') {
        strings = strings.replace(rReplace, value.toString());
      } else {
        if (trimNotFound) {
          strings = strings.replace(rReplace, '');
        }
      }
    });

    return strings;
  }

  export function trimString(str: any): string {
    let type = typeof str;

    if (type == 'string') {
      return str.trim();
    } else {
      var ret = '';

      switch (type) {
        case 'number':
        case 'boolean':
          ret = str.toString();
          break;
      }

      return ret;
    }
  }

  /**
   * @description
   * This function will map string based on provided arguments.
   *
   * @example
   * // Was returning "My name is Faisal, I live Indonesia, and my age is 22 year-old."
   * stringFormat('My name is %s, I live %s, and my age is %s year-old.', 'Faisal', 'Indonesia', 22);
   */
  export function stringFormat(): string {
    let ret = "";
    let aLength = arguments.length;
    let i = 1;

    if (aLength > 0) {
      ret = trimString(arguments[0]);

      for (; i < aLength; i += 1) {
        ret = ret.replace(/\%s/, trimString(arguments[i]));
      }
    }

    return ret;
  }

  /**
   * @description
   * This method will transform string to array, based on `splitChar` character(s).
   * Example:
   * stringToArray("a-b-c", "-"); // Will return ["a", "b", "c"]
   *
   * @param {string} inputString
   * @param {string} splitChar
   * @return {Array} splitted version
   */
  export function stringToArray(inputString, splitChar) {
    return trimString(inputString).split(
      new RegExp("\\s*" + Generics.escapeRegExp(splitChar) + "\\s*", "gim")
    );
  }

  export function trimExceedChars(text: string, max = 100, ellipsis = "...") {
    const len = text.length;

    if (len <= max) return text;
    return text.substring(0, max).trim() + ellipsis;
  }

  type TPluralizeOpts = {
    /** defaults to "s" */
    pluralSuffix: string;
  };

  export function pluralize(text: string, count: string|number = 0, opts?: TPluralizeOpts) {
    const options: TPluralizeOpts = {
      pluralSuffix: "s",
      ...opts
    };

    if (typeof count === "string") count = ~~count;

    return count > 1 ? `${text}${options.pluralSuffix}` : text;
  }

}
