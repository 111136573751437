import cookie from 'cookie';
import { Multilingual_EN } from "./dictionary/en";

// key is based on EN version
export type TDictionaryKey = keyof typeof Multilingual_EN;

const DICTIONARY: {[key: string ]: {[key: string]: string | number | any}} = {};

let hasInit = false;

async function prepareDictionary(langs: string[]) {
  langs.forEach(lang => {
    switch (lang) {
      case 'en':
        DICTIONARY[lang] = Multilingual_EN;
        break;
    }
  });
}

/**
 * @module Multilingual
 */
export default class Multilingual {

  static AVAILABLE_LANG = ['en'];
  static DEFAULT_LANG = 'en';

  static init() {
    if (hasInit) {
      return;
    }

    hasInit = true;

    prepareDictionary(this.AVAILABLE_LANG);
  }

  /**
   * Get value of specific key from dictionary based on provided `lang` and
   * `key` params. Param `ifNotFound` is optional and will be used as a fallback
   * when the value of that `key` does not exist on dictionary.
   */
  static get(key: TDictionaryKey, lang: string|undefined = "", ifNotFound: string = "") {
    if (this.AVAILABLE_LANG.indexOf(lang) === -1) {
      lang = this.DEFAULT_LANG;
    }

    let val = DICTIONARY?.[lang]?.[key];

    return typeof val !== 'undefined' ?  val :
      typeof ifNotFound === "undefined" || ifNotFound === ""
        ? key : ifNotFound;
  }

}

// @credit https://stackoverflow.com/a/20759804
function stringReplaceAll(str: string, find: string, replace: string) {
  return str.replace(new RegExp(find.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&'), 'g'), replace);
}

/**
 * This is utility function that comes with `Multilingual` module. The usage of
 * this is quite similar to C#'s `String.Format` method.
 *
 * @example
 * const TEXT_TEMPLATE = "Hello {0}, my name is {1}, I work at {2}. {2} is a good place to work.";
 *
 * // Will return "Hello there, my name is Faisal, I work at FXMedia. FXMedia is a good place to work."
 * stringFormat(TEXT_TEMPLATE, "there", "Faisal", "FXMedia")
 */
export function stringFormat(text: string, ...args: any[]) {
  const length = args.length;

  for (let i = 0, j = length; i < j; i += 1) {
    const val = args[i];
    text = stringReplaceAll(text, `{${i}}`, typeof val === "string" ? val : "");
  }

  return text;
}
