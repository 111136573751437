import { useEffect, useRef, useState } from "react";
import DataTable, { TableColumn, TableStyles } from "react-data-table-component";

export type TBasicDataTableProps = {
  data: any[];
  loading?: boolean;
  columns: TableColumn<any>[];
  mapper?: (items: any) => any;
  search?: string;
  searchDelay?: number;
  selectableRows?: boolean;
  onSelectedRowsChange?: (selected: {
    allSelected: boolean;
    selectedCount: number;
    selectedRows: any[];
  }) => void;
  customStyles?: TableStyles;
  /** This is the field's property name */
  defaultSortFieldId?: string;
  defaultSortAsc?: boolean;
  /** just create a state toggler (false/true) to refresh */
  refresh?: boolean;
  rowPerPage?: number;
  rowShowAll?: boolean;
};

BasicDataTable.defaultProps = {
  data: [],
  loading: false,
  rowPerPage: 10,
  rowShowAll: false
} as Partial<TBasicDataTableProps>;

/**
 * WIP documentation, currently don't have time to do this. 😁
 */
export default function BasicDataTable(props: TBasicDataTableProps) {
  const [items, setItems] = useState<any[]>([]);
  const [totalRows, setTotalRows] = useState(0);
  const [rowPerPage, setRowPerPage] = useState(props.rowPerPage);
  const mapper = props.mapper;

  useEffect(() => {
    setTotalRows(props.data.length);

    if (typeof mapper === "function") {
      setItems(props.data.map(mapper));
    } else {
      setItems(props.data);
    }
  }, [props.data]);

  return <div className="basic-rdt" data-comp="BasicDataTable">
    <DataTable
      progressPending={props.loading}
      data={items}
      paginationComponentOptions={{
        selectAllRowsItem: props.rowShowAll
      }}
      pagination
      paginationTotalRows={totalRows}
      paginationPerPage={rowPerPage}
      columns={props.columns}
      selectableRows={props.selectableRows}
      onSelectedRowsChange={props.onSelectedRowsChange}
      customStyles={props.customStyles}
      defaultSortFieldId={props.defaultSortFieldId}
      defaultSortAsc={props.defaultSortAsc}
    />
    {props.loading && <div className="ssd__loading">
      <div className="ssd__loading__text">Loading data...</div>
    </div>}
  </div>;
};

