import FGInput from '../../../components/dumb/FGInput';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../../routes';
import { CONST_SELECT_ROLES, CONST_SELECT_USER_STATUS, CONST_SELECT_YEAR, DEFAULT_SELECT_OPTION, USER_STATUS_ACTIVE } from '../../../config/constants';
import FGDropdownApi from '../../../components/form/FGDropdownApi';
import ApiGenericData from '../../../modules/api/generic-data';
import Debouncer from '../../../modules/debouncer/debouncer';
import ApiUser from '../../../modules/api/user';
import Notifier from '../../../modules/notifier/notifier';
import Utils from '../../../utils/utils';
import ROLE from '../../../modules/role';

export default function PageuserRegistManual() {
  const navigate = useNavigate();
  const [sending, setSending] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    role: ROLE.STUDENT,
    status: USER_STATUS_ACTIVE,
    group: "",
    industry: "",
    studentId: "",
    year: "",
    studyProgram: "",
    avatar: "",
    jobDesk: ""
  });
  const onChange = (e) => {
    const value = e.target.type === "checkbox" ? e.target.checked : e.target.value;
    setFormData((data) => ({ ...data, [e.target.name]: value }));
  };
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    Debouncer.execute("SUBMIT_DATA", async () => {
      setSending(true);
      const res = await ApiUser.add(formData);

      if (res.status) {
        Notifier.success(res.message);
        await Utils.sleep(2000);
        setSending(false);
        navigate(ROUTES.PORTAL_USERS);
      } else {
        setSending(false);
        Notifier.error(res.message);
      }
    });
  };

  return <div>
    <form className="form-register" onSubmit={handleSubmit}>
      <div className='row' data-comp="PageuserRegistManual">
        <div className='col-md-6'>
          <FGInput
            label="Name*"
            name="name"
            value={formData.name}
            onChange={onChange}
            required
            autoFocus
          />
        </div>
        <div className='col-md-6'>
          <FGInput
            label="Email*"
            name="email"
            type="email"
            value={formData.email}
            onChange={onChange}
            required
          />
        </div>
        <div className='col-md-6'>
          <FGInput
            label="Student ID"
            name="studentId"
            value={formData.studentId}
            onChange={onChange}
          />
        </div>
        <div className='col-md-6'>
          <FGInput
            label="Job title"
            name="jobDesk"
            value={formData.jobDesk}
            onChange={onChange}
          />
        </div>
        <div className="col-md-6">
          <FGDropdownApi
            label="Cohort"
            name="year"
            defaultValue={formData.year}
            api={ApiGenericData.selectQuery("Year", "year", { ...DEFAULT_SELECT_OPTION })}
            onChange={onChange}
          />
        </div>
        <div className="col-md-6">
          <FGDropdownApi
            label="Roles"
            name="role"
            options={CONST_SELECT_ROLES}
            defaultValue={formData.role}
            onChange={onChange}
          />
        </div>
        <div className="col-md-6">
          <FGDropdownApi
            label="Group"
            name="group"
            defaultValue={formData.group}
            api={ApiGenericData.selectQuery("Group", "group")}
            onChange={onChange}
            multiple
          />
        </div>
        <div className="col-md-6">
          <FGDropdownApi
            label="Company"
            name="industry"
            defaultValue={formData.industry}
            api={ApiGenericData.selectQuery("Industry", "industry", { ...DEFAULT_SELECT_OPTION })}
            onChange={onChange}
          />
        </div>
        <div className="col-md-6">
          <FGDropdownApi
            label="Programme"
            name="studyProgram"
            defaultValue={formData.studyProgram}
            api={ApiGenericData.selectQuery("StudyProgram", "programme", { ...DEFAULT_SELECT_OPTION })}
            onChange={onChange}
          />
        </div>
        <hr/>
        <div className="col-md-12">
          <FGDropdownApi
            variant="normal"
            label="Status"
            desc={`Set to "Inactive" if we'd like to disable this user.`}
            name="status"
            defaultValue={formData.status}
            options={CONST_SELECT_USER_STATUS}
            onChange={onChange}
          />
        </div>
      </div>
      <div>
        <button type="submit" className="btn btn-primary" disabled={sending}>
          Submit Data
        </button>
        {" "}
        <button type="button" className="btn btn-outline-primary"
          onClick={(e) => {
            e.preventDefault();
            if (window.history.length === 1) {
              navigate(ROUTES.PORTAL_DASHBOARD);
            } else {
              navigate(-1);
            }
          }}
        >
          Cancel
        </button>
      </div>
    </form>
  </div>;
}
